import React from "react";
import styles from "../FindOthers.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import StoryCard from "components/StoryCard/StoryCard";
import useMediaQuery from "hooks/useMediaQuery";

const SearchResults = ({
  searchResults,
  activeFilter,
  setActiveFilter,
  isLoading,
  searchError,
}) => {
  const isMobile = useMediaQuery("sm");
  const isExtraSmall = useMediaQuery("xs");

  return (
    <div
      className={`${styles.searchResults} ${
        isMobile ? styles.mobile : styles.desktop
      } ${isExtraSmall ? styles.extraSmall : ""}`}
    >
      <h2>Search Results ({searchResults.metadata?.totalResults || 0})</h2>

      <div className={styles.filterToggle}>
        <button
          className={`${styles.filterButton} ${
            activeFilter === "all" ? styles.active : ""
          }`}
          onClick={() => setActiveFilter("all")}
        >
          All
        </button>
        <button
          className={`${styles.filterButton} ${
            activeFilter === "experiences" ? styles.active : ""
          }`}
          onClick={() => setActiveFilter("experiences")}
        >
          Life Experiences ({searchResults.experiences.length})
        </button>
        <button
          className={`${styles.filterButton} ${
            activeFilter === "stories" ? styles.active : ""
          }`}
          onClick={() => setActiveFilter("stories")}
        >
          Stories ({searchResults.stories.length})
        </button>
      </div>

      {isLoading ? (
        <LoadingMessage message="Searching..." />
      ) : searchError ? (
        <p className={styles.errorMessage}>{searchError}</p>
      ) : searchResults.experiences.length > 0 ||
        searchResults.stories.length > 0 ? (
        <>
          {(activeFilter === "all" || activeFilter === "experiences") &&
            searchResults.experiences.length > 0 && (
              <div className={styles.resultsSection}>
                <h3>Life Experiences</h3>
                <div className={styles.resultsGrid}>
                  {searchResults.experiences.map((experience) => (
                    <LifeExperienceCard
                      key={experience._id}
                      lifeExpData={experience}
                    />
                  ))}
                </div>
              </div>
            )}

          {(activeFilter === "all" || activeFilter === "stories") &&
            searchResults.stories.length > 0 && (
              <div className={styles.resultsSection}>
                <h3>Stories</h3>
                <div className={styles.resultsGrid}>
                  {searchResults.stories.map((story) => (
                    <StoryCard key={story._id} story={story} />
                  ))}
                </div>
              </div>
            )}
        </>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;
