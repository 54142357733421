import React, { useEffect, useRef, useState } from "react";
import styles from "./LifeExperienceForm.module.css";

import DateSelector from "./DateSelector";
import StoryEditor from "./StoryEditor";

import ToggleButton from "components/Button/ToggleButton/ToggleButton";
import Button from "components/Button/Button";
import cn from "classnames";
import * as TAGS_API from "api/tags";

import _ from "lodash";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";

import TagInput from "components/TagInput/TagInput";

const LifeExperienceForm = ({
  lifeExperience,
  initialData,
  mode,
  onSave,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const [formData, setFormData] = useState({
    ...initialData,
    intents: initialData.intents || {
      venting: false,
      seeking_advice: false,
      offering_support: false,
      sharing_experience: true, // Default to true
    },
  });

  // Declare all refs at the top level
  const isInitialMount = useRef(true);
  const tagEditorRef = useRef(null);
  const storyEditorRef = useRef(null);
  const yearEditorRef = useRef(null);
  const prevStoryRef = useRef(initialData?.story || "");

  useEffect(() => {
    // Only update form data in these cases:
    // 1. On first mount
    // 2. When editing an existing experience (mode === "edit")
    if (isInitialMount.current) {
      setFormData(initialData);
      isInitialMount.current = false;
    } else if (mode === "edit" && initialData) {
      // This is an edit of an existing experience
      setFormData(initialData);
    }
  }, [initialData, mode]);

  const [errors, setErrors] = useState({
    tagError: "",
    storyError: "",
    yearError: "",
  });

  const handleTagChange = (tags) => {
    setFormData((prevData) => ({ ...prevData, tags }));
  };

  const handleStoryChange = (story) => {
    setFormData((prevData) => ({ ...prevData, story }));
  };

  const handleDateChange = (month, year) => {
    setFormData((prevData) => ({
      ...prevData,
      date: { month, year },
    }));
  };

  const handleToggleSearchable = () => {
    setFormData((prevData) => ({
      ...prevData,
      searchable: !prevData?.searchable,
    }));
  };

  const [recommendedTags, setRecommendedTags] = useState([]);
  const [isLoadingRecommendedTags, setIsLoadingRecommendedTags] =
    useState(false);

  useEffect(() => {
    const fetchRecommendedTags = async (storyContent) => {
      // Skip empty or very short content
      if (!storyContent || storyContent.trim().length < 10) {
        return;
      }

      // Skip if the trimmed content hasn't changed significantly
      const trimmedContent = storyContent.trim();
      const prevTrimmedContent = prevStoryRef.current.trim();

      // Only fetch if content changed by more than just whitespace
      // and has at least 3 new characters
      if (
        trimmedContent === prevTrimmedContent ||
        Math.abs(trimmedContent.length - prevTrimmedContent.length) < 3
      ) {
        return;
      }

      setIsLoadingRecommendedTags(true);
      prevStoryRef.current = storyContent;

      try {
        const data = await TAGS_API.suggestTags({
          text: trimmedContent, // Send trimmed content to API
          limit: 3,
        });
        setRecommendedTags(data.tags);
      } catch (err) {
        setRecommendedTags([]);
      } finally {
        setIsLoadingRecommendedTags(false);
      }
    };

    // Increase debounce time to 800ms
    const debouncedFetchRecommendedTags = _.debounce(fetchRecommendedTags, 800);

    if (formData?.story !== prevStoryRef.current) {
      debouncedFetchRecommendedTags(formData?.story);
    }

    return () => {
      debouncedFetchRecommendedTags.cancel();
    };
  }, [formData]);

  const validateForm = () => {
    const newErrors = {};
    let hasError = false;

    // Count words (split by whitespace and filter out empty strings)
    const wordCount =
      formData.story?.trim().split(/\s+/).filter(Boolean).length || 0;

    if (!formData.story || wordCount < 15) {
      newErrors.storyError = "Story must be at least 15 words";
      hasError = true;
      storyEditorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (wordCount > 25) {
      newErrors.storyError = "Story cannot exceed 25 words";
      hasError = true;
      storyEditorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    if (!formData.date?.year) {
      newErrors.yearError =
        "At least the year you experienced this is required";
      hasError = true;
      yearEditorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    setErrors(newErrors);

    // Clear errors after 5 seconds
    if (hasError) {
      setTimeout(() => {
        setErrors({});
      }, 5000);
    }

    return !hasError;
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(formData);
    }
  };

  const handleCreate = () => {
    if (validateForm()) {
      onCreate(formData);
    }
  };

  const handleUpdate = () => {
    if (validateForm()) {
      onUpdate(formData);
    }
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    onDelete();
    setShowDeleteConfirmation(false);
  };

  const handleIntentToggle = (intent) => {
    setFormData((prev) => ({
      ...prev,
      intents: {
        ...prev.intents,
        [intent]: !prev.intents[intent],
      },
    }));
  };

  const intentDescriptions = {
    venting: "Share your feelings and emotions without seeking specific advice",
    seeking_advice: "Get guidance and suggestions from others who understand",
    offering_support: "Help others by sharing what worked for you",
    sharing_experience:
      "Simply document your experience for others to relate to",
  };

  return (
    <div className={cn(styles.container)} data-tour="editor">
      <div className={cn(styles.section, errors.storyError && styles.hasError)}>
        <p className={styles.heading}>Write your story...</p>
        <StoryEditor
          className={cn(
            styles["story-editor"],
            errors.storyError && styles.errorInput
          )}
          ref={storyEditorRef}
          story={formData?.story}
          setStory={handleStoryChange}
        />
        {errors.storyError && (
          <div className={styles.errorHelper}>
            <span>⚠️</span>
            <span>{errors.storyError}</span>
          </div>
        )}
      </div>

      <div className={cn(styles.section, errors.yearError && styles.hasError)}>
        <div className={styles.headingWithTooltip}>
          <p className={styles.heading}>When did you experience this?</p>
          <InfoTooltip content="The timing of your experience helps us understand its context. Recent experiences might need immediate support, while past experiences can offer valuable perspective to others." />
        </div>
        <p className={styles.helper}>
          Understanding when this happened helps us connect you with others who
          can relate better to your current emotional state
        </p>
        <DateSelector
          selectedMonth={formData?.date?.month}
          setSelectedMonth={(month) =>
            handleDateChange(month, formData?.date?.year)
          }
          selectedYear={formData?.date?.year}
          setSelectedYear={(year) =>
            handleDateChange(formData?.date?.month, year)
          }
          className={cn(errors.yearError && styles.errorInput)}
        />
        {errors.yearError && (
          <div className={styles.errorHelper}>
            <span>⚠️</span>
            <span>{errors.yearError}</span>
          </div>
        )}
      </div>

      <div className={styles.intentSection} data-tour="intent-editor">
        <div className={styles.headingWithTooltip}>
          <p className={styles.heading}>
            What's your purpose for sharing this?
          </p>
          <InfoTooltip content="Your sharing purpose helps us connect you with others who can best respond to your needs, whether that's listening, advice, or mutual support" />
        </div>
        <p className={styles.helper}>
          Understanding your intention helps us recommend the right emotional
          match for your experience
        </p>
        <div className={styles.intentGrid}>
          {Object.entries(formData.intents || {}).map(([key, value]) => (
            <label key={key} className={styles.intentOption}>
              <div className={styles.intentCheckbox}>
                <input
                  type="checkbox"
                  checked={value}
                  onChange={() => handleIntentToggle(key)}
                  disabled={key === "sharing_experience"}
                />
                <span className={styles.checkmark}></span>
                <span className={styles.intentLabel}>
                  {key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                </span>
              </div>
              <p className={styles.intentDescription}>
                {intentDescriptions[key]}
              </p>
            </label>
          ))}
        </div>
      </div>

      <div className={styles.toggleSection}>
        <div className={styles.toggleRow}>
          <div className={styles.toggleLabel}>
            <span>Make this experience searchable</span>
            <p className={styles.toggleHint}>
              Searchable experiences can be found by other users when they
              search using similar text strings
            </p>
          </div>
          <InfoTooltip content="When enabled, your experience can be discovered by others through the search feature on the experiences page" />
          <ToggleButton
            isOn={formData?.searchable}
            handleToggle={handleToggleSearchable}
          />
        </div>
      </div>

      <div className={styles.tagSection}>
        <div className={styles.headingWithTooltip}>
          <p className={styles.heading}>Choose tags (optional)</p>
          <InfoTooltip content="Tags help others find similar experiences, but they're optional. Your story is valuable even without tags." />
        </div>
        <p className={styles.helper}>
          Tags help connect your experience with others who have similar stories
        </p>
        <TagInput
          selectedTags={formData?.tags || []}
          setSelectedTags={handleTagChange}
          className={styles.tagInput}
          recommendedTags={recommendedTags}
          theme="light"
        />
      </div>

      <div className={styles.actionMenu}>
        {mode === "register" && <Button onClick={handleSave}>Save</Button>}
        {mode === "new" && <Button onClick={handleCreate}>Create</Button>}
        {mode === "edit" && (
          <>
            <Button onClick={handleDeleteClick} color="error">
              Delete
            </Button>
            <Button onClick={handleUpdate}>Update</Button>
          </>
        )}
      </div>
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteConfirm}
          itemName="life experience"
        />
      )}
    </div>
  );
};

export default LifeExperienceForm;
