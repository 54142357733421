import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import styles from "./StatusBanner.module.css";

/**
 * A banner that displays the user's account status and registration progress
 * with appropriate actions
 */
const StatusBanner = () => {
  const { userData } = useUserContext();
  const navigate = useNavigate();

  if (!userData) return null;

  // Handle registration flow first (highest priority)
  if (!userData.isRegistered) {
    return (
      <div className={`${styles.banner} ${styles.registrationBanner}`}>
        <p>
          Complete your registration to access all features.{" "}
          <Link to="/register" className={styles.actionLink}>
            Complete Registration
          </Link>
        </p>
      </div>
    );
  }

  // Then handle email verification
  if (!userData.isEmailVerified) {
    return (
      <div className={`${styles.banner} ${styles.verificationBanner}`}>
        <p>
          Please verify your email address to continue.{" "}
          <Link to="/verify-email" className={styles.actionLink}>
            Resend Verification Email
          </Link>
        </p>
      </div>
    );
  }

  // Then handle admin approval if applicable
  if (userData.isAdminApproved === false) {
    return (
      <div className={`${styles.banner} ${styles.approvalBanner}`}>
        <p>
          Your account is pending admin approval. Some features may be limited.
        </p>
      </div>
    );
  }

  // Then handle account status issues
  switch (userData.status) {
    case "suspended":
      return (
        <div className={`${styles.banner} ${styles.suspendedBanner}`}>
          <p>
            Your account is temporarily suspended.{" "}
            <Link to="/profile" className={styles.actionLink}>
              View Details
            </Link>
          </p>
        </div>
      );

    case "banned":
      return (
        <div className={`${styles.banner} ${styles.bannedBanner}`}>
          <p>
            Your account has been banned.{" "}
            <Link to="/profile" className={styles.actionLink}>
              View Details
            </Link>
          </p>
        </div>
      );

    case "deleted":
      return (
        <div className={`${styles.banner} ${styles.deletedBanner}`}>
          <p>
            Your account has been deleted.{" "}
            <Link to="/profile" className={styles.actionLink}>
              View Details
            </Link>
          </p>
        </div>
      );

    default:
      return null;
  }
};

export default StatusBanner;
