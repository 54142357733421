import React from "react";
import styles from "./ChatStatusBar.module.css";
import {
  HiOutlineClock,
  HiOutlineInformationCircle,
  HiOutlineShieldExclamation,
} from "react-icons/hi";
import cn from "classnames";
import { getExpiryTimeLeft } from "utils/dates";
import {
  useFloating,
  useHover,
  useInteractions,
  offset,
  flip,
  shift,
  autoUpdate,
} from "@floating-ui/react";

const ChatStatusBar = ({
  data,
  type, // 'chat', 'match', or 'reachout'
  onGoToChat,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isTooltipOpen,
    onOpenChange: setIsTooltipOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    move: false,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  // Render blocked status banner if the interaction is blocked
  const renderBlockedStatus = () => {
    if (!data?.isBlocked) return null;

    return (
      <div className={styles.blockedStatus}>
        <HiOutlineShieldExclamation />
        <span>
          This {type} is blocked. You can unblock it from the side menu.
        </span>
      </div>
    );
  };

  const renderExpiryInfo = () => {
    if (!data?.metadata?.expiresAt || data?.status !== "pending") return null;

    const { text, urgency } = getExpiryTimeLeft(data.metadata.expiresAt);
    const tooltipContent =
      type === "match" ? (
        <div className={styles.tooltipContent}>
          <p>This is a temporary match that will expire soon!</p>
          <ul>
            <li>Think of this as an "airlock" to get to know each other</li>
            <li>
              If you enjoy the conversation, click "Accept Match" to reveal
              usernames
            </li>
            <li>Accepting adds this match as a connection</li>
          </ul>
        </div>
      ) : (
        <div className={styles.tooltipContent}>
          <p>This is a temporary reachout that will expire soon!</p>
          <ul>
            <li>Think of this as an "airlock" to get to know each other</li>
            <li>
              If you enjoy the conversation, click "Accept" to reveal usernames
            </li>
            <li>Accepting adds this reachout as a connection</li>
          </ul>
        </div>
      );

    return (
      <div className={styles.expirySection}>
        <div className={cn(styles.expiryInfo, styles[urgency])}>
          <HiOutlineClock />
          <span>{text}</span>
          <div
            ref={refs.setReference}
            className={styles.infoIconWrapper}
            {...getReferenceProps()}
          >
            <HiOutlineInformationCircle className={styles.infoIcon} />
          </div>
          {isTooltipOpen && (
            <div
              ref={refs.setFloating}
              className={styles.tooltip}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {tooltipContent}
            </div>
          )}
        </div>
        <div className={styles.expiryHint}>
          Accept {type} to continue chatting after expiry
        </div>
      </div>
    );
  };

  const renderSpecialStatus = () => {
    const statusClasses = cn(styles.statusMessage, {
      [styles.accepted]:
        data?.status === "accepted" || data?.status === "completed",
      [styles.expired]: data?.status === "expired",
      [styles.rejected]: data?.status === "rejected",
      [styles.canceled]: data?.status === "canceled",
    });

    switch (data?.status) {
      case "accepted":
      case "completed":
        return (
          <div className={statusClasses}>
            <span>This {type} has been completed.</span>{" "}
            <span className={styles.link} onClick={onGoToChat}>
              Go to chat
            </span>
          </div>
        );
      case "expired":
        return (
          <div className={statusClasses}>
            This {type} has expired. You can no longer accept it.
          </div>
        );
      case "rejected":
        return (
          <div className={statusClasses}>This {type} has been rejected.</div>
        );
      case "canceled":
        return (
          <div className={statusClasses}>This {type} has been canceled.</div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.statusBar}>
      {renderBlockedStatus()}
      {renderExpiryInfo()}
      {renderSpecialStatus()}
    </div>
  );
};

export default ChatStatusBar;
