import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchAllBadges } from "../api/badges";
import { useUserContext } from "./UserContext";

const BadgeContext = createContext({
  badges: [],
  loading: true,
  error: null,
  getUserBadges: () => [],
  hasUserEarnedBadge: () => false,
});

export const BadgeProvider = ({ children }) => {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userData } = useUserContext();

  useEffect(() => {
    const loadBadges = async () => {
      try {
        setLoading(true);
        const badgeData = await fetchAllBadges();
        setBadges(badgeData);
      } catch (err) {
        console.error("Error loading badges:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadBadges();
  }, []);

  /**
   * Gets badges for the current user from UserContext
   * @returns {Array} Array of user badges with full badge data
   */
  const getCurrentUserBadges = () => {
    if (!userData || !userData.badges || !badges.length) return [];

    // Map user badges to full badge objects
    return userData.badges.map((userBadge) => {
      // Handle both populated and unpopulated badge references
      const badgeId =
        typeof userBadge.badge === "object"
          ? userBadge.badge._id.toString()
          : userBadge.badge.toString();
      const badgeData = badges.find((b) => b._id.toString() === badgeId);

      return {
        ...userBadge,
        badgeData,
      };
    });
  };

  /**
   * Checks if the current user has earned a specific badge
   * @param {string} badgeKey - The key of the badge to check
   * @returns {boolean} True if the user has earned the badge
   */
  const hasCurrentUserEarnedBadge = (badgeKey) => {
    if (!userData || !userData.badges || !badges.length) return false;

    // Find the badge by key
    const badge = badges.find((b) => b.key === badgeKey);
    if (!badge) return false;

    // Check if user has this badge
    return userData.badges.some(
      (userBadge) => userBadge.badge.toString() === badge._id.toString()
    );
  };

  /**
   * Gets badges for any user (including the current user)
   * @param {Array} userBadges - Array of user badge objects
   * @returns {Array} Array of user badges with full badge data
   */
  const getUserBadges = (userBadges) => {
    if (!userBadges || !badges.length) return [];

    // Map user badges to full badge objects
    return userBadges.map((userBadge) => {
      // Handle both populated and unpopulated badge references
      const badgeId =
        typeof userBadge.badge === "object"
          ? userBadge.badge._id.toString()
          : userBadge.badge.toString();
      const badgeData = badges.find((b) => b._id.toString() === badgeId);

      return {
        ...userBadge,
        badgeData,
      };
    });
  };

  /**
   * Checks if a user has earned a specific badge
   * @param {Array} userBadges - Array of user badge objects
   * @param {string} badgeKey - The key of the badge to check
   * @returns {boolean} True if the user has earned the badge
   */
  const hasUserEarnedBadge = (userBadges, badgeKey) => {
    if (!userBadges || !badges.length) return false;

    // Find the badge by key
    const badge = badges.find((b) => b.key === badgeKey);
    if (!badge) return false;

    // Check if user has this badge
    return userBadges.some(
      (userBadge) => userBadge.badge.toString() === badge._id.toString()
    );
  };

  return (
    <BadgeContext.Provider
      value={{
        badges,
        loading,
        error,
        getUserBadges,
        hasUserEarnedBadge,
        // New methods for current user
        getCurrentUserBadges,
        hasCurrentUserEarnedBadge,
      }}
    >
      {children}
    </BadgeContext.Provider>
  );
};

export const useBadges = () => {
  const context = useContext(BadgeContext);
  if (context === undefined) {
    console.error("useBadges must be used within a BadgeProvider");
  }
  return context;
};
