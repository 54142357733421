import React, { useState } from "react";
import styles from "./ReviewModal.module.css";
import cn from "classnames";

const StarRating = ({ rating, setRating, totalStars = 5, disabled, view }) => {
  const [hoverRating, setHoverRating] = useState(0);

  if (!setRating || disabled) {
    return (
      <div className={styles.starRating}>
        {[...Array(totalStars)].map((_, index) => (
          <span
            key={index}
            className={cn(styles.star, {
              [styles.filled]: index + 1 <= rating,
            })}
          >
            ★
          </span>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.starRating}>
      {[...Array(totalStars)].map((_, index) => (
        <span
          key={index}
          className={cn(styles.star, {
            [styles.filled]: index + 1 <= (hoverRating || rating),
            [styles.hover]: index + 1 <= hoverRating,
          })}
          onClick={() => setRating(index + 1)}
          onMouseEnter={() => setHoverRating(index + 1)}
          onMouseLeave={() => setHoverRating(0)}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default StarRating;
