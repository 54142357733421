import { useState, useEffect } from "react";
import * as REVIEWS_API from "api/reviews";
import { useToastContext } from "contexts/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchInteractionReviews } from "store/actions";
import { selectReviewsByInteractionId } from "store/selectors";

export const useReview = (
  interactionType,
  interactionId,
  otherParticipantId
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { addToast } = useToastContext();
  const dispatch = useDispatch();

  // Get reviews from Redux store
  const reviews = useSelector((state) =>
    selectReviewsByInteractionId(state, interactionId)
  );

  // Load reviews on hook initialization
  useEffect(() => {
    if (interactionId && otherParticipantId) {
      dispatch(fetchInteractionReviews(interactionType, interactionId));
    }
  }, [interactionId, interactionType, otherParticipantId, dispatch]);

  const submitReview = async ({ trustScore, warmthScore, comments }) => {
    if (!otherParticipantId) {
      const error = "Cannot submit review: participant data not loaded";
      setError(error);
      addToast(error, "error");
      return false;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      await REVIEWS_API.submitReview({
        interactionType: interactionType.toLowerCase(),
        interactionId,
        subjectId: otherParticipantId,
        trustScore,
        warmthScore,
        comments: comments.trim(),
      });

      await dispatch(fetchInteractionReviews(interactionType, interactionId));

      addToast("Review submitted successfully", "success");
      return true;
    } catch (err) {
      // Extract error code and message from response
      const errorCode = err.response?.data?.error;
      const errorMessage =
        err.response?.data?.message || err.message || "Failed to submit review";

      setError(errorCode || errorMessage);
      addToast(errorMessage, "error");
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    reviews,
    submitReview,
    isSubmitting,
    error,
  };
};
