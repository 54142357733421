import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "./FAQSection.module.css";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className={styles.faqItem}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <button
        className={`${styles.faqQuestion} ${isOpen ? styles.open : ""}`}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {question}
        <span className={styles.arrow}>{isOpen ? "−" : "+"}</span>
      </button>
      <motion.div
        className={styles.faqAnswer}
        initial={false}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className={styles.faqAnswerContent}>{answer}</div>
      </motion.div>
    </motion.div>
  );
};

const FAQSection = () => {
  const faqItems = [
    {
      question: "How does Stumble protect my privacy?",
      answer:
        "Your privacy is our top priority. We use pseudonyms by default, so you can share authentically without revealing your identity. You control what personal information you share and when. All communications in the 'airlock' phase are temporary and can be deleted. We never sell your data or use it for advertising.",
    },
    {
      question: "What makes Stumble different from other social platforms?",
      answer:
        "Unlike traditional social media, Stumble connects people based on shared life experiences rather than existing social circles. We focus on meaningful one-to-one connections rather than broadcasting to many. Our pseudonymity approach lets you be authentic without the pressure of maintaining a public profile or persona.",
    },
    {
      question:
        "I'm going through something difficult. How will Stumble help me?",
      answer:
        "Stumble helps you find others who truly understand your specific situation because they've lived through it themselves. Whether you're dealing with grief, illness, life transitions, or other challenges, connecting with someone who's been there can provide validation, perspective, and emotional support that even well-meaning friends and family sometimes can't offer.",
    },
    {
      question: "How does the matching process work?",
      answer:
        "Our AI analyzes the experiences you share to understand their emotional context and key themes. It then identifies others with similar experiences. You can also browse stories and reach out directly to people whose experiences resonate with you. All connections start in a temporary 'airlock' chat where you can get to know each other before deciding to form a permanent connection.",
    },
    {
      question: "What are Trust and Warmth scores?",
      answer:
        "After meaningful conversations, you can rate each other on Trust (reliability, honesty) and Warmth (empathy, supportiveness). These scores help our community identify supportive members. They're visible only to other users, not publicly, and help maintain a positive environment while preserving pseudonymity.",
    },
    {
      question: "Can I delete my data if I want to leave?",
      answer:
        "Absolutely. You can delete your account at any time, which permanently removes all your data from our systems. We also provide options to delete individual stories, experiences, and messages. Your privacy and control over your data are fundamental to how we've built Stumble.",
    },
    {
      question: "Is Stumble a therapy replacement?",
      answer:
        "No. While connecting with others who share your experiences can be incredibly healing, Stumble is not a substitute for professional mental health care. We encourage users who need clinical support to work with qualified therapists or counselors. Stumble works best as a complement to professional care or for those seeking peer support.",
    },
    {
      question: "What if I'm matched with someone inappropriate?",
      answer:
        "Your safety is important to us. You can end any conversation at any time. We have reporting tools for inappropriate behavior, and our Trust and Warmth scores help identify supportive community members. All connections start in the temporary 'airlock' phase, giving you time to assess if you want to continue the relationship.",
    },
  ];

  return (
    <motion.section
      className={styles.faqSection}
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8 }}
      id="faq"
    >
      <div className={styles.sectionHeader}>
        <motion.h2
          className={styles.sectionTitle}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Frequently Asked Questions
        </motion.h2>
        <motion.p
          className={styles.sectionSubtitle}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.1 }}
        >
          We're here to help you connect safely
        </motion.p>
      </div>

      <div className={styles.faqContainer}>
        {faqItems.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>

      <div className={styles.moreQuestions}>
        <p>Still have questions? We're happy to help.</p>
        <a href="mailto:support@stumble.chat" className={styles.supportLink}>
          Contact Support
        </a>
      </div>
    </motion.section>
  );
};

export default FAQSection;
