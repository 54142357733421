import React from "react";
import styles from "./ChatSideMenu.module.css";
import { Link } from "react-router-dom";

const ReachoutDetails = ({ data, userData }) => {
  if (!data?.resource) return null;

  const { resource, message, createdAt, isSender } = data;

  return (
    <div className={styles.sideMenuSection}>
      <p className={styles.sectionTitle}>
        {isSender ? "You" : "They"} resonated with:
      </p>
      <div className={styles.resourceCard}>
        <div className={styles.resourceHeader}>
          <span className={styles.resourceType}>
            {resource.type === "Story" ? "Story" : "Life Experience"}
          </span>
        </div>

        <div className={styles.resourceContent}>
          {resource.type === "Story" ? (
            <Link
              to={`/stories/${resource._id}`}
              className={styles.resourceLink}
            >
              {resource.label}
            </Link>
          ) : (
            <p className={styles.resourceLabel}>{resource.label}</p>
          )}
        </div>

        {resource.tags?.length > 0 && (
          <div className={styles.resourceTags}>
            <span className={styles.tagsLabel}>Tags:</span>
            <div className={styles.tagsList}>
              {resource.tags.map((tag) => (
                <span key={tag._id} className={styles.tag}>
                  {tag.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className={styles.reachoutMessage}>
        <p className={styles.messageLabel}>Message:</p>
        <p className={styles.message}>"{message}"</p>
        <span className={styles.timestamp}>
          {new Date(createdAt).toLocaleDateString()}
        </span>
      </div>
    </div>
  );
};

export default ReachoutDetails;
