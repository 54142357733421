import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as API from "api/stories"; // Assume you have this API function
import useStoryCRUD from "hooks/useStoryCRUD";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import styles from "./StoryDetail.module.css";
import { UserContext } from "contexts/UserContext";
import { FaEllipsisH, FaHeart } from "react-icons/fa";
import { formatRelativeTime } from "utils/dates";
import { PiHandHeart } from "react-icons/pi";

import ReachOutButton from "components/Button/ReachOutButton/ReachOutButton"; // Import ReachOutButton
import ReachOutDialog from "components/ReachOutDialog/ReachOutDialog"; // Import ReachOutDialog
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu"; // Import ReusableActionMenu
import * as typedefs from "../../typedefs";

import { GiBilledCap } from "react-icons/gi"; // Import the anonymous icon
import ErrorDisplay from "components/ErrorDisplay/ErrorDisplay"; // Import ErrorDisplay

import ReportModal from "components/ReportModal/ReportModal";
import * as REPORTS_API from "api/reports";
import useStoryHug from "hooks/useStoryHug";
import cn from "classnames";
import UserInfoDialog from "components/UserInfoDialog/UserInfoDialog";

/**
 *
 * @typedef {typedefs.StoryData} StoryData
 */

const StoryHeader = ({ title, options }) => (
  <>
    <div className={styles.topRow}>
      <ReusableActionMenu options={options} icon={FaEllipsisH} theme="dark" />
    </div>
    <h1 className={styles.title}>{title}</h1>
  </>
);

const AuthorInfo = ({ author, pseudonym, onAuthorClick }) => {
  const isAnonymous = author?.username === "anonymous";

  return (
    <div
      className={styles.author}
      onClick={onAuthorClick}
      role="button"
      tabIndex={0}
    >
      {isAnonymous ? (
        <>
          <GiBilledCap className={styles.anonymousIcon} />
          <span>{pseudonym}</span>
        </>
      ) : (
        author.username
      )}
    </div>
  );
};

const TagsList = ({ tags }) => (
  <div className={styles.tags}>
    {tags.map((tag) => (
      <span key={tag._id} className={styles.tagLabel}>
        {tag.name}
      </span>
    ))}
  </div>
);

const StoryMetadata = ({
  author,
  pseudonym,
  createdAt,
  tags,
  onAuthorClick,
}) => (
  <div className={styles.metadata}>
    <AuthorInfo
      author={author}
      pseudonym={pseudonym}
      onAuthorClick={onAuthorClick}
    />
    <div className={styles.date}>{formatRelativeTime(createdAt)}</div>
    <TagsList tags={tags} />
  </div>
);

const ActionButton = ({
  icon: Icon,
  label,
  onClick,
  isActive,
  count,
  showLabel = true,
}) => (
  <button
    className={cn(styles.actionButton, { [styles.active]: isActive })}
    onClick={onClick}
    aria-label={label}
  >
    <Icon className={styles.actionIcon} />
    {count !== undefined && <span className={styles.actionCount}>{count}</span>}
    {showLabel && <span className={styles.actionLabel}>{label}</span>}
  </button>
);

const StoryActions = ({
  isHugged,
  hugsCount,
  onHugClick,
  isReachedOut,
  onReachOutClick,
  showReachOut,
}) => (
  <div className={styles.actionsSection}>
    <div className={styles.actionButtons}>
      <ActionButton
        icon={FaHeart}
        label={isHugged ? "Remove Hug" : "Give Hug"}
        onClick={onHugClick}
        isActive={isHugged}
        count={hugsCount}
        showLabel={false}
      />
      {showReachOut && (
        <ActionButton
          icon={PiHandHeart}
          label={isReachedOut ? "Message Sent" : "Reach Out"}
          onClick={onReachOutClick}
          isActive={isReachedOut}
        />
      )}
    </div>
  </div>
);

const StoryBody = ({ content }) => (
  <div className={styles.body} dangerouslySetInnerHTML={{ __html: content }} />
);

function StoryDetail() {
  const { userData } = useContext(UserContext);
  const { storyId } = useParams();
  const navigate = useNavigate();
  const [story, setStory] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isReachedOut, setIsReachedOut] = useState(false);
  const [isReachOutDialogOpen, setIsReachOutDialogOpen] = useState(false);
  const { deleteStory, status, statusMessage } = useStoryCRUD();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const { isHugged, hugsLength, handleHugClick } = useStoryHug(story, userData);
  const [showUserInfo, setShowUserInfo] = useState(false);

  useEffect(() => {
    const loadStory = async () => {
      setIsLoading(true);
      try {
        const fetchedStory = await API.fetchStoryById(storyId);
        setStory(fetchedStory);
        setError("");
      } catch (error) {
        console.error("Failed to load story:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadStory();
  }, [storyId]);

  const isAuthor = userData?._id === story?.author?._id;

  const displayedName = useMemo(() => {
    if (!story) return ""; // Return an empty string or a placeholder if story is not loaded
    if (isAuthor) return story.author?.username;
    if (story.isConnected) return story.author?.username;
    if (story.author?.username === "anonymous") return story.authorPseudonym;
    return story.author?.username;
  }, [story, isAuthor]);

  const isHuggedByUser = useCallback(
    (story) => {
      if (!userData || !story.hugs) {
        return false;
      }
      return story.hugs.includes(userData._id);
    },
    [userData]
  );

  useEffect(() => {
    if (story && userData) {
      const isHugged = isHuggedByUser(story);
      setIsReachedOut(story.isReachedOut);
    }
  }, [story, userData, isHuggedByUser]);

  const handleReachOutClick = () => {
    setIsReachOutDialogOpen(true);
  };

  const handleReport = async (reportData) => {
    return REPORTS_API.reportStory({
      targetId: story._id,
      ...reportData,
    });
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <LoadingMessage message="Loading story details..." />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <ErrorDisplay message={error} onRetry={() => window.location.reload()} />
    );
  }

  if (!story) {
    return (
      <ErrorDisplay message="Story not found." onRetry={() => navigate("/")} />
    );
  }

  const handleDeleteStory = async () => {
    try {
      await deleteStory(story._id);
    } catch (error) {
      console.error("Error deleting story:", error);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    await handleDeleteStory();
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const authorOptions = [
    { label: "Edit", onClick: () => navigate(`/stories/${story._id}/edit`) },
    { label: "Hide", onClick: () => {} },
    { label: "Delete", onClick: handleDeleteClick },
  ];

  const nonAuthorOptions = [
    { label: "Save", onClick: () => {} },
    {
      label: "Report",
      onClick: () => setIsReportModalOpen(true),
      className: styles.reportOption,
    },
  ];

  const dropdownOptions = isAuthor ? authorOptions : nonAuthorOptions;

  const authorData = {
    ...story.author,
    pseudonym: story.authorPseudonym,
  };

  const handleReachOut = () => {
    if (isReachedOut) return;
    setIsReachOutDialogOpen(true);
  };

  const showReachOut = userData?._id !== story?.author?._id;

  return (
    <div className={styles.container}>
      <StoryHeader title={story.title} options={dropdownOptions} />

      <StoryMetadata
        author={story.author}
        pseudonym={story.authorPseudonym}
        createdAt={story.createdAt}
        tags={story.tags}
        onAuthorClick={() => setShowUserInfo(true)}
      />

      <StoryActions
        isHugged={isHugged}
        hugsCount={hugsLength}
        onHugClick={handleHugClick}
        isReachedOut={isReachedOut}
        onReachOutClick={handleReachOut}
        showReachOut={showReachOut}
      />

      <StoryBody content={story.body} />

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        itemName="story"
        status={status === "deleting" ? "loading" : status}
        statusMessage={statusMessage}
      />
      <ReportModal
        targetType="Story"
        targetId={story?._id}
        targetData={story}
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        onSubmit={handleReport}
      />
      <UserInfoDialog
        data={authorData}
        open={showUserInfo}
        onOpenChange={setShowUserInfo}
      />
      <ReachOutDialog
        open={isReachOutDialogOpen}
        onOpenChange={setIsReachOutDialogOpen}
        onSuccess={() => setIsReachedOut(true)}
        type="Story"
        resource={story}
      />
    </div>
  );
}

export default StoryDetail;
