import { useContext } from "react";
import { SocketContext } from "../contexts/SocketContext";

export const useServerStats = () => {
  const { serverStats, serverOnline } = useContext(SocketContext);

  return {
    onlineCount: serverStats.onlineCount,
    searchingCount: serverStats.searchingCount,
    isOffline: !serverOnline || serverStats.status === "disconnected",
  };
};
