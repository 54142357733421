// packages/client/src/hooks/useMultistepForm.js
import { registerUser } from "api/users";
import { useState, useCallback } from "react";

export function useMultistepForm(steps) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [stepValidation, setStepValidation] = useState(
    Array(steps.length).fill(false)
  );

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  // Update validation status for the current step
  const updateStepValidation = useCallback(
    (isValid) => {
      setStepValidation((prev) => {
        const newValidation = [...prev];
        newValidation[currentStepIndex] = isValid;
        return newValidation;
      });
    },
    [currentStepIndex]
  );

  // Check if we can navigate to a specific step
  const canNavigateTo = useCallback(
    (targetIndex) => {
      // Can always go back
      if (targetIndex < currentStepIndex) return true;

      // Can only go forward if all previous steps are valid
      for (let i = 0; i < targetIndex; i++) {
        if (!stepValidation[i]) return false;
      }
      return true;
    },
    [currentStepIndex, stepValidation]
  );

  function next() {
    if (currentStepIndex >= steps.length - 1) return;
    if (canNavigateTo(currentStepIndex + 1)) {
      setCurrentStepIndex((i) => i + 1);
    }
  }

  function back() {
    if (currentStepIndex <= 0) return;
    setCurrentStepIndex((i) => i - 1);
  }

  function goTo(index) {
    if (index < 0 || index >= steps.length) return;
    if (canNavigateTo(index)) {
      setCurrentStepIndex(index);
    }
  }

  async function submit(formData) {
    try {
      const response = await registerUser(formData);
      return response;
    } catch (error) {
      console.error("Error during registration:", error);
      throw error;
    }
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep,
    isLastStep,
    goTo,
    next,
    back,
    submit,
    updateStepValidation,
    stepValidation,
  };
}
