import React, { useState, useRef, useEffect, useCallback } from "react";
import useTagsSearch from "../../../../hooks/useTagsSearch";
import styles from "./TagSelector.module.css";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";

const TagSelector = ({
  selectedTags = [],
  onChange,
  placeholder,
  className,
  maxTags = 3,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const { tags, loading, hasMore } = useTagsSearch(searchTerm, page);

  // Floating UI setup
  const { refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(3),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const handleClickOutside = useCallback(
    (event) => {
      if (
        refs.floating.current &&
        !refs.floating.current.contains(event.target) &&
        refs.reference.current &&
        !refs.reference.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    },
    [refs]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const handleScroll = useCallback(() => {
    const dropdown = dropdownRef.current;
    if (!dropdown || loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = dropdown;
    if (scrollHeight - scrollTop <= clientHeight + 1) {
      setPage((prev) => prev + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    if (dropdown) {
      dropdown.addEventListener("scroll", handleScroll);
      return () => dropdown.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const filteredTags = tags.filter(
    (tag) => !selectedTags.some((selected) => selected._id === tag._id)
  );

  const handleTagSelect = (tag) => {
    if (selectedTags.length < maxTags) {
      onChange([...selectedTags, tag]);
      setSearchTerm("");
      setHighlightedIndex(0);
      inputRef.current?.focus();
    }
  };

  const handleTagRemove = (tagId) => {
    onChange(selectedTags.filter((tag) => tag._id !== tagId));
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.selectedTags}>
        {selectedTags.map((tag) => (
          <button
            key={tag._id}
            onClick={() => handleTagRemove(tag._id)}
            className={styles.optionBadge}
          >
            {tag.name}
            <span className={styles.removeBtn}>&times;</span>
          </button>
        ))}
      </div>

      <div ref={refs.setReference} className={styles.inputContainer}>
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setIsOpen(true);
            setPage(1);
          }}
          onFocus={() => setIsOpen(true)}
          placeholder={
            selectedTags.length === 0
              ? placeholder
              : selectedTags.length >= maxTags
              ? `Maximum ${maxTags} tags`
              : "Add more tags..."
          }
          className={styles.input}
          disabled={selectedTags.length >= maxTags}
        />
      </div>

      {isOpen && (filteredTags.length > 0 || loading) && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={styles.dropdown}
        >
          <div ref={dropdownRef} className={styles.dropdownScroller}>
            {filteredTags.map((tag, i) => (
              <button
                key={tag._id}
                type="button"
                onClick={() => handleTagSelect(tag)}
                onMouseEnter={() => setHighlightedIndex(i)}
                className={`${styles.option} ${
                  i === highlightedIndex ? styles.highlighted : ""
                }`}
              >
                <div className={styles.optionHeader}>
                  <div className={styles.optionName}>{tag.name}</div>
                  {tag.description && (
                    <div className={styles.optionDescription}>
                      {tag.description}
                    </div>
                  )}
                </div>
              </button>
            ))}
            {loading && (
              <div className={styles.loading}>Loading more tags...</div>
            )}
            {!loading && !hasMore && filteredTags.length > 0 && (
              <div className={styles.noMore}>No more tags to load</div>
            )}
            {!loading && filteredTags.length === 0 && (
              <div className={styles.noResults}>No matching tags found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagSelector;
