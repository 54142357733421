/**
 * Get description text for trust or warmth scores
 * @param {number} score - Score value (1-5)
 * @param {'trust' | 'warmth'} type - Score type
 * @returns {string} Description text
 */
export const getScoreDescription = (score, type) => {
  if (!score) return "";

  switch (Math.round(score)) {
    case 1:
      return type === "trust"
        ? "Very low trust. Felt unreliable and untrustworthy."
        : "Very cold. Felt distant and unapproachable.";
    case 2:
      return type === "trust"
        ? "Low trust. Somewhat unreliable."
        : "Cold. Somewhat distant.";
    case 3:
      return type === "trust"
        ? "Neutral trust. Neither trustworthy nor untrustworthy."
        : "Neutral warmth. Neither warm nor cold.";
    case 4:
      return type === "trust"
        ? "High trust. Generally reliable and trustworthy."
        : "Warm. Generally approachable and friendly.";
    case 5:
      return type === "trust"
        ? "Very high trust. Extremely reliable and trustworthy."
        : "Very warm. Extremely approachable and friendly.";
    default:
      return "";
  }
};
