// packages/client/src/components/JoyrideFab/JoyrideFab.jsx
import React from "react";
import { IoHelpCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import styles from "./JoyrideFab.module.css";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";

/**
 * Floating action button for triggering Joyride tours
 *
 * @param {Object} props Component props
 * @param {Function} props.onClick Click handler function
 * @param {boolean} props.hasCompletedTour Whether the tour has been completed
 * @param {string} props.position Position of the FAB. Available options:
 *   - "bottom-left": Bottom left corner (default)
 *   - "bottom-right": Bottom right corner
 *   - "bottom-center": Centered at the bottom
 *   - "top-left": Top left corner
 *   - "top-right": Top right corner
 *   - "top-center": Centered at the top
 * @param {string} props["data-tour"] Data attribute for tour targeting
 * @returns {React.Component} JoyrideFab component
 */
const JoyrideFab = ({
  onClick,
  hasCompletedTour = false,
  position = "bottom-left",
  "data-tour": dataTour,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.(e);
  };

  // Choose icon based on completion status
  const Icon = hasCompletedTour
    ? IoCheckmarkCircleOutline
    : IoHelpCircleOutline;

  // Set appropriate tooltip text
  const tooltipText = hasCompletedTour
    ? "Tour completed! Click to view again"
    : "Click for a guided tour";

  // Determine tooltip side based on position
  const getTooltipSide = () => {
    if (position.startsWith("bottom")) return "top";
    if (position.startsWith("top")) return "bottom";
    if (position.endsWith("left")) return "right";
    if (position.endsWith("right")) return "left";
    return "right"; // Default
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={`${styles.fab} ${
            hasCompletedTour ? styles.completed : ""
          } ${styles[position]}`}
          onClick={handleClick}
          aria-label={hasCompletedTour ? "Restart tour" : "Help tour"}
          data-tour={dataTour}
          data-joyride="true"
        >
          <Icon className={styles.icon} aria-hidden="true" />
        </button>
      </TooltipTrigger>
      <TooltipContent side={getTooltipSide()}>{tooltipText}</TooltipContent>
    </Tooltip>
  );
};

export default JoyrideFab;
