import React, { useState } from "react";
import styles from "./Profile.module.css";
import { useUserContext } from "contexts/UserContext";
import cn from "classnames";

import Button from "components/Button/Button";
import SearchInput from "./components/SearchInput";
import SortSelect from "./components/SortSelect";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

import StoryCard from "components/StoryCard/StoryCard";
import { FaPen } from "react-icons/fa";
import useMediaQuery from "hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
// Import the unified search function instead of the specific one
import { unifiedContentSearch } from "api/search";

const StoriesContent = ({
  stories,
  undiscoveredCount,
  profileData,
  onStoriesChange,
}) => {
  const { userData } = useUserContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState("newest");
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const isSmallScreen = useMediaQuery("sm");
  const isMediumScreen = useMediaQuery("md");
  const navigate = useNavigate();

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const handleSearch = async () => {
    setLoading(true);
    setHasSearched(true);
    try {
      const searchParams = {
        query: searchQuery,
        authorId: profileData._id,
        sort: sort,
        type: "stories",
      };

      const searchResults = await unifiedContentSearch(searchParams);

      const results = searchResults.stories?.results || [];
      const newUndiscoveredCount =
        searchResults.stories?.undiscoveredCount || 0;

      onStoriesChange(results, newUndiscoveredCount);
    } catch (error) {
      console.error("Error searching stories:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderNewStoryButton = () => {
    const isOwnProfile = profileData?._id === userData?._id;
    if (!isOwnProfile) return null;

    if (isSmallScreen) {
      return (
        <div className={styles.newContentCard}>
          <p>Create a new story</p>
          <button
            className={styles.newContentCardButton}
            onClick={() => navigate("/stories/new")}
            aria-label="Create new story"
          >
            <FaPen />
          </button>
        </div>
      );
    }

    return (
      <button
        className={styles.newContentButton}
        onClick={() => navigate("/stories/new")}
      >
        <FaPen />
        New Story
      </button>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingMessage message="Loading stories..." />;
    }

    // Show stories if we have them
    if (stories && stories.length > 0) {
      return (
        <>
          {stories.map((story) => (
            <StoryCard key={story._id} story={story} userData={userData} />
          ))}

          {/* Show undiscovered message below content if applicable */}
          {undiscoveredCount > 0 && (
            <div className={styles.undiscoveredMessage}>
              <strong>{profileData.username}</strong> has {undiscoveredCount}
              {undiscoveredCount === 1 ? " more story" : " more stories"} you
              haven't yet
              <a href="/faq/connections"> connected on.</a> Keep chatting to
              build trust.
            </div>
          )}
        </>
      );
    }

    // No stories found after search
    if (hasSearched) {
      return <div className={styles.emptyMessage}>No stories found</div>;
    }

    // Initial state - no stories yet
    const isOwnProfile = profileData?._id === userData?._id;

    if (isOwnProfile) {
      return (
        <div className={styles.emptyContent}>
          <p className={styles.emptyMessage}>
            You haven't shared any stories yet.
          </p>
          <button
            className={styles.connectPrompt}
            onClick={() => navigate("/stories/new")}
          >
            Create Your First Story
          </button>
        </div>
      );
    }

    // Someone else's profile with no visible stories but with undiscovered ones
    if (undiscoveredCount > 0) {
      return (
        <div className={styles.undiscoveredMessage}>
          <strong>{profileData.username}</strong> has {undiscoveredCount}
          {undiscoveredCount === 1 ? " story" : " stories"} you haven't yet
          <a href="/faq/connections"> connected on.</a> Keep chatting with them
          and maybe if they're comfortable enough, they will reveal more about
          themselves to you.
        </div>
      );
    }

    // Truly empty - no stories at all
    return (
      <div className={styles.emptyContent}>
        <p className={styles.emptyMessage}>
          No stories shared yet by {profileData.username}.
        </p>
      </div>
    );
  };

  return (
    <div className={styles.lifeExperiences}>
      {isSmallScreen ? (
        // Mobile layout
        <div className={styles.toolbar}>
          <SearchInput
            value={searchQuery}
            onChange={handleSearchChange}
            onClear={() => {
              setSearchQuery("");
              setHasSearched(false);
            }}
            onSearch={handleSearch}
            placeholder="Search stories..."
          />
          <div className={styles.toolbarBottom}>
            <SortSelect currentSort={sort} onSortChange={setSort} />
          </div>
          {profileData?._id === userData?._id && renderNewStoryButton()}
        </div>
      ) : (
        // Desktop layout - all elements in one row
        <div className={styles.toolbar}>
          <div className={styles.toolbarRow}>
            <div className={styles.searchContainer}>
              <SearchInput
                value={searchQuery}
                onChange={handleSearchChange}
                onClear={() => {
                  setSearchQuery("");
                  setHasSearched(false);
                }}
                onSearch={handleSearch}
                placeholder="Search stories..."
              />
            </div>
            <div className={styles.sortContainer}>
              <SortSelect currentSort={sort} onSortChange={setSort} />
            </div>
            {profileData?._id === userData?._id && (
              <div className={styles.newButtonContainer}>
                {renderNewStoryButton()}
              </div>
            )}
          </div>
        </div>
      )}

      {renderContent()}
    </div>
  );
};

export default StoriesContent;
