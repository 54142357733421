import React from "react";
import { motion } from "framer-motion";
import styles from "./TechnologySection.module.css";
import NeuralNetworkIcon from "components/NeuralNetworkIcon";
import PseudonymityIcon from "components/PseudonymityIcon";
import ScoringIcon from "components/ScoringIcon";

const TechnologyCard = ({ title, description, icon: Icon, delay }) => (
  <motion.div
    className={styles.technologyCard}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay }}
  >
    {Icon && (
      <div className={styles.iconContainer}>
        <Icon className={styles.technologyIcon} fill="var(--chair-orange)" />
      </div>
    )}
    <h3>{title}</h3>
    <p>{description}</p>
  </motion.div>
);

const TechnologySection = () => {
  return (
    <motion.section
      className={styles.technology}
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8 }}
      id="technology"
    >
      <div className={styles.sectionHeader}>
        <motion.h2
          className={styles.sectionTitle}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Technology Behind Stumble
        </motion.h2>
        <motion.p
          className={styles.sectionSubtitle}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.1 }}
        >
          Innovative solutions for authentic connections
        </motion.p>
      </div>

      <div className={styles.technologyCards}>
        <TechnologyCard
          icon={NeuralNetworkIcon}
          title="AI-Powered Matching"
          description="Our natural language processing algorithms understand the emotional context of your experiences, connecting you with others who truly understand what you've been through."
          delay={0.2}
        />
        <TechnologyCard
          icon={PseudonymityIcon}
          title="Pseudonymity Engine"
          description="Share authentically without fear. Our pseudonymity system lets you control your identity while still forming meaningful connections based on shared experiences."
          delay={0.3}
        />
        <TechnologyCard
          icon={ScoringIcon}
          title="Trust & Warmth Framework"
          description="Our unique review system helps build a community of supportive members through accountability and recognition of positive interactions."
          delay={0.4}
        />
      </div>
    </motion.section>
  );
};

export default TechnologySection;
