import React from "react";
import styles from "./DateSelector.module.css";
import SimpleCombobox from "components/SimpleCombobox/SimpleCombobox";

const DateSelector = ({
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
}) => {
  // Generate month options
  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  // Generate year options (100 years back from current year)
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 100 }, (_, i) => ({
      value: currentYear - i,
      label: (currentYear - i).toString(),
    }));
  };

  const yearOptions = generateYearOptions();

  // Handle month selection
  const handleMonthChange = (value) => {
    setSelectedMonth(value ? parseInt(value, 10) : null);
  };

  // Handle year selection
  const handleYearChange = (value) => {
    setSelectedYear(value ? parseInt(value, 10) : null);
  };

  return (
    <div className={styles.dateSelector}>
      <div className={styles.dateField}>
        <SimpleCombobox
          options={monthOptions}
          value={selectedMonth}
          onChange={handleMonthChange}
          placeholder="Month"
          theme="light"
        />
      </div>
      <div className={styles.dateField}>
        <SimpleCombobox
          options={yearOptions}
          value={selectedYear}
          onChange={handleYearChange}
          placeholder="Year"
          theme="light"
        />
      </div>
    </div>
  );
};

export default DateSelector;
