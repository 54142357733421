import React from "react";
import {
  FloatingOverlay,
  FloatingFocusManager,
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  useId,
} from "@floating-ui/react";
import { motion } from "framer-motion";
import styles from "./ErrorModal.module.css";

const ErrorModal = ({ state, error, metadata, onRetry, onClose }) => {
  const { refs, context } = useFloating({
    open: true,
    onOpenChange: () => onClose(),
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  const labelId = useId();
  const descriptionId = useId();

  const getErrorContent = () => {
    switch (state) {
      case "match_rejected":
        return {
          title: "Match Declined",
          message:
            "The other person declined the match. Would you like to keep searching?",
          icon: "✗",
        };
      case "match_expired":
        return {
          title: "Match Expired",
          message: "The match request timed out. Would you like to try again?",
          icon: "⏰",
        };
      case "match_disconnected":
        return {
          title: "User Disconnected",
          message:
            "The other person disconnected. Would you like to keep searching?",
          icon: "🔌",
        };
      case "error":
        return {
          title: "Something Went Wrong",
          message:
            error?.message || "An unexpected error occurred. Please try again.",
          icon: "⚠️",
        };
      default:
        return {
          title: "Error",
          message: "Something went wrong. Please try again.",
          icon: "⚠️",
        };
    }
  };

  const { title, message, icon } = getErrorContent();

  return (
    <FloatingOverlay className={styles.overlay} lockScroll>
      <FloatingFocusManager context={context}>
        <motion.div
          ref={refs.setFloating}
          className={styles.modal}
          aria-labelledby={labelId}
          aria-describedby={descriptionId}
          {...getFloatingProps()}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
        >
          <div className={styles.content}>
            <div className={styles.icon}>{icon}</div>

            <div className={styles.textContent}>
              <h2 id={labelId} className={styles.title}>
                {title}
              </h2>

              <p id={descriptionId} className={styles.message}>
                {message}
              </p>

              {metadata && (
                <div className={styles.metadata}>
                  <span>Time elapsed: {metadata.elapsedTime}</span>
                  {metadata.searchingCount && (
                    <span>People searching: {metadata.searchingCount}</span>
                  )}
                </div>
              )}
            </div>

            <div className={styles.actions}>
              <button
                onClick={onClose}
                className={`${styles.button} ${styles.secondaryButton}`}
              >
                Back to Find Others
              </button>
              <button
                onClick={onRetry}
                className={`${styles.button} ${styles.primaryButton}`}
              >
                Try Again
              </button>
            </div>
          </div>
        </motion.div>
      </FloatingFocusManager>
    </FloatingOverlay>
  );
};

export default ErrorModal;
