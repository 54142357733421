// packages/client/src/components/Tooltip/Tooltip.jsx
import React, { useState } from "react";
import styles from "./Tooltip.module.css";

export const Tooltip = ({ children, content, placement = "top" }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className={`${styles.tooltip} ${styles[placement]}`}>
          <div className={styles.tooltipContent}>{content}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

// references:
// https://www.freecodecamp.org/news/how-to-render-modals-in-react-bbe9685e947e/
