import { useState } from "react";
import styles from "./ActionModals.module.css";
import Button from "components/Button/Button";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

/**
 * A modal component for confirming hard deletion of a user account
 * with extra safeguards to prevent accidental deletion
 */
const HardDeleteConfirmModal = ({
  isOpen,
  onClose,
  user,
  onConfirm,
  isSubmitting,
}) => {
  const [confirmText, setConfirmText] = useState("");
  const [reason, setReason] = useState("");

  // Set up floating UI
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context, { role: "dialog" });
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  const handleSubmit = () => {
    onConfirm({
      userId: user._id,
      reason: reason,
    });
  };

  const handleClose = () => {
    setConfirmText("");
    setReason("");
    onClose();
  };

  // Handle overlay background click
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  if (!isOpen || !user) return null;

  const isConfirmValid = confirmText.toLowerCase() === "confirm";

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll
        className={styles.overlay}
        onClick={handleOverlayClick}
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.floatingModal}
            {...getFloatingProps()}
          >
            <div className={styles.modalHeader}>
              <h3>Permanently Delete User</h3>
              <button
                onClick={handleClose}
                className={styles.closeButton}
                aria-label="Close modal"
                disabled={isSubmitting}
              >
                ×
              </button>
            </div>

            <div className={styles.resourceIdentifier}>User ID: {user._id}</div>

            <div className={styles.resourceDetails}>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Username:</span>
                <span className={styles.detailValue}>{user.username}</span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Email:</span>
                <span className={styles.detailValue}>{user.email}</span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Status:</span>
                <span className={styles.detailValue}>
                  <span className={styles[`status-${user.status}`]}>
                    {user.status}
                  </span>
                </span>
              </div>
            </div>

            <div className={styles.modalContent}>
              <div
                className={styles.warningText}
                style={{
                  marginBottom: "20px",
                  backgroundColor: "#ffebee",
                  borderColor: "#f44336",
                }}
              >
                <strong>WARNING:</strong> This action will permanently delete
                the user and ALL associated data. This cannot be undone. All
                content, messages, reports, and interactions will be permanently
                removed.
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="reason">Reason for permanent deletion:</label>
                <textarea
                  id="reason"
                  placeholder="Enter detailed reason for permanently deleting this user..."
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  disabled={isSubmitting}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="confirmText">
                  Type "confirm" to proceed with permanent deletion:
                </label>
                <input
                  id="confirmText"
                  type="text"
                  placeholder="confirm"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  disabled={isSubmitting}
                  className={styles.confirmInput}
                />
              </div>

              <div className={styles.modalActions}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={!isConfirmValid || !reason || isSubmitting}
                  color="danger"
                >
                  {isSubmitting ? "Processing..." : "Permanently Delete User"}
                </Button>
              </div>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default HardDeleteConfirmModal;
