import React, { useState } from "react";
import Badge from "../../../components/Badge/Badge";
import { useBadges } from "../../../contexts/BadgeContext";
import styles from "./BadgeSection.module.css";
import useMediaQuery from "../../../hooks/useMediaQuery";

const BadgeSection = ({ userId, isCurrentUser = false, profileData }) => {
  const { badges, loading, error, getUserBadges } = useBadges();
  const [showAllBadges, setShowAllBadges] = useState(false);

  // Use media query for responsive design
  const isMobile = useMediaQuery("sm");

  if (loading) {
    return <div className={styles.loadingBadges}>Loading badges...</div>;
  }

  if (error) {
    return (
      <div className={styles.badgeError}>Error loading badges: {error}</div>
    );
  }

  // Get the user's earned badges from profile data
  const earnedBadges = profileData?.badges
    ? getUserBadges(profileData.badges)
    : [];

  // If there are no badges at all
  if (!badges || badges.length === 0) {
    return <div className={styles.noBadges}>No badges available</div>;
  }

  // Sort badges: earned badges first, then unearned badges
  const sortedBadges = [...badges].sort((a, b) => {
    const aEarned = earnedBadges.some(
      (eb) => eb.badgeData && eb.badgeData._id === a._id
    );
    const bEarned = earnedBadges.some(
      (eb) => eb.badgeData && eb.badgeData._id === b._id
    );

    if (aEarned && !bEarned) return -1;
    if (!aEarned && bEarned) return 1;
    return 0;
  });

  // Determine how many badges to show initially
  const initialBadgeCount = isMobile ? 6 : 12;
  const visibleBadges = showAllBadges
    ? sortedBadges
    : sortedBadges.slice(0, initialBadgeCount);
  const hasMoreBadges = sortedBadges.length > initialBadgeCount;

  return (
    <div className={styles.badgeSection}>
      <h3>
        Badges
        {earnedBadges.length > 0 && (
          <span className={styles.badgeCount}>
            {earnedBadges.length} earned
          </span>
        )}
      </h3>

      <div className={styles.badgeGrid}>
        {visibleBadges.map((badge) => {
          const earnedBadgeInfo = earnedBadges.find(
            (eb) => eb.badgeData && eb.badgeData._id === badge._id
          );
          const isEarned = !!earnedBadgeInfo;

          return (
            <Badge
              key={badge._id}
              badge={badge}
              earned={isEarned}
              awardedAt={earnedBadgeInfo?.awardedAt}
              size={isMobile ? "small" : "medium"}
            />
          );
        })}
      </div>

      {hasMoreBadges && (
        <button
          className={styles.showMoreButton}
          onClick={() => setShowAllBadges(!showAllBadges)}
        >
          {showAllBadges ? "Show Less" : "Show More Badges"}
        </button>
      )}
    </div>
  );
};

export default BadgeSection;
