import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
} from "@floating-ui/react";
import { IoClose } from "react-icons/io5";
import styles from "./BlockModal.module.css";
import Button from "components/Button/Button";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import { useToastContext } from "contexts/ToastContext";
import { HiOutlineBan, HiShieldExclamation } from "react-icons/hi";

const BLOCK_REASONS = [
  { value: "harassment", label: "Harassment" },
  { value: "spam", label: "Spam" },
  { value: "inappropriate_content", label: "Inappropriate Content" },
  { value: "personal", label: "Personal Reasons" },
  { value: "other", label: "Other" },
];

const BlockModal = ({
  sourceType,
  instanceData,
  onAction,
  onClose,
  isOpen,
  error,
}) => {
  const { addToast } = useToastContext();
  const [reason, setReason] = useState("");
  const [notes, setNotes] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [status, setStatus] = useState("idle"); // idle, loading, success, error
  const [view, setView] = useState("form"); // form, success, error
  const [actionPerformed, setActionPerformed] = useState(null); // 'block' or 'unblock'

  const textAreaRef = useRef();
  const selectRef = useRef();

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  // Extract isBlocked from instanceData
  const isBlocked = instanceData?.isBlocked || false;

  // Reset form state when modal opens/closes or block status changes
  useEffect(() => {
    if (isOpen) {
      setReason("");
      setNotes("");
      setFormErrors({});
      setStatus("idle");
      setView("form");
      setActionPerformed(null); // Reset the action performed
    }
  }, [isOpen, isBlocked]);

  // Compute if form is valid for submit button
  const isFormValid = useMemo(() => {
    return isBlocked || reason !== "";
  }, [isBlocked, reason]);

  const validate = () => {
    if (isBlocked) return true; // No validation needed for unblock

    const errors = {};
    if (!reason) {
      errors.reason = "Please select a reason for blocking.";
    }

    setFormErrors(errors);

    // Clear errors after 3 seconds
    if (Object.keys(errors).length > 0) {
      setTimeout(() => setFormErrors({}), 3000);
      return false;
    }

    return true;
  };

  const handleAction = async (e) => {
    if (e) e.preventDefault();

    if (!validate()) return;

    setStatus("loading");

    // Set the action being performed
    const action = isBlocked ? "unblock" : "block";

    try {
      // Call the action handler with block data if blocking
      const success = await onAction(isBlocked ? null : { reason, notes });

      if (success) {
        setStatus("success");
        setView("success");
        setActionPerformed(action); // Store the action that was performed
      } else {
        setStatus("error");
        setView("error");
      }
    } catch (err) {
      console.error(`Failed to ${action}:`, err);
      setStatus("error");
      setView("error");
    }
  };

  const getBlockLevelInfo = () => {
    switch (sourceType) {
      case "chat":
        return {
          level: "regular_chat",
          description:
            "This will block this chat and prevent future interactions with this user.",
          icon: <HiShieldExclamation className={styles.blockLevelIcon} />,
        };
      case "match":
        return {
          level: "airlock",
          description:
            "This will block this match. You will still be able to see existing chats with this user.",
          icon: <HiOutlineBan className={styles.blockLevelIcon} />,
        };
      case "reachout":
        return {
          level: "airlock",
          description:
            "This will block this reachout. You will still be able to see existing chats with this user.",
          icon: <HiOutlineBan className={styles.blockLevelIcon} />,
        };
      default:
        return {
          level: "discovery",
          description: "This will block this interaction.",
          icon: <HiOutlineBan className={styles.blockLevelIcon} />,
        };
    }
  };

  const blockInfo = getBlockLevelInfo();

  const getErrorMessage = (error) => {
    if (!error) return "An unknown error occurred";
    if (typeof error === "string") return error;
    return error.message || "Failed to complete the operation";
  };

  const renderContent = () => {
    if (view === "success") {
      // Use the actionPerformed state to determine which message to show
      const wasUnblockAction = actionPerformed === "unblock";

      return (
        <div className={styles.successView}>
          <div className={styles.successHeader}>
            <div className={styles.checkmark}>✓</div>
            <h3>{wasUnblockAction ? "User Unblocked" : "User Blocked"}</h3>
          </div>

          <p className={styles.successMessage}>
            {wasUnblockAction
              ? `You have successfully unblocked this ${sourceType}.`
              : `You have successfully blocked this ${sourceType}.`}
            {!wasUnblockAction && (
              <span className={styles.extraInfo}>{blockInfo.description}</span>
            )}
          </p>

          <Button
            onClick={onClose}
            className={styles.actionButton}
            size="small"
            shape="square"
          >
            Close
          </Button>
        </div>
      );
    }

    if (view === "error") {
      return (
        <div className={styles.errorView}>
          <div className={styles.errorHeader}>
            <div className={styles.errorIcon}>⚠</div>
            <h3>{isBlocked ? "Unable to Unblock" : "Unable to Block"}</h3>
          </div>

          <p className={styles.errorMessage}>{getErrorMessage(error)}</p>

          <Button
            onClick={onClose}
            className={styles.actionButton}
            size="small"
            shape="square"
          >
            Close
          </Button>
        </div>
      );
    }

    // Form view - different for block vs unblock
    if (isBlocked) {
      return (
        <div className={styles.unblockView}>
          <div className={styles.header}>
            <div className={styles.title}>
              Unblock {sourceType.charAt(0).toUpperCase() + sourceType.slice(1)}
            </div>
            <button onClick={onClose} className={styles.closeButton}>
              <IoClose />
            </button>
          </div>
          <div className={styles.content}>
            <p>
              This {sourceType} is currently blocked. Would you like to unblock
              it?
            </p>
            <div className={styles.buttonGroup}>
              <Button
                onClick={onClose}
                variant="secondary"
                size="small"
                shape="square"
                className={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAction}
                disabled={status === "loading"}
                fetchState={status === "loading" ? "loading" : "initial"}
                color="primary"
                size="small"
                shape="square"
              >
                {status === "loading" ? "Unblocking..." : "Unblock"}
              </Button>
            </div>
          </div>
        </div>
      );
    }

    // Block form
    return (
      <>
        <div className={styles.header}>
          <div className={styles.title}>Block User</div>
          <button onClick={onClose} className={styles.closeButton}>
            <IoClose />
          </button>
        </div>

        <div className={styles.content}>
          <div className={styles.blockLevelInfo}>
            {blockInfo.icon}
            <div className={styles.blockLevelText}>
              <h4>
                Block Level: {blockInfo.level.replace("_", " ").toUpperCase()}
              </h4>
              <p>{blockInfo.description}</p>
            </div>
          </div>

          <div className={styles.formContainer}>
            <div className={styles.formField}>
              <label className={styles.formFieldLabel}>Reason:</label>
              <select
                ref={selectRef}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                disabled={status === "loading"}
              >
                <option value="">Select a reason...</option>
                {BLOCK_REASONS.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              {formErrors.reason && (
                <FloatingTooltip anchorRef={selectRef} show={formErrors.reason}>
                  <p style={{ color: "white" }}>{formErrors.reason}</p>
                </FloatingTooltip>
              )}
            </div>

            <div className={styles.formField}>
              <label className={styles.formFieldLabel}>
                Additional Notes:{" "}
                <span className={styles.optional}>(optional)</span>
              </label>
              <textarea
                ref={textAreaRef}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                disabled={status === "loading"}
                placeholder="Add any additional notes about why you're blocking this user..."
                maxLength={500}
              />
            </div>

            <div className={styles.buttonGroup}>
              <Button
                onClick={onClose}
                variant="secondary"
                size="small"
                shape="square"
                className={styles.cancelButton}
                disabled={status === "loading"}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAction}
                disabled={!isFormValid || status === "loading"}
                fetchState={status === "loading" ? "loading" : "initial"}
                color="error"
                size="small"
                shape="square"
              >
                {status === "loading" ? "Blocking..." : "Block User"}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!isOpen) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay className={styles.overlay} lockScroll>
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.dialog}
            {...getFloatingProps()}
          >
            {renderContent()}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default BlockModal;
