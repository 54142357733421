import React from "react";
import { motion } from "framer-motion";
import styles from "./HowItWorksSection.module.css";

const HowItWorksSection = ({ stats }) => {
  return (
    <motion.section
      className={styles.howItWorks}
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8 }}
      id="how-it-works"
    >
      <div className={styles.sectionHeader}>
        <motion.h2
          className={styles.sectionTitle}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          How Stumble Works
        </motion.h2>
        <motion.p
          className={styles.sectionSubtitle}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.1 }}
        >
          Connect with stories, not storytellers
        </motion.p>
      </div>

      <div className={styles.stepsGrid}>
        {/* Step 1: Join the Community */}
        <motion.div
          className={styles.step}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <div className={styles.stepNumber}>1</div>
          <h3>Join the Community</h3>
          <p>
            Create your account and become part of a supportive community where
            people connect through shared experiences rather than superficial
            profiles.
          </p>
          <div className={styles.stepStats}>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.users_registered}
              </span>
              <span className={styles.stepStatLabel}>Users Registered</span>
            </div>
          </div>
        </motion.div>

        {/* Step 2: Share Pseudonymously */}
        <motion.div
          className={styles.step}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <div className={styles.stepNumber}>2</div>
          <h3>Share Pseudonymously</h3>
          <p>
            Share your life experiences and stories under a pseudonym, allowing
            you to be completely authentic while protecting your identity.
          </p>
          <div className={styles.stepStats}>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.experiences_shared}
              </span>
              <span className={styles.stepStatLabel}>Experiences Shared</span>
            </div>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.stories_written}
              </span>
              <span className={styles.stepStatLabel}>Stories Written</span>
            </div>
          </div>
        </motion.div>

        {/* Step 3: Match & Reach Out */}
        <motion.div
          className={styles.step}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <div className={styles.stepNumber}>3</div>
          <h3>Match & Reach Out</h3>
          <p>
            Find others with similar stories and life experiences through AI
            matching or by reaching out directly to stories that resonate with
            you.
          </p>
          <div className={styles.stepStats}>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.reachouts_sent}
              </span>
              <span className={styles.stepStatLabel}>Reachouts Sent</span>
            </div>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.matches_made}
              </span>
              <span className={styles.stepStatLabel}>Matches Made</span>
            </div>
          </div>
        </motion.div>

        {/* Step 4: Chat in the Airlock */}
        <motion.div
          className={styles.step}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          <div className={styles.stepNumber}>4</div>
          <h3>Chat in the Airlock</h3>
          <p>
            Begin conversations in a temporary, pseudonymous chat room that
            serves as a buffer space where you can connect safely before
            deciding to form a permanent bond.
          </p>
          <div className={styles.stepStats}>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.reachouts_sent + stats.matches_made}
              </span>
              <span className={styles.stepStatLabel}>Airlock Chats</span>
            </div>
          </div>
        </motion.div>

        {/* Step 5: Stumble Into Connection */}
        <motion.div
          className={styles.step}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <div className={styles.stepNumber}>5</div>
          <h3>Stumble Into Connection</h3>
          <p>
            When you're ready, choose to "stumble" to transform your temporary
            chat into a permanent connection, creating a lasting bond based on
            shared experiences.
          </p>
          <div className={styles.stepStats}>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.connections_made}
              </span>
              <span className={styles.stepStatLabel}>Connections Made</span>
            </div>
          </div>
        </motion.div>

        {/* Step 6: Building Trust & Warmth */}
        <motion.div
          className={styles.step}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.7 }}
        >
          <div className={styles.stepNumber}>6</div>
          <h3>Building Trust & Warmth</h3>
          <p>
            After meaningful conversations, you can rate each other on Trust and
            Warmth, helping the community identify supportive members while
            maintaining pseudonymity where it matters.
          </p>
          <div className={styles.stepStats}>
            <div className={styles.stepStat}>
              <span className={styles.stepStatNumber}>
                {stats.great_conversations}
              </span>
              <span className={styles.stepStatLabel}>Great Conversations</span>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default HowItWorksSection;
