import React from "react";
import styles from "./Footer.module.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { useUserContext } from "contexts/UserContext";
import { Link } from "react-router-dom";

function Footer() {
  const { userData } = useUserContext();
  const isRegisteredUser = !!userData && userData.emailVerified;

  const socialLinks = [
    {
      icon: <InstagramIcon />,
      url: "https://instagram.com/stumble.chat",
      label: "Instagram",
    },
    {
      icon: <XIcon />,
      url: "https://twitter.com/stumble_chat",
      label: "X",
    },
  ];

  // Base footer links for all users
  let footerLinks = [
    { label: "Privacy Policy", path: "/privacy" },
    { label: "Terms of Service", path: "/terms" },
    { label: "Rules", path: "/rules" },
  ];

  // Add Help/Support link only for registered users
  if (isRegisteredUser) {
    footerLinks.push({ label: "Help & Support", path: "/help" });
  }

  // Always show Contact Us
  footerLinks.push({ label: "Contact Us", path: "/contact" });

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.social}>
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noreferrer"
              aria-label={link.label}
              className={styles.socialLink}
            >
              {link.icon}
            </a>
          ))}
        </div>

        <nav className={styles.links}>
          <ul>
            {footerLinks.map((link, index) => (
              <li key={index}>
                {link.path.startsWith("/") ? (
                  <Link to={link.path}>{link.label}</Link>
                ) : (
                  <a href={link.path} target="_blank" rel="noopener noreferrer">
                    {link.label}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className={styles.copyright}>
          <p>&copy; {new Date().getFullYear()} Stumble. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
