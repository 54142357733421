import React, { useEffect, useState } from "react";
import styles from "./PageWrapper.module.css";
import Footer from "components/Footer/Footer";
import PageHeader from "components/PageHeader/PageHeader";
import { useUserContext } from "contexts/UserContext";

/**
 * PageWrapper component that provides consistent layout and header/footer
 * @param {Object} props
 * @param {React.ReactNode} props.children - Page content
 * @param {boolean} [props.ticker=false] - Whether to show the ticker
 * @param {boolean} [props.footer=false] - Whether to show the footer
 * @param {Object} [props.headerSettings] - Settings for the PageHeader
 * @param {boolean} [props.headerSettings.showStatusBanner=true] - Whether to show status banners
 * @param {boolean} [props.headerSettings.showTicker=true] - Override ticker display
 */
const PageWrapper = ({ children, ticker, footer, headerSettings = {} }) => {
  const { userData } = useUserContext();
  const [headerHeight, setHeaderHeight] = useState(0);

  // Reference to the header element
  const headerRef = React.useRef(null);

  // Update the content padding when header elements change
  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        const height = headerRef.current.offsetHeight;
        setHeaderHeight(height);
      }
    };

    // Initial measurement
    updateHeaderHeight();

    // Add a delayed measurement to catch any post-render changes
    const delayedMeasurement = setTimeout(() => {
      updateHeaderHeight();
    }, 100);

    // Set up resize observer to handle dynamic height changes
    const resizeObserver = new ResizeObserver(() => {
      updateHeaderHeight();
    });

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    // Clean up
    return () => {
      clearTimeout(delayedMeasurement);
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
    };
  }, [ticker, userData, headerSettings]);

  return (
    <div className={styles.wrapper}>
      <PageHeader ref={headerRef} ticker={ticker} settings={headerSettings} />
      <div
        className={styles.content}
        style={{
          paddingTop: `calc(var(--navbar-height) + ${headerHeight}px)`,
        }}
      >
        {children}
      </div>
      {footer && <Footer />}
    </div>
  );
};

export default PageWrapper;
