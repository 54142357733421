import React from "react";
import StoryForm from "components/StoryForm/StoryForm";
import { useParams } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import useAsyncResource from "components/AsyncResourceRenderer/useAsyncResource";
import AsyncResourceRenderer from "components/AsyncResourceRenderer/AsyncResourceRenderer";
import useStoryCRUD from "hooks/useStoryCRUD";
import * as API from "api/stories";

const EditStory = () => {
  const { storyId } = useParams();
  const { userData } = useUserContext();

  const {
    status: storyCRUDStatus,
    statusMessage,
    fetchStory,
    updateStory,
  } = useStoryCRUD();

  const initializeStory = async () => {
    const story = await fetchStory(storyId);
    if (story.author._id !== userData._id) {
      throw new Error("You are not authorized to edit this story.");
    }
    return story;
  };

  const {
    status,
    data: initialData,
    error,
  } = useAsyncResource(initializeStory, [storyId, userData]);

  return (
    <AsyncResourceRenderer status={status} data={initialData} error={error}>
      {(story) => (
        <StoryForm
          initialData={story}
          onSubmit={(storyData) => updateStory(storyId, storyData)}
          status={storyCRUDStatus}
          statusMessage={statusMessage}
          theme="dark"
        />
      )}
    </AsyncResourceRenderer>
  );
};

export default EditStory;
