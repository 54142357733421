import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale"; // Import updateLocale plugin

// Extend dayjs with the necessary plugins
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale); // Extend dayjs with updateLocale
// Update the relative time locale configuration
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day", // Customized
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

/**
 * Calculates the time until the next review is allowed.
 *
 * @param {string|Date} lastReviewDate - The date of the last review.
 * @param {number} reviewCooldown - The cooldown period in days.
 * @returns {string} - A detailed message indicating when the next review can be submitted. Or false if review can be submitted.
 */
export const isReviewThrottled = (lastReviewDate, reviewCooldown) => {
  const nextReviewDate = dayjs(lastReviewDate).add(reviewCooldown, "day");
  const now = dayjs();

  if (nextReviewDate.isAfter(now)) {
    const diff = dayjs.duration(nextReviewDate.diff(now));
    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();

    let parts = [];

    if (days > 0) parts.push(`${days} day${days > 1 ? "s" : ""}`);
    if (hours > 0) parts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);

    let message = parts.join(", ");

    return message;
  } else {
    return false;
  }
};

export const formatDate = (ISODate) => {
  const formattedDate = dayjs(ISODate).format("MMMM D, YYYY");
  return formattedDate;
};

/**
 * Formats a date into a relative time string (e.g., "1 min ago").
 *
 * @param {string|Date} date - The date to format.
 * @returns {string} - The formatted relative time string.
 */
export const formatRelativeTime = (date) => {
  return dayjs(date).fromNow();
};

/**
 * Formats a month and year into a string of the format "MMM YYYY".
 *
 * @param {number} [month] - The month as a number (1-12). Optional.
 * @param {number} year - The year as a number.
 * @returns {string} - The formatted string in the format "MMM YYYY" or just the year if month is not provided.
 * @throws {Error} - Throws an error if the month is not between 1 and 12 or if the year is not provided.
 */
export const formatMonthYear = (month, year) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (!year) {
    throw new Error("Year is required");
  }

  if (month && (month < 1 || month > 12)) {
    throw new Error("Invalid month");
  }

  return month ? `${months[month - 1]} ${year}` : `${year}`;
};

export const monthYearToDate = (month, year) => {
  if (!year) return null;
  // If only year is provided, use middle of the year (July 1st)
  if (!month) return new Date(year, 6, 1);
  return new Date(year, month - 1, 1);
};

export const getTimeAgoString = (isoString) => {
  if (!isoString) return "Date not specified";

  const date = dayjs(isoString);
  const now = dayjs();
  const monthsDiff = now.diff(date, "month");
  const yearsDiff = now.diff(date, "year");

  if (monthsDiff < 3) {
    return "recently";
  } else if (monthsDiff < 6) {
    return "a few months ago";
  } else if (monthsDiff < 12) {
    return "in the past year";
  } else if (yearsDiff === 1) {
    return "a year ago";
  } else {
    return `${yearsDiff} years ago`;
  }
};

// Usage helper that combines both
export const getRelativeTimeFromMonthYear = (month, year) => {
  const date = monthYearToDate(month, year);
  if (!date) return "Date not specified";
  return getTimeAgoString(date.toISOString());
};

/**
 * Formats time until expiration in a human-readable format
 * @param {string|Date} expiryDate - The expiration date
 * @returns {Object} - Object containing formatted string and urgency level
 */
export const getExpiryTimeLeft = (expiryDate) => {
  const now = dayjs();
  const expiry = dayjs(expiryDate);
  const diff = expiry.diff(now);

  // Return early if already expired
  if (diff <= 0) {
    return { text: "Expired", urgency: "expired" };
  }

  const duration = dayjs.duration(diff);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let text = "";
  let urgency = "normal";

  if (days > 0) {
    text = `${days} day${days > 1 ? "s" : ""}`;
    if (hours > 0) text += ` and ${hours} hour${hours > 1 ? "s" : ""}`;
    urgency = days < 2 ? "warning" : "normal";
  } else if (hours > 0) {
    text = `${hours} hour${hours > 1 ? "s" : ""}`;
    if (minutes > 0) text += ` and ${minutes} minute${minutes > 1 ? "s" : ""}`;
    urgency = hours < 3 ? "urgent" : "warning";
  } else {
    text = `${minutes} minute${minutes > 1 ? "s" : ""}`;
    urgency = "urgent";
  }

  return {
    text: `${text} left`,
    urgency,
  };
};

/**
 * Formats a date as a relative time (e.g., "2 days ago", "in 3 days")
 * @param {Date|string} date - The date to format
 * @returns {string} The formatted relative time
 */
export const formatTimeAgo = (date) => {
  if (!date) return "";

  const now = new Date();
  const dateObj = typeof date === "string" ? new Date(date) : date;
  const diffMs = dateObj.getTime() - now.getTime();
  const diffDays = Math.round(diffMs / (1000 * 60 * 60 * 24));

  if (diffDays > 0) {
    return `in ${diffDays} day${diffDays !== 1 ? "s" : ""}`;
  } else if (diffDays < 0) {
    const absDiffDays = Math.abs(diffDays);
    return `${absDiffDays} day${absDiffDays !== 1 ? "s" : ""} ago`;
  } else {
    return "today";
  }
};
