// packages/client/src/api/users.js
import api from "./index"; // Ensure this path is correct
import handleAxiosError from "./handleAxiosError";

/**
 * Fetches the authenticated user's profile.
 */
export const fetchOwnProfile = async () => {
  try {
    const response = await api.get("/profile");
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Fetches a user's profile based on the identifier.
 * The identifier can be either a userId or a username.
 *
 * @async
 * @function fetchPublicProfile
 * @param {string} identifier - The identifier (userId or username) of the user whose profile is to be fetched.
 * @param {Object} options - Optional parameters to include in the request.
 * @param {string} [options.reachoutId] - The reachout ID to be included as a query parameter.
 * @param {string} [options.chatId] - The chat ID to be included as a query parameter.
 * @param {string} [options.matchId] - The match ID to be included as a query parameter.
 * @returns {Promise<Object>} The data of the user's profile.
 * @throws {Error} Throws an error if the request fails.
 */
export const fetchPublicProfile = async (identifier, options = {}) => {
  try {
    const response = await api.get(`/profile/${identifier}`, {
      params: options,
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Fetches a user's profile summary based on the identifier.
 * The identifier can be either a userId or a username.
 *
 * @async
 * @function fetchProfileSummary
 * @param {string} identifier - The identifier (userId or username) of the user whose profile summary is to be fetched.
 * @returns {Promise<Object>} The summary of the user's profile.
 * @throws {Error} Throws an error if the request fails.
 */
export const fetchProfileSummary = async (identifier) => {
  try {
    const response = await api.get(`/profile/${identifier}/summary`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * @typedef {Object} FormData
 * @property {string} username - The username of the user.
 * @property {string} birthdate - The birthdate of the user in ISO format.
 * @property {Object} location - The location of the user.
 * @property {string} location.name - The name of the location.
 * @property {string} location.formattedName - The formatted name of the location.
 * @property {string} location.code - The country code of the location.
 * @property {Array<Object>} lifeExperiences - The life experiences of the user.
 * @property {Object} lifeExperiences[].tag - The tag associated with the life experience.
 * @property {string} lifeExperiences[].tag._id - The ID of the tag.
 * @property {string} lifeExperiences[].tag.name - The name of the tag.
 * @property {string} lifeExperiences[].story - The story related to the life experience.
 * @property {Object} lifeExperiences[].date - The date of the life experience.
 * @property {number} lifeExperiences[].date.month - The month of the life experience.
 * @property {number} lifeExperiences[].date.year - The year of the life experience.
 * @property {boolean} lifeExperiences[].searchable - Indicates if the experience is searchable.
 * @property {string} gender - The gender of the user.
 */

/**
 * Registers a new user.
 *
 * @async
 * @function registerUser
 * @param {FormData} formData - The data for registering the user.
 * @returns {Promise<Object>} The response data from the registration.
 * @throws {Error} Throws an error if the request fails.
 */
export const registerUser = async (formData) => {
  try {
    const response = await api.post("/users/register", formData);
    return response.data;
  } catch (error) {
    // Process the error but don't throw it
    const processedError = handleAxiosError(error);

    // Log the error for debugging
    console.error("Registration error:", processedError);

    // Return an error result object instead of throwing
    return {
      success: false,
      error: processedError,
    };
  }
};

export const updateUserData = async (userData) => {
  try {
    const response = await api.post("/users/update", userData);
    if (response.status !== 200) {
      throw new Error("Update user failed: " + response.data.message);
    }

    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const checkAvailability = async (username) => {
  try {
    const response = await api.get("/users/check-availability", {
      params: { username },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getUserDataById = async (userId) => {
  try {
    const response = await api.get(`/users/${userId}`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchTagSuggestions = async () => {
  try {
    const res = await api.get(`/tagsuggestions`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

/**
 * Deletes a user by their ID.
 *
 * @param {string} userId - The ID of the user to be deleted.
 * @returns {Promise<Object>} The response data from the server after the user is deleted.
 * @throws {Error} Throws an error if the deletion fails or if the server responds with an error.
 * @throws {string} If a redirect URL is provided in the response, the user will be redirected to that URL.
 */
export const deleteUserById = async (userId) => {
  try {
    const response = await api.delete(`/users/${userId}`);
    if (response.status !== 200) {
      throw new Error("Delete user failed: " + response.data.message);
    }

    // Check for a redirect URL in the response data
    if (response.data.redirect) {
      window.location.href = response.data.redirect; // Redirect the user
    }

    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const changeUsername = async (userId, formData) => {
  try {
    const response = await api.patch(`/users/${userId}/username`, formData);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Fetches badges for a specific user
 * @param {string} identifier - The user ID or username to fetch badges for
 * @returns {Promise<Array>} Array of badge objects
 */
export const fetchUserBadges = async (identifier) => {
  try {
    const response = await api.get(`/profile/${identifier}/badges`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user badges:", error);
    handleAxiosError(error);
  }
};
