import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Card.module.css";
import { useSelector } from "react-redux";
import { selectLastMessageByReachoutId } from "store/selectors";
import LastMessageDisplay from "./LastMessageDisplay";
import { HiArrowRight } from "react-icons/hi";
import { Tooltip } from "components/Tooltip/Tooltip";
import { standardizeParticipant } from "utils/chatUtils";
import BaseCard from "./BaseCard";
import { UserContext } from "contexts/UserContext";
import cn from "classnames";

/**
 * @typedef {Object} props
 * @property {Reachout} data - The reachout data.
 * @property {function} onClick - Optional click handler.
 * @property {boolean} isSelected - Indicates if the chat is selected.
 */
/**
 * @type {React.ForwardRefExoticComponent<props & React.RefAttributes<any>>}
 */
const ReachoutCard = React.forwardRef(({ data, onClick, isSelected }, ref) => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const lastMessage = useSelector((state) =>
    selectLastMessageByReachoutId(state, data._id)
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/chats?type=reachouts&id=${data._id}`);
    }
  };

  // Identify participants
  const rawOtherParticipant = data.participants.find(
    (participant) => participant.user._id !== userData._id
  );
  const otherParticipant = standardizeParticipant(rawOtherParticipant);
  const currentUserParticipant = data.participants.find(
    (participant) => participant.user._id === userData._id
  );
  const isSender = currentUserParticipant?.role === "sender";

  const renderParticipantInfo = () => (
    <div className={styles.participantInfo}>
      {isSender ? (
        <>
          <span className={styles.participantName}>You</span>
          <HiArrowRight className={styles.directionArrow} />
          <span className={styles.participantName}>
            {otherParticipant?.displayName}
          </span>
        </>
      ) : (
        <>
          <span className={styles.participantName}>
            {otherParticipant?.displayName}
          </span>
          <HiArrowRight className={styles.directionArrow} />
          <span className={styles.participantName}>You</span>
        </>
      )}
    </div>
  );

  const renderResourceTooltip = () => (
    <div className={styles.resourceTooltip}>
      <p className={styles.resourceLabel}>{data.resource.label}</p>
      {data.resource.tags?.length > 0 && (
        <div className={styles.tooltipTags}>
          {data.resource.tags.map((tag) => (
            <span key={tag._id} className={styles.tooltipTag}>
              {tag.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <BaseCard
      ref={ref}
      data={data}
      onClick={handleClick}
      isSelected={isSelected}
      className={cn(styles[data.status], styles.reachoutCard)}
      headerContent={renderParticipantInfo()}
    >
      <LastMessageDisplay
        lastMessage={lastMessage}
        currentUserId={userData._id}
        otherParticipantName={otherParticipant?.displayName}
      />
      <div className={styles.sharedTags}>
        <Tooltip content={renderResourceTooltip()}>
          <div className={styles.tag}>
            {data.resource.type === "LifeExperience"
              ? "Life Experience"
              : "Story"}
          </div>
        </Tooltip>
      </div>
      {data.message && (
        <div className={styles.reachoutMessage}>
          {isSender ? "Your message: " : "Their message: "}
          {data.message}
        </div>
      )}
    </BaseCard>
  );
});

export default ReachoutCard;
