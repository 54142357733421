// packages/client/src/pages/Register/StepReview.jsx
import React from "react";
import common from "../Register.module.css";
import self from "./StepReview.module.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { BsCheckCircleFill } from "react-icons/bs";
import { BaseLifeExperienceCard } from "components/LifeExperienceCard/LifeExperienceCard";
import { motion } from "framer-motion";
import getAge from "utils/getAge";

const fadeInVariants = {
  hidden: {
    opacity: 0,
    y: 10,
    pointerEvents: "none",
  },
  visible: {
    opacity: 1,
    y: 0,
    pointerEvents: "auto",
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    pointerEvents: "none",
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.2,
    },
  },
};

const StepReview = ({ data, submissionStatus }) => {
  function getGender(val) {
    let gender = val.toLowerCase();
    if (gender === "male") {
      return "(M)";
    } else if (gender === "female") {
      return "(F)";
    } else {
      return "person";
    }
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <div className={common.header}>
        <motion.h1 variants={fadeInVariants}>Review your information</motion.h1>
      </div>

      <div
        className={`${self.container} ${
          submissionStatus !== "idle" ? self.overlay : ""
        }`}
      >
        {/* Username Section */}
        <motion.div className={self.section} variants={fadeInVariants}>
          <h2 className={self.sectionTitle}>Account</h2>
          <div className={self.infoRow}>
            <span>Username:</span>
            <span className={self.data}>{data.username}</span>
          </div>
        </motion.div>

        {/* Personal Info Section */}
        <motion.div className={self.section} variants={fadeInVariants}>
          <h2 className={self.sectionTitle}>Personal Information</h2>
          <div className={self.infoRow}>
            <span>Age:</span>
            <span className={self.data}>{getAge(data.birthdate)}</span>
          </div>
          <div className={self.infoRow}>
            <span>Gender:</span>
            <span className={self.data}>{data.gender}</span>
          </div>
          <div className={self.infoRow}>
            <span>From:</span>
            <span className={self.data}>
              {data.location?.formattedName ||
                data.location?.name ||
                "Not specified"}
            </span>
          </div>
        </motion.div>

        {/* Life Experiences Section */}
        <motion.div className={self.section} variants={fadeInVariants}>
          <h2 className={self.sectionTitle}>Life Experiences</h2>
          <div className={self.experiencesSection}>
            {data.lifeExperiences && data.lifeExperiences.length > 0 ? (
              <motion.div
                variants={containerVariants}
                className={self.lifeExperienceList}
              >
                {data.lifeExperiences.map((experience, i) => (
                  <div key={i} className={self.cardWrapper}>
                    <BaseLifeExperienceCard
                      experience={experience}
                      theme="transparent"
                    />
                  </div>
                ))}
              </motion.div>
            ) : (
              <div className={self.emptyState}>
                You haven't shared any life experiences yet
              </div>
            )}
          </div>
        </motion.div>

        {submissionStatus !== "idle" && (
          <motion.div
            className={self.statusOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {submissionStatus === "loading" && <LoadingSpinner size={40} />}
            {submissionStatus === "success" && (
              <BsCheckCircleFill size={40} className={self.successIcon} />
            )}
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default StepReview;
