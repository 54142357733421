import React, { useCallback } from "react";
import styles from "./StoryInput.module.css";

const StoryInput = ({
  value,
  onChange,
  onValidationChange,
  placeholder,
  minWords = 15,
  required = false,
}) => {
  const wordCount = value.trim() ? value.trim().split(/\s+/).length : 0;

  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      const newWordCount = newValue.trim()
        ? newValue.trim().split(/\s+/).length
        : 0;

      onChange(newValue);
      onValidationChange?.({
        isValid: newWordCount >= minWords,
        errors:
          newWordCount < minWords
            ? [`At least ${minWords - newWordCount} more words needed`]
            : [],
      });
    },
    [onChange, onValidationChange, minWords]
  );

  return (
    <div className={styles.container}>
      <textarea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={`${styles.input} ${
          wordCount >= minWords ? styles.valid : value ? styles.invalid : ""
        }`}
        required={required}
        rows={6}
      />
      <div className={styles.footer}>
        <div className={styles.wordCounter}>
          <span
            className={`${styles.count} ${
              wordCount >= minWords ? styles.valid : value ? styles.invalid : ""
            }`}
          >
            {wordCount}
          </span>
          <span className={styles.label}>/ {minWords} words minimum</span>
        </div>
        {wordCount < minWords && value && (
          <div className={styles.error}>
            {`At least ${minWords - wordCount} more words needed`}
          </div>
        )}
      </div>
    </div>
  );
};

export default StoryInput;
