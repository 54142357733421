import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsExclamationTriangle } from "react-icons/bs";
import { FaFlag, FaUser, FaBook, FaComment } from "react-icons/fa";
import styles from "./ReportDetail.module.css";
import Button from "components/Button/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useAdmin } from "hooks/useAdmin";
import { formatDate } from "utils/dates";
import { useToastContext } from "contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import ReportActionModal from "../../components/ReportActionModal";

const ReportDetail = ({ reportId }) => {
  const {
    reportDetails,
    fetchReportDetail,
    startReportInvestigation,
    resolveReportIssue,
    dismissReportIssue,
  } = useAdmin();
  const { addToast } = useToastContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
  const [isDismissModalOpen, setIsDismissModalOpen] = useState(false);
  const [actionReason, setActionReason] = useState("");
  const [actionType, setActionType] = useState("content_removed");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Define the loadReportDetails function that will be used for retrying
  const loadReportDetails = async () => {
    try {
      setIsLoading(true);
      await fetchReportDetail(reportId);
      setError(null);
    } catch (err) {
      setError(
        "Report not found or you don't have permission to view this report"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadReportDetails();
  }, [reportId]);

  const handleBack = () => {
    navigate("/admin/reports");
  };

  const handleStartInvestigation = async () => {
    try {
      setIsSubmitting(true);
      await startReportInvestigation(reportId);
      addToast("Investigation started", "success");
      loadReportDetails();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResolveSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      await resolveReportIssue(reportId, data);
      addToast("Report resolved", "success");
      setIsResolveModalOpen(false);
      loadReportDetails();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDismissSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      await dismissReportIssue(reportId, data);
      addToast("Report dismissed", "success");
      setIsDismissModalOpen(false);
      loadReportDetails();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Format target type for display
  const formatTargetType = (type) => {
    switch (type) {
      case "Story":
        return "Content (Story)";
      case "LifeExperience":
        return "Content (Life Experience)";
      case "User":
        return "User";
      case "Reachout":
        return "Reachout";
      case "Match":
        return "Match";
      case "Chat":
        return "Chat";
      default:
        return type;
    }
  };

  // Format reason for display
  const formatReason = (reason) => {
    return reason?.replace(/_/g, " ") || "";
  };

  // Render snapshot content based on type
  const renderSnapshotContent = () => {
    if (!reportDetails?.snapshot) return null;

    const snapshot = reportDetails.snapshot;

    switch (reportDetails.targetType) {
      case "Story":
        return (
          <div className={styles.snapshotContent}>
            <h4>{snapshot.title || "No title"}</h4>
            <p className={styles.contentText}>
              {snapshot.text || "No content"}
            </p>
            <div className={styles.contentMeta}>
              <span>Created: {formatDate(snapshot.createdAt)}</span>
              {snapshot.status && (
                <span className={styles[`status-${snapshot.status}`]}>
                  {snapshot.status}
                </span>
              )}
            </div>
          </div>
        );

      case "LifeExperience":
        return (
          <div className={styles.snapshotContent}>
            <h4>
              Experience from {snapshot.date?.year}
              {snapshot.date?.month ? `-${snapshot.date.month}` : ""}
            </h4>
            <p className={styles.contentText}>
              {snapshot.story || "No content"}
            </p>
            <div className={styles.contentMeta}>
              <span>Created: {formatDate(snapshot.createdAt)}</span>
              {snapshot.status && (
                <span className={styles[`status-${snapshot.status}`]}>
                  {snapshot.status}
                </span>
              )}
            </div>
          </div>
        );

      case "User":
        return (
          <div className={styles.snapshotContent}>
            <div className={styles.userInfo}>
              <div className={styles.userAvatar}>
                <FaUser size={32} />
              </div>
              <div>
                <h4>{snapshot.username || "Unknown user"}</h4>
                <p>{snapshot.email || ""}</p>
                {snapshot.status && (
                  <span className={styles[`status-${snapshot.status}`]}>
                    {snapshot.status}
                  </span>
                )}
              </div>
            </div>
          </div>
        );

      case "Reachout":
      case "Match":
      case "Chat":
        return (
          <div className={styles.snapshotContent}>
            <h4>{formatTargetType(reportDetails.targetType)}</h4>
            {snapshot.recentMessages && snapshot.recentMessages.length > 0 ? (
              <div className={styles.messagesList}>
                {snapshot.recentMessages.map((msg, index) => (
                  <div key={index} className={styles.messageItem}>
                    <div className={styles.messageSender}>
                      {msg.sender?.username || "Unknown"}:
                    </div>
                    <div className={styles.messageContent}>
                      {msg.content?.text || "No message content"}
                    </div>
                    <div className={styles.messageTime}>
                      {formatDate(msg.createdAt)}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No messages available</p>
            )}
          </div>
        );

      default:
        return <p>No preview available for this content type</p>;
    }
  };

  // Render action buttons based on report status
  const renderActionButtons = () => {
    const buttons = [];

    if (reportDetails?.status === "pending") {
      buttons.push(
        <Button
          key="investigate"
          onClick={handleStartInvestigation}
          disabled={isSubmitting}
        >
          Start Investigation
        </Button>
      );
    }

    if (
      reportDetails?.status === "pending" ||
      reportDetails?.status === "investigating"
    ) {
      buttons.push(
        <Button
          key="resolve"
          onClick={() => setIsResolveModalOpen(true)}
          disabled={isSubmitting}
        >
          Resolve Report
        </Button>,
        <Button
          key="dismiss"
          onClick={() => setIsDismissModalOpen(true)}
          color="secondary"
          disabled={isSubmitting}
        >
          Dismiss Report
        </Button>
      );
    }

    return buttons.length > 0 ? (
      <div className={styles.actionButtons}>{buttons}</div>
    ) : null;
  };

  if (isLoading) {
    return (
      <div className={styles.reportDetailContainer}>
        <div className={styles.reportDetailHeader}>
          <button className={styles.backButton} onClick={handleBack}>
            <BsArrowLeft /> Back to Reports
          </button>
        </div>
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.reportDetailContainer}>
        <div className={styles.reportDetailHeader}>
          <button className={styles.backButton} onClick={handleBack}>
            <BsArrowLeft /> Back to Reports
          </button>
        </div>
        <div className={styles.errorContainer}>
          <BsExclamationTriangle size={48} />
          <p>{error || "Report not found"}</p>
          <Button onClick={loadReportDetails}>Try Again</Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.reportDetailContainer}>
      <div className={styles.reportDetailHeader}>
        <button className={styles.backButton} onClick={handleBack}>
          <BsArrowLeft /> Back to Reports
        </button>
        <div className={styles.reportStatus}>
          <span className={styles[`status-${reportDetails.status}`]}>
            {reportDetails.status}
          </span>
        </div>
      </div>

      <div className={styles.reportDetailContent}>
        <div className={styles.reportOverview}>
          <div className={styles.reportIcon}>
            <FaFlag size={32} />
          </div>
          <div className={styles.reportInfo}>
            <h2>Report #{reportDetails._id.substring(0, 8)}</h2>
            <div className={styles.reportMeta}>
              <span>Reported: {formatDate(reportDetails.createdAt)}</span>
              <span>Type: {formatTargetType(reportDetails.targetType)}</span>
              <span>Reason: {formatReason(reportDetails.reason)}</span>
              <span>Age: {reportDetails.ageInDays} days</span>
            </div>
          </div>
        </div>

        {renderActionButtons()}

        <div className={styles.reportSection}>
          <h3>Report Details</h3>
          <div className={styles.reportDetails}>
            <p>{reportDetails.details}</p>
          </div>
        </div>

        <div className={styles.reportSection}>
          <h3>Reported Content</h3>
          <div className={styles.snapshotContainer}>
            {renderSnapshotContent()}
          </div>
        </div>

        <div className={styles.reportSection}>
          <h3>People Involved</h3>
          <div className={styles.peopleInvolved}>
            <div className={styles.personCard}>
              <h4>Reporter</h4>
              <div className={styles.personInfo}>
                <div className={styles.personAvatar}>
                  <FaUser size={24} />
                </div>
                <div>
                  <p className={styles.personName}>
                    {reportDetails.reporter?.username || "Unknown"}
                  </p>
                  <p className={styles.personEmail}>
                    {reportDetails.reporter?.email || ""}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.personCard}>
              <h4>Reported User</h4>
              <div className={styles.personInfo}>
                <div className={styles.personAvatar}>
                  <FaUser size={24} />
                </div>
                <div>
                  <p className={styles.personName}>
                    {reportDetails.reportedUser?.username || "Unknown"}
                  </p>
                  <p className={styles.personEmail}>
                    {reportDetails.reportedUser?.email || ""}
                  </p>
                  {reportDetails.reportedUser?.status && (
                    <span
                      className={
                        styles[`status-${reportDetails.reportedUser.status}`]
                      }
                    >
                      {reportDetails.reportedUser.status}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {reportDetails.statusHistory &&
          reportDetails.statusHistory.length > 0 && (
            <div className={styles.reportSection}>
              <h3>Status History</h3>
              <div className={styles.statusHistory}>
                {reportDetails.statusHistory.map((status, index) => (
                  <div key={index} className={styles.statusItem}>
                    <div className={styles.statusInfo}>
                      <span className={styles[`status-${status.status}`]}>
                        {status.status}
                      </span>
                      <span className={styles.statusDate}>
                        {formatDate(status.changedAt)}
                      </span>
                    </div>
                    {status.note && (
                      <p className={styles.statusNote}>{status.note}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

        {reportDetails.resolution && (
          <div className={styles.reportSection}>
            <h3>Resolution</h3>
            <div className={styles.resolution}>
              <div className={styles.resolutionInfo}>
                <span className={styles.resolutionAction}>
                  Action: {formatReason(reportDetails.resolution.action)}
                </span>
              </div>
              {reportDetails.resolution.notes && (
                <p className={styles.resolutionNotes}>
                  {reportDetails.resolution.notes}
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Replace the existing modals with our shared components */}
      <ReportActionModal
        isOpen={isResolveModalOpen}
        onClose={() => setIsResolveModalOpen(false)}
        actionType="Resolve"
        report={reportDetails}
        onSubmit={handleResolveSubmit}
        isSubmitting={isSubmitting}
      />

      <ReportActionModal
        isOpen={isDismissModalOpen}
        onClose={() => setIsDismissModalOpen(false)}
        actionType="Dismiss"
        report={reportDetails}
        onSubmit={handleDismissSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ReportDetail;
