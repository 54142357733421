import { useEffect, useState } from "react";
import styles from "./ReportManagement.module.css";
import { useAdmin } from "hooks/useAdmin";
import {
  BsThreeDotsVertical,
  BsExclamationTriangle,
  BsSearch,
  BsFilter,
} from "react-icons/bs";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { formatDate } from "utils/dates";
import Modal from "components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import ReportActionModal from "../components/ReportActionModal";

// Status options for filtering
const STATUS_OPTIONS = [
  { value: "", label: "All Statuses" },
  { value: "pending", label: "Pending" },
  { value: "investigating", label: "Investigating" },
  { value: "resolved", label: "Resolved" },
  { value: "dismissed", label: "Dismissed" },
];

// Target type options for filtering
const TARGET_TYPE_OPTIONS = [
  { value: "", label: "All Types" },
  { value: "User", label: "User" },
  { value: "Story", label: "Story" },
  { value: "LifeExperience", label: "Life Experience" },
  { value: "Reachout", label: "Reachout" },
  { value: "Match", label: "Match" },
  { value: "Chat", label: "Chat" },
];

// Reason options for filtering
const REASON_OPTIONS = [
  { value: "", label: "All Reasons" },
  { value: "harassment", label: "Harassment" },
  { value: "hate_speech", label: "Hate Speech" },
  { value: "inappropriate_content", label: "Inappropriate Content" },
  { value: "spam", label: "Spam" },
  { value: "impersonation", label: "Impersonation" },
  { value: "misinformation", label: "Misinformation" },
  { value: "other", label: "Other" },
];

// Helper function to format target type
const formatTargetType = (type) => {
  switch (type) {
    case "Story":
      return "Content (Story)";
    case "LifeExperience":
      return "Content (Life Experience)";
    case "User":
      return "User";
    case "Reachout":
      return "Reachout";
    case "Match":
      return "Match";
    case "Chat":
      return "Chat";
    default:
      return type;
  }
};

// Helper function to format reason
const formatReason = (reason) => {
  return reason?.replace(/_/g, " ") || "";
};

const ReportManagement = () => {
  const { addToast } = useToastContext();
  const {
    reports,
    fetchAllReports,

    resolveReportIssue,
    dismissReportIssue,
  } = useAdmin();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filtering and sorting states
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [targetTypeFilter, setTargetTypeFilter] = useState("");
  const [reasonFilter, setReasonFilter] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  // Add these states for report actions
  const [selectedReport, setSelectedReport] = useState(null);
  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
  const [isDismissModalOpen, setIsDismissModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Load reports on initial render and when filters change
  useEffect(() => {
    loadReports();
  }, [
    page,
    statusFilter,
    targetTypeFilter,
    reasonFilter,
    sortField,
    sortDirection,
  ]);

  const loadReports = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await fetchAllReports({
        page,
        status: statusFilter,
        targetType: targetTypeFilter,
        reason: reasonFilter,
        sort: `${sortDirection === "desc" ? "-" : ""}${sortField}`,
        search: searchTerm,
      });
    } catch (error) {
      console.error("Error loading reports:", error);
      setError("Failed to load reports. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page
    loadReports();
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("desc"); // Default to descending for new field
    }
  };

  const clearFilters = () => {
    setSearchTerm("");
    setStatusFilter("");
    setTargetTypeFilter("");
    setReasonFilter("");
    setSortField("createdAt");
    setSortDirection("desc");
    setPage(1);
  };

  // Handle row click to view report details
  const handleRowClick = (reportId) => {
    navigate(`/admin/reports/${reportId}`);
  };

  // Add these functions to handle report actions
  const handleResolveAction = (report) => {
    setSelectedReport(report);
    setIsResolveModalOpen(true);
  };

  const handleDismissAction = (report) => {
    setSelectedReport(report);
    setIsDismissModalOpen(true);
  };

  const handleResolveSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await resolveReportIssue(selectedReport._id, data);
      addToast("Report resolved successfully", "success");
      loadReports();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsResolveModalOpen(false);
    }
  };

  const handleDismissSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await dismissReportIssue(selectedReport._id, data);
      addToast("Report dismissed successfully", "success");
      loadReports();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsDismissModalOpen(false);
    }
  };

  // Update the getReportActionOptions function
  const getReportActionOptions = (report) => {
    const options = [];

    options.push({
      label: "View Details",
      onClick: () => handleRowClick(report._id),
    });

    // Add resolve/dismiss options based on report status
    if (report.status === "pending" || report.status === "investigating") {
      options.push({
        label: "Resolve",
        onClick: () => handleResolveAction(report),
      });

      options.push({
        label: "Dismiss",
        onClick: () => handleDismissAction(report),
      });
    }

    return options;
  };

  return (
    <div className={styles.reportManagement}>
      <div className={styles.header}>
        <h1>Report Management</h1>
        <div className={styles.stats}>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {Array.isArray(reports)
                ? reports.filter((r) => r.status === "pending").length
                : 0}
            </span>
            <span className={styles.statLabel}>Pending</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {Array.isArray(reports)
                ? reports.filter((r) => r.status === "investigating").length
                : 0}
            </span>
            <span className={styles.statLabel}>Investigating</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {Array.isArray(reports)
                ? reports.filter((r) => r.needsAction).length
                : 0}
            </span>
            <span className={styles.statLabel}>Needs Action</span>
          </div>
        </div>
      </div>

      <div className={styles.filters}>
        <form className={styles.searchForm} onSubmit={handleSearch}>
          <div className={styles.searchInput}>
            <input
              type="text"
              placeholder="Search by username or user ID..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="submit">
              <BsSearch />
            </button>
          </div>
        </form>

        <div className={styles.filterControls}>
          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Status:
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                {STATUS_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Type:
              <select
                value={targetTypeFilter}
                onChange={(e) => setTargetTypeFilter(e.target.value)}
              >
                {TARGET_TYPE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Reason:
              <select
                value={reasonFilter}
                onChange={(e) => setReasonFilter(e.target.value)}
              >
                {REASON_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <Button
            onClick={clearFilters}
            color="secondary"
            className={styles.clearButton}
          >
            Clear Filters
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : error ? (
        <div className={styles.errorContainer}>
          <BsExclamationTriangle size={48} />
          <p>{error}</p>
          <Button onClick={loadReports}>Try Again</Button>
        </div>
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.reportsTable}>
            <thead>
              <tr>
                <th
                  onClick={() => toggleSort("createdAt")}
                  className={styles.sortableHeader}
                >
                  Date
                  {sortField === "createdAt" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th>Type</th>
                <th>Reason</th>
                <th>Reporter</th>
                <th>Reported</th>
                <th
                  onClick={() => toggleSort("status")}
                  className={styles.sortableHeader}
                >
                  Status
                  {sortField === "status" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSort("ageInDays")}
                  className={styles.sortableHeader}
                >
                  Age
                  {sortField === "ageInDays" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th className={styles.actionsHeader}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.length === 0 ? (
                <tr>
                  <td colSpan="8" className={styles.noResults}>
                    No reports found matching your criteria
                  </td>
                </tr>
              ) : (
                reports.map((report) => (
                  <tr
                    key={report._id}
                    className={styles.reportRow}
                    onClick={() => handleRowClick(report._id)}
                  >
                    <td className={styles.userDataCell}>
                      {formatDate(report.createdAt)}
                    </td>
                    <td className={styles.userDataCell}>
                      {formatTargetType(report.targetType)}
                    </td>
                    <td className={styles.userDataCell}>
                      {formatReason(report.reason)}
                    </td>
                    <td className={styles.userDataCell}>
                      {report.reporter?.username || "Unknown"}
                    </td>
                    <td className={styles.userDataCell}>
                      {report.reportedUser?.username || "Unknown"}
                    </td>
                    <td className={styles.userDataCell}>
                      <span className={styles[`status-${report.status}`]}>
                        {report.status}
                      </span>
                    </td>
                    <td className={styles.userDataCell}>
                      {report.ageInDays} days
                    </td>
                    <td
                      className={styles.actionCell}
                      onClick={(e) => e.stopPropagation()} // Prevent row click when clicking on actions
                    >
                      <ReusableActionMenu
                        options={getReportActionOptions(report)}
                        icon={BsThreeDotsVertical}
                        menuHeader="Report Actions"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination controls */}
      {reports.length > 0 && (
        <div className={styles.pagination}>
          <Button
            onClick={() => setPage(Math.max(1, page - 1))}
            disabled={page === 1 || isLoading}
          >
            Previous
          </Button>
          <span>
            Page {page}
            {/* Add total pages when available from API */}
          </span>
          <Button
            onClick={() => setPage(page + 1)}
            disabled={reports.length < 10 || isLoading} // Assuming 10 is the page size
          >
            Next
          </Button>
        </div>
      )}

      {/* Add the shared modal components */}
      <ReportActionModal
        isOpen={isResolveModalOpen}
        onClose={() => setIsResolveModalOpen(false)}
        actionType="Resolve"
        report={selectedReport}
        onSubmit={handleResolveSubmit}
        isSubmitting={isSubmitting}
      />

      <ReportActionModal
        isOpen={isDismissModalOpen}
        onClose={() => setIsDismissModalOpen(false)}
        actionType="Dismiss"
        report={selectedReport}
        onSubmit={handleDismissSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ReportManagement;
