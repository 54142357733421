import { useState, useCallback, useEffect } from "react";
import { hugStory } from "api/stories";
import { useToastContext } from "contexts/ToastContext";

/**
 * Custom hook to manage story hug functionality
 * @param {Object} story - The story object
 * @param {Object} userData - The current user's data
 * @returns {Object} Hook methods and state
 */
const useStoryHug = (story, userData) => {
  const [isHugged, setIsHugged] = useState(story?.isHugged ?? false);
  const [hugsLength, setHugsLength] = useState(story?.hugs?.length ?? 0);
  const { addToast } = useToastContext();

  // Update states when story changes
  useEffect(() => {
    if (story) {
      setIsHugged(story.isHugged ?? false);
      setHugsLength(story.hugs?.length ?? 0);
    }
  }, [story]);

  const handleHugClick = useCallback(async () => {
    if (!userData) {
      addToast("Please log in to hug stories", "info");
      return;
    }

    if (!story?._id) {
      addToast("Cannot hug story at this time", "error");
      return;
    }

    // Optimistic update
    setIsHugged((prev) => !prev);
    setHugsLength((prev) => prev + (isHugged ? -1 : 1));

    try {
      const response = await hugStory(story._id);
      setIsHugged(response.isHugged);
      setHugsLength(response.hugCount);
    } catch (error) {
      // Revert on error
      setIsHugged((prev) => !prev);
      setHugsLength((prev) => prev + (isHugged ? 1 : -1));
      addToast("Failed to hug story. Please try again.", "error");
    }
  }, [userData, story?._id, isHugged, addToast]);

  return {
    isHugged,
    hugsLength,
    handleHugClick,
  };
};

export default useStoryHug;
