import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./LoadingMessage.module.css";
import cn from "classnames";

const LoadingMessage = ({ className, message, spinnerClassName }) => {
  return (
    <div className={cn(styles.container, className)}>
      <LoadingSpinner className={spinnerClassName} />
      <span className={styles.message}>{message}</span>
    </div>
  );
};

export default LoadingMessage;
