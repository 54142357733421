import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

/**
 * Component that redirects to the user's profile page or registration if needed
 */
const ProfileRedirect = () => {
  const { userData } = useUserContext();
  const navigate = useNavigate();

  // Use useEffect for navigation to avoid render loops
  useEffect(() => {
    if (userData) {
      // If user is not registered, redirect to registration
      if (!userData.isRegistered) {
        navigate("/register", { replace: true });
        return;
      }

      // If user is registered but has no username, handle this edge case
      if (!userData.username) {
        navigate("/register", { replace: true });
        return;
      }

      // Normal case: redirect to user profile

      navigate(`/user/${userData.username}`, { replace: true });
    }
  }, [userData, navigate]);

  // Show loading state while navigating
  return (
    <LoadingContainer>
      <LoadingMessage message="Redirecting..." />
    </LoadingContainer>
  );
};

export default ProfileRedirect;
