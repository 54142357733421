import React, { useState, useRef, useEffect } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import styles from "../Profile.module.css";

const SearchInput = ({ value, onChange, onClear, onSearch, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && onSearch) {
      onSearch();
    }
  };

  // Focus the input when component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div
      className={`${styles.searchContainer} ${isFocused ? styles.focused : ""}`}
    >
      <div className={styles.searchIcon}>
        <FiSearch size={20} />
      </div>
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        className={styles.searchInput}
        aria-label={placeholder}
      />
      {value && (
        <button
          onClick={onClear}
          className={styles.clearButton}
          aria-label="Clear search"
          type="button"
        >
          <FiX size={20} />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
