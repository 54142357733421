const SYSTEM_MESSAGE_TYPES = {
  REACHOUT_CREATED: "reachoutCreated",
  REACHOUT_ACCEPTED: "reachoutAccepted",
  REACHOUT_COMPLETED: "reachoutCompleted",
  REACHOUT_EXPIRED: "reachoutExpired",
  MATCH_CREATED: "matchCreated",
  MATCH_ACCEPTED: "matchAccepted",
  MATCH_COMPLETED: "matchCompleted",
  MATCH_EXPIRED: "matchExpired",
  CONNECTION_ADDED: "connectionAdded",
};

// Helper to safely get message payload
const getMessagePayload = (message) => {
  if (!message?.content) return null;

  // Handle new message format
  if (message.content.payload) {
    return message.content.payload;
  }

  // Handle legacy format
  if (message.content.data?.[message.content.systemType]) {
    return message.content.data[message.content.systemType];
  }

  return null;
};

const formatResourceType = (type) => {
  switch (type) {
    case "LifeExperience":
      return "life experience";
    case "Story":
      return "story";
    default:
      return type.toLowerCase();
  }
};

const getOtherParticipant = (participants, currentUserId) => {
  if (!participants?.length || !currentUserId) return null;
  return participants.find((p) => p.userId !== currentUserId);
};

const isUserParticipant = (participants, userId) => {
  if (!participants?.length || !userId) return false;
  return participants.some((p) => p.userId === userId);
};

// Helper to check message type
const isSystemMessageType = (message, type) => {
  return message?.content?.systemType === type;
};

/**
 * @typedef {Object} StandardizedParticipant
 * @property {string} _id - Participant's ID
 * @property {string} username - Real username
 * @property {string} [pseudonym] - Optional pseudonym (for match/reachout)
 * @property {string} [ageRange] - Optional age range
 * @property {Object} [location] - Optional location info
 * @property {string} location.name - Location name
 * @property {string} [location.formattedName] - Formatted location name
 * @property {string} [location.code] - Location code
 * @property {string} [gender] - Optional gender
 * @property {string} status - Account status
 * @property {string} registrationStatus - Registration status
 * @property {Object} scores - User scores
 * @property {number} scores.trustScore - Trust score
 * @property {number} scores.warmthScore - Warmth score
 * @property {number} scores.totalReviews - Total reviews
 * @property {string} [role] - Optional role in reachout/match ("sender"|"recipient")
 * @property {boolean} [accepted] - Optional acceptance status for reachout/match
 * @property {string} [acceptedAt] - Optional acceptance timestamp for reachout/match
 */

/**
 * Transforms participant data from various sources into a standardized format
 * @param {Object} rawParticipant - Raw participant data from match/reachout/chat
 * @returns {StandardizedParticipant} Standardized participant data
 */
const standardizeParticipant = (rawParticipant) => {
  if (!rawParticipant) {
    console.warn("[standardizeParticipant] No rawParticipant provided.");
    return null;
  }

  // For profile data, prefer root level over nested user data, except for username
  const standardized = {
    _id: rawParticipant._id,
    // Prefer nested user.username over root username since root might be "anonymous"
    username: rawParticipant.user?.username || rawParticipant.username,
    ageRange: rawParticipant.ageRange,
    location: rawParticipant.location,
    gender: rawParticipant.gender,
    status: rawParticipant.status || rawParticipant.user?.status,
    registrationStatus: rawParticipant.registrationStatus,
    scores: rawParticipant.scores ||
      rawParticipant.user?.scores || {
        trustScore: 0,
        warmthScore: 0,
        totalReviews: 0,
      },
  };

  // Add match/reachout specific fields if they exist
  if (rawParticipant.pseudonym) {
    standardized.pseudonym = rawParticipant.pseudonym;
  }
  if (rawParticipant.role) {
    standardized.role = rawParticipant.role;
  }
  if (rawParticipant.accepted !== undefined) {
    standardized.accepted = rawParticipant.accepted;
  }
  if (rawParticipant.acceptedAt) {
    standardized.acceptedAt = rawParticipant.acceptedAt;
  }

  // Determine display name
  standardized.displayName =
    standardized.username === "anonymous" && standardized.pseudonym
      ? standardized.pseudonym
      : standardized.username;

  return standardized;
};

/**
 * Transforms a participant object into the format expected by UserInfoDialog
 * @param {Object} participant - The participant object from chat/match/reachout
 * @returns {Object|null} Transformed data for UserInfoDialog
 */
const transformParticipantForDialog = (participant) => {
  if (!participant) return null;

  // Participant is already standardized, so we just need to reshape it
  return {
    _id: participant._id,
    status: participant.status,
    username: participant.username,
    pseudonym: participant.pseudonym,
    scores: participant.scores,
    registrationStatus: participant.registrationStatus,
    ageRange: participant.ageRange,
    gender: participant.gender,
    location: participant.location,
  };
};

export {
  SYSTEM_MESSAGE_TYPES,
  formatResourceType,
  getOtherParticipant,
  isUserParticipant,
  getMessagePayload,
  isSystemMessageType,
  transformParticipantForDialog,
  standardizeParticipant,
};
