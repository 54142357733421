import React from "react";
import StoryForm from "components/StoryForm/StoryForm";
import useStoryCRUD from "hooks/useStoryCRUD";
import { useLocation } from "react-router-dom";

const NewStory = () => {
  const { status, statusMessage, submitStory } = useStoryCRUD();
  const location = useLocation();
  const initialData = location.state?.initialData || {};

  return (
    <StoryForm
      initialData={initialData}
      onSubmit={submitStory}
      status={status}
      statusMessage={statusMessage}
      initialTheme="dark"
    />
  );
};

export default NewStory;
