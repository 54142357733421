import api from "api";
import handleAxiosError from "./handleAxiosError";

/**
 * Searches for content based on a query.
 * @param {Object} params - The search parameters.
 * @param {string} params.query - The search query.
 * @param {number} [params.page=1] - The page number for pagination.
 * @param {number} [params.limit=10] - The number of results per page.
 * @param {string} [params.sort="relevance"] - The sorting method for results:
 *   - "relevance": Most relevant to search query (default)
 *   - "newest": Most recently created
 *   - "oldest": Oldest content first
 *   - "popular": Most overall engagement
 *   - "trending": Recently trending content
 * @param {string} [params.type] - The type of content to search for ('experiences', 'stories', or undefined for both).
 * @param {string} [params.tags] - Comma-separated list of tags to filter results.
 * @param {string} [params.authorId] - The ID of the author to filter results by.
 * @param {boolean} [params.includeOwn] - Whether to include the user's own content in the results.
 * @returns {Promise<Object>} A promise that resolves to the search results with the following structure:
 * - query: The search query used
 * - appliedFilters: The filters that were applied to the search
 * - experiences: Experience results (if requested)
 *   - results: Array of experience objects
 *   - total: Total number of matching experiences
 *   - undiscoveredCount: Number of undiscovered experiences (for user-specific searches)
 *   - pagination: Pagination information for experiences
 * - stories: Story results (if requested)
 *   - results: Array of story objects
 *   - total: Total number of matching stories
 *   - undiscoveredCount: Number of undiscovered stories (for user-specific searches)
 *   - pagination: Pagination information for stories
 */
export const unifiedContentSearch = async ({
  query,
  page = 1,
  limit = 10,
  sort = "relevance",
  type,
  tags,
  authorId,
  includeOwn,
}) => {
  // Validate sort parameter against allowed values
  const validSorts = ["relevance", "newest", "oldest", "popular", "trending"];
  if (!validSorts.includes(sort)) {
    throw new Error(
      `Invalid sort value. Allowed values are: ${validSorts.join(", ")}`
    );
  }

  try {
    const response = await api.get("search", {
      params: {
        q: query,
        page,
        limit,
        sort,
        type,
        tags,
        authorId,
        includeOwn,
      },
    });

    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Helper function to get the total results count from a search response
 * @param {Object} searchResponse - The response from unifiedContentSearch
 * @returns {number} The total number of results across all content types
 */
export const getTotalResultsCount = (searchResponse) => {
  let total = 0;

  if (searchResponse.experiences) {
    total += searchResponse.experiences.total || 0;
  }

  if (searchResponse.stories) {
    total += searchResponse.stories.total || 0;
  }

  return total;
};

/**
 * Helper function to check if there are more results to load
 * @param {Object} searchResponse - The response from unifiedContentSearch
 * @returns {boolean} True if there are more results to load
 */
export const hasMoreResults = (searchResponse) => {
  if (searchResponse.experiences?.pagination?.hasMore) {
    return true;
  }

  if (searchResponse.stories?.pagination?.hasMore) {
    return true;
  }

  return false;
};
