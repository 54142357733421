import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import PageWrapper from "components/PageWrapper/PageWrapper";
import styles from "./Rules.module.css";

const Rules = () => {
  const [markdown, setMarkdown] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch("/rules.md")
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading rules:", error);
        setIsLoading(false);
      });
  }, []);

  return (
   
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {isLoading ? (
            <div className={styles.loading}>Loading...</div>
          ) : (
            <>
              <h1 className={styles.mainTitle}>Rules</h1>
              <div className={styles.lastUpdated}>
                Last updated: {new Date().toLocaleDateString()}
              </div>
              <div className={styles.content}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {markdown}
                </ReactMarkdown>
              </div>
            </>
          )}
        </div>
      </div>

  );
};

export default Rules;
