import React from "react";
import { motion } from "framer-motion";
import { ReactTyped } from "react-typed";
import ConnectionAnimation from "./ConnectionAnimation";
import styles from "./HeroSection.module.css";

const HeroSection = ({ onLearnMore, onTechnologyClick }) => {
  return (
    <section className={styles.hero}>
      <div className={styles.content}>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={styles.title}
        >
          You aren't alone.
        </motion.h1>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className={styles.subtitleContainer}
        >
          <p className={styles.subtitle}>Find someone who has</p>
          <div className={styles.dynamicText}>
            <ReactTyped
              strings={[
                "lost a mom",
                "struggled with cancer",
                "cared for a sick loved one",
                "been a victim of abuse",
                "felt alone",
                "battled depression",
                "needed someone to talk to",
                "overcome addiction",
                "survived trauma",
                "lost a child",
                "faced chronic illness",
                "lived with anxiety",
                "experienced grief",
              ]}
              typeSpeed={40}
              backSpeed={30}
              backDelay={2000}
              loop
              className={styles.typedText}
            />
          </div>
        </motion.div>
      </div>

      <ConnectionAnimation />

      <motion.div
        className={styles.cta}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1.2 }}
      >
        <button
          className={styles.primaryButton}
          onClick={() => (window.location.href = "/login")}
        >
          Join Stumble
        </button>
        <button className={styles.secondaryButton} onClick={onLearnMore}>
          Learn how it works
        </button>
        <button className={styles.secondaryButton} onClick={onTechnologyClick}>
          Our technology
        </button>
      </motion.div>
    </section>
  );
};

export default HeroSection;
