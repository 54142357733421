// packages/client/src/pages/Register/StepLifeExperiences.jsx
import React, { useRef, useState, useEffect } from "react";
import common from "../Register.module.css";
import self from "./StepLifeExperiences.module.css";
import { motion } from "framer-motion";
import Button from "components/Button/Button";
import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";
import { ReactTyped } from "react-typed";
import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";
import { BaseLifeExperienceCard } from "components/LifeExperienceCard/LifeExperienceCard";
import useRegistrationLifeExperiences from "../hooks/useRegistrationLifeExperiences";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";

const steps = [
  {
    target: '[data-tour="editor"]',
    title: "What is a Life Experience?",
    content: (
      <div>
        <p>Share significant life challenges that have shaped who you are:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🤍 Loss of loved ones</li>
          <li>🏥 Health challenges</li>
          <li>💔 Relationship struggles</li>
          <li>📚 Academic/career pressures</li>
          <li>💰 Financial difficulties</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="tag-editor"]',
    title: "Adding Tags",
    content: (
      <div>
        <p>Tags help organize and connect experiences:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🏷️ Choose relevant categories</li>
          <li>🤝 Find others with similar experiences</li>
          <li>🔍 Make your story discoverable</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="story-editor"]',
    title: "Sharing Your Story",
    content: (
      <div>
        <p>Your story helps others understand your journey:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>📝 Share what happened</li>
          <li>💭 Describe how it affected you</li>
          <li>💪 Include how you're coping</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="editor"]',
    title: "Privacy & Matching",
    content: (
      <div>
        <p>Your experiences are protected:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🔒 Share only what you're comfortable with</li>
          <li>👥 Control who sees your stories</li>
          <li>✨ Get matched with understanding peers</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
];

const StepLifeExperiences = ({
  data,
  onSave,
  handleStepValidity,
  tourCompleted = false,
  onTourComplete,
}) => {
  const {
    lifeExperiences,
    isModalOpen,
    currentExperience,
    canAddNew,
    showDeleteConfirmation,
    handleCreate,
    handleUpdate,
    handleDeleteClick,
    handleDeleteConfirm,
    handleDeleteCancel,
    handleAddClick,
    handleEditClick,
    handleCloseModal,
  } = useRegistrationLifeExperiences(data, onSave, handleStepValidity);

  const [animationStep, setAnimationStep] = useState(1);
  const isFirstOpen = useRef(true);
  const [startTour, setStartTour] = useState(false);

  // Only show the tour when the modal is open and manage completion state properly
  useEffect(() => {
    if (isModalOpen) {
      setStartTour(true);
    } else {
      // Hide tour when modal closes
      setStartTour(false);
    }
  }, [isModalOpen]);

  // Animation variants
  const variants = {
    hidden: { opacity: 0, pointerEvents: "none" },
    visible: { opacity: 1, pointerEvents: "auto" },
  };

  // Calculate remaining experiences
  const maxExperiences = 3;
  const remainingExperiences = maxExperiences - lifeExperiences.length;

  // Determine if we've reached the limit
  const isLimitReached = remainingExperiences <= 0;

  // Modify the handleAddWithTour function
  const handleAddWithTour = () => {
    handleAddClick();

    // The tour will automatically show because of the useEffect that watches isModalOpen
    // We just need to track if this is the first time for auto-running
    isFirstOpen.current = isFirstOpen.current && tourCompleted;
  };

  return (
    <>
      <div className={common.header}>
        <ReactTyped
          strings={[`What has life been like for you,^500 ${data?.username}?`]}
          typeSpeed={50}
          backSpeed={35}
          showCursor
          onComplete={(el) => {
            setTimeout(() => {
              el.cursor.hidden = true;
            }, 1500);
            setAnimationStep((prev) => prev + 1);
          }}
        />
      </div>

      <motion.div
        className={self.container}
        variants={variants}
        transition={{ ease: "easeIn", duration: 1 }}
        initial="hidden"
        animate={animationStep >= 1 ? "visible" : "hidden"}
      >
        {/* Experience counter */}
        <div className={self.experienceCounter}>
          <span className={self.counterText}>
            {lifeExperiences.length} of {maxExperiences} experiences added
          </span>
          <div className={self.counterBar}>
            <div
              className={self.counterFill}
              style={{
                width: `${(lifeExperiences.length / maxExperiences) * 100}%`,
              }}
            ></div>
          </div>
        </div>

        <div className={self.lifeExperienceList}>
          {lifeExperiences.length > 0 ? (
            lifeExperiences.map((experience, index) => {
              // Create action menu options for each card
              const actionMenuOptions = [
                {
                  label: "Edit",
                  onClick: () => handleEditClick(experience, index),
                },
                {
                  label: "Delete",
                  onClick: () => handleDeleteClick(experience, index),
                },
              ];

              return (
                <BaseLifeExperienceCard
                  key={index}
                  experience={experience}
                  theme="transparent"
                  actionMenuOptions={actionMenuOptions}
                />
              );
            })
          ) : (
            <div className={self.emptyState}>
              <p>
                Share at least one life experience to connect with others who
                understand (max {maxExperiences})
              </p>
              <p className={self.emptyStateRequired}>
                * At least one experience is required to continue
              </p>
            </div>
          )}
        </div>

        <div className={self.addLifeExp}>
          {isLimitReached ? (
            <div className={self.limitMessage}>
              <p>You've reached the maximum of {maxExperiences} experiences</p>
              <p className={self.limitHelper}>
                You can edit or delete existing experiences if needed
              </p>
            </div>
          ) : (
            <Button onClick={handleAddWithTour} disabled={isLimitReached}>
              {lifeExperiences.length === 0
                ? "Add required experience"
                : remainingExperiences === 1
                ? "Add 1 more experience"
                : `Add up to ${remainingExperiences} more experiences`}
            </Button>
          )}
        </div>
      </motion.div>

      {/* Life Experience Modal */}
      <LifeExperienceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        mode={currentExperience ? "edit" : "new"}
        lifeExperience={currentExperience}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDeleteConfirm}
        initialData={
          currentExperience
            ? currentExperience
            : {
                story: "",
                tags: [],
                date: {
                  month: new Date().getMonth() + 1,
                  year: new Date().getFullYear(),
                },
                anonymized: true,
                searchable: true,
                intents: {
                  venting: false,
                  seeking_advice: false,
                  offering_support: false,
                  sharing_experience: true,
                },
              }
        }
      />

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          itemName="life experience"
        />
      )}

      {/* Only render JoyrideWrapper when the modal is open */}
      {isModalOpen && (
        <JoyrideWrapper
          steps={steps}
          runOnInit={!tourCompleted}
          onTourComplete={onTourComplete}
          hasCompletedTour={tourCompleted}
          fabPosition="bottom-right"
        />
      )}
    </>
  );
};

export default StepLifeExperiences;
