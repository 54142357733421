import { useState } from "react";
import * as REPORTS_API from "api/reports";
import { useToastContext } from "contexts/ToastContext";

export const useReport = (instanceType, instanceId) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { addToast } = useToastContext();

  const submitReport = async ({ reason, details }) => {
    setIsSubmitting(true);
    setError(null);

    try {
      await REPORTS_API.submitReport({
        targetType: instanceType,
        targetId: instanceId,
        reason,
        details,
      });

      addToast("Report submitted successfully", "success");
      return true;
    } catch (err) {
      const errorMessage =
        err.response?.data?.error || err.message || "Failed to submit report";

      setError(errorMessage);
      addToast(errorMessage, "error");
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    submitReport,
    isSubmitting,
    error,
  };
};
