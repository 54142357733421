import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  TbLayoutDashboard,
  TbUsersGroup,
  TbReportSearch,
  TbSettings,
} from "react-icons/tb";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styles from "./AdminDashboard.module.css";

import PageWrapper from "components/PageWrapper/PageWrapper";

import Overview from "./views/Overview";
import UserManagement from "./views/UserManagement";
import ReportManagement from "./views/ReportManagement";
import Settings from "./views/Settings";
import UserDetail from "./views/UserDetail/UserDetail";
import ReportDetail from "./views/ReportDetail/ReportDetail";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleLabel, setToggleLabel] = useState(true);

  // Extract the current view from the URL
  const path = location.pathname.split("/admin/")[1] || "";

  // Map path to view component
  const getViewComponent = () => {
    switch (path) {
      case "":
        return <Overview />;
      case "users":
        return <UserManagement />;
      case "reports":
        return <ReportManagement />;
      case "settings":
        return <Settings />;
      default:
        // Handle nested paths like users/123 or reports/456
        if (path.startsWith("users/")) {
          const userId = path.split("users/")[1];
          return <UserDetail userId={userId} />;
        }
        if (path.startsWith("reports/")) {
          const reportId = path.split("reports/")[1];
          return <ReportDetail reportId={reportId} />;
        }
        return <Overview />;
    }
  };

  // Function to handle view selection
  const handleViewSelect = (path) => {
    navigate(`/admin/${path}`);
  };

  return (
    <PageWrapper>
      <div className={styles.dashboard}>
        <div
          className={styles.sidebar}
          style={{
            width: toggleLabel ? "200px" : "60px",
          }}
        >
          <div className={styles.sidebarHeader}>
            {toggleLabel && <h3>Admin Panel</h3>}
            <button
              className={styles.toggleButton}
              onClick={() => setToggleLabel(!toggleLabel)}
            >
              {toggleLabel ? <IoIosArrowBack /> : <IoIosArrowForward />}
            </button>
          </div>

          <ul className={styles.navMenu}>
            <li
              className={
                location.pathname === "/admin" ||
                location.pathname === "/admin/"
                  ? styles.active
                  : ""
              }
            >
              <button onClick={() => handleViewSelect("")}>
                <TbLayoutDashboard />
                {toggleLabel && <span>Overview</span>}
              </button>
            </li>
            <li
              className={
                location.pathname.includes("/admin/users") ? styles.active : ""
              }
            >
              <button onClick={() => handleViewSelect("users")}>
                <TbUsersGroup />
                {toggleLabel && <span>Users</span>}
              </button>
            </li>
            <li
              className={
                location.pathname.includes("/admin/reports")
                  ? styles.active
                  : ""
              }
            >
              <button onClick={() => handleViewSelect("reports")}>
                <TbReportSearch />
                {toggleLabel && <span>Reports</span>}
              </button>
            </li>
            <li
              className={
                location.pathname.includes("/admin/settings")
                  ? styles.active
                  : ""
              }
            >
              <button onClick={() => handleViewSelect("settings")}>
                <TbSettings />
                {toggleLabel && <span>Settings</span>}
              </button>
            </li>
          </ul>
        </div>

        <div className={styles.contentArea}>{getViewComponent()}</div>
      </div>
    </PageWrapper>
  );
};

export default AdminDashboard;
