import React, { useState, useRef, useMemo } from "react";
import {
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
} from "@floating-ui/react";
import { IoClose } from "react-icons/io5";
import styles from "./ReportModal.module.css";
import Button from "components/Button/Button";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import { useToastContext } from "contexts/ToastContext";

const REPORT_REASONS = [
  { value: "harassment", label: "Harassment" },
  { value: "hate_speech", label: "Hate Speech" },
  { value: "inappropriate_content", label: "Inappropriate Content" },
  { value: "spam", label: "Spam" },
  { value: "impersonation", label: "Impersonation" },
  { value: "misinformation", label: "Misinformation" },
  { value: "other", label: "Other" },
];

const ReportModal = ({
  targetType,
  targetData,
  targetId,
  onSubmit,
  onClose,
  isOpen,
  isSubmitting,
  error,
}) => {
  const { addToast } = useToastContext();
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [errors, setErrors] = useState({});
  const [fetchState, setFetchState] = useState("initial");
  const [view, setView] = useState("form"); // 'form' | 'success' | 'error'
  const [errorMessage, setErrorMessage] = useState("");

  const textAreaRef = useRef();
  const selectRef = useRef();

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  // Compute if form is valid for submit button
  const isFormValid = useMemo(() => {
    if (!reason) return false;
    if (reason === "other" && (!details || details.trim() === "")) return false;
    return true;
  }, [reason, details]);

  const validate = () => {
    const newErrors = {};

    if (!reason) {
      newErrors.reason = "Please select a reason for reporting.";
    }

    if (reason === "other" && (!details || details.trim() === "")) {
      newErrors.details =
        "Please provide details when selecting 'Other' as the reason.";
    } else if (!details || details.trim() === "") {
      newErrors.details = "Please provide details about your report.";
    }

    setErrors(newErrors);

    setTimeout(() => {
      setErrors({});
    }, 3000);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setFetchState("loading");
    try {
      const success = await onSubmit({ reason, details });
      setFetchState("success");
      if (success) {
        setView("success");
        // Don't close automatically - let user see success state
      } else {
        setView("error");
      }
    } catch (err) {
      console.error("Failed to submit report:", err);
      setFetchState("error");
      setView("error");
    }
  };

  // Update SuccessView to handle its own closing
  const SuccessView = ({ onClose, targetType, onBlock }) => (
    <div className={styles.successView}>
      <div className={styles.successHeader}>
        <div className={styles.checkmark}>✓</div>
        <h3>Report Submitted</h3>
      </div>

      <p className={styles.successMessage}>
        Thank you for helping us keep Stumble safe. Our team will review this
        soon.
        {targetType === "User" && (
          <span className={styles.extraInfo}>
            • You won't see this user again in matches.
          </span>
        )}
      </p>

      {targetType === "User" && (
        <div className={styles.actionGroup}>
          <Button onClick={onClose}>Close</Button>
          <Button
            onClick={onBlock}
            variant="danger"
            className={styles.blockButton}
          >
            Block User
          </Button>
        </div>
      )}
    </div>
  );

  const ErrorView = ({ error, onClose }) => {
    // Map error codes to user-friendly messages
    const getErrorMessage = (error) => {
      if (!error) return "An unexpected error occurred. Please try again.";

      const errorMessages = {
        DUPLICATE_REPORT:
          "You already have an active report for this. Our team is reviewing the case.",
        INVALID_REASON: "Please select a valid reason for reporting.",
        INVALID_DETAILS: "Please provide valid details for your report.",
        // Add more error mappings as needed
      };

      return errorMessages[error] || error;
    };

    return (
      <div className={styles.errorView}>
        <div className={styles.errorHeader}>
          <div className={styles.errorIcon}>⚠</div>
          <h3>Unable to Submit Report</h3>
        </div>

        <p className={styles.errorMessage}>{getErrorMessage(error)}</p>

        <Button
          onClick={onClose}
          className={styles.actionButton}
          size="small"
          shape="square"
        >
          Close
        </Button>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay className={styles.overlay} lockScroll>
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.dialog}
            {...getFloatingProps()}
          >
            {view === "form" ? (
              <>
                <div className={styles.header}>
                  <div className={styles.title}>
                    Report{" "}
                    {targetType === "LifeExperience"
                      ? "Life Experience"
                      : targetType}
                  </div>
                  <button onClick={onClose} className={styles.closeButton}>
                    <IoClose />
                  </button>
                </div>

                <div className={styles.content}>
                  <p className={styles.description}>
                    This report will be reviewed by our moderation team. Please
                    provide as much detail as possible.
                  </p>

                  <div className={styles.formContainer}>
                    <div className={styles.formField}>
                      <label className={styles.formFieldLabel}>Reason:</label>
                      <select
                        ref={selectRef}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        disabled={fetchState === "success"}
                      >
                        <option value="">Select a reason...</option>
                        {REPORT_REASONS.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                      {errors.reason && (
                        <FloatingTooltip
                          anchorRef={selectRef}
                          show={errors.reason}
                        >
                          <p style={{ color: "white" }}>{errors.reason}</p>
                        </FloatingTooltip>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <label className={styles.formFieldLabel}>
                        Details:
                        {reason === "other" && (
                          <span className={styles.required}> (required)</span>
                        )}
                      </label>
                      <textarea
                        ref={textAreaRef}
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                        disabled={fetchState === "success"}
                        placeholder={
                          reason === "other"
                            ? "Please explain your reason for reporting..."
                            : "Please provide specific details about your report..."
                        }
                        maxLength={1000}
                      />
                      {errors.details && (
                        <FloatingTooltip
                          anchorRef={textAreaRef}
                          show={errors.details}
                        >
                          <p style={{ color: "white" }}>{errors.details}</p>
                        </FloatingTooltip>
                      )}
                    </div>

                    <Button
                      onClick={handleSubmit}
                      disabled={!isFormValid || fetchState === "success"}
                      fetchState={fetchState}
                      size="small"
                      shape="square"
                    >
                      {fetchState === "loading"
                        ? "Submitting..."
                        : fetchState === "success"
                        ? "Report submitted"
                        : fetchState === "error"
                        ? "Failed to submit"
                        : "Submit Report"}
                    </Button>
                  </div>
                </div>
              </>
            ) : view === "success" ? (
              <SuccessView onClose={onClose} targetType={targetType} />
            ) : (
              <ErrorView error={error} onClose={onClose} />
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default ReportModal;
