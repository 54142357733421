import React from "react";
import styles from "./ChatSideMenu.module.css";

const MatchDetails = ({ data, userData }) => {
  // More thorough null checks
  if (!data?.participants?.length || !userData?._id) return null;

  // Find current user's participant data and other user's participant data
  const currentUserParticipant = data.participants.find(
    (p) => p?.user?._id === userData._id
  );
  const otherParticipant = data.participants.find(
    (p) => p?.user?._id !== userData._id
  );

  // Check if we found both participants
  if (!currentUserParticipant?.story || !otherParticipant?.story) return null;

  return (
    <div className={styles.sideMenuSection}>
      <p className={styles.sectionTitle}>Match Details</p>
      <div className={styles.matchDetailsCard}>
        {/* Stories Section */}
        <div className={styles.storiesContainer}>
          <div className={styles.storySection}>
            <div className={styles.storyLabel}>Their Story</div>
            <div className={styles.storyText}>{otherParticipant.story}</div>
          </div>
          <div className={styles.storySection}>
            <div className={styles.storyLabel}>Your Story</div>
            <div className={styles.storyText}>
              {currentUserParticipant.story}
            </div>
          </div>
        </div>

        {/* Match Metadata */}
        <div className={styles.matchMetadata}>
          <div className={styles.similarityScore}>
            {Math.round(data.metadata.similarity * 100)}% Match
          </div>

          {/* Shared Tags */}
          {data.metadata.sharedTags?.length > 0 && (
            <div className={styles.sharedTags}>
              <div className={styles.tagsLabel}>Shared Interests:</div>
              <div className={styles.tagsList}>
                {data.metadata.sharedTags.map((tag) => (
                  <span key={tag._id} className={styles.tag}>
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div className={styles.matchTimestamp}>
            Matched on {new Date(data.metadata.matchedAt).toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchDetails;
