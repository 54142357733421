import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateBlockStatus } from "../store/actions";
import { useToastContext } from "../contexts/ToastContext";

/**
 * Custom hook for handling blocking functionality for interaction instances
 * @param {string} sourceType - The type of interaction ('chat', 'match', 'reachout')
 * @param {string} sourceId - The ID of the interaction
 * @param {Object} instanceData - The full instance data object
 * @param {Function} [onSuccess] - Optional callback to run on successful block/unblock
 * @returns {Object} Block-related state and functions
 */
export const useBlock = (sourceType, sourceId, instanceData, onSuccess) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const { addToast } = useToastContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Extract the current block status from instanceData
  const isCurrentlyBlocked = instanceData?.isBlocked || false;

  /**
   * Toggle the block status of an interaction
   * @param {Object} [blockData] - Data for blocking (reason, notes)
   * @returns {Promise<boolean>} Success status
   */
  const toggleBlock = useCallback(
    async (blockData = null) => {
      if (!sourceType || !sourceId) {
        setError("Invalid interaction information");
        return false;
      }

      // Determine the target state (opposite of current state)
      const shouldBlock = !isCurrentlyBlocked;

      setIsProcessing(true);
      setError(null);

      try {
        await dispatch(
          updateBlockStatus(
            sourceType,
            sourceId,
            shouldBlock,
            shouldBlock ? blockData : null
          )
        );

        // Show success toast
        addToast(
          `${sourceType.charAt(0).toUpperCase() + sourceType.slice(1)} ${
            shouldBlock ? "blocked" : "unblocked"
          } successfully`,
          "success"
        );

        // Call onSuccess callback if provided
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(shouldBlock);
        }

        // For chat blocks, navigate back to chats list
        if (shouldBlock && sourceType === "chat") {
          navigate("/chats");
        }

        return true;
      } catch (error) {
        console.error(
          `Error ${shouldBlock ? "blocking" : "unblocking"} interaction:`,
          error
        );
        setError(
          error.message ||
            `Failed to ${shouldBlock ? "block" : "unblock"} interaction`
        );
        addToast(
          error.message ||
            `Failed to ${shouldBlock ? "block" : "unblock"} interaction`,
          "error"
        );
        return false;
      } finally {
        setIsProcessing(false);
      }
    },
    [
      sourceType,
      sourceId,
      isCurrentlyBlocked,
      addToast,
      navigate,
      onSuccess,
      dispatch,
    ]
  );

  return {
    toggleBlock,
    isCurrentlyBlocked,
    isProcessing,
    error,
  };
};

export default useBlock;
