import React from "react";
import {
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
} from "@floating-ui/react";
import { GiBilledCap } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import {
  HiCheckCircle,
  HiXCircle,
  HiExclamationCircle,
  HiOutlineUser,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import styles from "./UserInfoDialog.module.css";
import cn from "classnames";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { getScoreDescription } from "utils/scoreUtils";

/**
 * @typedef {Object} UserInfoData
 * @property {string} _id - User ID
 * @property {string} status - Account status (active/suspended/banned/deleted)
 * @property {string} username - Username
 * @property {string} registrationStatus - Registration status (pending/registered/emailVerified/adminApproved)
 * @property {Object} scores - User scores
 * @property {number} scores.trustScore - Trust score (0-5)
 * @property {number} scores.warmthScore - Warmth score (0-5)
 * @property {number} scores.totalReviews - Total number of reviews
 * @property {string} [pseudonym] - Optional anonymous pseudonym
 * @property {string} [ageRange] - Optional age range
 * @property {string} [gender] - Optional gender
 * @property {Object} [location] - Optional location info
 * @property {string} location.name - Location name
 * @property {boolean} [isEmailVerified] - Optional email verification status
 */

const getStatusInfo = (status, registrationStatus) => {
  // Account Status
  const accountStatus = {
    active: { label: "Active", icon: HiCheckCircle, className: "valid" },
    suspended: {
      label: "Suspended",
      icon: HiExclamationCircle,
      className: "warning",
    },
    banned: { label: "Banned", icon: HiXCircle, className: "invalid" },
    deleted: { label: "Deleted", icon: HiXCircle, className: "invalid" },
  };

  // Registration Status
  const regStatus = {
    pending: {
      label: "Registration Pending",
      icon: HiExclamationCircle,
      className: "warning",
    },
    registered: {
      label: "Registered",
      icon: HiCheckCircle,
      className: "valid",
    },
    emailVerified: {
      label: "Email Verified",
      icon: HiCheckCircle,
      className: "valid",
    },
    adminApproved: {
      label: "Admin Approved",
      icon: HiCheckCircle,
      className: "valid",
    },
  };

  return {
    account: accountStatus[status] || accountStatus.active,
    registration: regStatus[registrationStatus] || regStatus.pending,
  };
};

/**
 * Dialog component to display user information
 * @param {Object} props
 * @param {UserInfoData} props.data - User data to display
 * @param {Object} props.chatData - Chat data
 * @param {boolean} props.open - Whether the dialog is open
 * @param {Function} props.onOpenChange - Callback when dialog open state changes
 */
const UserInfoDialog = ({ data, chatData, open, onOpenChange }) => {
  // Add logging

  const { refs, context } = useFloating({
    open,
    onOpenChange,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  if (!open) return null;

  // Show loading state if no data
  if (!data) {
    return (
      <FloatingPortal>
        <FloatingOverlay className={styles.overlay} lockScroll>
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              className={styles.dialog}
              {...getFloatingProps()}
            >
              <div className={styles.header}>
                <div className={styles.title}>Loading...</div>
                <button
                  onClick={() => onOpenChange(false)}
                  className={styles.closeButton}
                >
                  <IoClose />
                </button>
              </div>
              <div className={styles.content}>
                <LoadingSpinner />
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    );
  }

  const {
    username,
    pseudonym,
    scores,
    status,
    registrationStatus,
    // Optional fields
    ageRange,
    gender,
    location,
  } = data;

  const isAnonymous = username === "anonymous";
  const hasNoReviews = !scores?.totalReviews;
  const statusInfo = getStatusInfo(status, registrationStatus);

  const displayName = isAnonymous ? pseudonym : username;

  return (
    <FloatingPortal>
      <FloatingOverlay className={styles.overlay} lockScroll>
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.dialog}
            {...getFloatingProps()}
          >
            <div className={styles.header}>
              <div className={styles.title}>
                {isAnonymous ? (
                  <div className={styles.anonymousHeader}>
                    <GiBilledCap className={styles.anonymousIcon} />
                    <span>{displayName}</span>
                  </div>
                ) : (
                  <span>{displayName}</span>
                )}
              </div>
              <button
                onClick={() => onOpenChange(false)}
                className={styles.closeButton}
              >
                <IoClose />
              </button>
            </div>

            <div className={styles.content}>
              {/* User Info Section */}
              <div className={styles.userInfoSection}>
                <div className={styles.userButton}>
                  <HiOutlineUser />
                  <span>{isAnonymous ? pseudonym : username}</span>
                </div>

                {/* Optional Metadata */}
                {(ageRange || gender || location) && (
                  <div className={styles.userMetadata}>
                    {ageRange && (
                      <div className={styles.metadataItem}>{ageRange}</div>
                    )}
                    {gender && (
                      <div className={styles.metadataItem}>{gender}</div>
                    )}
                    {location && (
                      <div className={styles.metadataItem}>
                        <HiOutlineLocationMarker />
                        {location.name}
                      </div>
                    )}
                  </div>
                )}

                {/* Status Badges */}
                <div className={styles.userMetadata}>
                  <div
                    className={cn(
                      styles.badge,
                      styles[statusInfo.account.className]
                    )}
                  >
                    <statusInfo.account.icon />
                    <span>{statusInfo.account.label}</span>
                  </div>
                  <div
                    className={cn(
                      styles.badge,
                      styles[statusInfo.registration.className]
                    )}
                  >
                    <statusInfo.registration.icon />
                    <span>{statusInfo.registration.label}</span>
                  </div>
                </div>
              </div>

              {/* Scores Section */}
              <div className={styles.scoreSection}>
                <h3>Trust & Reputation</h3>
                {scores && (
                  <div
                    className={cn(styles.scores, {
                      [styles.noReviews]: hasNoReviews,
                    })}
                  >
                    <div className={styles.scoreItem}>
                      <div className={styles.scoreHeader}>
                        <label>Trust Score</label>
                        <div className={styles.scoreValue}>
                          <span>{scores.trustScore.toFixed(1)}</span>
                          <span className={styles.maxScore}>/5.0</span>
                        </div>
                      </div>
                      {!hasNoReviews && (
                        <p className={styles.scoreDescription}>
                          {getScoreDescription(scores.trustScore, "trust")}
                        </p>
                      )}
                    </div>
                    <div className={styles.scoreItem}>
                      <div className={styles.scoreHeader}>
                        <label>Warmth Score</label>
                        <div className={styles.scoreValue}>
                          <span>{scores.warmthScore.toFixed(1)}</span>
                          <span className={styles.maxScore}>/5.0</span>
                        </div>
                      </div>
                      {!hasNoReviews && (
                        <p className={styles.scoreDescription}>
                          {getScoreDescription(scores.warmthScore, "warmth")}
                        </p>
                      )}
                    </div>
                    <div className={styles.scoreItem}>
                      <label>Total Reviews</label>
                      <span>{scores.totalReviews}</span>
                    </div>
                    {hasNoReviews && (
                      <p className={styles.noReviewsMessage}>
                        This user hasn't received any reviews yet. Scores will
                        be visible after their first review.
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default UserInfoDialog;
