import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "hooks/useMediaQuery";
import styles from "./FindOthers.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { unifiedContentSearch } from "api/search"; // Updated import
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import StoryCard from "components/StoryCard/StoryCard";
import OptionsContainer from "./components/OptionsContainer";
import SearchResults from "./components/SearchResults";

function FindOthers() {
  const [input, setInput] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({
    experiences: [],
    stories: [],
    metadata: null,
  });
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const textareaRef = useRef(null);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("sm");
  const isExtraSmall = useMediaQuery("xs");
  const [activeFilter, setActiveFilter] = useState("all");

  // Auto-resize textarea - extremely simplified implementation
  useEffect(() => {
    if (textareaRef.current) {
      // Reset height to content minimum
      textareaRef.current.style.height = "auto";

      // Set height to scrollHeight to fit content exactly
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  // Handle initial query from navigation state
  useEffect(() => {
    const handleInitialSearch = async () => {
      const initialQuery = location.state?.initialQuery;
      const shouldAutoSearch = location.state?.autoSearch;

      if (initialQuery && shouldAutoSearch) {
        // Set input
        setInput(initialQuery);

        // Trigger search
        setIsSearching(true);
        setIsLoading(true);
        setSearchError(null);

        try {
          const results = await unifiedContentSearch({
            query: initialQuery,
            sort: "relevance",
            limit: 10,
          });
          setSearchResults({
            experiences: results.experiences.results || [],
            stories: results.stories.results || [],
            metadata: results.metadata,
          });
        } catch (error) {
          console.error("Search failed:", error);
          setSearchError("Failed to fetch search results. Please try again.");
        } finally {
          setIsLoading(false);
        }

        // Clear the location state to prevent re-searching on refresh
        navigate(".", { replace: true, state: {} });
      }
    };

    handleInitialSearch();
  }, [location.state, navigate]);

  // Handle search change with debounce
  const handleSearchChange = (event) => {
    setInput(event.target.value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setShowOptions(event.target.value.length > 0);
    }, 500);
  };

  const handleOptionClick = async (path) => {
    setShowOptions(false);
    if (path === "browse") {
      if (input.trim().length === 0) {
        return;
      }
      setIsSearching(true);
      setIsLoading(true);
      setSearchError(null);
      try {
        const results = await unifiedContentSearch({
          query: input,
          sort: "relevance",
          limit: 10,
        });
        setSearchResults({
          experiences: results.experiences.results || [],
          stories: results.stories.results || [],
          metadata: results.metadata,
        });
      } catch (error) {
        console.error("Search failed:", error);
        setSearchError("Failed to fetch search results. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else if (path === "match") {
      navigate("/lobby", {
        state: { story: input },
      });
    }
  };

  // Determine current state
  const getLayoutState = () => {
    if (isSearching) return "searching";
    return showOptions ? "options" : "idle";
  };

  return (
    <div
      className={`${styles.container} ${
        isMobile ? styles.containerMobile : ""
      }`}
    >
      <div className={styles.contentWrapper}>
        {/* Search Section - Centered prominently */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={`${styles.searchContainer} ${
            styles[`state-${getLayoutState()}`]
          } ${isMobile ? styles.mobile : styles.desktop} ${
            isExtraSmall ? styles.extraSmall : ""
          }`}
        >
          {!isSearching && (
            <h1 className={styles.searchTitle}>Find Someone Like You</h1>
          )}

          <div
            className={`${styles.searchWrapper} ${
              showOptions ? styles.centeredWithOptions : ""
            }`}
          >
            <textarea
              value={input}
              onChange={handleSearchChange}
              placeholder="What are you going through?"
              className={`${styles.searchInput} ${
                isExtraSmall ? styles.extraSmall : ""
              }`}
              rows={1}
              ref={textareaRef}
            />
          </div>

          <AnimatePresence>
            {getLayoutState() === "options" && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className={styles.optionsContainer}
              >
                <button
                  className={styles.optionButton}
                  onClick={() => handleOptionClick("match")}
                >
                  <span className={styles.optionIcon}>👥</span>
                  Find Someone Now
                  <span className={styles.optionDescription}>
                    Connect with someone to talk in real-time
                  </span>
                </button>

                <button
                  className={styles.optionButton}
                  onClick={() => handleOptionClick("browse")}
                >
                  <span className={styles.optionIcon}>📚</span>
                  Browse Content
                  <span className={styles.optionDescription}>
                    Discover stories and experiences from others
                  </span>
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* Clear Search Button with conditional classes */}
        {isSearching && !isLoading && (
          <button
            className={`${styles.clearSearchButton} ${
              isMobile ? styles.clearButtonMobile : ""
            } ${isExtraSmall ? styles.clearButtonExtraSmall : ""}`}
            onClick={() => {
              setIsSearching(false);
              setSearchResults({
                experiences: [],
                stories: [],
                metadata: null,
              });
              setInput("");
            }}
          >
            Clear Search
          </button>
        )}

        {isSearching && (
          <SearchResults
            searchResults={searchResults}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            isLoading={isLoading}
            searchError={searchError}
          />
        )}
      </div>
    </div>
  );
}

export default FindOthers;
