// packages/client/src/api/auth.js
import { authApi as api } from "api";
import handleAxiosError from "./handleAxiosError";

export const fetchUserData = async () => {
  try {
    const res = await api.get("/user");

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const getAccountStatus = async () => {
  try {
    const res = await api.get("/profile");
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const resendVerificationEmail = async () => {
  try {
    const response = await api.post("/resend-verification");
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

export const reactivateAccount = async () => {
  try {
    const res = await api.post("/reactivate");
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};
