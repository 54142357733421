import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./StoryForm.module.css";
import { debounce } from "lodash";
import cn from "classnames";
import Button from "components/Button/Button";
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { FaEllipsis } from "react-icons/fa6";
import { useInteractions } from "@floating-ui/react";
import { useDismiss } from "@floating-ui/react";
import { FloatingArrow } from "@floating-ui/react";
import useTagsSearch from "hooks/useTagsSearch";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchTagByHyphenatedName } from "api/tags";
import { AnimatePresence, motion } from "framer-motion";
import Portal from "components/Portal/Portal";
import * as API from "api/stories";
import { ToastContext } from "contexts/ToastContext";
import { Tooltip } from "react-tooltip";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu"; // Import ReusableActionMenu
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import { FaInfoCircle, FaMoon, FaSun, FaArrowLeft } from "react-icons/fa";
import {
  Tooltip as ReusableTooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import ToggleButton from "components/Button/ToggleButton/ToggleButton";
import TagInput from "components/TagInput/TagInput";
import * as TAGS_API from "api/tags";
import _ from "lodash";
import useMediaQuery from "hooks/useMediaQuery";

/**
 * Moves the cursor to the end of the provided element.
 * @param {Element} element - The element to move the cursor to the end of.
 */
const moveCursorToEnd = (element) => {
  // Check if the element has content
  if (element && document.createRange) {
    // Create a new range
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false); // Collapse the range to the end point. false means end of the content

    // Get the selection object
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges(); // Remove all ranges from the selection
      selection.addRange(range); // Add the new range
    }
  }
};

// Update the ThemeToggle component to include a back button
const FormHeader = ({ currentTheme, onToggle, onBack }) => {
  return (
    <div className={styles.formTopBar}>
      <button
        onClick={onBack}
        className={styles.backButton}
        aria-label="Go back"
      >
        <FaArrowLeft />
      </button>
      <button
        onClick={onToggle}
        className={styles.themeToggleButton}
        aria-label={`Switch to ${
          currentTheme === "light" ? "dark" : "light"
        } mode`}
      >
        {currentTheme === "light" ? <FaMoon /> : <FaSun />}
      </button>
    </div>
  );
};

// Renamed ToggleButton to EditorToggleButton for MenuBar
const EditorToggleButton = ({ onPressedChange, pressed, children }) => {
  return (
    <button
      onClick={onPressedChange}
      className={pressed ? styles.isActive : ""}
    >
      {children}
    </button>
  );
};

const MenuBar = ({ editor, theme }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className={styles.controlGroup}>
      <div className={styles.buttonGroup}>
        <EditorToggleButton
          onPressedChange={() => editor.chain().focus().toggleBold().run()}
          pressed={editor.isActive("bold")}
        >
          B
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() => editor.chain().focus().toggleItalic().run()}
          pressed={editor.isActive("italic")}
        >
          I
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() => editor.chain().focus().toggleUnderline().run()}
          pressed={editor.isActive("underline")}
        >
          U
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          pressed={editor.isActive("heading", { level: 1 })}
        >
          H1
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          pressed={editor.isActive("heading", { level: 2 })}
        >
          H2
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          pressed={editor.isActive("heading", { level: 3 })}
        >
          H3
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleBlockquote().run()
          }
          pressed={editor.isActive("blockquote")}
        >
          " "
        </EditorToggleButton>
      </div>
    </div>
  );
};

const TiptapEditor = ({ initialData, content, setContent, theme }) => {
  const editorRef = useRef(null);

  const extensions = [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3],
      },
    }),
    Placeholder.configure({
      placeholder: "Write your story...",
    }),
    Underline,
  ];

  const editor = useEditor(
    {
      extensions,
      content: content || initialData?.body || "",
      onUpdate: ({ editor }) => {
        setContent(editor.getHTML());
      },
      editorProps: {
        attributes: {
          class: styles.editorContent,
        },
      },
    },
    [initialData]
  );

  // Handle click on the editor container to focus at the end
  const handleContainerClick = (e) => {
    if (!editor) return;

    // Only handle clicks directly on the container, not on content
    if (e.target === e.currentTarget) {
      editor.commands.focus("end");
    }
  };

  return (
    <div className={styles.editorContainer} ref={editorRef}>
      <MenuBar editor={editor} theme={theme} />
      <div className={styles.editorWrapper} onClick={handleContainerClick}>
        <EditorContent editor={editor} className={styles.body} />
      </div>
    </div>
  );
};

const TitleInput = ({ value, onChange, placeholder }) => {
  const handleInput = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleInput}
      className={cn(styles.input, styles.title)}
      placeholder={placeholder}
    />
  );
};

function StoryForm({
  initialData = {},
  onSubmit,
  status,
  statusMessage,
  initialTheme = "dark",
}) {
  const navigate = useNavigate();
  const [title, setTitle] = useState(initialData?.title || "");
  const [bodyContent, setBodyContent] = useState(initialData?.body || "");
  const [selectedTags, setSelectedTags] = useState(initialData?.tags || []);
  const [recommendedTags, setRecommendedTags] = useState([]);
  const [errors, setErrors] = useState({});
  const [theme, setTheme] = useState(initialTheme);
  const [isLoadingRecommendedTags, setIsLoadingRecommendedTags] =
    useState(false);
  const isMobile = useMediaQuery("sm");

  const prevTitleRef = useRef(initialData?.title);
  const prevBodyRef = useRef(initialData?.body);

  // Add state to track if form has been submitted
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    // Only load initial data if form hasn't been submitted
    if (initialData && !hasSubmitted) {
      setTitle(initialData.title || "");
      setBodyContent(initialData.body || "");
      setSelectedTags(initialData.tags || []);
    }
  }, [initialData, hasSubmitted]);

  // Toggle theme function
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  // Fetch tag recommendations based on title and body content
  useEffect(() => {
    const fetchRecommendedTags = async (text) => {
      if (!text.trim()) {
        return;
      }

      setIsLoadingRecommendedTags(true);
      try {
        const data = await TAGS_API.suggestTags({
          text,
          limit: 5,
        });
        setRecommendedTags(data.tags);
      } catch (err) {
        console.error("Error fetching tag recommendations:", err);
        setRecommendedTags([]);
      } finally {
        setIsLoadingRecommendedTags(false);
      }
    };

    const debouncedFetchRecommendedTags = _.debounce(fetchRecommendedTags, 500);

    // Combine title and body for better tag recommendations
    const combinedText = `${title} ${bodyContent.replace(/<[^>]*>/g, "")}`;

    if (title !== prevTitleRef.current || bodyContent !== prevBodyRef.current) {
      debouncedFetchRecommendedTags(combinedText);
      prevTitleRef.current = title;
      prevBodyRef.current = bodyContent;
    }

    return () => {
      debouncedFetchRecommendedTags.cancel();
    };
  }, [title, bodyContent]);

  const handleTitleChange = (value) => {
    setTitle(value);
    if (errors.title) {
      setErrors((prev) => ({ ...prev, title: "" }));
    }
  };

  const handleBodyChange = (value) => {
    setBodyContent(value);
    if (errors.body) {
      setErrors((prev) => ({ ...prev, body: "" }));
    }
  };

  const handleTagsChange = (tags) => {
    setSelectedTags(tags);
    if (errors.tags) {
      setErrors((prev) => ({ ...prev, tags: "" }));
    }
  };

  const { addToast } = useContext(ToastContext);

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!title.trim()) {
      newErrors.title = "Please add a title for your story";
      isValid = false;
    }

    const plainTextBody = bodyContent.replace(/<[^>]*>/g, "").trim();
    if (!plainTextBody) {
      newErrors.body = "Please write something in your story";
      isValid = false;
    }

    // Tags are optional, so no validation needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setHasSubmitted(true); // Mark form as submitted

    if (!validateForm()) {
      addToast("Please fix the errors in the form", "error");
      return;
    }

    try {
      const tagIds = selectedTags.map((tag) => tag._id);

      if (typeof onSubmit === "function") {
        await onSubmit({
          title,
          body: bodyContent,
          tags: tagIds,
          anonymized: true,
        });
      }
    } catch (error) {
      console.error("Error submitting story:", error);
      addToast("Failed to save story", "error");
      setHasSubmitted(false); // Reset submitted state on error
    }
  };

  const handleBack = () => {
    navigate(-1); // Go back to previous page
  };

  // Add a separate useEffect to handle status changes without resetting form
  useEffect(() => {
    if (status === "success" && statusMessage) {
      addToast(statusMessage, "success");
      // Only navigate away on success if desired
      // navigate(-1);
    } else if (status === "error" && statusMessage) {
      addToast(statusMessage, "error");
    }
  }, [status, statusMessage, addToast]);

  return (
    <div
      className={cn(styles.container, styles[theme], {
        [styles.mobileContainer]: isMobile,
      })}
      data-theme={theme}
    >
      <div
        className={cn(styles.formHeader, {
          [styles.mobileFormHeader]: isMobile,
        })}
      >
        <FormHeader
          currentTheme={theme}
          onToggle={toggleTheme}
          onBack={handleBack}
        />
        <h1 className={styles.formTitle}>
          {initialData?._id ? "Edit Your Story" : "Write a New Story"}
        </h1>
        <p className={styles.formDescription}>
          Share your experience in a safe space. Your story can help others who
          might be going through similar situations.
        </p>
      </div>

      <div
        className={cn(styles.formContent, {
          [styles.mobileFormContent]: isMobile,
        })}
      >
        <div className={styles.formSection}>
          <label className={styles.label}>Title</label>
          <TitleInput
            value={title}
            onChange={handleTitleChange}
            placeholder="Give your story a title..."
          />
          {errors.title && (
            <div className={styles.errorMessage}>{errors.title}</div>
          )}
        </div>

        <div className={styles.formSection}>
          <label className={styles.label}>Story</label>
          <TiptapEditor
            initialData={initialData}
            content={bodyContent}
            setContent={handleBodyChange}
            theme={theme}
          />
          {errors.body && (
            <div className={styles.errorMessage}>{errors.body}</div>
          )}
        </div>

        <div className={styles.formSection}>
          <div className={styles.labelWithTooltip}>
            <label className={styles.label}>Tags (Optional)</label>
            <Tooltip>
              <TooltipTrigger>
                <FaInfoCircle className={styles.infoIcon} />
              </TooltipTrigger>
              <TooltipContent>
                Adding tags helps others find your story when they're looking
                for similar experiences
              </TooltipContent>
            </Tooltip>
          </div>
          <TagInput
            selectedTags={selectedTags}
            setSelectedTags={handleTagsChange}
            recommendedTags={recommendedTags}
            theme={theme}
          />
          <p className={styles.helperText}>
            Tags are optional, but they help others find your story
          </p>
          {errors.tags && (
            <div className={styles.errorMessage}>{errors.tags}</div>
          )}
        </div>
      </div>

      <LoadingContainer>
        {status === "submitting" && <LoadingMessage message={statusMessage} />}
        {status === "error" && (
          <p className={styles.errorStatus}>{statusMessage}</p>
        )}
        {status === "success" && (
          <p className={styles.successStatus}>{statusMessage}</p>
        )}
      </LoadingContainer>

      <div
        className={cn(styles.formActions, {
          [styles.mobileFormActions]: isMobile,
        })}
      >
        <Button
          type="button"
          onClick={handleBack}
          color="secondary"
          disabled={status === "submitting"}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={handleSubmit}
          disabled={status === "submitting"}
        >
          {status === "submitting"
            ? "Saving..."
            : initialData?._id
            ? "Update Story"
            : "Publish Story"}
        </Button>
      </div>
    </div>
  );
}

export default StoryForm;
