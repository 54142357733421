import { useState, useEffect } from "react";
import { fetchPublicProfile, fetchProfileSummary } from "api/users";
import { unifiedContentSearch } from "api/search";
import { fetchUserBadges } from "api/badges";

/**
 * Custom hook to manage profile data fetching and state
 *
 * @param {string} username - The username of the profile to fetch
 * @returns {Object} Profile data state and handlers
 */
export const useProfileData = (username) => {
  const [profileData, setProfileData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load initial profile data
  useEffect(() => {
    const loadProfileData = async () => {
      try {
        setLoading(true);

        // Fetch basic profile data
        const data = await fetchPublicProfile(username);

        // Set profile data
        setProfileData(data);

        // Continue with fetching additional data
        try {
          // Fetch profile summary (optimized tag data)
          const summary = await fetchProfileSummary(username);
          setSummaryData(summary);
        } catch (summaryError) {
          console.error("Error fetching profile summary:", summaryError);
          // Don't fail the whole profile load if summary fails
        }

        // Fetch badges
        try {
          const badgesData = await fetchUserBadges(data._id);
          setBadges(badgesData);
        } catch (badgeError) {
          console.error("Error fetching badges:", badgeError);
          // Don't fail the whole profile load if badges fail
        }

        // Initial content fetch with pagination
        try {
          const searchResults = await unifiedContentSearch({
            authorId: data._id,
            limit: 10,
            page: 1,
          });

          // Extract the results from the response
          const stories = searchResults.stories?.results || [];
          const undiscoveredStoriesCount =
            searchResults.stories?.undiscoveredCount || 0;
          const lifeExperiences = searchResults.experiences?.results || [];
          const undiscoveredLifeExperiencesCount =
            searchResults.experiences?.undiscoveredCount || 0;

          setProfileData((prev) => ({
            ...prev,
            stories,
            undiscoveredStoriesCount,
            lifeExperiences,
            undiscoveredLifeExperiencesCount,
          }));
        } catch (contentError) {
          console.error("Error fetching content:", contentError);
          // Don't fail if content fetch fails
        }
      } catch (error) {
        console.error("Error loading profile data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      loadProfileData();
    }
  }, [username]);

  // Add this debugging to the hook
  useEffect(() => {}, [loading, profileData, summaryData, error]);

  // Handler for updating life experiences
  const updateLifeExperiences = (updatedExperiences, undiscoveredCount = 0) => {
    setProfileData((prev) => ({
      ...prev,
      lifeExperiences: updatedExperiences,
      undiscoveredLifeExperiencesCount: undiscoveredCount,
    }));
  };

  // Handler for updating stories
  const updateStories = (updatedStories, undiscoveredCount = 0) => {
    setProfileData((prev) => ({
      ...prev,
      stories: updatedStories,
      undiscoveredStoriesCount: undiscoveredCount,
    }));
  };

  // Function to load more content
  const loadMoreContent = async (contentType, page = 1) => {
    try {
      setLoading(true);

      if (!profileData?._id) {
        throw new Error("Profile data not loaded");
      }

      const searchResults = await unifiedContentSearch({
        authorId: profileData._id,
        type: contentType,
        limit: 10,
        page,
      });

      if (contentType === "experiences") {
        const newExperiences = searchResults.experiences?.results || [];
        const undiscoveredCount =
          searchResults.experiences?.undiscoveredCount || 0;

        setProfileData((prev) => ({
          ...prev,
          lifeExperiences:
            page === 1
              ? newExperiences
              : [...(prev.lifeExperiences || []), ...newExperiences],
          undiscoveredLifeExperiencesCount: undiscoveredCount,
        }));

        return {
          results: newExperiences,
          pagination: searchResults.experiences?.pagination,
        };
      } else if (contentType === "stories") {
        const newStories = searchResults.stories?.results || [];
        const undiscoveredCount = searchResults.stories?.undiscoveredCount || 0;

        setProfileData((prev) => ({
          ...prev,
          stories:
            page === 1 ? newStories : [...(prev.stories || []), ...newStories],
          undiscoveredStoriesCount: undiscoveredCount,
        }));

        return {
          results: newStories,
          pagination: searchResults.stories?.pagination,
        };
      }
    } catch (error) {
      console.error(`Error loading more ${contentType}:`, error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    profileData,
    summaryData,
    badges,
    loading,
    error,
    updateLifeExperiences,
    updateStories,
    loadMoreContent,
  };
};
