import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BsChatDots,
  BsHandIndex,
  BsPeople,
  BsChevronLeft,
} from "react-icons/bs";

import Chat from "./Chat/Chat";
import Reachout from "./Reachout/Reachout";
import Match from "./Match/Match";

import ChatCard from "./components/ChatCard";
import ReachoutCard from "./components/ReachoutCard";
import MatchCard from "./components/MatchCard";
import ChatList from "./components/ChatList";

import styles from "./ChatWindow.module.css";
import cn from "classnames";
import { useSelector } from "react-redux";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import {
  selectSortedChats,
  selectSortedMatches,
  selectSortedReachouts,
} from "store/selectors";

const NoSelectionState = () => (
  <div className={styles.noSelection}>
    <BsChevronLeft className={styles.noSelectionIcon} />
    <p className={styles.noSelectionMessage}>
      Select a conversation from the list to start chatting
    </p>
  </div>
);

const ChatWindow = ({ initialTab, initialId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(initialTab || "chats");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  // Simplified selection state management
  const [selections, setSelections] = useState({
    chats: initialTab === "chats" ? initialId : null,
    reachouts: initialTab === "reachouts" ? initialId : null,
    matches: initialTab === "matches" ? initialId : null,
  });

  // Compute current selection based on active tab
  const currentSelection = useMemo(
    () => selections[activeTab],
    [selections, activeTab]
  );

  // Handle window resize with improved mobile logic
  useEffect(() => {
    const handleResize = () => {
      const newIsMobileView = window.innerWidth <= 768;
      if (isMobileView !== newIsMobileView) {
        setIsMobileView(newIsMobileView);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileView]);

  // Improved URL sync
  useEffect(() => {
    const type = searchParams.get("type");
    const id = searchParams.get("id");

    if (type && id) {
      setActiveTab(type);
      setSelections((prev) => ({
        ...prev,
        [type]: id,
      }));
    } else if (type) {
      setActiveTab(type);
    }
  }, [searchParams]);

  // Improved tab change handler
  const handleTabChange = useCallback(
    (newTab) => {
      setActiveTab(newTab);

      if (isMobileView && !selections[newTab]) {
        // On mobile, only show list if nothing selected in new tab
        setSearchParams({ type: newTab });
      } else {
        // On desktop or if there's a selection, show the selected item
        const selectedId = selections[newTab];
        setSearchParams(
          selectedId ? { type: newTab, id: selectedId } : { type: newTab }
        );
      }
    },
    [isMobileView, selections, setSearchParams]
  );

  // Simplified selection handler
  const handleSelect = useCallback(
    (type, id) => {
      setSelections((prev) => ({
        ...prev,
        [type]: id,
      }));
      setSearchParams({ type, id });
    },
    [setSearchParams]
  );

  // Improved back button behavior
  const handleBackButtonClick = useCallback(() => {
    if (isMobileView) {
      setSearchParams({ type: activeTab });
      setSelections((prev) => ({
        ...prev,
        [activeTab]: null,
      }));
      // Force a small delay to allow for smooth transition
      setTimeout(() => {
        document
          .querySelector(`.${styles.list}`)
          ?.style.removeProperty("opacity");
      }, 50);
    } else {
      setSearchParams({});
      setActiveTab("chats");
      setSelections({
        chats: null,
        reachouts: null,
        matches: null,
      });
    }
  }, [isMobileView, activeTab, setSearchParams]);

  const sortedChats = useSelector(selectSortedChats);
  const sortedReachouts = useSelector(selectSortedReachouts);
  const sortedMatches = useSelector(selectSortedMatches);

  const isLoadingChats = useSelector((state) => state.chats.isLoading);
  const isLoadingReachouts = useSelector((state) => state.reachouts.isLoading);
  const isLoadingMatches = useSelector((state) => state.matches.isLoading);
  const isLoadingMessages = useSelector((state) => state.messages.isLoading);
  const loading =
    isLoadingChats ||
    isLoadingReachouts ||
    isLoadingMatches ||
    isLoadingMessages;

  // Update the chat instance rendering to handle all types
  const renderChatInstance = (activeTab, currentSelection) => {
    switch (activeTab) {
      case "chats":
        return <Chat onBack={handleBackButtonClick} />;
      case "reachouts":
        return <Reachout onBack={handleBackButtonClick} />;
      case "matches":
        return <Match onBack={handleBackButtonClick} />;
      default:
        return <NoSelectionState />;
    }
  };

  return (
    <div className={styles.chatWindow}>
      {(!isMobileView || !currentSelection) && (
        <>
          <div className={styles.tabs}>
            <div
              className={cn(styles.tab, {
                [styles.active]: activeTab === "chats",
              })}
              onClick={() => handleTabChange("chats")}
            >
              Active
            </div>
            <div
              className={cn(styles.tab, {
                [styles.active]: activeTab === "reachouts",
              })}
              onClick={() => handleTabChange("reachouts")}
            >
              Reachouts
            </div>
            <div
              className={cn(styles.tab, {
                [styles.active]: activeTab === "matches",
              })}
              onClick={() => handleTabChange("matches")}
            >
              Matches
            </div>
          </div>
          <ChatList
            activeTab={activeTab}
            currentSelection={currentSelection}
            loading={loading}
            sortedChats={sortedChats}
            sortedReachouts={sortedReachouts}
            sortedMatches={sortedMatches}
            onSelect={handleSelect}
          />
        </>
      )}
      <div
        className={cn(styles.chatInstance, {
          [styles.mobileInstance]: isMobileView && currentSelection,
        })}
      >
        {currentSelection ? (
          renderChatInstance(activeTab, currentSelection)
        ) : (
          <NoSelectionState />
        )}
      </div>
    </div>
  );
};

export default ChatWindow;
