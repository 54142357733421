import React, { useEffect, useState } from "react";
import {
  FloatingOverlay,
  FloatingFocusManager,
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  useId,
} from "@floating-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./MatchFoundModal.module.css";

const MATCH_TIMEOUT = 30000; // 30 seconds

const MatchFoundModal = ({ matchData, onAccept, onReject, serverStats }) => {
  const [timeLeft, setTimeLeft] = useState(30);

  // Convert acceptedBy array to Set for easier checking
  const hasUserAccepted = matchData?.acceptedBy?.includes(
    matchData.currentUserId
  );
  const hasOtherUserAccepted = matchData?.acceptedBy?.includes(
    matchData.user.userId
  );

  const { refs, context } = useFloating({
    open: true,
    onOpenChange: () => onReject(),
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  const labelId = useId();
  const descriptionId = useId();

  // Countdown timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          onReject();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onReject]);

  // Add loading state
  if (!matchData?.user) {
    return (
      <FloatingOverlay className={styles.overlay} lockScroll>
        <motion.div
          className={styles.modal}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <div className={styles.content}>
            <h2 className={styles.title}>Finding Match...</h2>
            <motion.div
              className={styles.spinner}
              animate={{
                scale: [1, 1.1, 1],
                opacity: [1, 0.8, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <motion.div
                className={styles.spinnerInner}
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              />
            </motion.div>
          </div>
        </motion.div>
      </FloatingOverlay>
    );
  }

  const renderScores = () => {
    const scores = matchData.user.scores || {
      trustScore: 0,
      warmthScore: 0,
      totalReviews: 0,
    };

    return (
      <div className={styles.scores}>
        <div className={styles.scoreItem}>
          <span className={styles.scoreLabel}>Trust Score</span>
          <div className={styles.scoreValue}>
            {scores.trustScore.toFixed(1)}
            <span className={styles.scoreMax}>/5.0</span>
          </div>
        </div>
        <div className={styles.scoreItem}>
          <span className={styles.scoreLabel}>Warmth Score</span>
          <div className={styles.scoreValue}>
            {scores.warmthScore.toFixed(1)}
            <span className={styles.scoreMax}>/5.0</span>
          </div>
        </div>
        <div className={styles.scoreItem}>
          <span className={styles.scoreLabel}>Total Reviews</span>
          <div className={styles.scoreValue}>{scores.totalReviews}</div>
        </div>
      </div>
    );
  };

  const renderAcceptanceStatus = () => {
    if (hasOtherUserAccepted) {
      return (
        <div className={styles.acceptanceStatus}>
          <span className={styles.acceptedBadge}>Match Accepted!</span>
          <p className={styles.acceptanceMessage}>
            They've accepted the match. Accept to start chatting!
          </p>
        </div>
      );
    }
    return null;
  };

  const renderActions = () => {
    if (hasUserAccepted) {
      return (
        <div className={styles.actionsAccepted}>
          <button className={styles.acceptedButton} disabled>
            <span>✓</span> Accepted
          </button>
          <button onClick={onReject} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      );
    }

    return (
      <div className={styles.actions}>
        <button onClick={onReject} className={styles.rejectButton}>
          Decline
        </button>
        <button onClick={onAccept} className={styles.acceptButton}>
          Accept Match
        </button>
      </div>
    );
  };

  return (
    <FloatingOverlay className={styles.overlay} lockScroll>
      <FloatingFocusManager context={context}>
        <motion.div
          ref={refs.setFloating}
          className={styles.modal}
          aria-labelledby={labelId}
          aria-describedby={descriptionId}
          {...getFloatingProps()}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
        >
          <div className={styles.content}>
            <h2 id={labelId} className={styles.title}>
              Match Found!
            </h2>

            {renderAcceptanceStatus()}

            <div className={styles.timer}>
              <svg viewBox="0 0 36 36" className={styles.timerCircle}>
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="var(--background-tertiary)"
                  strokeWidth="3"
                />
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="var(--primary-color)"
                  strokeWidth="3"
                  strokeDasharray={`${(timeLeft / 30) * 100}, 100`}
                />
              </svg>
              <span className={styles.timeLeft}>{timeLeft}s</span>
            </div>

            <div className={styles.matchInfo}>
              <div className={styles.story}>
                <h3>Their Story</h3>
                <p>{matchData.user.story || "Loading story..."}</p>
              </div>

              {matchData.sharedTags?.length > 0 && (
                <div className={styles.sharedTags}>
                  <h3>Shared Interests</h3>
                  <div className={styles.tags}>
                    {matchData.sharedTags.map((tag) => (
                      <span key={tag._id} className={styles.tag}>
                        {tag.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <div className={styles.userInfo}>
                <div className={styles.demographic}>
                  <span>{matchData.user.ageRange || "Unknown age"}</span>
                  <span>•</span>
                  <span>{matchData.user.gender || "Unknown gender"}</span>
                </div>
                {renderScores()}
              </div>
            </div>

            {renderActions()}

            <div className={styles.searchStats}>
              <div className={styles.statItem}>
                <span className={styles.statIcon}>👥</span>
                <span>
                  {serverStats.isOffline
                    ? "Connecting..."
                    : `${serverStats.searchingCount} ${
                        serverStats.searchingCount === 1 ? "person" : "people"
                      } searching`}
                </span>
              </div>
            </div>
          </div>
        </motion.div>
      </FloatingFocusManager>
    </FloatingOverlay>
  );
};

export default MatchFoundModal;
