import React from "react";
import cn from "classnames";
import styles from "../ChatWindow.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import ChatCard from "./ChatCard";
import ReachoutCard from "./ReachoutCard";
import MatchCard from "./MatchCard";
import { BsChatDots, BsHandIndex, BsPeople } from "react-icons/bs";

const EmptyState = ({ type }) => {
  const content = {
    chats: {
      icon: <BsChatDots className={styles.emptyStateIcon} />,
      title: "No Active Chats",
      message:
        "When you connect with others through matches or reachouts, your conversations will appear here.",
    },
    reachouts: {
      icon: <BsHandIndex className={styles.emptyStateIcon} />,
      title: "No Reachouts",
      message:
        "When you reach out to others or receive reachouts based on life experiences or stories you've shared, they'll show up here.",
    },
    matches: {
      icon: <BsPeople className={styles.emptyStateIcon} />,
      title: "No Matches",
      message:
        "When you accept matches with others through the lobby, those matches will appear here.",
    },
  };

  return (
    <div className={styles.emptyState}>
      {content[type].icon}
      <h3 className={styles.emptyStateTitle}>{content[type].title}</h3>
      <p className={styles.emptyStateMessage}>{content[type].message}</p>
    </div>
  );
};

const ChatList = ({
  activeTab,
  currentSelection,
  loading,
  sortedChats,
  sortedReachouts,
  sortedMatches,
  onSelect,
}) => {
  return (
    <div className={styles.list}>
      {loading && (
        <div className={styles.status}>
          <LoadingMessage message="Loading chats..." />
        </div>
      )}
      {!loading && activeTab === "chats" && (
        <>
          {sortedChats.map((chat) => (
            <ChatCard
              key={chat._id}
              data={chat}
              isSelected={chat._id === currentSelection}
              className={cn(styles.listItem, {
                [styles.selected]: chat._id === currentSelection,
              })}
              onClick={() => onSelect("chats", chat._id)}
            />
          ))}
          {sortedChats.length === 0 && <EmptyState type="chats" />}
        </>
      )}
      {!loading && activeTab === "reachouts" && (
        <>
          {sortedReachouts.map((reachout) => (
            <ReachoutCard
              key={reachout._id}
              data={reachout}
              isSelected={reachout._id === currentSelection}
              className={cn(styles.listItem, {
                [styles.selected]: reachout._id === currentSelection,
              })}
              onClick={() => onSelect("reachouts", reachout._id)}
            />
          ))}
          {sortedReachouts.length === 0 && <EmptyState type="reachouts" />}
        </>
      )}
      {!loading && activeTab === "matches" && (
        <>
          {sortedMatches.map((match) => (
            <MatchCard
              key={match._id}
              data={match}
              isSelected={match._id === currentSelection}
              className={cn(styles.listItem, {
                [styles.selected]: match._id === currentSelection,
              })}
              onClick={() => onSelect("matches", match._id)}
            />
          ))}
          {sortedMatches.length === 0 && <EmptyState type="matches" />}
        </>
      )}
    </div>
  );
};

export default ChatList;
