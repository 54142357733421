import handleAxiosError from "./handleAxiosError";
import api from "./index";

export const submitReview = async ({
  interactionType,
  interactionId,
  subjectId,
  trustScore,
  warmthScore,
  comments,
}) => {
  try {
    const res = await api.post("/reviews", {
      interactionType: interactionType.toLowerCase(),
      interactionId,
      subjectId,
      trustScore,
      warmthScore,
      comments,
    });

    return res.data;
  } catch (err) {
    console.error("[ReviewsAPI] Review submission error:", err);
    handleAxiosError(err);
  }
};

export const fetchReviews = async (filters = {}) => {
  try {
    const queryParams = new URLSearchParams();
    if (filters.interactionType)
      queryParams.append("interactionType", filters.interactionType);
    if (filters.interactionId)
      queryParams.append("interactionId", filters.interactionId);
    if (filters.authorId) queryParams.append("authorId", filters.authorId);
    if (filters.subjectId) queryParams.append("subjectId", filters.subjectId);
    if (filters.status) queryParams.append("status", filters.status);

    const response = await api.get(`/reviews?${queryParams}`);

    return response.data;
  } catch (error) {
    console.error("[ReviewsAPI] Error fetching reviews:", error);
    handleAxiosError(error);
  }
};
