import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import styles from "./TagInput.module.css";
import cn from "classnames";
import useTagsSearch from "hooks/useTagsSearch";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  size,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

const TagOption = ({ tag, isSelected, theme }) => (
  <>
    <div className={styles.tagName}>
      <span className={styles.tagNameText}>{tag.name}</span>
      {tag.score && (
        <span className={cn(styles.scoreIndicator, styles[theme])}>
          {Math.round(tag.score * 100)}% match
        </span>
      )}
      {isSelected && <span className={styles.selectedIndicator}>✓</span>}
    </div>
    {tag.description && (
      <div className={styles.tagDescription}>{tag.description}</div>
    )}
    <div className={styles.tagMetadata}>
      {tag.storiesCount > 0 && (
        <span className={styles.metadataItem}>
          {tag.storiesCount} {tag.storiesCount === 1 ? "story" : "stories"}
        </span>
      )}
      {tag.lifeExpCount > 0 && (
        <span className={styles.metadataItem}>
          {tag.lifeExpCount} {tag.lifeExpCount === 1 ? "life exp" : "life exps"}
        </span>
      )}
      {tag.matchesCount > 0 && (
        <span className={styles.metadataItem}>
          {tag.matchesCount} {tag.matchesCount === 1 ? "match" : "matches"}
        </span>
      )}
    </div>
  </>
);

const TagInput = ({
  selectedTags,
  setSelectedTags,
  recommendedTags = [],
  className,
  theme = "light",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const observer = useRef();

  const { tags, loading, hasMore } = useTagsSearch(query, pageNumber, 5);

  // Reset page when query changes
  useEffect(() => {
    setPageNumber(1);
  }, [query]);

  // Last element ref callback for infinite scroll
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // Add floating UI setup from StoryForm
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(5),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  // Improved interaction hooks
  const click = useClick(context, {
    // Prevent click from toggling dropdown when clicking inside it
    ignoreMouse: true,
  });

  const role = useRole(context);
  const dismiss = useDismiss(context, {
    outsidePress: true,
    outsidePressEvent: "mousedown",
    referencePress: false, // Don't close when clicking the reference element
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  // Sort and filter tags with deduplication
  const sortedAvailableTags = useMemo(() => {
    const selectedIds = new Set(selectedTags.map((tag) => tag._id));
    const recommendedIds = new Set(recommendedTags.map((tag) => tag._id));

    const sortBySelected = (a, b) => {
      const aSelected = selectedIds.has(a._id);
      const bSelected = selectedIds.has(b._id);
      return bSelected - aSelected;
    };

    if (query) {
      return [...tags].sort(sortBySelected);
    }

    return {
      recommended: recommendedTags.sort(sortBySelected),
      regular: tags
        .filter((tag) => !recommendedIds.has(tag._id))
        .sort(sortBySelected),
    };
  }, [tags, recommendedTags, selectedTags, query]);

  const handleTagSelect = (tag) => {
    const isSelected = selectedTags.some((t) => t._id === tag._id);

    if (isSelected) {
      setSelectedTags(selectedTags.filter((t) => t._id !== tag._id));
    } else if (selectedTags.length < 3) {
      setSelectedTags([...selectedTags, tag]);
    }

    // Keep focus in the input after selection
    inputRef.current?.focus();

    // Don't close dropdown after selection
    // This allows users to make multiple selections
  };

  const handleContainerClick = (e) => {
    // Prevent click from bubbling to document which might close the dropdown
    e.stopPropagation();

    // Focus the input when clicking anywhere in the container
    if (inputRef.current && e.target !== inputRef.current) {
      inputRef.current.focus();
      setIsOpen(true);
    }
  };

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  // We'll use this instead of onBlur to avoid closing when clicking options
  const handleInputKeyDown = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={className}
      ref={(node) => {
        // Assign both refs
        containerRef.current = node;
        refs.setReference(node);
      }}
      {...getReferenceProps()}
      data-theme={theme}
    >
      <div
        className={cn(styles.container, isOpen && styles.focused)}
        onClick={handleContainerClick}
      >
        <div className={styles.selectedList}>
          <AnimatePresence mode="sync">
            {selectedTags.map((tag) => (
              <motion.button
                key={tag._id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTagSelect(tag);
                }}
                className={styles.tagBadge}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -10 }}
                transition={{ duration: 0.2 }}
              >
                {tag.name}
                <span className={styles.removeBtn}>&times;</span>
              </motion.button>
            ))}
          </AnimatePresence>

          {selectedTags.length < 3 && (
            <input
              ref={inputRef}
              className={styles.tagInput}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onFocus={handleInputFocus}
              onKeyDown={handleInputKeyDown}
              placeholder={
                selectedTags.length === 0
                  ? "Add a tag (max 3)"
                  : `Add ${3 - selectedTags.length} more tag${
                      selectedTags.length === 2 ? "" : "s"
                    }`
              }
            />
          )}
        </div>
      </div>

      {isOpen && (
        <FloatingPortal>
          <ul
            ref={refs.setFloating}
            {...getFloatingProps({
              onClick: (e) => {
                // Prevent clicks in the dropdown from closing it
                e.stopPropagation();
              },
            })}
            style={floatingStyles}
            className={styles.tagDropdown}
            data-theme={theme}
          >
            {!query && sortedAvailableTags.recommended.length > 0 && (
              <li className={styles.dropdownSection}>
                <span className={styles.sectionTitle}>Recommended</span>
                {sortedAvailableTags.recommended.map((tag) => (
                  <div
                    key={`recommended-${tag._id}`}
                    className={cn(styles.tagOption, {
                      [styles.tagOptionSelected]: selectedTags.some(
                        (t) => t._id === tag._id
                      ),
                    })}
                    onClick={() => handleTagSelect(tag)}
                  >
                    <TagOption
                      tag={tag}
                      isSelected={selectedTags.some((t) => t._id === tag._id)}
                      theme={theme}
                    />
                  </div>
                ))}
              </li>
            )}

            {(query ? sortedAvailableTags : sortedAvailableTags.regular)
              .length > 0 && (
              <li className={styles.dropdownSection}>
                <span className={styles.sectionTitle}>
                  {query ? "Search Results" : "All Tags"}
                </span>
                {(query
                  ? sortedAvailableTags
                  : sortedAvailableTags.regular
                ).map((tag, index, array) => {
                  const isLastElement = index === array.length - 1;
                  const isSelected = selectedTags.some(
                    (t) => t._id === tag._id
                  );

                  return (
                    <div
                      key={`${query ? "search" : "regular"}-${tag._id}`}
                      ref={isLastElement ? lastElementRef : null}
                      className={cn(styles.tagOption, {
                        [styles.tagOptionSelected]: isSelected,
                      })}
                      onClick={() => handleTagSelect(tag)}
                    >
                      <TagOption
                        tag={tag}
                        isSelected={isSelected}
                        theme={theme}
                      />
                    </div>
                  );
                })}
              </li>
            )}

            {loading && (
              <div className={styles.loadingItem}>
                <LoadingSpinner />
              </div>
            )}
          </ul>
        </FloatingPortal>
      )}
    </div>
  );
};

export default TagInput;
