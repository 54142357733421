import api from "./index";
import handleAxiosError from "./handleAxiosError";

/**
 * Block an interaction instance
 * @param {string} sourceType - The type of interaction ('chat', 'match', 'reachout')
 * @param {string} sourceId - The ID of the interaction
 * @param {Object} blockData - The block data
 * @param {string} blockData.reason - The reason for blocking
 * @param {string} [blockData.notes] - Additional notes about the block
 * @returns {Promise<Object>} The block result
 */
export const blockInteraction = async (sourceType, sourceId, blockData) => {
  try {
    const response = await api.post(
      `/blocks/${sourceType}/${sourceId}`,
      blockData
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Unblock an interaction instance
 * @param {string} sourceType - The type of interaction ('chat', 'match', 'reachout')
 * @param {string} sourceId - The ID of the interaction
 * @returns {Promise<Object>} The unblock result
 */
export const unblockInteraction = async (sourceType, sourceId) => {
  try {
    const response = await api.delete(`/blocks/${sourceType}/${sourceId}`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export default {
  blockInteraction,
  unblockInteraction,
};
