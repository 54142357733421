import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useAdmin } from "hooks/useAdmin";
import styles from "./Overview.module.css";
import axios from "axios";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";

const Overview = () => {
  const navigate = useNavigate();
  const { users, fetchAllUsers, reports, fetchAllReports } = useAdmin();
  const [stats, setStats] = useState({
    stories: 0,
    lifeExperiences: 0,
    activeUsers: 0,
    pendingUsers: 0,
    openReports: 0,
    resolvedReports: 0,
  });
  const [recentActivity, setRecentActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchAllUsers(), fetchAllReports()]);

        // This would be replaced with actual API calls to get these stats
        // For now, we'll simulate with mock data
        setStats({
          stories: 245,
          lifeExperiences: 189,
          activeUsers: users.filter((u) => u.status === "active").length,
          pendingUsers: users.filter((u) => u.registrationStatus === "pending")
            .length,
          openReports: reports.filter((r) => r.status === "pending").length,
          resolvedReports: reports.filter((r) => r.status === "resolved")
            .length,
        });
      } catch (error) {
        console.error("Error fetching admin overview data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleStatCardClick = (type) => {
    // Navigate to the appropriate section based on the stat card clicked
    switch (type) {
      case "users":
        navigate("/admin/users");
        break;
      case "pendingUsers":
        navigate("/admin/users?status=pending");
        break;
      case "reports":
        navigate("/admin/reports");
        break;
      default:
        // Do nothing for now
        break;
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner />
      </div>
    );
  }

  const data = [
    { name: "January", users: 40 },
    { name: "February", users: 20 },
    { name: "March", users: 70 },
    { name: "April", users: 50 },
    { name: "May", users: 30 },
    { name: "June", users: 90 },
  ];

  return (
    <div className={styles.overviewContainer}>
      <h1 className={styles.pageTitle}>Dashboard Overview</h1>

      <div className={styles.statsGrid}>
        <div
          className={styles.statCard}
          onClick={() => handleStatCardClick("users")}
        >
          <h3>Total Users</h3>
          <div className={styles.statValue}>{users.length}</div>
        </div>

        <div
          className={styles.statCard}
          onClick={() => handleStatCardClick("users")}
        >
          <h3>Active Users</h3>
          <div className={styles.statValue}>{stats.activeUsers}</div>
        </div>

        <div
          className={styles.statCard}
          onClick={() => handleStatCardClick("pendingUsers")}
        >
          <h3>Pending Approval</h3>
          <div className={styles.statValue}>{stats.pendingUsers}</div>
        </div>

        <div className={styles.statCard}>
          <h3>Total Stories</h3>
          <div className={styles.statValue}>{stats.stories}</div>
        </div>

        <div className={styles.statCard}>
          <h3>Life Experiences</h3>
          <div className={styles.statValue}>{stats.lifeExperiences}</div>
        </div>

        <div
          className={styles.statCard}
          onClick={() => handleStatCardClick("reports")}
        >
          <h3>Open Reports</h3>
          <div className={styles.statValue}>{stats.openReports}</div>
        </div>
      </div>

      <div className={styles.recentActivity}>
        <h2>Recent Activity</h2>
        <div className={styles.activityList}>
          <div className={styles.activityItem}>
            <div className={styles.activityTime}>Today, 10:23 AM</div>
            <div className={styles.activityContent}>
              New user registered: johndoe123
            </div>
          </div>
          <div className={styles.activityItem}>
            <div className={styles.activityTime}>Today, 09:45 AM</div>
            <div className={styles.activityContent}>
              New report submitted: Inappropriate content
            </div>
          </div>
          <div className={styles.activityItem}>
            <div className={styles.activityTime}>Yesterday, 4:30 PM</div>
            <div className={styles.activityContent}>
              User account suspended: spammer99
            </div>
          </div>
          <div className={styles.activityItem}>
            <div className={styles.activityTime}>Yesterday, 2:15 PM</div>
            <div className={styles.activityContent}>
              New life experience posted by user: travel_lover
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
