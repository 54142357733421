import React from "react";
import styles from "./Card.module.css";
import cn from "classnames";
import LastMessageDisplay from "./LastMessageDisplay";
import { formatRelativeTime } from "utils/dates";

const BaseCard = React.forwardRef(
  ({ data, onClick, isSelected, children, className, headerContent }, ref) => {
    const lastMessageTime = data.lastMessage?.createdAt || data.createdAt;

    return (
      <li
        ref={ref}
        className={cn(styles.card, className, {
          [styles.selected]: isSelected,
        })}
        onClick={onClick}
      >
        <div className={styles.cardHeader}>
          {headerContent}
          <span className={styles.relativeTime}>
            {formatRelativeTime(lastMessageTime)}
          </span>
        </div>
        {children}
      </li>
    );
  }
);

export default BaseCard;
