// packages/client/src/pages/Login/Login.jsx
import React, { useContext } from "react";
import { motion } from "framer-motion";
import { GOOGLE_LOGIN_URL, UserContext } from "contexts/UserContext";
import { useBrowserContext } from "contexts/BrowserContext";
import styles from "./Login.module.css";
import googleIcon from "../../assets/google_icon.png";
import logo from "../../assets/logo_chair.png";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const containerVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};

const Login = () => {
  const { userData, loading, loadingMessage, handleGoogleLogin, handleLogout } =
    useContext(UserContext);
  const { isWebView, redirectToNativeBrowser } = useBrowserContext();

  const handleLogin = () => {
    if (isWebView) {
      redirectToNativeBrowser();
      return;
    }
    handleGoogleLogin();
  };

  return (
    <>
      <motion.div
        className={styles.overlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          className={styles.loginContainer}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className={styles.header}
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, duration: 0.4 }}
          >
            <img src={logo} alt="Logo" className={styles.logo} />
            <h1 className={styles.headerText}>
              {userData ? "Log out" : "Welcome to Stumble"}
            </h1>
            {!userData && (
              <p className={styles.subheader}>Find others like you</p>
            )}
          </motion.div>

          {userData ? (
            <button
              onClick={handleLogout}
              className={styles.logoutButton}
              disabled={loading}
            >
              {loading ? (
                <>
                  <LoadingSpinner />
                  {loadingMessage}
                </>
              ) : (
                "Logout"
              )}
            </button>
          ) : (
            <>
              <button
                className={styles.googleBtn}
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <LoadingSpinner />
                    {loadingMessage}
                  </>
                ) : (
                  <>
                    <img src={googleIcon} alt="Google Icon" />
                    Log in with Google
                  </>
                )}
              </button>
              <hr className={styles.divider} />
              <div className={styles.signupContainer}>
                Don't have an account?{" "}
                <a className={styles.signupLink} href={GOOGLE_LOGIN_URL}>
                  Sign up with Google
                </a>
              </div>
            </>
          )}
        </motion.div>
      </motion.div>
    </>
  );
};

export default Login;
