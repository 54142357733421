import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import PageHeader from "components/PageHeader/PageHeader";
import {
  RiMailLine,
  RiTimeLine,
  RiSendPlaneLine,
  RiCheckLine,
  RiErrorWarningLine,
} from "react-icons/ri";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // In a real implementation, you would send this data to your backend
      // await api.post('/contact', formData);

      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setSubmitStatus({
        success: true,
        message: "Thank you for your message. We'll get back to you soon!",
      });
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      setSubmitStatus({
        success: false,
        message: "Something went wrong. Please try again later.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.contactContainer}>
      <PageHeader title="Contact Us" />

      <div className={styles.contactContent}>
        <div className={styles.contactInfo}>
          <h2>Get in Touch</h2>
          <p>
            Have questions, feedback, or need assistance? We're here to help!
            Fill out the form and our team will respond as soon as possible.
          </p>

          <div className={styles.contactMethods}>
            <div className={styles.contactMethod}>
              <h3>
                <RiMailLine /> Email
              </h3>
              <p>support@stumble.com</p>
            </div>

            <div className={styles.contactMethod}>
              <h3>
                <RiTimeLine /> Response Time
              </h3>
              <p>We typically respond within 24-48 hours</p>
            </div>
          </div>
        </div>

        <form className={styles.contactForm} onSubmit={handleSubmit}>
          {submitStatus && (
            <div
              className={`${styles.statusMessage} ${
                submitStatus.success ? styles.success : styles.error
              }`}
            >
              {submitStatus.success ? (
                <RiCheckLine size={20} />
              ) : (
                <RiErrorWarningLine size={20} />
              )}
              {submitStatus.message}
            </div>
          )}

          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Your name"
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="your.email@example.com"
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              placeholder="What is your message about?"
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="5"
              required
              placeholder="Please provide details about your inquiry..."
            ></textarea>
          </div>

          <button
            type="submit"
            className={styles.submitButton}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              "Sending..."
            ) : (
              <>
                <RiSendPlaneLine /> Send Message
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
