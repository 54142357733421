// packages/client/src/pages/Register/StepPersonalInfo.jsx
import React, { useState, useEffect } from "react";
import countryList from "country-list";
import { motion } from "framer-motion";
import common from "../Register.module.css";
import styles from "./StepPersonalInfo.module.css";
import DatePickerInput from "components/DatePickerInput/DatePickerInput";
import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";
import { fetchAgeRanges } from "api/config";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { getFormattedCountryName } from "utils/location";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";
import SimpleCombobox from "components/SimpleCombobox/SimpleCombobox";

const steps = [
  {
    target: '[data-tour="birthdate-input"]',
    title: "Why Share Your Age?",
    content: (
      <div>
        <p>Age helps provide context to advice and experiences:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🎯 Get more relevant advice for your life stage</li>
          <li>🤝 Connect with people in similar situations</li>
          <li>🔒 Only your age range is shown to others</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-tour="gender-input"]',
    title: "Why Share Your Gender?",
    content: (
      <div>
        <p>Gender helps personalize your experience:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>👥 Connect with people who share similar perspectives</li>
          <li>💬 Receive more relevant advice</li>
          <li>🔒 You control who sees this information</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-tour="country-input"]',
    title: "Why Share Your Country?",
    content: (
      <div>
        <p>Your country helps provide cultural context:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>
            🌎 Connect with people who understand your cultural background
          </li>
          <li>🤝 Find advice relevant to where you live</li>
          <li>💡 Share experiences specific to your region</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
  },
];

export const StepPersonalInfo = ({
  data,
  onSave,
  handleStepValidity,
  tourCompleted = false,
  onTourComplete,
}) => {
  const [birthdate, setBirthdate] = useState(
    data?.birthdate ? new Date(data.birthdate) : null
  );
  const [gender, setGender] = useState(data?.gender || "");
  const [location, setLocation] = useState(
    data?.location || { name: "", code: "" }
  );
  const [ageRanges, setAgeRanges] = useState([]);
  const [ageRange, setAgeRange] = useState("");
  const [isLoadingAgeRanges, setIsLoadingAgeRanges] = useState(false);
  const [startTour, setStartTour] = useState(false);

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const countryOptions = countryList.getData().map((country) => ({
    value: country.code,
    label: country.name,
  }));

  useEffect(() => {
    const isValid = birthdate && gender && location.name;
    handleStepValidity(isValid);
  }, [birthdate, gender, location, handleStepValidity]);

  useEffect(() => {
    const loadAgeRanges = async () => {
      setIsLoadingAgeRanges(true);
      try {
        const ranges = await fetchAgeRanges();
        setAgeRanges(ranges);
      } catch (error) {
        console.error("Failed to fetch age ranges:", error);
      } finally {
        setIsLoadingAgeRanges(false);
      }
    };

    loadAgeRanges();
  }, []);

  useEffect(() => {
    if (birthdate && ageRanges.length > 0) {
      const today = new Date();
      const birth = new Date(birthdate);
      const age = today.getFullYear() - birth.getFullYear();

      // Find the age range that includes the user's age
      const range = ageRanges.find(
        (r) => age >= r.min && (r.max === null || age <= r.max)
      );

      if (range) {
        setAgeRange(range.label);
      } else {
        setAgeRange("");
      }
    } else {
      setAgeRange("");
    }
  }, [birthdate, ageRanges]);

  useEffect(() => {
    // Always show the tour guide, but only run it automatically if not completed
    setStartTour(true);
  }, []);

  const handleBirthdateChange = (date) => {
    setBirthdate(date);
    onSave({ birthdate: date ? date.toISOString() : null });
  };

  const handleGenderChange = (value) => {
    setGender(value);
    onSave({ gender: value });
  };

  const handleLocationChange = (value) => {
    if (value) {
      const country = countryOptions.find((option) => option.value === value);
      if (country) {
        const formattedName = getFormattedCountryName(country.label, value);
        const locationData = {
          name: country.label,
          formattedName,
          code: value,
        };
        setLocation(locationData);
        onSave({ location: locationData });
      }
    } else {
      const emptyLocation = { name: "", code: "", formattedName: "" };
      setLocation(emptyLocation);
      onSave({ location: emptyLocation });
    }
  };

  return (
    <>
      {/* Always render JoyrideWrapper, but only run automatically if not completed */}
      <JoyrideWrapper
        steps={steps}
        runOnInit={!tourCompleted}
        onTourComplete={onTourComplete}
        hasCompletedTour={tourCompleted}
        fabPosition="bottom-center"
      />

      <motion.div
        className={styles.personalInfoStep}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className={common.header}>
          <h1>Tell us about yourself</h1>
        </div>

        <p className={common.subheader}>
          This information helps us personalize your experience
        </p>

        <div className={common["input-group"]} data-tour="birthdate-input">
          <div className={common["label-tooltip"]}>
            <label>Date of Birth</label>
            <InfoTooltip content="Your birth date helps us show your age range to other users, making advice more relevant." />
          </div>
          <DatePickerInput
            selected={birthdate ? new Date(birthdate) : null}
            onChange={handleBirthdateChange}
            placeholderText="Select your date of birth (MM/DD/YYYY)"
            className={styles.dateInput}
            value={birthdate ? new Date(birthdate) : null}
            theme="transparent"
          />

          {ageRange && (
            <div className={styles.ageRangeDisplay}>
              Your age range: <strong>{ageRange}</strong>
            </div>
          )}

          {isLoadingAgeRanges && (
            <LoadingMessage message="Loading age ranges..." />
          )}
        </div>

        <div className={common["input-group"]} data-tour="gender-input">
          <div className={common["label-tooltip"]}>
            <label>Gender</label>
            <InfoTooltip content="Your gender helps personalize your experience and connect you with relevant advice." />
          </div>
          <SimpleCombobox
            options={genderOptions}
            value={gender}
            onChange={handleGenderChange}
            placeholder="Select Gender"
            theme="transparent"
          />
        </div>

        <div className={common["input-group"]} data-tour="country-input">
          <div className={common["label-tooltip"]}>
            <label>Country</label>
            <InfoTooltip content="Your country helps provide cultural context to your experiences and connect you with people from similar backgrounds." />
          </div>
          <SimpleCombobox
            options={countryOptions}
            value={location.code}
            onChange={handleLocationChange}
            placeholder="Select Country"
            theme="transparent"
          />
        </div>
      </motion.div>
    </>
  );
};
