import { useState, useCallback, useEffect } from "react";
import { useSocketContext } from "contexts/SocketContext";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "contexts/ToastContext";
import { useServerStats } from "hooks/useServerStats";

const SearchStates = {
  INITIALIZING: "initializing",
  SEARCHING: "searching",
  MATCH_FOUND: "match_found",
  WAITING_ACCEPTANCE: "waiting_acceptance",
  MATCH_ACCEPTED: "match_accepted",
  MATCH_COMPLETE: "match_complete",
  MATCH_EXPIRED: "match_expired",
  MATCH_DISCONNECTED: "match_disconnected",
  STOPPED: "stopped",
  ERROR: "error",
};

const useDocumentTitle = (searchState) => {
  useEffect(() => {
    const defaultTitle = "Stumble";

    const titles = {
      [SearchStates.INITIALIZING]: "Initializing... - Stumble",
      [SearchStates.SEARCHING]: "Searching... - Stumble",
      [SearchStates.MATCH_FOUND]: "Match Found! - Stumble",
      [SearchStates.WAITING_ACCEPTANCE]: "Waiting... - Stumble",
      [SearchStates.MATCH_ACCEPTED]: "Match Accepted - Stumble",
      [SearchStates.MATCH_COMPLETE]: "Match Complete - Stumble",
      [SearchStates.MATCH_EXPIRED]: "Match Expired - Stumble",
      [SearchStates.MATCH_DISCONNECTED]: "Disconnected - Stumble",
      [SearchStates.ERROR]: "Error - Stumble",
    };

    document.title = titles[searchState] || defaultTitle;

    return () => {
      document.title = defaultTitle;
    };
  }, [searchState]);
};

export const useLobbyMatching = (initialStory = "") => {
  const navigate = useNavigate();
  const { addToast } = useToastContext();

  const {
    searchState,
    searchMetadata,
    matchData,
    startSearch,
    stopSearch,
    continueSearch,
    acceptMatch,
    rejectMatch,
    cleanupSearchStates,
    error,
    matchComplete,
  } = useSocketContext();

  const { onlineCount, searchingCount, isOffline } = useServerStats();

  const [searchPreferences, setSearchPreferences] = useState({
    story: initialStory,
    tags: [],
    ageRanges: [],
    genders: [],
  });

  // Derive formatted stats message here if needed
  const serverStats = {
    onlineCount,
    searchingCount,
    isOffline,
  };

  // Handle search initiation
  const handleStartSearch = useCallback(
    (preferences) => {
      if (!preferences.story?.trim()) {
        addToast("Please enter your story", "error");
        return;
      }

      setSearchPreferences(preferences);
      startSearch(preferences);
    },
    [startSearch, addToast]
  );

  // Handle search cancellation
  const handleStopSearch = useCallback(() => {
    stopSearch();
    cleanupSearchStates();
  }, [stopSearch, cleanupSearchStates]);

  // Handle match acceptance
  const handleAcceptMatch = useCallback(() => {
    acceptMatch();
  }, [acceptMatch]);

  // Handle match rejection
  const handleRejectMatch = useCallback(() => {
    rejectMatch();
  }, [rejectMatch]);

  // Handle navigation away
  const handleBack = useCallback(() => {
    if (searchState === SearchStates.SEARCHING) {
      stopSearch();
    }
    cleanupSearchStates();
    navigate("/find");
  }, [searchState, stopSearch, cleanupSearchStates, navigate]);

  // Handle successful match completion
  useEffect(() => {
    if (matchComplete) {
      addToast("Match successful! Redirecting to chat...", "success");
      navigate(`/chats?type=matches&id=${matchComplete.matchId}`);
    }
  }, [matchComplete, navigate, addToast]);

  // Handle continuing search
  const handleContinueSearch = useCallback(() => {
    continueSearch();
  }, [continueSearch]);

  // Update error handling
  useEffect(() => {
    if (error) {
      const errorMessages = {
        [SearchStates.MATCH_EXPIRED]: "Match request expired",
        [SearchStates.MATCH_DISCONNECTED]: "Other user disconnected",
        [SearchStates.ERROR]: "An error occurred during matching",
      };
      const message = errorMessages[searchState] || "Matching error occurred";
      addToast(message, "error");
    }
  }, [error, searchState, addToast]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupSearchStates();
    };
  }, [cleanupSearchStates]);

  // Handle navigation to FindOthers with story
  const handleBrowseContent = useCallback(() => {
    const story = searchPreferences.story;
    stopSearch();
    cleanupSearchStates();
    navigate("/find", {
      state: {
        initialQuery: story,
        autoSearch: true, // Flag to trigger immediate search
      },
    });
  }, [searchPreferences.story, stopSearch, cleanupSearchStates, navigate]);

  // Add the title effect
  useDocumentTitle(searchState);

  return {
    // States
    searchState,
    searchMetadata,
    matchData,
    error,
    searchPreferences,
    serverStats,

    // Actions
    handleStartSearch,
    handleStopSearch,
    handleAcceptMatch,
    handleRejectMatch,
    handleBack,
    handleContinueSearch,
    setSearchPreferences,
    handleBrowseContent,

    // Constants
    SearchStates,
  };
};
