import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaDiscord,
  FaEnvelope,
  FaQuestionCircle,
  FaExclamationTriangle,
  FaLightbulb,
  FaShieldAlt,
  FaBook,
  FaSearch,
} from "react-icons/fa";
import styles from "./Help.module.css";

const Help = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFaqs, setFilteredFaqs] = useState([]);

  // Combined FAQs from landing page and help page
  const faqItems = [
    {
      question: "How does Stumble protect my privacy?",
      answer:
        "Your privacy is our top priority. We use pseudonyms by default, so you can share authentically without revealing your identity. You control what personal information you share and when. All communications in the 'airlock' phase are temporary and can be deleted. We never sell your data or use it for advertising.",
    },
    {
      question: "What makes Stumble different from other social platforms?",
      answer:
        "Unlike traditional social media, Stumble connects people based on shared life experiences rather than existing social circles. We focus on meaningful one-to-one connections rather than broadcasting to many. Our pseudonymity approach lets you be authentic without the pressure of maintaining a public profile or persona.",
    },
    {
      question:
        "I'm going through something difficult. How will Stumble help me?",
      answer:
        "Stumble helps you find others who truly understand your specific situation because they've lived through it themselves. Whether you're dealing with grief, illness, life transitions, or other challenges, connecting with someone who's been there can provide validation, perspective, and emotional support that even well-meaning friends and family sometimes can't offer.",
    },
    {
      question: "How does the matching process work?",
      answer:
        "Our AI analyzes the experiences you share to understand their emotional context and key themes. It then identifies others with similar experiences. You can also browse stories and reach out directly to people whose experiences resonate with you. All connections start in a temporary 'airlock' chat where you can get to know each other before deciding to form a permanent connection.",
    },
    {
      question: "How do I update my profile?",
      answer:
        "You can update your profile by going to Settings and selecting the 'Profile' tab. From there, you can edit your bio, change your profile picture, and update your preferences.",
    },
    {
      question: "What if I want to delete my account?",
      answer:
        "You can delete your account from the Settings page under the 'Account' tab. Please note that account deletion is permanent and will remove all your data from our systems.",
    },
    {
      question: "How can I report inappropriate behavior?",
      answer:
        "If you encounter any concerning behavior, you can report it directly from the chat interface using the report button, or by emailing safety@stumble.chat with details about the incident.",
    },
    {
      question: "Is Stumble available on mobile devices?",
      answer:
        "Yes, Stumble is designed to work on both desktop and mobile browsers. We're also working on dedicated mobile apps that will be available in the future.",
    },
    {
      question: "Can I use Stumble anonymously?",
      answer:
        "Yes, Stumble is designed with privacy in mind. You can use a pseudonym and control how much personal information you share. Your real identity is never required to use the platform.",
    },
    {
      question: "What should I do if I forget my password?",
      answer:
        "If you forget your password, you can use the 'Forgot Password' link on the login page. We'll send you an email with instructions to reset your password.",
    },
  ];

  // Filter FAQs based on search term
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredFaqs(faqItems);
    } else {
      const lowercasedSearch = searchTerm.toLowerCase();
      const filtered = faqItems.filter(
        (faq) =>
          faq.question.toLowerCase().includes(lowercasedSearch) ||
          faq.answer.toLowerCase().includes(lowercasedSearch)
      );
      setFilteredFaqs(filtered);
    }
  }, [searchTerm]);

  return (
    <div className={styles.helpContainer}>
      <div className={styles.header}>
        <h1>Help & Support Center</h1>
        <p className={styles.subtitle}>
          Find the resources you need to make the most of Stumble
        </p>
      </div>

      <div className={styles.helpContent}>
        <section className={styles.helpSection}>
          <div className={styles.sectionIcon}>
            <FaDiscord size={28} />
          </div>
          <h2>Community Support</h2>
          <p>
            Connect with other Stumble users in our Discord community. Get help,
            share experiences, and provide feedback directly to our team.
          </p>
          <a
            href="https://discord.gg/dPXXA9DTDz"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.discordLink}
          >
            <FaDiscord size={24} />
            <span>Join our Discord community</span>
          </a>
        </section>

        <section className={styles.helpSection}>
          <div className={styles.sectionIcon}>
            <FaBook size={28} />
          </div>
          <h2>Resources</h2>
          <p>
            Access helpful resources for mental health support and professional
            help.
          </p>
          <div className={styles.resourceGrid}>
            <div className={styles.resourceCard}>
              <h3>Crisis Support</h3>
              <p>
                If you're in crisis or need immediate support, these resources
                can help.
              </p>
              <a href="/resources/crisis" className={styles.resourceLink}>
                View Crisis Resources
              </a>
            </div>
            <div className={styles.resourceCard}>
              <h3>Mental Health</h3>
              <p>
                Find information about mental health conditions and treatment
                options.
              </p>
              <a
                href="/resources/mental-health"
                className={styles.resourceLink}
              >
                Mental Health Resources
              </a>
            </div>
          </div>
        </section>

        <section className={styles.helpSection}>
          <div className={styles.sectionIcon}>
            <FaEnvelope size={28} />
          </div>
          <h2>Contact Support</h2>
          <p>
            Our support team is here to help with any questions or issues you
            might have with Stumble.
          </p>
          <div className={styles.contactOptions}>
            <div className={styles.contactOption}>
              <h3>
                <FaQuestionCircle className={styles.contactIcon} />
                General Support
              </h3>
              <a href="mailto:support@stumble.chat">support@stumble.chat</a>
              <p className={styles.contactDescription}>
                For general questions about using Stumble
              </p>
            </div>
            <div className={styles.contactOption}>
              <h3>
                <FaShieldAlt className={styles.contactIcon} />
                Account Issues
              </h3>
              <a href="mailto:accounts@stumble.chat">accounts@stumble.chat</a>
              <p className={styles.contactDescription}>
                For login problems or account-related concerns
              </p>
            </div>
            <div className={styles.contactOption}>
              <h3>
                <FaLightbulb className={styles.contactIcon} />
                Feedback & Suggestions
              </h3>
              <a href="mailto:feedback@stumble.chat">feedback@stumble.chat</a>
              <p className={styles.contactDescription}>
                Share your ideas to help improve Stumble
              </p>
            </div>
            <div className={styles.contactOption}>
              <h3>
                <FaExclamationTriangle className={styles.contactIcon} />
                Safety Concerns
              </h3>
              <a href="mailto:safety@stumble.chat">safety@stumble.chat</a>
              <p className={styles.contactDescription}>
                Report safety issues or concerning behavior
              </p>
            </div>
          </div>
        </section>

        <section className={styles.helpSection}>
          <div className={styles.sectionIcon}>
            <FaQuestionCircle size={28} />
          </div>
          <h2>Frequently Asked Questions</h2>
          <p>Find answers to common questions about using Stumble.</p>

          <div className={styles.searchContainer}>
            <div className={styles.searchInputWrapper}>
              <FaSearch className={styles.searchIcon} />
              <input
                type="text"
                placeholder="Search FAQs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
              {searchTerm && (
                <button
                  className={styles.clearSearch}
                  onClick={() => setSearchTerm("")}
                >
                  ×
                </button>
              )}
            </div>
          </div>

          <div className={styles.faqList}>
            {filteredFaqs.length > 0 ? (
              filteredFaqs.map((faq, index) => (
                <div key={index} className={styles.faqItem}>
                  <h4>{faq.question}</h4>
                  <p>{faq.answer}</p>
                </div>
              ))
            ) : (
              <div className={styles.noResults}>
                <p>
                  No FAQs match your search. Try different keywords or{" "}
                  <a href="mailto:support@stumble.chat">contact support</a>.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Help;
