import React, { useState, useEffect } from "react";
import {
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
} from "@floating-ui/react";
import { IoClose } from "react-icons/io5";
import styles from "./ReviewModal.module.css";
import Button from "components/Button/Button";
import StarRating from "./StarRating";
import { isReviewThrottled } from "utils/dates";
import { getScoreDescription } from "utils/scoreUtils";

const ReviewListModal = ({ reviews, isOpen, onClose }) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  if (!isOpen) return null;

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <FloatingPortal>
      <FloatingOverlay
        className={styles.overlay}
        lockScroll
        onClick={handleClick}
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.dialog}
            {...getFloatingProps({
              onClick: handleClick,
            })}
          >
            <div className={styles.header}>
              <div className={styles.title}>Previous Reviews</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                className={styles.closeButton}
              >
                <IoClose />
              </button>
            </div>
            <div className={styles.content}>
              {!reviews?.length ? (
                <p className={styles.description}>No reviews yet.</p>
              ) : (
                <ul className={styles.reviewList}>
                  {reviews.slice(0, 3).map((review) => (
                    <li key={review._id} className={styles.reviewItem}>
                      <div className={styles.reviewScores}>
                        <div className={styles.score}>
                          <label className={styles.scoreLabel}>Trust:</label>
                          <StarRating
                            rating={review.trustScore}
                            totalStars={5}
                            disabled
                            view="chat"
                          />
                        </div>
                        <div className={styles.score}>
                          <label className={styles.scoreLabel}>Warmth:</label>
                          <StarRating
                            rating={review.warmthScore}
                            totalStars={5}
                            disabled
                            view="chat"
                          />
                        </div>
                      </div>
                      <div className={styles.reviewText}>{review.comments}</div>
                      <div className={styles.reviewMeta}>
                        <span>
                          {new Date(review.createdAt).toLocaleDateString()}
                        </span>
                        <span className={styles.reviewStatus}>
                          {review.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

const ReviewModal = ({
  interactionType,
  interactionId,
  otherParticipant,
  reviews,
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  error,
}) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  const [trustScore, setTrustScore] = useState();
  const [warmthScore, setWarmthScore] = useState();
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({});
  const [fetchState, setFetchState] = useState("initial");
  const [reviewThrottle, setReviewThrottle] = useState(null);
  const [showPreviousReviews, setShowPreviousReviews] = useState(false);
  const [view, setView] = useState("form"); // 'form' | 'success' | 'error'

  useEffect(() => {
    const updateReviewAvailability = () => {
      if (reviews?.length > 0) {
        const message = isReviewThrottled(reviews[0].createdAt, 3);
        setReviewThrottle(message || false);
      } else {
        setReviewThrottle(false);
      }
    };

    updateReviewAvailability();
    const intervalId = setInterval(updateReviewAvailability, 60000);
    return () => clearInterval(intervalId);
  }, [reviews]);

  const handleSubmit = async (reviewData) => {
    setFetchState("loading");
    try {
      const success = await onSubmit(reviewData);
      setFetchState("success");
      if (success) {
        setView("success");
      } else {
        setView("error");
      }
    } catch (err) {
      console.error("Error submitting review:", err);
      setFetchState("error");
      setView("error");
    }
  };

  const handleClose = () => {
    onClose();
    // Reset state for next time
    setView("form");
  };

  if (!isOpen) return null;

  if (!otherParticipant) {
    return null; // Or show loading state
  }

  const ErrorView = ({ error, onClose }) => {
    // Map error codes to user-friendly messages
    const getErrorMessage = (error) => {
      if (!error) return "An unexpected error occurred. Please try again.";

      const errorMessages = {
        DISALLOWED_STATUS: error,
        DUPLICATE_REVIEW: "You've already reviewed this interaction recently.",
      };

      return errorMessages[error] || error;
    };

    return (
      <div className={styles.errorView}>
        <div className={styles.errorHeader}>
          <div className={styles.errorIcon}>⚠</div>
          <h3>Unable to Submit Review</h3>
        </div>

        <p className={styles.errorMessage}>{getErrorMessage(error)}</p>

        <Button
          onClick={onClose}
          className={styles.actionButton}
          size="small"
          shape="square"
        >
          Close
        </Button>
      </div>
    );
  };

  const SuccessView = ({ onClose }) => (
    <div className={styles.successView}>
      <div className={styles.successHeader}>
        <div className={styles.checkmark}>✓</div>
        <h3>Review Submitted</h3>
      </div>

      <p className={styles.successMessage}>
        Thank you for your feedback. This helps make Stumble a better place.
      </p>

      <Button
        onClick={() => {
          onClose();
          // Reset state for next time
          setView("form");
          setTrustScore(undefined);
          setWarmthScore(undefined);
          setComments("");
        }}
        className={styles.actionButton}
      >
        Close
      </Button>
    </div>
  );

  return (
    <>
      <FloatingPortal>
        <FloatingOverlay className={styles.overlay} lockScroll>
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              className={styles.dialog}
              {...getFloatingProps()}
            >
              {view === "form" ? (
                <>
                  <div className={styles.header}>
                    <div className={styles.title}>
                      Review your interaction with{" "}
                      {otherParticipant?.displayName || "this user"}
                    </div>
                    <button
                      onClick={handleClose}
                      className={styles.closeButton}
                    >
                      <IoClose />
                    </button>
                  </div>

                  <div className={styles.content}>
                    <div className={styles.formContainer}>
                      <div className={styles.ratingGroup}>
                        <label className={styles.formFieldLabel}>
                          Trust Score:
                        </label>
                        <StarRating
                          rating={trustScore}
                          setRating={setTrustScore}
                          totalStars={5}
                          disabled={fetchState === "success"}
                          view="chat"
                        />
                        {trustScore && (
                          <p className={styles.ratingDesc}>
                            {getScoreDescription(trustScore, "trust")}
                          </p>
                        )}
                        {errors.trustScore && (
                          <span className={styles.error}>
                            {errors.trustScore}
                          </span>
                        )}
                      </div>

                      <div className={styles.ratingGroup}>
                        <label className={styles.formFieldLabel}>
                          Warmth Score:
                        </label>
                        <StarRating
                          rating={warmthScore}
                          setRating={setWarmthScore}
                          totalStars={5}
                          disabled={fetchState === "success"}
                          view="chat"
                        />
                        {warmthScore && (
                          <p className={styles.ratingDesc}>
                            {getScoreDescription(warmthScore, "warmth")}
                          </p>
                        )}
                        {errors.warmthScore && (
                          <span className={styles.error}>
                            {errors.warmthScore}
                          </span>
                        )}
                      </div>

                      <div className={styles.formField}>
                        <label className={styles.formFieldLabel}>
                          Comments:
                        </label>
                        <textarea
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                          disabled={fetchState === "success"}
                          placeholder="Share your experience..."
                          className={styles.textarea}
                        />
                        {errors.comments && (
                          <span className={styles.error}>
                            {errors.comments}
                          </span>
                        )}
                      </div>

                      <div className={styles.buttonGroup}>
                        <Button
                          onClick={() =>
                            handleSubmit({
                              trustScore,
                              warmthScore,
                              comments: comments.trim(),
                            })
                          }
                          disabled={fetchState === "success" || reviewThrottle}
                          fetchState={fetchState}
                          size="small"
                          shape="square"
                          color="secondary"
                        >
                          {fetchState === "loading"
                            ? "Submitting..."
                            : fetchState === "success"
                            ? "Review submitted"
                            : fetchState === "error"
                            ? "Failed to submit"
                            : "Submit Review"}
                        </Button>

                        <Button
                          onClick={() => setShowPreviousReviews(true)}
                          size="small"
                          shape="square"
                          color="gray"
                        >
                          View Previous Reviews ({reviews?.length || 0})
                        </Button>
                      </div>

                      {reviewThrottle && (
                        <p className={styles.throttleMessage}>
                          {reviewThrottle}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : view === "success" ? (
                <SuccessView onClose={onClose} />
              ) : (
                <ErrorView error={error} onClose={onClose} />
              )}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>

      <ReviewListModal
        reviews={reviews}
        isOpen={showPreviousReviews}
        onClose={() => setShowPreviousReviews(false)}
      />
    </>
  );
};

export default ReviewModal;
