import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Chats.module.css";

import ChatWindow from "./ChatWindow"; // Import ChatWindow
import { useDispatch } from "react-redux";
import { fetchChats, initMessages, fetchReachouts } from "store/actions";

const Chats = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(initMessages());
    dispatch(fetchChats());
    dispatch(fetchReachouts());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <ChatWindow initialTab={type} initialId={id} />
    </div>
  );
};

export default Chats;
