import React, { useState, useEffect } from "react";
import {
  FloatingOverlay,
  FloatingFocusManager,
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  useId,
} from "@floating-ui/react";
import { motion } from "framer-motion";
import styles from "./SearchingOverlay.module.css";

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs.toString().padStart(2, "0")}`;
};

const SearchingOverlay = ({
  state,
  metadata,
  serverStats,
  onCancel,
  onBrowseContent,
}) => {
  const { refs, context } = useFloating({
    open: true,
    onOpenChange: () => {},
  });

  const dismiss = useDismiss(context, {
    enabled: false,
  });
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  const labelId = useId();
  const descriptionId = useId();

  const [elapsedSeconds, setElapsedSeconds] = useState(0);

  useEffect(() => {
    if (state === "searching") {
      if (metadata?.elapsedSeconds) {
        setElapsedSeconds(metadata.elapsedSeconds);
      }

      const timer = setInterval(() => {
        setElapsedSeconds((prev) => prev + 1);
      }, 1000);

      return () => clearInterval(timer);
    }
    return undefined;
  }, [state, metadata?.elapsedSeconds]);

  const getStateMessage = () => {
    switch (state) {
      case "initializing":
        return "Analyzing your story...";
      case "searching":
        if (metadata?.previousMatchRejected) {
          if (metadata.rejectionType === "you_rejected") {
            return "Finding another match...";
          }
          if (metadata.rejectionType === "other_rejected") {
            return "Previous match declined, finding new match...";
          }
        }
        if (metadata?.extendedSearch) {
          return "Still looking for the perfect match...";
        }
        return "Looking for someone to connect with...";
      default:
        return "Please wait...";
    }
  };

  const getDescription = () => {
    if (metadata?.previousMatchRejected) {
      if (metadata.rejectionType === "you_rejected") {
        return "Looking for someone who might be a better fit for you.";
      }
      if (metadata.rejectionType === "other_rejected") {
        return "Don't worry! We're looking for another person who might be a better match.";
      }
    }
    if (metadata?.extendedSearch) {
      return "Taking longer than usual, but we're still searching! You can continue waiting or try again with different preferences.";
    }
    return metadata?.message || "This might take a few moments...";
  };

  return (
    <FloatingOverlay className={styles.overlay} lockScroll>
      <FloatingFocusManager context={context}>
        <motion.div
          ref={refs.setFloating}
          className={styles.modal}
          aria-labelledby={labelId}
          aria-describedby={descriptionId}
          {...getFloatingProps()}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
        >
          <div className={styles.content}>
            <motion.div
              className={styles.spinner}
              animate={{
                scale: [1, 1.1, 1],
                opacity: [1, 0.8, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <motion.div
                className={styles.spinnerInner}
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              />
            </motion.div>

            <motion.h2
              id={labelId}
              className={styles.title}
              animate={{ opacity: [0.7, 1, 0.7] }}
              transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
            >
              {getStateMessage()}
            </motion.h2>

            <p id={descriptionId} className={styles.description}>
              {getDescription()}
            </p>

            {state === "searching" && (
              <motion.div
                className={styles.stats}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <div className={styles.statItem}>
                  <span className={styles.statIcon}>👥</span>
                  <span>
                    {serverStats.isStale
                      ? "Updating stats..."
                      : `${serverStats.searchingCount} ${
                          serverStats.searchingCount === 1 ? "person" : "people"
                        } searching`}
                  </span>
                </div>
                <span className={styles.statDivider}>•</span>
                <div className={styles.statItem}>
                  <span className={styles.statIcon}>⏱️</span>
                  <span>Time elapsed: {formatTime(elapsedSeconds)}</span>
                </div>
              </motion.div>
            )}

            {metadata?.extendedSearch && (
              <div className={styles.extendedOptions}>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onCancel}
                  className={styles.retryButton}
                  aria-label="Try different preferences"
                >
                  Try Different Preferences
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onBrowseContent}
                  className={styles.browseButton}
                  aria-label="Browse similar stories"
                >
                  Browse Similar Stories
                </motion.button>

                <motion.button
                  className={styles.therapistButton}
                  disabled
                  aria-label="Talk to a therapist (coming soon)"
                  title="Coming soon"
                >
                  Talk to a Therapist
                </motion.button>
              </div>
            )}

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onCancel}
              className={styles.cancelButton}
              aria-label="Cancel search"
            >
              Cancel
            </motion.button>
          </div>
        </motion.div>
      </FloatingFocusManager>
    </FloatingOverlay>
  );
};

export default SearchingOverlay;
