import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Card.module.css";
import { useSelector } from "react-redux";
import { selectLastMessageByChatId } from "store/selectors";
import LastMessageDisplay from "./LastMessageDisplay";
import { standardizeParticipant } from "utils/chatUtils";
import BaseCard from "./BaseCard";
import { UserContext } from "contexts/UserContext";

/**
 * @typedef {import("classes/Chat").default} Chat
 */
/**
 * @typedef {Object} props
 * @property {Chat} data - The chat data.
 * @property {function} onClick - Optional click handler.
 * @property {boolean} isSelected - Indicates if the chat is selected.
 */
/**
 * @type {React.ForwardRefExoticComponent<props & React.RefAttributes<any>>}
 */
const ChatCard = React.forwardRef(({ data, onClick, isSelected }, ref) => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const chatId = data._id;
  const lastMessage = useSelector((state) =>
    selectLastMessageByChatId(state, chatId)
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/chats?type=chats&id=${data._id}`);
    }
  };

  // Identify and standardize the other participant
  const rawOtherParticipant = data.participants.find(
    (p) => p._id !== userData._id
  );
  const otherParticipant = standardizeParticipant(rawOtherParticipant);

  const renderParticipantInfo = () => (
    <span className={styles.participantName}>
      {otherParticipant?.displayName}
    </span>
  );

  return (
    <BaseCard
      ref={ref}
      data={data}
      onClick={handleClick}
      isSelected={isSelected}
      headerContent={renderParticipantInfo()}
    >
      <LastMessageDisplay
        lastMessage={lastMessage}
        currentUserId={userData._id}
        otherParticipantName={otherParticipant?.displayName}
      />
    </BaseCard>
  );
});

export default ChatCard;
