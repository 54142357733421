import React from "react";
import { formatDate } from "utils/dates";
import { getScoreDescription } from "utils/scoreUtils";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoEllipsisHorizontal } from "react-icons/io5";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import BadgeSection from "./BadgeSection";
import useMediaQuery from "hooks/useMediaQuery";
import styles from "../Profile.module.css";
import { getStatusInfo } from "../utils/profileUtils";

/**
 * Component that displays the profile header with user info, scores, and badges
 */
const ProfileHeader = ({ profileData, isOwnProfile, getActionMenuOptions }) => {
  const isSmallScreen = useMediaQuery("sm");
  const isExtraSmallScreen = useMediaQuery("xs");
  const isLargeScreen = useMediaQuery("md");

  if (!profileData) return null;

  return (
    <div
      className={`${styles.header} ${
        isLargeScreen ? styles.desktopLayout : ""
      }`}
    >
      <div
        className={`${styles.profileMain} ${
          isExtraSmallScreen ? styles.stackedProfile : ""
        }`}
      >
        <div className={styles.userInfo}>
          <div className={styles.username}>{profileData.username}</div>
          <div className={styles.userMetadata}>
            {profileData.ageRange && (
              <div className={styles.metadataItem}>{profileData.ageRange}</div>
            )}
            {profileData.gender && (
              <div className={styles.metadataItem}>
                {profileData.gender.charAt(0).toUpperCase() +
                  profileData.gender.slice(1)}
              </div>
            )}
            {profileData.location?.name && (
              <div className={styles.metadataItem}>
                <HiOutlineLocationMarker />
                {profileData.location.name}
              </div>
            )}
          </div>

          {/* Status Badges */}
          {profileData.status && (
            <div className={styles.statusBadges}>
              {(() => {
                const statusInfo = getStatusInfo(
                  profileData.status,
                  profileData.registrationStatus,
                  profileData.isEmailVerified || false,
                  profileData.isAdminApproved || false
                );

                return (
                  <>
                    <div
                      className={`${styles.badge} ${
                        styles[statusInfo.account.className]
                      }`}
                    >
                      <statusInfo.account.icon />
                      <span>{statusInfo.account.label}</span>
                    </div>

                    <div
                      className={`${styles.badge} ${
                        styles[statusInfo.emailVerification.className]
                      }`}
                    >
                      <statusInfo.emailVerification.icon />
                      <span>{statusInfo.emailVerification.label}</span>
                    </div>

                    {profileData.isAdminApproved !== undefined && (
                      <div
                        className={`${styles.badge} ${
                          styles[statusInfo.adminApproval.className]
                        }`}
                      >
                        <statusInfo.adminApproval.icon />
                        <span>{statusInfo.adminApproval.label}</span>
                      </div>
                    )}
                  </>
                );
              })()}
            </div>
          )}

          <div className={styles.infoLine}>
            Joined on {formatDate(profileData.createdAt)}
          </div>
        </div>

        <div className={styles.actionMenu}>
          <ReusableActionMenu
            options={getActionMenuOptions()}
            icon={IoEllipsisHorizontal}
          />
        </div>
      </div>

      <div
        className={`${styles.scoresSection} ${
          isSmallScreen ? styles.compactScores : ""
        }`}
      >
        <div className={styles.scoreCard}>
          <div className={styles.scoreHeader}>
            {isSmallScreen ? "Trust" : "Trust Score"}
          </div>
          <div className={styles.scoreValue}>
            {profileData.scores?.trustScore?.toFixed(1)}
            <span className={styles.maxScore}>/5.0</span>
          </div>
          {!isSmallScreen && (
            <div className={styles.scoreDescription}>
              {getScoreDescription(profileData.scores?.trustScore, "trust")}
            </div>
          )}
        </div>

        <div className={styles.scoreCard}>
          <div className={styles.scoreHeader}>
            {isSmallScreen ? "Warmth" : "Warmth Score"}
          </div>
          <div className={styles.scoreValue}>
            {profileData.scores?.warmthScore?.toFixed(1)}
            <span className={styles.maxScore}>/5.0</span>
          </div>
          {!isSmallScreen && (
            <div className={styles.scoreDescription}>
              {getScoreDescription(profileData.scores?.warmthScore, "warmth")}
            </div>
          )}
        </div>

        <div className={styles.scoreCard}>
          <div className={styles.scoreHeader}>
            {isSmallScreen ? "Reviews" : "Total Reviews"}
          </div>
          <div className={styles.scoreValue}>
            {profileData.scores?.totalReviews || 0}
          </div>
        </div>
      </div>

      <BadgeSection
        userId={profileData._id}
        isCurrentUser={isOwnProfile}
        profileData={profileData}
      />
    </div>
  );
};

export default ProfileHeader;
