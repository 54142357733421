import React from "react";
import styles from "../Profile.module.css";

/**
 * Component that displays the profile tabs for navigation
 */
const ProfileTabs = ({ activeTab, handleTabClick }) => {
  const tabs = [
    { id: "summary", label: "Summary", path: "" },
    { id: "lifeExperiences", label: "Life Experiences", path: "experiences" },
    { id: "stories", label: "Stories", path: "stories" },
  ];

  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={`${styles.tab} ${
            activeTab === tab.id ? styles.activeTab : ""
          }`}
          onClick={() => handleTabClick(tab.path)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default ProfileTabs;
