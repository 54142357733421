import React, { useState } from "react";
import styles from "./ChatSideMenu.module.css";
import Button from "components/Button/Button";
import { IoClose } from "react-icons/io5";
import ConnectionCard from "./ConnectionCard";
import {
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
} from "@floating-ui/react";
import cn from "classnames";

const ConnectionsPanel = ({ connections, userData }) => {
  const [isShowingAll, setIsShowingAll] = useState(false);

  // Floating UI hooks
  const { refs, context } = useFloating({
    open: isShowingAll,
    onOpenChange: setIsShowingAll,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  if (!connections || connections.length === 0) return null;

  // Sort connections by date (most recent first)
  const sortedConnections = [...connections].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  // Get the three most recent connections
  const recentConnections = sortedConnections.slice(0, 3);

  return (
    <div className={styles.connectionsPanel}>
      <h3 className={styles.sectionTitle}>Recent Connections</h3>
      {recentConnections.map((connection) => (
        <ConnectionCard
          key={connection._id}
          connection={connection}
          userData={userData}
        />
      ))}

      {connections.length > 3 && (
        <Button
          className={styles.showMoreButton}
          onClick={() => setIsShowingAll(true)}
        >
          Show All Connections
        </Button>
      )}

      {isShowingAll && (
        <FloatingPortal>
          <FloatingOverlay className={styles.overlay} lockScroll>
            <FloatingFocusManager context={context}>
              <div
                ref={refs.setFloating}
                className={styles.allConnectionsDialog}
                {...getFloatingProps()}
              >
                <div className={styles.dialogHeader}>
                  <h2>All Connections</h2>
                  <button
                    onClick={() => setIsShowingAll(false)}
                    className={styles.closeButton}
                  >
                    <IoClose />
                  </button>
                </div>
                <div className={styles.dialogContent}>
                  {sortedConnections.map((connection) => (
                    <ConnectionCard
                      key={connection._id}
                      connection={connection}
                      userData={userData}
                    />
                  ))}
                </div>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </div>
  );
};

export default ConnectionsPanel;
