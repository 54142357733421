import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Card.module.css";
import { useSelector } from "react-redux";
import { selectLastMessageByMatchId } from "store/selectors";
import LastMessageDisplay from "./LastMessageDisplay";
import { standardizeParticipant } from "utils/chatUtils";
import BaseCard from "./BaseCard";
import { UserContext } from "contexts/UserContext";
import cn from "classnames";

const MatchCard = React.forwardRef(({ data, onClick, isSelected }, ref) => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const lastMessage = useSelector((state) =>
    selectLastMessageByMatchId(state, data._id)
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/chats?type=matches&id=${data._id}`);
    }
  };

  // Identify and standardize the other participant
  const rawOtherParticipant = data.participants.find(
    (participant) => participant.user._id !== userData._id
  );

  const otherParticipant = standardizeParticipant(rawOtherParticipant);

  // Logging the standardized participant info

  const renderParticipantInfo = () => (
    <span className={styles.participantName}>
      {otherParticipant?.displayName}
    </span>
  );

  return (
    <BaseCard
      ref={ref}
      data={data}
      onClick={handleClick}
      isSelected={isSelected}
      className={cn(styles[data.status], styles.matchCard)}
      headerContent={renderParticipantInfo()}
    >
      <LastMessageDisplay
        lastMessage={lastMessage}
        currentUserId={userData._id}
        otherParticipantName={otherParticipant?.displayName}
      />
      {data.metadata?.sharedTags?.length > 0 && (
        <div className={styles.sharedTags}>
          {data.metadata.sharedTags.map((tag, index) => (
            <div key={index} className={styles.tag}>
              {tag.name}
            </div>
          ))}
        </div>
      )}
    </BaseCard>
  );
});

export default MatchCard;
