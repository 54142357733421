// packages/client/src/pages/Register/StepUsername.jsx
import React, { useContext, useEffect, useRef, useState } from "react";
import validator from "validator";
import common from "../Register.module.css";
import styles from "./StepUsername.module.css";
import { motion } from "framer-motion";
import cn from "classnames";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import * as USERS_API from "api/users";

import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";

const InputWrapper = ({
  children,
  valid,
  helperText,
  isLoading = false,
  status,
}) => {
  const getStatusIcon = () => {
    if (isLoading) {
      return (
        <motion.div
          className={styles.icon}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <LoadingSpinner />
        </motion.div>
      );
    }

    if (status === "success") {
      return (
        <motion.div
          className={cn(styles.icon, styles.success)}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <FaCheckCircle />
        </motion.div>
      );
    }

    if (status === "error") {
      return (
        <motion.div
          className={cn(styles.icon, styles.error)}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <FaTimesCircle />
        </motion.div>
      );
    }

    return null;
  };

  return (
    <div className={styles.fieldContainer}>
      <div className={cn(styles.inputWrapper, status && styles[status])}>
        {children}
        {getStatusIcon()}
      </div>
      {helperText && (
        <div className={cn(styles.helperText, status && styles[status])}>
          {helperText}
        </div>
      )}
    </div>
  );
};

const steps = [
  {
    target: '[data-tour="username-input"]',
    title: "Choose a Username",
    content: (
      <div>
        <p>Your username is how others will identify you:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>✨ Be creative but appropriate</li>
          <li>🔒 Avoid using personal information</li>
          <li>📝 Lowercase letters only</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
];

export const StepUsername = ({
  data,
  onSave,
  handleStepValidity,
  tourCompleted = false,
  onTourComplete,
}) => {
  const [username, setUsername] = useState(data?.username || "");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validationStatus, setValidationStatus] = useState(null);
  const [startTour, setStartTour] = useState(false);

  useEffect(() => {
    const isValid = username && isUsernameAvailable;
    handleStepValidity(isValid);
  }, [username, isUsernameAvailable, handleStepValidity]);

  useEffect(() => {
    if (username) {
      const delayDebounceFn = setTimeout(() => {
        if (validator.isAlphanumeric(username) && username.length >= 3) {
          checkUsernameAvailability(username);
        } else {
          setValidationStatus("error");
          setMessage(
            "Username must be at least 3 characters and contain only letters and numbers"
          );
        }
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setValidationStatus(null);
      setMessage("");
    }
  }, [username]);

  useEffect(() => {
    setStartTour(true);
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value.toLowerCase());
  };

  const checkUsernameAvailability = async (username) => {
    try {
      setIsLoading(true);
      const data = await USERS_API.checkAvailability(username);

      setIsUsernameAvailable(data.available);
      setMessage(data.message);
      setValidationStatus(data.available ? "success" : "error");

      if (data.available) {
        onSave({ username });
      }
    } catch (error) {
      setIsUsernameAvailable(false);
      setMessage(error.message || "Error checking username availability");
      setValidationStatus("error");
    } finally {
      setIsLoading(false);
    }
  };

  const inputRef = useRef(null);

  return (
    <div className={styles.usernameStep}>
      <div className={common.header}>
        <h1>What should people call you?</h1>
      </div>

      <p className={common.subheader}>
        Choose a username that will identify you in the community
      </p>

      <div className={common["input-group"]}>
        <div className={common["label-tooltip"]}>
          <label>Username</label>
          <InfoTooltip content="Choose a unique username that will identify you in the community. Avoid using personal information." />
        </div>

        <InputWrapper
          isLoading={isLoading}
          status={validationStatus}
          helperText={message}
        >
          <input
            className={styles.usernameInput}
            ref={inputRef}
            autoFocus
            required
            type="text"
            value={username}
            onChange={handleUsernameChange}
            data-tour="username-input"
          />
        </InputWrapper>
      </div>

      <JoyrideWrapper
        steps={steps}
        runOnInit={!tourCompleted}
        onTourComplete={onTourComplete}
        hasCompletedTour={tourCompleted}
        fabPosition="bottom-center"
      />
    </div>
  );
};
