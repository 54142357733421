import React from "react";
import { motion } from "framer-motion";
import styles from "./BetaCalloutSection.module.css";

// Import social media icons
import { FaInstagram, FaTwitter } from "react-icons/fa";

const BetaCalloutSection = () => {
  return (
    <motion.section
      className={styles.betaCalloutSection}
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8 }}
      id="beta-callout"
    >
      <div className={styles.calloutContainer}>
        <motion.div
          className={styles.betaCallout}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2>Help Shape Stumble's Future</h2>
          <p>
            Stumble is still in beta, which means it's not perfect. You might
            encounter bugs or rough edges. But that also means you have the
            opportunity to help shape what Stumble becomes.
          </p>
          <p>
            Your voice matters. Your experience matters. And your feedback will
            help us build Stumble into the platform that people like us need.
          </p>

          <div className={styles.socialLinks}>
            <div className={styles.otherSocials}>
              <a
                href="https://instagram.com/stumble.chat"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialIcon}
                aria-label="Instagram"
              >
                <FaInstagram size={20} />
              </a>
              <a
                href="https://twitter.com/stumble_chat"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialIcon}
                aria-label="Twitter"
              >
                <FaTwitter size={20} />
              </a>
            </div>
          </div>

          <div className={styles.feedbackEmail}>
            <p>Have suggestions or feedback?</p>
            <a href="mailto:feedback@stumble.chat" className={styles.emailLink}>
              feedback@stumble.chat
            </a>
          </div>

          <div className={styles.roadmap}>
            <h3>What we're building right now...</h3>
            <ul>
              <li>Enhanced matching algorithms</li>
              <li>Improved lobby experience</li>
              <li>Better messaging features</li>
              <li>More privacy controls</li>
            </ul>
          </div>

          <button
            className={styles.joinButton}
            onClick={() => (window.location.href = "/login")}
          >
            Join the Beta
          </button>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default BetaCalloutSection;
