import { useState, useEffect } from "react";
import { useBrowserContext } from "contexts/BrowserContext";
import WebViewModal from "components/WebViewModal/WebViewModal";

const WebViewRedirect = () => {
  const { isWebView } = useBrowserContext();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // If in a WebView, show the modal
    if (isWebView) {
      setShowModal(true);
    }
  }, [isWebView]);

  // Only render the modal if we're in a WebView
  return showModal ? <WebViewModal /> : null;
};

export default WebViewRedirect;
