import { createSelector } from "reselect";

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

// Selector to get all chats by ID
const selectChatsById = (state) => state.chats.byId;

// Selector to get all reachouts by ID
const selectReachoutsById = (state) => state.reachouts.byId;

// Selector to get a particular reachout by ID
export const selectReachoutById = (state, id) =>
  state.reachouts.byId[id] || EMPTY_OBJECT;

// Selector to get all messages by ID
const selectMessagesById = (state) => state.messages.byId;

// Selector to get message objects by their IDs
export const selectMessagesByChatId = createSelector(
  [
    (state, chatId) => state.messages.byChatId[chatId] || EMPTY_ARRAY,
    (state) => state.messages.byId,
  ],
  (messageIds, messagesById) =>
    messageIds
      .map((id) => messagesById[id])
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort by created date
);

// Selector to get the last message by chat ID
export const selectLastMessageByChatId = createSelector(
  [selectMessagesByChatId],
  (sortedMessages) => {
    return sortedMessages[sortedMessages.length - 1] || null; // Return the last message or null if no messages
  }
);

// Selector to get message objects by their IDs for chat requests
export const selectMessagesByChatRequestId = createSelector(
  [
    (state, chatRequestId) =>
      state.messages.byChatRequestId[chatRequestId] || EMPTY_ARRAY,
    (state) => state.messages.byId,
  ],
  (messageIds, messagesById) =>
    messageIds
      .map((id) => messagesById[id])
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort by created date
);

// Selector to get message objects by their IDs for reachouts
export const selectMessagesByReachoutId = createSelector(
  [
    (state, reachoutId) =>
      state.messages.byReachoutId[reachoutId] || EMPTY_ARRAY,
    (state) => state.messages.byId,
  ],
  (messageIds, messagesById) =>
    messageIds
      .map((id) => messagesById[id])
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort by created date
);

// Selector to get the last message by reachout ID
export const selectLastMessageByReachoutId = createSelector(
  [selectMessagesByReachoutId],
  (sortedMessages) => {
    return sortedMessages[sortedMessages.length - 1] || null; // Return the last message or null if no messages
  }
);

// Selector to get sorted chats using the existing `selectLastMessageByChatId`
export const selectSortedChats = createSelector(
  [selectChatsById, selectMessagesById], // Get all chatIds and state for lookup
  (chatsById, messagesById) => {
    // Helper function to find the most recent message for each chat
    const getLastMessageForChat = (chatId, messagesById) => {
      // Filter the messages that belong to this chatId
      const messagesForChat = Object.values(messagesById).filter(
        (message) => message.chatId === chatId
      );

      // Sort them by createdAt timestamp and get the most recent one
      const lastMessage = messagesForChat
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .pop();

      return lastMessage || null; // Return null if no messages
    };

    return Object.values(chatsById)
      .map((chat) => {
        // Get the last message for each chat
        const lastMessage = getLastMessageForChat(chat._id, messagesById);
        return { ...chat, lastMessage };
      })
      .sort((a, b) => {
        // Sort chats based on the last message timestamp
        const aLastMessageTime = new Date(a.lastMessage?.createdAt || 0);
        const bLastMessageTime = new Date(b.lastMessage?.createdAt || 0);
        return bLastMessageTime - aLastMessageTime; // Sort descending by time
      });
  }
);

// Selector to get sorted reachouts using the last message for each reachout
export const selectSortedReachouts = createSelector(
  [selectReachoutsById, selectMessagesById],
  (reachoutsById, messagesById) => {
    const getLastMessageForReachout = (reachoutId, messagesById) => {
      // Filter the messages that belong to this reachoutId
      const messagesForReachout = Object.values(messagesById).filter(
        (message) => message.reachoutId === reachoutId
      );

      // Sort them by createdAt timestamp and get the most recent one
      const lastMessage = messagesForReachout
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .pop();

      return lastMessage || null; // Return null if no messages
    };

    return Object.values(reachoutsById)
      .map((reachout) => {
        const lastMessage = getLastMessageForReachout(
          reachout._id,
          messagesById
        );
        return { ...reachout, lastMessage };
      })
      .sort((a, b) => {
        // Get the most recent timestamp for each reachout (either last message or reachout creation)
        const aTimestamp = Math.max(
          new Date(a.lastMessage?.createdAt || 0),
          new Date(a.createdAt)
        );
        const bTimestamp = Math.max(
          new Date(b.lastMessage?.createdAt || 0),
          new Date(b.createdAt)
        );
        return bTimestamp - aTimestamp; // Sort descending by time
      });
  }
);

// Selector to get all reviews by ID
const selectReviewsById = (state) => state.reviews.byId;

// Selector to get review objects by their IDs for a specific chat
export const selectReviewsByChatId = createSelector(
  [
    (state, chatId) => state.reviews.byChatId[chatId] || EMPTY_ARRAY,
    (state) => state.reviews.byId,
  ],
  (reviewIds, reviewsById) => reviewIds.map((id) => reviewsById[id])
);

// Update review selectors
export const selectReviewsByInteractionId = createSelector(
  [
    (state, interactionId) =>
      state.reviews.byInteractionId[interactionId] || EMPTY_ARRAY,
    (state) => state.reviews.byId,
  ],
  (reviewIds, reviewsById) => reviewIds.map((id) => reviewsById[id])
);

// Add new selector for filtering reviews
export const selectReviewsByFilters = createSelector(
  [(state) => state.reviews.byId, (_, filters) => filters],
  (reviewsById, filters) => {
    return Object.values(reviewsById).filter((review) => {
      if (
        filters.interactionType &&
        review.interactionType !== filters.interactionType
      )
        return false;
      if (
        filters.interactionId &&
        review.interactionId !== filters.interactionId
      )
        return false;
      if (filters.authorId && review.author !== filters.authorId) return false;
      if (filters.subjectId && review.subject !== filters.subjectId)
        return false;
      if (filters.status && review.status !== filters.status) return false;
      return true;
    });
  }
);

// Add new selectors
const selectMatchesById = (state) => state.matches.byId;

export const selectMatchById = (state, id) =>
  state.matches.byId[id] || EMPTY_OBJECT;

export const selectMessagesByMatchId = createSelector(
  [
    (state, matchId) => state.messages.byMatchId[matchId] || EMPTY_ARRAY,
    selectMessagesById,
  ],
  (messageIds, messagesById) =>
    messageIds
      .map((id) => messagesById[id])
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
);

export const selectLastMessageByMatchId = createSelector(
  [selectMessagesByMatchId],
  (sortedMessages) => sortedMessages[sortedMessages.length - 1] || null
);

export const selectSortedMatches = createSelector(
  [selectMatchesById, selectMessagesById],
  (matchesById, messagesById) => {
    const getLastMessageForMatch = (matchId, messagesById) => {
      const messagesForMatch = Object.values(messagesById).filter(
        (message) => message.matchId === matchId
      );
      return (
        messagesForMatch
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .pop() || null
      );
    };

    return Object.values(matchesById)
      .map((match) => ({
        ...match,
        lastMessage: getLastMessageForMatch(match._id, messagesById),
      }))
      .sort((a, b) => {
        const aTimestamp = Math.max(
          new Date(a.lastMessage?.createdAt || 0),
          new Date(a.createdAt)
        );
        const bTimestamp = Math.max(
          new Date(b.lastMessage?.createdAt || 0),
          new Date(b.createdAt)
        );
        return bTimestamp - aTimestamp;
      });
  }
);
