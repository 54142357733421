import React from "react";
import styles from "./Profile.module.css";
import useMediaQuery from "hooks/useMediaQuery";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { HiLockClosed, HiLockOpen } from "react-icons/hi";
import { useUserContext } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";
import cn from "classnames"; // Import classnames for conditional styling

const SummaryContent = ({ summaryData, profileData, isRestricted = false }) => {
  const isSmallScreen = useMediaQuery("sm");
  const isExtraSmallScreen = useMediaQuery("xs");
  const { userData: currentUser } = useUserContext();
  const isOwnProfile = currentUser?.username === profileData?.username;
  const navigate = useNavigate();

  if (isRestricted) {
    return (
      <div className={styles.summaryContent}>
        <div className={styles.section}>
          <h3>Profile Summary</h3>
          <p className={styles.restrictedNote}>
            Limited information is available while this account is restricted.
          </p>
          {profileData?.bio && (
            <div className={styles.bioSection}>
              <h4>Bio</h4>
              <p>{profileData.bio}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (!summaryData || !profileData) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingMessage message="Loading summary data..." />
      </div>
    );
  }

  const { lifeExperienceTags, storyTags, counts } = summaryData;
  const hasHiddenContent =
    counts.lifeExperiences.hidden > 0 || counts.stories.hidden > 0;

  const handleTagClick = (tagName) => {
    // This could be implemented to filter content by tag
  };

  // Create tag component with conditional styling
  const Tag = ({ tag }) => (
    <div
      key={tag._id}
      className={cn(styles.tag, {
        [styles.mobileTag]: isSmallScreen,
        [styles.extraSmallTag]: isExtraSmallScreen,
      })}
      onClick={() => handleTagClick(tag.name)}
    >
      <span className={styles.tagName}>{tag.name}</span>
      <span className={styles.tagCount}>{tag.count}</span>
    </div>
  );

  // Create tags container with conditional styling
  const TagsContainer = ({ children }) => (
    <div
      className={cn(styles.tags, {
        [styles.mobileTags]: isSmallScreen,
      })}
    >
      {children}
    </div>
  );

  return (
    <div className={styles.summary}>
      <div className={styles.summaryHeader}>
        <h2>User Summary</h2>
        <p className={styles.summaryDescription}>
          {isOwnProfile
            ? "This is an overview of your interests and experiences."
            : `This is an overview of ${summaryData.username}'s interests and experiences.`}
        </p>
      </div>

      {/* Content Visibility Overview */}
      {!isOwnProfile && hasHiddenContent && (
        <div className={styles.visibilityOverview}>
          <div className={styles.visibilityCard}>
            <div className={styles.visibilityIcon}>
              <HiLockOpen size={24} />
            </div>
            <div className={styles.visibilityContent}>
              <h3>Visible Content</h3>
              <p>
                You can see {counts.lifeExperiences.visible} life experiences
                and {counts.stories.visible} stories.
              </p>
            </div>
          </div>

          <div className={styles.visibilityCard}>
            <div className={styles.visibilityIcon}>
              <HiLockClosed size={24} />
            </div>
            <div className={styles.visibilityContent}>
              <h3>Hidden Content</h3>
              <p>
                {profileData.username} has {counts.lifeExperiences.hidden} life
                experiences and {counts.stories.hidden} stories you haven't
                connected on
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Life Experiences Section */}
      <div className={styles.summarySection}>
        <div className={styles.sectionHeader}>
          <h3>Life Experiences</h3>
          <div className={styles.sectionStats}>
            <span className={styles.tagCount}>
              {lifeExperienceTags.length} tags
            </span>
            {!isOwnProfile && counts.lifeExperiences.hidden > 0 && (
              <span className={styles.hiddenCount}>
                {counts.lifeExperiences.hidden} locked
              </span>
            )}
          </div>
        </div>

        {lifeExperienceTags.length > 0 ? (
          <TagsContainer>
            {lifeExperienceTags.map((tag) => (
              <Tag key={tag._id} tag={tag} />
            ))}
          </TagsContainer>
        ) : (
          <div className={styles.emptyContent}>
            {isOwnProfile ? (
              <p className={styles.emptyMessage}>
                You haven't shared any life experiences yet.
              </p>
            ) : counts.lifeExperiences.total > 0 ? (
              <p className={styles.emptyMessage}>
                {profileData.username} has {counts.lifeExperiences.total} life
                experiences
                {counts.lifeExperiences.hidden > 0 &&
                  ` (${counts.lifeExperiences.hidden} you haven't connected on)`}
              </p>
            ) : (
              <p className={styles.emptyMessage}>
                No life experiences shared yet.
              </p>
            )}
          </div>
        )}
      </div>

      {/* Stories Section */}
      <div className={styles.summarySection}>
        <div className={styles.sectionHeader}>
          <h3>Stories</h3>
          <div className={styles.sectionStats}>
            <span className={styles.tagCount}>{storyTags.length} tags</span>
            {!isOwnProfile && counts.stories.hidden > 0 && (
              <span className={styles.hiddenCount}>
                {counts.stories.hidden} locked
              </span>
            )}
          </div>
        </div>

        {storyTags.length > 0 ? (
          <TagsContainer>
            {storyTags.map((tag) => (
              <Tag key={tag._id} tag={tag} />
            ))}
          </TagsContainer>
        ) : (
          <div className={styles.emptyContent}>
            {isOwnProfile ? (
              <p className={styles.emptyMessage}>
                You haven't shared any stories yet.
              </p>
            ) : counts.stories.total > 0 ? (
              <p className={styles.emptyMessage}>
                {profileData.username} has {counts.stories.total} stories
                {counts.stories.hidden > 0 &&
                  ` (${counts.stories.hidden} you haven't connected on)`}
              </p>
            ) : (
              <p className={styles.emptyMessage}>No stories shared yet.</p>
            )}
          </div>
        )}
      </div>

      {!isSmallScreen && (
        <div className={styles.summaryFooter}>
          <p>
            Explore the tabs above to see detailed life experiences and stories.
          </p>
        </div>
      )}
    </div>
  );
};

export default SummaryContent;
