import { useState } from "react";
import styles from "./ActionModals.module.css";
import Button from "components/Button/Button";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

/**
 * A reusable modal component for report actions in the admin dashboard
 * using Floating UI for better positioning and accessibility
 *
 * @param {Object} props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when closing the modal
 * @param {string} props.actionType - The type of action (Resolve, Dismiss)
 * @param {Object} props.report - The report object the action is being performed on
 * @param {Function} props.onSubmit - Function to call when submitting the action
 * @param {boolean} props.isSubmitting - Whether the action is currently being submitted
 */
const ReportActionModal = ({
  isOpen,
  onClose,
  actionType,
  report,
  onSubmit,
  isSubmitting,
}) => {
  const [actionReason, setActionReason] = useState("");
  const [actionOption, setActionOption] = useState("content_removed");

  // Set up floating UI
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context, { role: "dialog" });
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  const handleSubmit = () => {
    if (actionType === "Resolve") {
      onSubmit({
        action: actionOption,
        notes: actionReason,
      });
    } else {
      onSubmit({
        reason: actionReason,
      });
    }
  };

  const handleClose = () => {
    setActionReason(""); // Reset form on close
    setActionOption("content_removed"); // Reset to default
    onClose();
  };

  // Handle overlay background click manually
  const handleOverlayClick = (e) => {
    // Only close if clicking directly on the overlay (not on the modal or its children)
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  if (!isOpen || !report) return null;

  // Format target type for display
  const formatTargetType = (type) => {
    switch (type) {
      case "Story":
        return "Content (Story)";
      case "LifeExperience":
        return "Content (Life Experience)";
      case "User":
        return "User";
      case "Reachout":
        return "Reachout";
      case "Match":
        return "Match";
      case "Chat":
        return "Chat";
      default:
        return type;
    }
  };

  // Format reason for display
  const formatReason = (reason) => {
    return reason?.replace(/_/g, " ") || "";
  };

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll
        className={styles.overlay}
        onClick={handleOverlayClick}
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.floatingModal}
            {...getFloatingProps()}
          >
            <div className={styles.modalHeader}>
              <h3>{actionType} Report</h3>
              <button
                onClick={handleClose}
                className={styles.closeButton}
                aria-label="Close modal"
                disabled={isSubmitting}
              >
                ×
              </button>
            </div>

            <div className={styles.resourceIdentifier}>
              Report ID: {report._id}
            </div>

            <div className={styles.resourceDetails}>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Type:</span>
                <span className={styles.detailValue}>
                  {formatTargetType(report.targetType)}
                </span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Reason:</span>
                <span className={styles.detailValue}>
                  {formatReason(report.reason)}
                </span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Status:</span>
                <span className={styles.detailValue}>
                  <span className={styles[`status-${report.status}`]}>
                    {report.status}
                  </span>
                </span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Reporter:</span>
                <span className={styles.detailValue}>
                  {report.reporter?.username || "Unknown"}
                </span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Reported User:</span>
                <span className={styles.detailValue}>
                  {report.reportedUser?.username || "Unknown"}
                </span>
              </div>
            </div>

            <div className={styles.modalContent}>
              {actionType === "Resolve" && (
                <div className={styles.formGroup}>
                  <label htmlFor="actionType">Action to take:</label>
                  <select
                    id="actionType"
                    value={actionOption}
                    onChange={(e) => setActionOption(e.target.value)}
                    disabled={isSubmitting}
                  >
                    <option value="content_removed">Remove Content</option>
                    <option value="user_warned">Warn User</option>
                    <option value="user_suspended">
                      Suspend User (7 days)
                    </option>
                    <option value="user_banned">Ban User (Permanent)</option>
                    <option value="no_action">No Action Needed</option>
                  </select>
                </div>
              )}
              <div className={styles.formGroup}>
                <label htmlFor="actionReason">
                  {actionType === "Resolve"
                    ? "Resolution notes:"
                    : "Dismissal reason:"}
                </label>
                <textarea
                  id="actionReason"
                  value={actionReason}
                  onChange={(e) => setActionReason(e.target.value)}
                  placeholder={
                    actionType === "Resolve"
                      ? "Provide detailed explanation of resolution actions taken..."
                      : "Explain why this report is being dismissed..."
                  }
                  disabled={isSubmitting}
                />
                {actionType === "Resolve" && actionOption.includes("user_") && (
                  <p className={styles.warningText}>
                    This action will affect the reported user's account status.
                  </p>
                )}
              </div>

              <div className={styles.modalActions}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={!actionReason || isSubmitting}
                  color={
                    actionType === "Resolve" &&
                    actionOption.includes("user_banned")
                      ? "danger"
                      : "primary"
                  }
                >
                  {isSubmitting ? "Processing..." : actionType}
                </Button>
              </div>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default ReportActionModal;
