import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import styles from "./ConnectionAnimation.module.css";

const ConnectionAnimation = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;
    let particles = [];

    // Set canvas dimensions
    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    // Particle class
    class Particle {
      constructor(x, y, speedX) {
        this.x = x;
        this.y = y;
        this.size = Math.random() * 3 + 1;
        this.speedX = speedX;
        this.speedY = (Math.random() - 0.5) * 1;
        this.color = "#FED030";
        this.alpha = Math.random() * 0.5 + 0.5;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        if (this.size > 0.2) this.size -= 0.05;
        this.alpha -= 0.01;
      }

      draw() {
        ctx.globalAlpha = this.alpha;
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
        ctx.globalAlpha = 1;
      }
    }

    // Animation loop
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw line
      ctx.strokeStyle = "#FED030";
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(canvas.width * 0.3, canvas.height / 2);
      ctx.lineTo(canvas.width * 0.7, canvas.height / 2);
      ctx.stroke();

      // Generate new particles
      if (Math.random() < 0.2) {
        const x = canvas.width * 0.3 + Math.random() * (canvas.width * 0.4);
        const y = canvas.height / 2;
        const speedX = (Math.random() - 0.5) * 2;
        particles.push(new Particle(x, y, speedX));
      }

      // Update and draw particles
      for (let i = 0; i < particles.length; i++) {
        particles[i].update();
        particles[i].draw();

        if (particles[i].alpha <= 0 || particles[i].size <= 0.2) {
          particles.splice(i, 1);
          i--;
        }
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    // Start animation after a delay
    const timeoutId = setTimeout(() => {
      animate();
    }, 1000);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      cancelAnimationFrame(animationFrameId);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={styles.connectionContainer}>
      <motion.div
        className={styles.personLeft}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          className={styles.personSvg}
        >
          <circle cx="30" cy="20" r="12" fill="#FED030" />
          <path d="M15,60 C15,42 45,42 45,60" fill="#FED030" stroke="none" />
        </svg>
      </motion.div>

      <canvas ref={canvasRef} className={styles.connectionCanvas} />

      <motion.div
        className={styles.personRight}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          className={styles.personSvg}
        >
          <circle cx="30" cy="20" r="12" fill="#FED030" />
          <path d="M15,60 C15,42 45,42 45,60" fill="#FED030" stroke="none" />
        </svg>
      </motion.div>
    </div>
  );
};

export default ConnectionAnimation;
