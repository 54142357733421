import { useState } from "react";
import styles from "./Settings.module.css";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";

const Settings = () => {
  const { addToast } = useToastContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Example settings state
  const [settings, setSettings] = useState({
    userApprovalRequired: true,
    maxReportsBeforeAutoFlag: 3,
    contentRetentionDays: 90,
    maintenanceMode: false,
    allowNewRegistrations: true,
  });

  const handleToggle = (setting) => {
    setSettings({
      ...settings,
      [setting]: !settings[setting],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      [name]: value,
    });
  };

  const handleSaveSettings = async () => {
    setIsSubmitting(true);

    try {
      // This would be an API call to save settings
      // await api.saveAdminSettings(settings);

      // Simulate API delay
      await new Promise((resolve) => setTimeout(resolve, 800));

      addToast("Settings saved successfully", "success");
    } catch (error) {
      console.error("Error saving settings:", error);
      addToast("Failed to save settings", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.settingsContainer}>
      <h1 className={styles.pageTitle}>Admin Settings</h1>

      <div className={styles.settingsSection}>
        <h2>User Management</h2>

        <div className={styles.settingItem}>
          <div className={styles.settingInfo}>
            <h3>Require Admin Approval</h3>
            <p>
              New users must be approved by an admin before accessing the
              platform
            </p>
          </div>
          <div className={styles.settingControl}>
            <label className={styles.toggle}>
              <input
                type="checkbox"
                checked={settings.userApprovalRequired}
                onChange={() => handleToggle("userApprovalRequired")}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>

        <div className={styles.settingItem}>
          <div className={styles.settingInfo}>
            <h3>Allow New Registrations</h3>
            <p>Enable or disable new user registrations</p>
          </div>
          <div className={styles.settingControl}>
            <label className={styles.toggle}>
              <input
                type="checkbox"
                checked={settings.allowNewRegistrations}
                onChange={() => handleToggle("allowNewRegistrations")}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
      </div>

      <div className={styles.settingsSection}>
        <h2>Content Moderation</h2>

        <div className={styles.settingItem}>
          <div className={styles.settingInfo}>
            <h3>Auto-Flag Threshold</h3>
            <p>
              Number of reports before content is automatically flagged for
              review
            </p>
          </div>
          <div className={styles.settingControl}>
            <input
              type="number"
              name="maxReportsBeforeAutoFlag"
              value={settings.maxReportsBeforeAutoFlag}
              onChange={handleInputChange}
              min="1"
              max="10"
            />
          </div>
        </div>

        <div className={styles.settingItem}>
          <div className={styles.settingInfo}>
            <h3>Content Retention (days)</h3>
            <p>
              Number of days to retain deleted content before permanent removal
            </p>
          </div>
          <div className={styles.settingControl}>
            <input
              type="number"
              name="contentRetentionDays"
              value={settings.contentRetentionDays}
              onChange={handleInputChange}
              min="1"
              max="365"
            />
          </div>
        </div>
      </div>

      <div className={styles.settingsSection}>
        <h2>System</h2>

        <div className={styles.settingItem}>
          <div className={styles.settingInfo}>
            <h3>Maintenance Mode</h3>
            <p>Put the site in maintenance mode (only admins can access)</p>
          </div>
          <div className={styles.settingControl}>
            <label className={styles.toggle}>
              <input
                type="checkbox"
                checked={settings.maintenanceMode}
                onChange={() => handleToggle("maintenanceMode")}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
      </div>

      <div className={styles.actionButtons}>
        <Button onClick={handleSaveSettings} disabled={isSubmitting}>
          {isSubmitting ? "Saving..." : "Save Settings"}
        </Button>
      </div>
    </div>
  );
};

export default Settings;
