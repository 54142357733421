import React, { forwardRef } from "react";
import StatusBanner from "components/StatusBanner/StatusBanner";
import TalkTicker from "components/TalkTicker/TalkTicker";
import styles from "./PageHeader.module.css";
import { useUserContext } from "contexts/UserContext";

/**
 * Component that combines StatusBanner and TalkTicker in a fixed header
 * below the navbar
 *
 * @param {Object} props
 * @param {boolean} [props.ticker] - Whether to show the ticker
 * @param {Object} [props.settings] - Configuration settings
 * @param {boolean} [props.settings.showStatusBanner=true] - Whether to show status banners
 * @param {boolean} [props.settings.showTicker] - Override for ticker display
 */
const PageHeader = forwardRef(({ ticker, settings = {} }, ref) => {
  const { userData } = useUserContext();

  // Destructure settings with defaults
  const {
    showStatusBanner: enableStatusBanner = true,
    showTicker: enableTicker = true,
  } = settings;

  // Determine if we should show the status banner
  const shouldShowStatusBanner =
    enableStatusBanner &&
    userData &&
    (!userData.isRegistered ||
      !userData.isEmailVerified ||
      userData.status !== "active" ||
      userData.isAdminApproved === false);

  // Determine if we should show the ticker
  const shouldShowTicker = enableTicker && ticker;

  // If neither component should be shown, return null
  if (!shouldShowStatusBanner && !shouldShowTicker) return null;

  return (
    <div ref={ref} className={styles.pageHeader}>
      {shouldShowTicker && <TalkTicker />}
      {shouldShowStatusBanner && <StatusBanner />}
    </div>
  );
});

PageHeader.displayName = "PageHeader";

export default PageHeader;
