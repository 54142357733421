import React from "react";
import styles from "./StepEmailVerification.module.css";
import { motion } from "framer-motion";
import { FaEnvelope, FaCheckCircle } from "react-icons/fa";

const StepEmailVerification = ({ onClose }) => {
  return (
    <motion.div
      className={styles.verificationContainer}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <div className={styles.iconContainer}>
        <FaEnvelope className={styles.envelopeIcon} />
        <FaCheckCircle className={styles.checkIcon} />
      </div>

      <h1 className={styles.verificationHeader}>Check Your Email!</h1>

      <p className={styles.verificationText}>
        We've sent a verification email to complete your registration. Please
        check your inbox and spam folder for an email from
        <span className={styles.emailHighlight}>no-reply@stumble.chat</span>
      </p>

      <div className={styles.infoBox}>
        <h3>What's Next?</h3>
        <ol className={styles.stepsList}>
          <li>Click the verification link in your email</li>
          <li>Return to Stumble to start connecting</li>
          <li>Complete your profile to improve matches</li>
        </ol>
      </div>

      <button className={styles.continueButton} onClick={onClose}>
        Continue to Stumble
      </button>
    </motion.div>
  );
};

export default StepEmailVerification;
