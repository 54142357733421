import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import { useToastContext } from "contexts/ToastContext";
import Button from "components/Button/Button";
import styles from "./VerifyEmail.module.css";
import {
  IoCheckmarkCircle,
  IoCloseCircle,
  IoMail,
  IoArrowBack,
  IoRefresh,
  IoLogIn,
} from "react-icons/io5";

/**
 * A simplified email verification component that handles verification outcomes
 * based solely on URL parameters
 */
const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const { addToast } = useToastContext();
  const { userData } = useUserContext();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");

  // Debug: Log all parameters
  useEffect(() => {}, [searchParams]);

  // Check verification status from query params
  useEffect(() => {
    const verificationStatus = searchParams.get("status");

    // Simple status determination based on URL params
    if (verificationStatus === "success") {
      setStatus("success");
      addToast("Email verification successful!", "success");
    } else if (verificationStatus === "already-verified") {
      setStatus("already-verified");
      addToast("Your email is already verified", "info");
    } else if (verificationStatus === "failure") {
      setStatus("failure");
      const errorMessage = searchParams.get("message")
        ? decodeURIComponent(searchParams.get("message"))
        : "Email verification failed";
      addToast(errorMessage, "error");
    } else {
      // No status found, user might have navigated here directly
      setStatus("info");
    }
  }, [searchParams, addToast]);

  // Render different content based on status
  const renderContent = () => {
    // If user is logged in, show profile button
    const primaryAction = userData ? (
      <Button
        onClick={() => navigate("/profile")}
        color="primary"
        className={styles.primaryButton}
      >
        Go to Profile
      </Button>
    ) : (
      <Button
        onClick={() => navigate("/login")}
        color="primary"
        className={styles.primaryButton}
      >
        <IoLogIn /> Log In
      </Button>
    );

    switch (status) {
      case "success":
        return (
          <>
            <div className={styles.statusSection}>
              <IoCheckmarkCircle className={styles.statusIcon} />
              <div>
                <h1 className={styles.statusTitle}>Email Verified</h1>
                <p className={styles.statusText}>
                  Your email has been successfully verified. You now have full
                  access to all features.
                </p>
              </div>
            </div>

            <div className={styles.actions}>{primaryAction}</div>
          </>
        );

      case "failure":
        const reason = searchParams.get("reason") || "unknown";
        const messages = {
          expired:
            "Your verification link has expired. Please request a new one.",
          invalid: "The verification link is invalid or has already been used.",
          unknown:
            "We couldn't verify your email. Please try again or contact support.",
        };

        return (
          <>
            <div className={styles.statusSection}>
              <IoCloseCircle className={styles.errorIcon} />
              <div>
                <h1 className={styles.statusTitle}>Verification Failed</h1>
                <p className={styles.statusText}>{messages[reason]}</p>
                <p className={styles.statusDetail}>
                  Error: {searchParams.get("message") || "Unknown error"}
                </p>
              </div>
            </div>

            <div className={styles.actions}>{primaryAction}</div>
          </>
        );

      case "info":
        return (
          <>
            <div className={styles.statusSection}>
              <IoMail className={styles.infoIcon} />
              <div>
                <h1 className={styles.statusTitle}>Verify Your Email</h1>
                <p className={styles.statusText}>
                  Please check your inbox for a verification link. Click the
                  link to verify your email address.
                </p>
              </div>
            </div>

            <div className={styles.actions}>
              {primaryAction}
              <Button
                color="secondary"
                onClick={() => navigate(-1)}
                className={styles.secondaryButton}
              >
                <IoArrowBack /> Go Back
              </Button>
            </div>
          </>
        );

      case "already-verified":
        return (
          <>
            <div className={styles.statusSection}>
              <IoCheckmarkCircle className={styles.statusIcon} />
              <div>
                <h1 className={styles.statusTitle}>Already Verified</h1>
                <p className={styles.statusText}>
                  Your email has already been verified. You have full access to
                  all features.
                </p>
              </div>
            </div>

            <div className={styles.actions}>{primaryAction}</div>
          </>
        );

      case "loading":
      default:
        return (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingSpinner}></div>
            <p>Loading verification status...</p>
          </div>
        );
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <div className={styles.content}>{renderContent()}</div>
      </div>
    </div>
  );
};

export default VerifyEmail;
