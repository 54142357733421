import React, { createContext, useContext, useEffect, useState } from "react";
import {
  isWebView,
  getBrowserEnvironment,
  getWebViewType,
} from "utils/browserDetection";

const BrowserContext = createContext({
  isWebView: false,
  browserEnvironment: "desktop",
  isFacebookWebView: false,
  webViewType: null,
  redirectToNativeBrowser: () => {},
  getWebViewType: () => null,
});

export const useBrowserContext = () => useContext(BrowserContext);

export const BrowserContextProvider = ({ children }) => {
  const [browserState, setBrowserState] = useState({
    isWebView: false,
    browserEnvironment: "desktop",
    isFacebookWebView: false,
    webViewType: null,
  });

  useEffect(() => {
    // Detect browser environment on mount
    const webViewDetected = isWebView();
    const environment = getBrowserEnvironment();
    const webViewType = getWebViewType();
    const isFacebookWebView = webViewType === "facebook";

    setBrowserState({
      isWebView: webViewDetected,
      browserEnvironment: environment,
      isFacebookWebView,
      webViewType,
    });

    // Optional: Auto-redirect on initial load if in WebView
    if (webViewDetected && !sessionStorage.getItem("webview_prompted")) {
      // Set a flag to prevent infinite redirects if user returns to WebView
      sessionStorage.setItem("webview_prompted", "true");
    }
  }, []);

  const redirectToNativeBrowser = () => {
    const currentUrl = window.location.href;
    const userAgent = navigator.userAgent.toLowerCase();

    try {
      let sanitizedUrl = new URL(currentUrl).href;
      const isIOS = /iphone|ipad|ipod/i.test(userAgent);
      const isAndroid = /android/i.test(userAgent);
      const webViewType = getWebViewType();

      // For all WebViews, we'll try the appropriate method
      if (isIOS) {
        window.location.href = `googlechrome://${sanitizedUrl.replace(
          /^https?:\/\//,
          ""
        )}`;
        setTimeout(() => (window.location.href = sanitizedUrl), 500); // Fallback to Safari
      } else if (isAndroid) {
        window.location.href = `intent:${sanitizedUrl}#Intent;scheme=https;package=com.android.chrome;end`;
        setTimeout(() => (window.location.href = sanitizedUrl), 500); // Fallback to default browser
      } else {
        window.open(sanitizedUrl, "_blank"); // Default for desktops
      }
    } catch (err) {
      console.error("Failed to redirect to native browser:", err);
    }
  };

  // Expose the getWebViewType function
  const getWebViewTypeWrapper = () => getWebViewType();

  return (
    <BrowserContext.Provider
      value={{
        ...browserState,
        redirectToNativeBrowser,
        getWebViewType: getWebViewTypeWrapper,
      }}
    >
      {children}
    </BrowserContext.Provider>
  );
};
