import AxiosError from "classes/AxiosError"; // Assuming AxiosError is defined in another file
import * as typedefs from "typedefs";

/**
 * @typedef {typedefs.ErrorResponseData} ErrorResponseData
 * @typedef {import("classes/AxiosError").default} AxiosError
 */
/**
 * Handles errors from Axios requests.
 *
 * @param {ErrorResponseData} error - The error object returned from the Axios request.
 * @throws {AxiosError} Throws an AxiosError with a combined message from structured error responses or a fallback message.
 */
export default function handleAxiosError(error) {
  if (error.response) {
    const statusCode = error.response.status;
    let message = "Server responded with an error";
    let redirect = null;
    let errors = [];

    // Extract structured error data
    if (
      error.response.data.errors &&
      Array.isArray(error.response.data.errors)
    ) {
      errors = error.response.data.errors;
      message = errors.map((e) => e.message).join(", ");
    } else if (error.response.data.message) {
      message = error.response.data.message;
    }

    // Extract redirect URL if present
    if (error.response.data.redirect) {
      redirect = error.response.data.redirect;
    }

    // Return a structured error object instead of throwing
    return new AxiosError({ message, statusCode, redirect, errors });
  } else if (error.request) {
    return new AxiosError({
      message: "No response from server",
      statusCode: null,
      errors: [{ message: "No response from server" }],
    });
  } else {
    return new AxiosError({
      message: "Error in setting up request: " + error.message,
      statusCode: null,
    });
  }
}
