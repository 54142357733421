import React, { useState } from "react";
import styles from "./Profile.module.css";
import { useUserContext } from "contexts/UserContext";
import cn from "classnames";

import Button from "components/Button/Button";
import SearchInput from "./components/SearchInput";
import SortSelect from "./components/SortSelect";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

import { FaPen } from "react-icons/fa";
import useMediaQuery from "hooks/useMediaQuery";

import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import useLifeExperience from "components/LifeExperienceCard/useLifeExperience";

import { unifiedContentSearch } from "api/search";

const NewLifeExperienceModal = ({ isOpen, onClose, onCreate }) => {
  const { lifeExperience, error, status, statusMessage, createLifeExperience } =
    useLifeExperience();

  const handleCreate = async (formData) => {
    try {
      const createdLifeExp = await createLifeExperience(formData);
      onCreate(createdLifeExp);
      onClose();
    } catch (error) {
      console.error("Error creating life experience:", error);
    }
  };

  return (
    <LifeExperienceModal
      lifeExperience={lifeExperience}
      isOpen={isOpen}
      onClose={onClose}
      mode="new"
      onCreate={handleCreate}
      error={error}
      status={status}
      statusMessage={statusMessage}
    />
  );
};

const LifeExperienceContent = ({
  experiences,
  undiscoveredCount,
  onExperiencesChange,
  profileData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState("newest");
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery("sm");
  const isMediumScreen = useMediaQuery("md");
  const { userData } = useUserContext();

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const handleSearch = async () => {
    setLoading(true);
    setHasSearched(true);
    try {
      const searchParams = {
        query: searchQuery,
        authorId: profileData._id,
        sort: sort,
        type: "experiences",
      };

      const searchResults = await unifiedContentSearch(searchParams);

      const results = searchResults.experiences?.results || [];
      const newUndiscoveredCount =
        searchResults.experiences?.undiscoveredCount || 0;

      onExperiencesChange(results, newUndiscoveredCount);
    } catch (error) {
      console.error("Error searching life experiences:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewExperienceClick = () => {
    setIsModalOpen(true);
  };

  const handleExperienceCreate = (newExperience) => {
    onExperiencesChange([newExperience, ...experiences]);
  };

  const handleExperienceUpdate = (updatedExperience) => {
    const updatedExperiences = experiences.map((exp) =>
      exp._id === updatedExperience._id ? updatedExperience : exp
    );
    onExperiencesChange(updatedExperiences);
  };

  const handleExperienceDelete = (deletedExperienceId) => {
    const updatedExperiences = experiences.filter(
      (exp) => exp._id !== deletedExperienceId
    );
    onExperiencesChange(updatedExperiences);
  };

  const renderNewExperienceButton = () => {
    const isOwnProfile = profileData?._id === userData?._id;
    if (!isOwnProfile) return null;

    if (isSmallScreen) {
      return (
        <div className={styles.newContentCard}>
          <p>Create a new life experience</p>
          <button
            className={styles.newContentCardButton}
            onClick={handleNewExperienceClick}
            aria-label="Create new life experience"
          >
            <FaPen />
          </button>
        </div>
      );
    }

    return (
      <button
        className={styles.newContentButton}
        onClick={handleNewExperienceClick}
      >
        <FaPen />
        New Life Experience
      </button>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingMessage message="Loading life experiences..." />;
    }

    // Show experiences if we have them
    if (experiences && experiences.length > 0) {
      return (
        <>
          {experiences.map((experience) => (
            <LifeExperienceCard
              key={experience._id}
              lifeExpData={experience}
              userData={userData}
              onUpdate={handleExperienceUpdate}
              onDelete={handleExperienceDelete}
            />
          ))}

          {/* Show undiscovered message below content if applicable */}
          {undiscoveredCount > 0 && (
            <div className={styles.undiscoveredMessage}>
              <strong>{profileData.username}</strong> has {undiscoveredCount}
              {undiscoveredCount === 1
                ? " more life experience"
                : " more life experiences"}{" "}
              you haven't yet
              <a href="/faq/connections"> connected on.</a> Keep chatting to
              build trust.
            </div>
          )}
        </>
      );
    }

    // No experiences found after search
    if (hasSearched) {
      return (
        <div className={styles.emptyMessage}>No life experiences found</div>
      );
    }

    // Initial state - no experiences yet
    const isOwnProfile = profileData?._id === userData?._id;

    if (isOwnProfile) {
      return (
        <div className={styles.emptyContent}>
          <p className={styles.emptyMessage}>
            You haven't shared any life experiences yet.
          </p>
          <button
            className={styles.connectPrompt}
            onClick={handleNewExperienceClick}
          >
            Create Your First Life Experience
          </button>
        </div>
      );
    }

    // Someone else's profile with no visible experiences but with undiscovered ones
    if (undiscoveredCount > 0) {
      return (
        <div className={styles.undiscoveredMessage}>
          <strong>{profileData.username}</strong> has {undiscoveredCount}
          {undiscoveredCount === 1
            ? " life experience"
            : " life experiences"}{" "}
          you haven't yet
          <a href="/faq/connections"> connected on.</a> Keep chatting with them
          and maybe if they're comfortable enough, they will reveal more about
          themselves to you.
        </div>
      );
    }

    // Truly empty - no experiences at all
    return (
      <div className={styles.emptyContent}>
        <p className={styles.emptyMessage}>
          No life experiences shared yet by {profileData.username}.
        </p>
      </div>
    );
  };

  return (
    <div className={styles.lifeExperiences}>
      {isSmallScreen ? (
        // Mobile layout
        <div className={styles.toolbar}>
          <SearchInput
            value={searchQuery}
            onChange={handleSearchChange}
            onClear={() => {
              setSearchQuery("");
              setHasSearched(false);
            }}
            onSearch={handleSearch}
            placeholder="Search experiences..."
          />
          <div className={styles.toolbarBottom}>
            <SortSelect currentSort={sort} onSortChange={setSort} />
          </div>
          {profileData._id === userData._id && renderNewExperienceButton()}
        </div>
      ) : (
        // Desktop layout - all elements in one row
        <div className={styles.toolbar}>
          <div className={styles.toolbarRow}>
            <div className={styles.searchContainer}>
              <SearchInput
                value={searchQuery}
                onChange={handleSearchChange}
                onClear={() => {
                  setSearchQuery("");
                  setHasSearched(false);
                }}
                onSearch={handleSearch}
                placeholder="Search experiences..."
              />
            </div>
            <div className={styles.sortContainer}>
              <SortSelect currentSort={sort} onSortChange={setSort} />
            </div>
            {profileData._id === userData._id && (
              <div className={styles.newButtonContainer}>
                {renderNewExperienceButton()}
              </div>
            )}
          </div>
        </div>
      )}

      {renderContent()}

      {isModalOpen && (
        <NewLifeExperienceModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCreate={handleExperienceCreate}
        />
      )}
    </div>
  );
};

export default LifeExperienceContent;
