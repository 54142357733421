import React, { useEffect, useState } from "react";
import styles from "./Match.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectMessagesByMatchId, selectMatchById } from "store/selectors";

import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { useUserContext } from "contexts/UserContext";
import {
  initMessages,
  fetchMoreMessages,
  initRoom,
  acceptMatch,
  fetchInteractionReviews,
} from "store/actions";
import * as USERS_API from "api/users";
import { useSocketContext } from "contexts/SocketContext";

import cn from "classnames";

import useMediaQuery from "hooks/useMediaQuery";
import { FaArrowDown } from "react-icons/fa";

import { motion, AnimatePresence } from "framer-motion";
import {
  offset,
  useFloating,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";
import UserInfoDialog from "components/UserInfoDialog/UserInfoDialog";
import { useToastContext } from "contexts/ToastContext";
import ChatToolbar from "../components/ChatToolbar/ChatToolbar";
import ChatStatusBar from "../components/ChatStatusBar/ChatStatusBar";
import ChatSideMenu from "../components/ChatSideMenu/ChatSideMenu";
import ChatInput from "../components/ChatInput/ChatInput";
import ChatMessage from "components/ChatMessage/ChatMessage";
import {
  transformParticipantForDialog,
  standardizeParticipant,
} from "utils/chatUtils";
import ReviewModal from "../components/ReviewModal/ReviewModal";
import ReportModal from "components/ReportModal/ReportModal";
import { useReport } from "hooks/useReport";
import { useReview } from "hooks/useReview";
import { useBlock } from "hooks/useBlock";
import BlockModal from "../components/BlockModal/BlockModal";

const MatchContent = ({
  toolbarProps,
  matchData,
  loadingStates,
  errors,
  handleRetry,
  messages,
  isScrollButtonVisible,
  refs,
  floatingStyles,
  scrollToBottom,
  lastMessageRef,
  isAccepting,
  acceptError,
  newMessage,
  setNewMessage,
  onEnterPress,
  handleSendMessage,
  renderMessages,
  navigate,
  isInteractionDisabled,
}) => {
  const renderStatusBanner = () => {
    // Log the matchData.status for debugging
    switch (matchData.status) {
      case "completed":
        return <div className={styles.statusBanner}>Match completed</div>;
      case "expired":
        return <div className={styles.statusBanner}>Match has expired</div>;
      case "canceled":
        return <div className={styles.statusBanner}>Match canceled</div>;
      default:
        return null;
    }
  };

  return (
    <div
      className={cn(styles.chatContent, {
        [styles.chatContentContracted]: toolbarProps.isSideMenuVisible,
      })}
    >
      <ChatToolbar {...toolbarProps} />
      {renderStatusBanner()}

      <div className={styles.messageContainer}>
        {loadingStates.isInitializingRoom ? (
          <div className={styles.status}>
            <LoadingMessage message="Initializing room..." />
          </div>
        ) : errors?.initError ? (
          <div className={styles.errorContainer}>
            <h2>{errors.initError}</h2>
            <button onClick={handleRetry}>Retry</button>
            <button onClick={() => navigate("/chats?type=matches")}>
              Go Back to Matches
            </button>
          </div>
        ) : loadingStates.isLoadingMessages ||
          loadingStates.isLoadingOtherParticipant ? (
          <div className={styles.status}>
            <LoadingMessage message="Loading messages..." />
          </div>
        ) : (
          <>
            {renderMessages()}
            <AnimatePresence>
              {isScrollButtonVisible && (
                <FloatingPortal>
                  <motion.div
                    key="scroll-btn"
                    ref={refs.setFloating}
                    style={{ ...floatingStyles, zIndex: "2" }}
                    className={styles.scrollToBottom}
                    onClick={scrollToBottom}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <FaArrowDown />
                  </motion.div>
                </FloatingPortal>
              )}
            </AnimatePresence>
          </>
        )}
      </div>
      <div ref={refs.setReference} style={{ width: "100%" }}></div>

      <ChatStatusBar
        data={matchData}
        type="match"
        onGoToChat={() => navigate(`/chats?type=chats&id=${matchData.chatId}`)}
      />

      {!matchData?.isBlocked && !isInteractionDisabled() && (
        <ChatInput
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          onEnterPress={onEnterPress}
          handleSendMessage={handleSendMessage}
          isSendingMessage={loadingStates.isSendingMessage}
        />
      )}
    </div>
  );
};

const Match = ({ onBack }) => {
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToastContext();

  const [otherParticipant, setOtherParticipant] = useState(null);
  const [lastMessageRef, setLastMessageRef] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [loadingStates, setLoadingStates] = useState({
    isLoadingMessages: true,
    isSendingMessage: false,
    isInitializingRoom: false,
    isLoadingOtherParticipant: true,
    isAcceptingMatch: false,
  });
  const isMobileView = useMediaQuery("sm");

  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    open: isScrollButtonVisible,
    onOpenChange: setIsScrollButtonVisible,
    middleware: [offset(10)],
    placement: "top",
    whileElementsMounted: autoUpdate,
  });

  const matchData = useSelector((state) => selectMatchById(state, matchId));
  const messages = useSelector((state) =>
    selectMessagesByMatchId(state, matchId)
  );
  const { userData } = useUserContext();
  const { sendMessage } = useSocketContext();
  const [retry, setRetry] = useState(false); // For retry logic

  const [errors, setErrors] = useState({
    initError: null,
    messageError: null,
  });

  const [isAccepting, setIsAccepting] = useState(false);
  const [acceptError, setAcceptError] = useState(null);

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const {
    submitReport,
    isSubmitting,
    error: reportError,
  } = useReport("Match", matchId);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const {
    submitReview,
    isSubmitting: isSubmittingReview,
    error: reviewError,
  } = useReview("Match", matchId, otherParticipant?._id);

  // Add block modal state
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);

  // Initialize the block hook
  const { toggleBlock, isCurrentlyBlocked, isProcessing, error } = useBlock(
    "match",
    matchId,
    matchData
  );

  // Load reviews when match loads
  useEffect(() => {
    if (matchId) {
      dispatch(fetchInteractionReviews("Match", matchId));
    }
  }, [matchId, dispatch]);

  const handleReviewSubmit = async (reviewData) => {
    const success = await submitReview(reviewData);
    if (success) {
      setIsReviewModalOpen(false);
    }
  };

  const handleAcceptMatch = async () => {
    if (!matchData?._id || isAccepting) return;

    setIsAccepting(true);
    setAcceptError(null);

    try {
      const { updatedMatch, newChat } = await dispatch(
        acceptMatch(matchData._id)
      );

      if (newChat) {
        navigate(`/chats?type=chats&id=${newChat._id}`);
      }
    } catch (error) {
      addToast(
        "Failed to accept match. Please try again later.",
        "error",
        5000
      );
      setAcceptError(error.message);
    } finally {
      setIsAccepting(false);
    }
  };

  // Initialize match room
  useEffect(() => {
    const initializeRoom = async () => {
      if (!matchId) return;

      setLoadingStates((prev) => ({
        ...prev,
        isInitializingRoom: true,
      }));

      setErrors((prev) => ({ ...prev, initError: null }));

      try {
        await dispatch(initRoom(matchId, "match"));
        await dispatch(initMessages(matchId, "match"));
      } catch (error) {
        console.error("Error initializing match:", error);
        setErrors((prev) => ({
          ...prev,
          initError: "Failed to load match. Please try again later.",
        }));
      } finally {
        setLoadingStates((prev) => ({
          ...prev,
          isInitializingRoom: false,
          isLoadingMessages: false,
        }));
      }
    };

    initializeRoom();
  }, [matchId, retry, dispatch]);

  // --- Handle Retry ---
  const handleRetry = () => {
    setRetry((prev) => !prev); // Toggle retry to re-trigger API calls
  };

  // Fetch other participant's profile
  useEffect(() => {
    const fetchOtherParticipant = async () => {
      if (matchData && matchData.participants && userData) {
        const otherParticipant = matchData.participants.find(
          (participant) => participant.user._id !== userData._id
        );

        try {
          const profile = await USERS_API.fetchPublicProfile(
            otherParticipant.user._id,
            { matchId: matchData._id }
          );

          // Use standardizeParticipant to create consistent structure
          const standardized = standardizeParticipant({
            ...otherParticipant,
            ...profile,
          });

          setOtherParticipant(standardized);
        } catch (error) {
          console.error("Error fetching other participant:", error);
        } finally {
          setLoadingStates((prev) => ({
            ...prev,
            isLoadingOtherParticipant: false,
          }));
        }
      }
    };

    fetchOtherParticipant();
  }, [matchData, userData]);

  const renderMessages = () => {
    return messages?.map((message, index) => (
      <ChatMessage
        key={`${matchId}:${message._id}`}
        message={message}
        otherParticipant={otherParticipant}
        ref={(el) => {
          if (index === messages.length - 1) {
            setLastMessageRef(el);
          }
        }}
      />
    ));
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setLoadingStates((prev) => ({
      ...prev,
      isSendingMessage: true,
    }));

    try {
      // Send the message using the socket context
      sendMessage(matchId, newMessage.trim(), "match");
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        isSendingMessage: false,
      }));
    }
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const toggleSideMenu = () => setIsSideMenuVisible((prev) => !prev);

  const handleReportClick = () => {
    setIsReportModalOpen(true);
  };

  const handleReportSubmit = async (reportData) => {
    const success = await submitReport(reportData);
    if (success) {
      setIsReportModalOpen(false);
    }
  };

  const handleReviewClick = () => {
    setIsReviewModalOpen(true);
  };

  // Add scroll button visibility effect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsScrollButtonVisible(!entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (lastMessageRef) {
      observer.observe(lastMessageRef);
    }

    return () => {
      if (lastMessageRef) {
        observer.unobserve(lastMessageRef);
      }
    };
  }, [messages, lastMessageRef]);

  // Add scroll to bottom function
  const scrollToBottom = () => {
    lastMessageRef?.scrollIntoView({ behavior: "smooth" });
  };

  // Update loadingStates when isAccepting changes
  useEffect(() => {
    setLoadingStates((prev) => ({
      ...prev,
      isAcceptingMatch: isAccepting,
    }));
  }, [isAccepting]);

  // Handle block interaction
  const handleBlockClick = () => {
    setIsBlockModalOpen(true);
  };

  const toolbarProps = {
    toggleSideMenu,
    otherParticipant,
    openProfileModal: () => setIsUserInfoOpen(true),
    isMobileView,
    isSideMenuVisible,
    onBack,
    chatData: matchData,
    type: "match",
  };

  const isInteractionDisabled = () => {
    return (
      matchData.status === "expired" ||
      matchData.status === "completed" ||
      matchData.status === "canceled"
    );
  };

  return (
    <div className={styles.chat}>
      <MatchContent
        toolbarProps={toolbarProps}
        matchData={matchData}
        loadingStates={loadingStates}
        errors={errors}
        handleRetry={handleRetry}
        messages={messages}
        isScrollButtonVisible={isScrollButtonVisible}
        refs={refs}
        floatingStyles={floatingStyles}
        scrollToBottom={scrollToBottom}
        lastMessageRef={lastMessageRef}
        isAccepting={isAccepting}
        acceptError={acceptError}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        onEnterPress={onEnterPress}
        handleSendMessage={handleSendMessage}
        renderMessages={renderMessages}
        navigate={navigate}
        isInteractionDisabled={isInteractionDisabled}
      />

      <ChatSideMenu
        type="match"
        data={matchData}
        otherParticipant={otherParticipant}
        userData={userData}
        isSideMenuVisible={isSideMenuVisible}
        toggleSideMenu={toggleSideMenu}
        onReportClick={handleReportClick}
        onReviewClick={handleReviewClick}
        onBlockClick={handleBlockClick}
        openProfileModal={() => setIsUserInfoOpen(true)}
        onAccept={handleAcceptMatch}
        isAccepting={isAccepting}
        acceptError={acceptError}
      />

      <UserInfoDialog
        data={transformParticipantForDialog(otherParticipant)}
        open={isUserInfoOpen}
        onOpenChange={setIsUserInfoOpen}
      />

      {isReportModalOpen && (
        <ReportModal
          targetType="Match"
          targetId={matchId}
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleReportSubmit}
          isSubmitting={isSubmitting}
          error={reportError}
        />
      )}

      {isReviewModalOpen && otherParticipant && (
        <ReviewModal
          interactionType="Match"
          interactionId={matchId}
          otherParticipant={otherParticipant}
          isOpen={isReviewModalOpen}
          onClose={() => setIsReviewModalOpen(false)}
          onSubmit={handleReviewSubmit}
          isSubmitting={isSubmittingReview}
          error={reviewError}
        />
      )}

      <BlockModal
        isOpen={isBlockModalOpen}
        onClose={() => setIsBlockModalOpen(false)}
        sourceType="match"
        instanceData={matchData}
        onAction={toggleBlock}
        error={error}
      />
    </div>
  );
};

export default Match;
