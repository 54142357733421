import React from "react";
import styles from "./Badge.module.css";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../ReusableTooltip/ReusableTooltip";

const Badge = ({
  badge,
  size = "medium",
  showTooltip = true,
  earned = false,
  awardedAt = null,
}) => {
  if (!badge) return null;

  const { name, description, iconUrl, emoji } = badge;

  // Format the awarded date if available
  const formattedDate = awardedAt
    ? new Date(awardedAt).toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : null;

  // Determine tooltip content based on earned status
  const tooltipContent = earned ? (
    <>
      <strong>{name}</strong>
      <p>{description}</p>
      {formattedDate && <p>Earned on {formattedDate}</p>}
    </>
  ) : (
    <>
      <strong>{name}</strong>
      <p>{description}</p>
      <p className={styles.lockedMessage}>Not yet earned</p>
    </>
  );

  const badgeContent = (
    <div
      className={`${styles.badge} ${styles[size]} ${
        earned ? styles.earned : styles.locked
      }`}
    >
      <div className={styles.badgeContent}>
        <div className={styles.badgeIconContainer}>
          {emoji ? (
            <span className={styles.badgeEmoji}>{emoji}</span>
          ) : (
            <img src={iconUrl} alt={name} className={styles.badgeIcon} />
          )}
        </div>
        <div className={styles.badgeName}>{name}</div>
      </div>
      {!earned && <div className={styles.lockedOverlay}></div>}
    </div>
  );

  if (showTooltip) {
    return (
      <Tooltip placement="top">
        <TooltipTrigger>{badgeContent}</TooltipTrigger>
        <TooltipContent>
          <div className={styles.tooltipContent}>{tooltipContent}</div>
        </TooltipContent>
      </Tooltip>
    );
  }

  return badgeContent;
};

export default Badge;
