import React, { useEffect, useState } from "react";
import styles from "./Landing.module.css";

import { getPublicStats } from "api/public";

import HeroSection from "./HeroSection/HeroSection";
import PublicSearchSection from "./PublicSearchSection/PublicSearchSection";
import HowItWorksSection from "./HowItWorksSection/HowItWorksSection";
import TechnologySection from "./TechnologySection/TechnologySection";
import FAQSection from "./FAQSection/FAQSection";
import FounderStorySection from "./FounderStorySection/FounderStorySection";
import BetaCalloutSection from "./BetaCalloutSection/BetaCalloutSection";

const Landing = () => {
  const [activeSection, setActiveSection] = useState("hero");
  const [stats, setStats] = useState({
    users_registered: 0,
    experiences_shared: 0,
    stories_written: 0,
    reachouts_sent: 0,
    matches_made: 0,
    connections_made: 0,
    great_conversations: 0,
  });

  useEffect(() => {
    // Fetch stats when component mounts
    const loadStats = async () => {
      try {
        const { stats: statsData } = await getPublicStats();
        if (statsData) {
          setStats(statsData);
        }
      } catch (error) {
        console.error("Failed to load stats:", error);
      }
    };

    loadStats();
  }, []);

  const handleLearnMore = () => {
    setActiveSection("how-it-works");
    document
      .getElementById("how-it-works")
      .scrollIntoView({ behavior: "smooth" });
  };

  const handleTechnologyClick = () => {
    setActiveSection("technology");
    document
      .getElementById("technology")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.container}>
      <HeroSection
        onLearnMore={handleLearnMore}
        onTechnologyClick={handleTechnologyClick}
      />

      <PublicSearchSection />

      <HowItWorksSection stats={stats} />

      <TechnologySection />

      <FAQSection />

      {/* <FounderStorySection /> */}

      <BetaCalloutSection />
    </div>
  );
};

export default Landing;
