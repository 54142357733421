import api from "./index";
import handleAxiosError from "./handleAxiosError";
import { adminApi } from "./index";

/**
 * Fetches all available badges
 * @returns {Promise<Array>} Array of badge objects
 */
export const fetchAllBadges = async () => {
  try {
    const response = await api.get("/badges");
    return response.data;
  } catch (error) {
    console.error("Error fetching badges:", error);
    handleAxiosError(error);
  }
};

/**
 * Fetches badges for a specific user
 * @param {string} userId - The user ID to fetch badges for
 * @returns {Promise<Array>} Array of badge objects with award dates
 */
export const fetchUserBadges = async (userId) => {
  try {
    const response = await api.get(`/users/${userId}/badges`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user badges:", error);
    handleAxiosError(error);
  }
};

// Admin API functions

/**
 * Admin function to check and award early adopter badge
 * @param {string} userId - The user ID to check and award badge to
 * @returns {Promise<Object>} Result of the badge check
 */
export const checkAndAwardEarlyAdopterBadge = async (userId) => {
  try {
    const response = await adminApi.post(
      `/badges/check-early-adopter/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error checking early adopter badge:", error);
    handleAxiosError(error);
  }
};

/**
 * Admin function to create the early adopter badge
 * @returns {Promise<Object>} The created badge
 */
export const createEarlyAdopterBadge = async () => {
  try {
    const response = await adminApi.post("/badges/run-script");
    return response.data;
  } catch (error) {
    console.error("Error creating early adopter badge:", error);
    handleAxiosError(error);
  }
};

/**
 * Admin function to update a badge
 * @param {string} badgeKey - The key of the badge to update
 * @param {Object} updates - The updates to apply to the badge
 * @returns {Promise<Object>} The updated badge
 */
export const updateBadge = async (badgeKey, updates) => {
  try {
    const response = await adminApi.patch(`/badges/${badgeKey}`, updates);
    return response.data;
  } catch (error) {
    console.error("Error updating badge:", error);
    handleAxiosError(error);
  }
};

/**
 * Admin function to batch process badges
 * @param {string} badgeKey - The key of the badge to process
 * @param {Object} options - Processing options
 * @param {number} [options.batchSize=100] - Number of users to process in each batch
 * @param {boolean} [options.dryRun=false] - If true, only simulate the awards without saving
 * @returns {Promise<Object>} Processing results
 */
export const batchProcessBadges = async (badgeKey, options = {}) => {
  try {
    const response = await adminApi.post(
      `/badges/${badgeKey}/batch-process`,
      options
    );
    return response.data;
  } catch (error) {
    console.error("Error batch processing badges:", error);
    handleAxiosError(error);
  }
};

/**
 * Admin function to check if a user is eligible for a badge
 * @param {string} badgeKey - The key of the badge to check
 * @param {string} userId - The ID of the user to check
 * @returns {Promise<Object>} Eligibility result
 */
export const checkBadgeEligibility = async (badgeKey, userId) => {
  try {
    const response = await adminApi.get(
      `/badges/${badgeKey}/check-eligibility/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error checking badge eligibility:", error);
    handleAxiosError(error);
  }
};

/**
 * Admin function to fetch all badges (including inactive ones)
 * @returns {Promise<Array>} Array of all badges
 */
export const adminFetchAllBadges = async () => {
  try {
    const response = await adminApi.get("/badges");
    return response.data;
  } catch (error) {
    console.error("Error fetching all badges:", error);
    handleAxiosError(error);
  }
};
