// packages/client/src/pages/Lobby/Lobby.jsx
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useLobbyMatching } from "./hooks/useLobbyMatching";
import styles from "./Lobby.module.css";

// Components
import SearchForm from "./components/SearchForm/SearchForm";
import SearchingOverlay from "./components/SearchingOverlay/SearchingOverlay";
import MatchFoundModal from "./components/MatchFoundModal/MatchFoundModal";
import ErrorModal from "./components/ErrorModal/ErrorModal";

const MIN_WORDS = 5; // Minimum word requirement

const Lobby = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialStory = location.state?.story || "";

  // Resetting location state after refresh
  useEffect(() => {
    if (location.state?.story) {
      navigate(".", { replace: true, state: {} }); // Clears location.state
    }
  }, [location, navigate]);

  const {
    searchState,
    searchMetadata,
    matchData,
    error,
    searchPreferences,
    serverStats,
    handleStartSearch,
    handleStopSearch,
    handleAcceptMatch,
    handleRejectMatch,
    handleBack,
    handleBrowseContent,
    SearchStates,
  } = useLobbyMatching(initialStory);

  const validateStory = (story) => {
    const wordCount = story.trim().split(/\s+/).length;
    return wordCount >= MIN_WORDS;
  };

  const handleSubmit = async (preferences) => {
    try {
      // Final business logic validation
      if (!validateStory(preferences.story)) {
        return;
      }

      // Here you could add API-level validations
      // const contentValidation = await validateContentAPI(preferences.story);
      // if (!contentValidation.isValid) {
      //   handleValidationError(contentValidation.errors);
      //   return;
      // }

      handleStartSearch(preferences);
    } catch (error) {
      console.error("Submission error:", error);
      // Handle error appropriately
    }
  };

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <button className={styles.backButton} onClick={handleBack}>
        <span>←</span> Back to Find Others
      </button>

      {/* Server Stats Banner */}
      <motion.div
        className={styles.statsBar}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <div className={styles.statItem}>
          <span className={styles.statValue}>
            {serverStats.isOffline ? "..." : serverStats.onlineCount}
          </span>
          <span className={styles.statLabel}>
            {serverStats.onlineCount === 1 ? "person" : "people"} online
          </span>
        </div>
        <div className={styles.statDivider}>•</div>
        <div className={styles.statItem}>
          <span className={styles.statValue}>
            {serverStats.isOffline ? "..." : serverStats.searchingCount}
          </span>
          <span className={styles.statLabel}>
            {serverStats.searchingCount === 1 ? "person" : "people"} searching
          </span>
        </div>
      </motion.div>

      {/* Initial Search Form */}
      {!searchState && (
        <SearchForm
          initialPreferences={searchPreferences}
          onSubmit={handleSubmit}
          minWords={MIN_WORDS}
        />
      )}

      {/* Dynamic States */}
      <AnimatePresence>
        {/* Search States */}
        {[SearchStates.INITIALIZING, SearchStates.SEARCHING].includes(
          searchState
        ) && (
          <SearchingOverlay
            state={searchState}
            metadata={searchMetadata}
            serverStats={serverStats}
            onCancel={handleStopSearch}
            onBrowseContent={handleBrowseContent}
          />
        )}

        {/* Match Found */}
        {searchState === SearchStates.MATCH_FOUND && (
          <MatchFoundModal
            matchData={matchData}
            onAccept={handleAcceptMatch}
            onReject={handleRejectMatch}
            serverStats={serverStats}
          />
        )}

        {/* Error States - Remove MATCH_REJECTED */}
        {[
          SearchStates.ERROR,
          SearchStates.MATCH_EXPIRED,
          SearchStates.MATCH_DISCONNECTED,
        ].includes(searchState) && (
          <ErrorModal
            state={searchState}
            error={error}
            metadata={searchMetadata}
            onRetry={() => handleSubmit(searchPreferences)}
            onClose={handleBack}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Lobby;
