import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./ChatSideMenu.module.css";
import Button from "components/Button/Button";
import cn from "classnames";
import {
  HiCheckCircle,
  HiXCircle,
  HiOutlineArrowRight,
  HiOutlineUser,
  HiOutlineLocationMarker,
  HiExclamationCircle,
  HiOutlineStar,
  HiOutlineCheck,
  HiOutlineBan,
  HiOutlineShieldExclamation,
} from "react-icons/hi";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import formatGender from "utils/formatGender";
import ConnectionsPanel from "./ConnectionsPanel";
import { isEmpty } from "lodash";
import BilateralAcceptancePanel from "./BilateralAcceptancePanel";
import MatchDetails from "./MatchDetails";
import ReachoutDetails from "./ReachoutDetails";
import { useSelector, useDispatch } from "react-redux";
import { selectReviewsByInteractionId } from "store/selectors";
import { fetchInteractionReviews } from "store/actions";

const getStatusInfo = (status, registrationStatus) => {
  // Account Status
  const accountStatus = {
    active: { label: "Active", icon: HiCheckCircle, className: "valid" },
    suspended: {
      label: "Suspended",
      icon: HiExclamationCircle,
      className: "warning",
    },
    banned: { label: "Banned", icon: HiXCircle, className: "invalid" },
    deleted: { label: "Deleted", icon: HiXCircle, className: "invalid" },
  };

  // Registration Status
  const regStatus = {
    pending: {
      label: "Registration Pending",
      icon: HiExclamationCircle,
      className: "warning",
    },
    registered: {
      label: "Registered",
      icon: HiCheckCircle,
      className: "valid",
    },
    emailVerified: {
      label: "Email Verified",
      icon: HiCheckCircle,
      className: "valid",
    },
    adminApproved: {
      label: "Admin Approved",
      icon: HiCheckCircle,
      className: "valid",
    },
  };

  return {
    account: accountStatus[status] || accountStatus.active,
    registration: regStatus[registrationStatus] || regStatus.pending,
  };
};

const ChatSideMenu = ({
  type,
  data,
  otherParticipant,
  userData,
  isSideMenuVisible,
  toggleSideMenu,
  onAccept,
  isAccepting,
  acceptError,
  onReportClick,
  onReviewClick,
  onBlockClick,
  openProfileModal,
}) => {
  // Move hooks to the top level
  const dispatch = useDispatch();
  const reviews = useSelector((state) =>
    selectReviewsByInteractionId(state, data?._id)
  );

  useEffect(() => {
    if (data?._id) {
      dispatch(fetchInteractionReviews(type, data._id));
    }
  }, [type, data?._id, dispatch]);

  // Add logging

  // Early return if essential data is missing or empty
  if (!data || isEmpty(data) || !otherParticipant || !userData || !type) {
    return (
      <div
        className={cn(styles.sideMenu, {
          [styles.sideMenuVisible]: isSideMenuVisible,
        })}
      >
        <LoadingSpinner />
      </div>
    );
  }

  const statusInfo = getStatusInfo(
    otherParticipant.status,
    otherParticipant.registrationStatus
  );

  // Check if the interaction is blocked
  const isBlocked = data?.isBlocked || false;

  const renderUserInfo = () => {
    if (!otherParticipant) return null;

    return (
      <div className={styles.sideMenuSection}>
        <div className={styles.flexColCenter}>
          <p className={styles.sectionTitle}>You are chatting with:</p>
          <Button className={styles.userButton} onClick={openProfileModal}>
            <HiOutlineUser />
            {otherParticipant.displayName}
          </Button>

          <div className={styles.userMetadata}>
            {otherParticipant.ageRange && (
              <div className={styles.metadataItem}>
                {otherParticipant.ageRange}
              </div>
            )}
            {otherParticipant.gender && (
              <div className={styles.metadataItem}>
                {formatGender(otherParticipant.gender)}
              </div>
            )}
            {otherParticipant.location && (
              <div className={styles.metadataItem}>
                <HiOutlineLocationMarker />
                {otherParticipant.location.name}
              </div>
            )}
          </div>

          <div className={styles.userMetadata}>
            <div
              className={cn(
                styles.badge,
                styles[statusInfo.registration.className]
              )}
            >
              <statusInfo.registration.icon />
              <span>{statusInfo.registration.label}</span>
            </div>
          </div>

          <Button
            onClick={onReviewClick}
            className={styles.actionButton}
            color="secondary"
            size="small"
            shape="square"
          >
            <HiOutlineStar />
            Leave Review
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (type) {
      case "chat":
        return (
          <ConnectionsPanel
            connections={data.connections}
            userData={userData}
          />
        );

      case "match":
      case "reachout":
        return (
          <>
            {type === "match" ? (
              <MatchDetails data={data} userData={userData} />
            ) : (
              <ReachoutDetails data={data} userData={userData} />
            )}
            <BilateralAcceptancePanel
              type={type}
              data={data}
              otherParticipant={otherParticipant}
              onAccept={onAccept}
              isAccepting={isAccepting}
              acceptError={acceptError}
              userData={userData}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={cn(styles.sideMenu, {
        [styles.sideMenuVisible]: isSideMenuVisible,
      })}
    >
      <div className={styles.mobileBackButton} onClick={toggleSideMenu}>
        <span>Close</span>
        <HiOutlineArrowRight />
      </div>

      {renderUserInfo()}
      {renderContent()}

      <div className={cn(styles.sideMenuSection, styles.actionContainer)}>
        <Button
          onClick={onReviewClick}
          className={styles.actionButton}
          color="secondary"
          size="small"
          shape="square"
        >
          <HiOutlineStar />
          Leave Review
        </Button>

        <Button
          onClick={onBlockClick}
          className={styles.actionButton}
          color={isBlocked ? "primary" : "error"}
          size="small"
          shape="square"
        >
          {isBlocked ? (
            <>
              <HiOutlineShieldExclamation />
              Unblock {type.charAt(0).toUpperCase() + type.slice(1)}
            </>
          ) : (
            <>
              <HiOutlineBan />
              Block {type.charAt(0).toUpperCase() + type.slice(1)}
            </>
          )}
        </Button>

        <Button
          onClick={onReportClick}
          color="error"
          className={styles.actionButton}
          size="small"
          shape="square"
        >
          <HiExclamationCircle />
          Report {type.charAt(0).toUpperCase() + type.slice(1)}
        </Button>
      </div>
    </div>
  );
};

ChatSideMenu.propTypes = {
  type: PropTypes.oneOf(["chat", "match", "reachout"]).isRequired,
  data: PropTypes.object.isRequired,
  otherParticipant: PropTypes.object,
  userData: PropTypes.object.isRequired,
  isSideMenuVisible: PropTypes.bool.isRequired,
  toggleSideMenu: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  isAccepting: PropTypes.bool,
  acceptError: PropTypes.string,
  onReportClick: PropTypes.func.isRequired,
  onReviewClick: PropTypes.func.isRequired,
  onBlockClick: PropTypes.func.isRequired,
  openProfileModal: PropTypes.func.isRequired,
};

ChatSideMenu.defaultProps = {
  onAccept: null,
  isAccepting: false,
  acceptError: null,
  otherParticipant: null,
};

export default ChatSideMenu;
