import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import styles from "./Reachout.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";

import useMediaQuery from "hooks/useMediaQuery";

import cn from "classnames";

import { useUserContext } from "contexts/UserContext";
import { ToastContext } from "contexts/ToastContext";

import { motion, AnimatePresence } from "framer-motion";
import { offset, useFloating, autoUpdate } from "@floating-ui/react";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import Portal from "components/Portal/Portal";

import * as typedefs from "typedefs/index";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptReachout,
  fetchMoreMessages,
  initMessages,
  initRoom,
  fetchInteractionReviews,
} from "store/actions";
import {
  selectMessagesByReachoutId,
  selectReachoutById,
  selectReviewsByInteractionId,
} from "store/selectors";
import * as USERS_API from "api/users";
import { useSocketContext } from "contexts/SocketContext";
import { FaArrowDown } from "react-icons/fa";
import { useCallContext } from "contexts/CallContext";
import ChatToolbar from "../components/ChatToolbar/ChatToolbar";
import ChatStatusBar from "../components/ChatStatusBar/ChatStatusBar";
import ChatInput from "../components/ChatInput/ChatInput";
import ChatMessage from "components/ChatMessage/ChatMessage";
import UserInfoDialog from "components/UserInfoDialog/UserInfoDialog";
import {
  transformParticipantForDialog,
  standardizeParticipant,
} from "utils/chatUtils";
import ChatSideMenu from "../components/ChatSideMenu/ChatSideMenu";
import ReviewModal from "../components/ReviewModal/ReviewModal";
import ReportModal from "components/ReportModal/ReportModal";
import { useReport } from "hooks/useReport";
import { useReview } from "hooks/useReview";
import { useBlock } from "hooks/useBlock";
import BlockModal from "../components/BlockModal/BlockModal";

/**
 * @typedef {import("classes/ChatMessage").default} ChatMessage
 * @typedef {import("classes/ChatRequest").default} ChatRequest
 */

const ReachoutContent = ({
  toolbarProps,
  reachoutData,
  loadingStates,
  errors,
  handleRetry,
  messages,
  isScrollButtonVisible,
  refs,
  floatingStyles,
  scrollToBottom,
  lastMessageRef,
  handleAccept,
  isAccepting,
  acceptError,
  newMessage,
  setNewMessage,
  onEnterPress,
  handleSendMessage,
  renderMessages,
  navigate,
  renderStatusBanner,
  isInteractionDisabled,
}) => {
  return (
    <div
      className={cn(styles.chatContent, {
        [styles.chatContentContracted]: toolbarProps.isSideMenuVisible,
      })}
    >
      <ChatToolbar {...toolbarProps} />
      {renderStatusBanner()}

      <div className={styles.messageContainer}>
        {loadingStates.isInitializingRoom ? (
          <div className={styles.status}>
            <LoadingMessage message="Initializing room..." />
          </div>
        ) : errors?.initError ? (
          <div className={styles.errorContainer}>
            <h2>{errors.initError}</h2>
            <button onClick={handleRetry}>Retry</button>
            <button onClick={() => navigate("/chats?type=reachouts")}>
              Go Back to Reachouts
            </button>
          </div>
        ) : loadingStates.isLoadingMessages ||
          loadingStates.isLoadingOtherParticipant ? (
          <div className={styles.status}>
            <LoadingMessage message="Loading messages..." />
          </div>
        ) : (
          <>
            {renderMessages()}
            <AnimatePresence>
              {isScrollButtonVisible && (
                <Portal>
                  <motion.div
                    key="scroll-btn"
                    ref={refs.setFloating}
                    style={{ ...floatingStyles, zIndex: "2" }}
                    className={styles.scrollToBottom}
                    onClick={scrollToBottom}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <FaArrowDown />
                  </motion.div>
                </Portal>
              )}
            </AnimatePresence>
          </>
        )}
      </div>
      <div ref={refs.setReference} style={{ width: "100%" }}></div>
      <ChatStatusBar
        data={reachoutData}
        type="reachout"
        onAccept={handleAccept}
        isAccepting={isAccepting}
        acceptError={acceptError}
        onGoToChat={() =>
          navigate(`/chats?type=chats&id=${reachoutData.chatId}`)
        }
      />
      {!reachoutData?.isBlocked && !isInteractionDisabled() && (
        <ChatInput
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          onEnterPress={onEnterPress}
          handleSendMessage={handleSendMessage}
          isSendingMessage={loadingStates.isSendingMessage}
        />
      )}
    </div>
  );
};

const Reachout = ({ onBack }) => {
  const { sendMessage } = useSocketContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const { userData } = useUserContext();
  const { cleanupChat } = useCallContext();
  const [searchParams] = useSearchParams();

  // --- State Hooks ---
  const [reachoutId, setReachoutId] = useState();

  const [newMessage, setNewMessage] = useState("");
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [retry, setRetry] = useState(false); // For retry logic

  // Error states
  const [errors, setErrors] = useState({
    initError: null,
    messageError: null,
    acceptError: null,
  });

  // Loading states
  const [loadingStates, setLoadingStates] = useState({
    isInitializingRoom: false,
    isLoadingMessages: false,
    isLoadingMoreMessages: false,
    isAcceptingReachout: false,
    isSendingMessage: false,
    isLoadingOtherParticipant: false,
  });

  // Function to update specific loading states
  const setLoadingState = (key, value) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Function to update specific error states
  const setError = (key, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // --- Refs ---
  const [lastMessageRef, setLastMessageRef] = useState(null); // Making it state so its reactive
  const sentinelRef = useRef(null);
  const audioRef = useRef();

  const { refs, floatingStyles } = useFloating({
    open: isScrollButtonVisible,
    onOpenChange: setIsScrollButtonVisible,
    middleware: [offset(10)],
    placement: "top",
    whileElementsMounted: autoUpdate,
  });

  // --- Redux State Hooks ---
  const messages = useSelector((state) =>
    selectMessagesByReachoutId(state, reachoutId)
  );

  const reachoutData = useSelector((state) =>
    selectReachoutById(state, reachoutId)
  );

  // Get reviews for this reachout
  const reviews = useSelector((state) =>
    selectReviewsByInteractionId(state, reachoutId)
  );

  // --- Fetch and Set Reachout Data ---
  useEffect(() => {
    const id = searchParams.get("id");
    const type = searchParams.get("type") || "reachouts";
    setReachoutId(id);
  }, [searchParams]);

  // --- Initialize Reachout ---
  useEffect(() => {
    const initializeReachout = async () => {
      if (reachoutId) {
        setLoadingState("isInitializingRoom", true);
        setError("initError", null);
        try {
          await dispatch(initRoom(reachoutId, "reachout"));
          setLoadingState("isLoadingMessages", true);
          await dispatch(initMessages(reachoutId, "reachout"));
          setError("initError", null); // Clear error on success
        } catch (err) {
          console.error("Error initializing reachout room:", err);

          // Display the error message as a toast notification
          addToast(err.message, "error");

          // Check if there's a redirect URL and navigate to it
          if (err.redirect) {
            navigate(err.redirect);
          } else {
            setError(
              "initError",
              "Failed to load reachout. Please try again later."
            );
          }
        } finally {
          setLoadingState("isInitializingRoom", false);
          setLoadingState("isLoadingMessages", false);
        }
      }
    };

    initializeReachout();
  }, [dispatch, reachoutId, retry, addToast, navigate]);

  // --- Handle Retry ---
  const handleRetry = () => {
    setRetry((prev) => !prev); // Toggle retry to re-trigger API calls
  };

  // Redirect if chatRequestData.status is approved
  useEffect(() => {
    if (reachoutData?.status === "approved") {
      navigate(`/chats?type=chats&id=${reachoutData.approvedChatId}`);
    }
  }, [reachoutData, navigate]);

  const isMobileView = useMediaQuery("sm");

  /**
   * @type {[ChatMessage, any]}
   */

  // --- Fetch Other Participant Data ---
  const [otherParticipant, setOtherParticipant] = useState();

  useEffect(() => {
    const fetchOtherParticipant = async () => {
      if (reachoutData && reachoutData.participants && userData) {
        const otherParticipant = reachoutData.participants.find(
          (participant) => participant.user._id !== userData._id
        );

        try {
          const profile = await USERS_API.fetchPublicProfile(
            otherParticipant.user._id,
            { reachoutId: reachoutData._id }
          );

          // Use standardizeParticipant to create consistent structure
          const standardized = standardizeParticipant({
            ...otherParticipant,
            ...profile,
          });

          setOtherParticipant(standardized);
        } catch (error) {
          console.error("Error fetching other participant:", error);
        } finally {
          setLoadingStates((prev) => ({
            ...prev,
            isLoadingOtherParticipant: false,
          }));
        }
      }
    };

    fetchOtherParticipant();
  }, [reachoutData, userData]);

  // --- Cleanup Chat on Unmount ---
  useEffect(() => {
    return () => cleanupChat();
  }, [cleanupChat]);

  // --- Load More Messages ---
  const isDebounced = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (!entry.isIntersecting) return;

        if (loadingStates.isLoadingMoreMessages) {
          return;
        }

        if (!hasMore) {
          setError(
            "messageError",
            "You've reached the end of the conversation."
          );
          return;
        }

        if (!isDebounced.current) {
          isDebounced.current = true;

          setLoadingState("isLoadingMoreMessages", true);
          setError("messageError", null);

          try {
            const firstMessage = messages[0];
            const firstMessageId = firstMessage?._id;

            if (firstMessageId) {
              const hasMore = await dispatch(
                fetchMoreMessages(reachoutId, "reachout", firstMessageId)
              );
              // Handle newMessages and update hasMore accordingly
              setHasMore(hasMore); // Update hasMore based on new messages
            }
          } catch (err) {
            console.error("Error loading more messages:", err);
            setError(
              "messageError",
              "Failed to load more messages. Please try again."
            );
          } finally {
            setLoadingState("isLoadingMoreMessages", false);
            isDebounced.current = false;
          }
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [
    messages,
    hasMore,
    reachoutId,
    loadingStates.isLoadingMoreMessages,
    dispatch,
  ]);

  // --- Scroll Button Visibility ---
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        setIsScrollButtonVisible(!entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (lastMessageRef) {
      observer.observe(lastMessageRef);
    }

    return () => {
      if (lastMessageRef) {
        observer.unobserve(lastMessageRef);
      }
    };
  }, [messages, lastMessageRef]);

  // --- Message Input Handlers ---
  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (newMessage.trim()) {
        sendMessage(reachoutId, newMessage, "reachout");
        setNewMessage("");
      }
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      setLoadingState("isSendingMessage", true);
      setError("messageError", null);
      try {
        await sendMessage(reachoutId, newMessage, "reachout");
        setNewMessage("");
        setError("messageError", null); // Clear error on success
      } catch (error) {
        console.error("Error sending message:", error);
        setError("messageError", "Failed to send message. Please try again.");
      } finally {
        setLoadingState("isSendingMessage", false);
      }
    }
  };

  // --- Utility Functions ---
  const scrollToBottom = () => {
    if (lastMessageRef) {
      lastMessageRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleSideMenu = () => setIsSideMenuVisible((prev) => !prev);
  const handleLeaveChat = () => navigate("/lobby");

  const openUserInfo = () => setIsUserInfoOpen(true);
  const closeUserInfo = () => setIsUserInfoOpen(false);

  // --- Render Messages ---
  const renderMessages = () => {
    if (!messages?.length) return null;

    return messages.map((message, index) => (
      <ChatMessage
        key={`${reachoutId}:${message._id}`}
        message={message}
        otherParticipant={otherParticipant}
        ref={(el) => {
          if (index === messages.length - 1) {
            setLastMessageRef(el);
          }
        }}
      />
    ));
  };

  // --- Handle Reachout Acceptance ---
  const handleAccept = async () => {
    setLoadingState("isAcceptingReachout", true);
    setError("acceptError", null);
    try {
      const newChat = await dispatch(acceptReachout(reachoutId));

      setError("acceptError", null); // Clear error on success
    } catch (error) {
      console.error("Error accepting Reachout:", error.message);
      setError("acceptError", "Failed to approve Reachout. Please try again.");
      addToast("Failed to approve Reachout", "error");
    } finally {
      setLoadingState("isAcceptingReachout", false);
    }
  };

  const isInteractionDisabled = useCallback(() => {
    return (
      reachoutData.status === "expired" ||
      reachoutData.status === "completed" ||
      reachoutData.status === "canceled"
    );
  }, [reachoutData.status]);

  const renderStatusBanner = () => {
    switch (reachoutData.status) {
      case "expired":
        return (
          <div className={styles.statusBanner}>This reachout has expired</div>
        );
      case "completed":
        return <div className={styles.statusBanner}>Reachout completed</div>;
      case "canceled":
        return <div className={styles.statusBanner}>Reachout canceled</div>;
      default:
        return null;
    }
  };

  // Add toolbarProps near the end of the component
  const toolbarProps = {
    toggleSideMenu,
    otherParticipant,
    openProfileModal: () => setIsUserInfoOpen(true),
    isMobileView,
    isSideMenuVisible,
    onBack,
    chatData: reachoutData,
    type: "reachout",
  };

  // Load reviews when reachout loads
  useEffect(() => {
    if (reachoutId) {
      dispatch(fetchInteractionReviews("Reachout", reachoutId));
    }
  }, [reachoutId, dispatch]);

  const {
    submitReview,
    isSubmitting: isSubmittingReview,
    error: reviewError,
  } = useReview("Reachout", reachoutId, otherParticipant?._id);

  const handleReviewSubmit = async (reviewData) => {
    return await submitReview(reviewData);
  };

  const handleReviewClick = () => {
    setIsReviewModalOpen(true);
  };

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const {
    submitReport,
    isSubmitting,
    error: reportError,
  } = useReport("Reachout", reachoutId);

  const handleReportClick = () => {
    setIsReportModalOpen(true);
  };

  const handleReportSubmit = async (reportData) => {
    const success = await submitReport(reportData);
    if (success) {
      setIsReportModalOpen(false);
    }
  };

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  // Add block modal state
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);

  // Initialize the block hook
  const { toggleBlock, isCurrentlyBlocked, isProcessing, error } = useBlock(
    "reachout",
    reachoutId,
    reachoutData
  );

  // Handle block interaction
  const handleBlockClick = () => {
    setIsBlockModalOpen(true);
  };

  return (
    <div className={styles.chat}>
      <ReachoutContent
        toolbarProps={toolbarProps}
        reachoutData={reachoutData}
        loadingStates={loadingStates}
        errors={errors}
        handleRetry={handleRetry}
        messages={messages}
        isScrollButtonVisible={isScrollButtonVisible}
        refs={refs}
        floatingStyles={floatingStyles}
        scrollToBottom={scrollToBottom}
        lastMessageRef={lastMessageRef}
        handleAccept={handleAccept}
        isAccepting={loadingStates.isAcceptingReachout}
        acceptError={errors.acceptError}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        onEnterPress={onEnterPress}
        handleSendMessage={handleSendMessage}
        renderMessages={renderMessages}
        navigate={navigate}
        renderStatusBanner={renderStatusBanner}
        isInteractionDisabled={isInteractionDisabled}
      />

      <ChatSideMenu
        type="reachout"
        data={reachoutData}
        otherParticipant={otherParticipant}
        userData={userData}
        isSideMenuVisible={isSideMenuVisible}
        toggleSideMenu={toggleSideMenu}
        onReportClick={handleReportClick}
        onReviewClick={handleReviewClick}
        onBlockClick={handleBlockClick}
        openProfileModal={() => setIsUserInfoOpen(true)}
      />

      <UserInfoDialog
        data={transformParticipantForDialog(otherParticipant)}
        open={isUserInfoOpen}
        onOpenChange={setIsUserInfoOpen}
      />

      {isReportModalOpen && (
        <ReportModal
          targetType="Reachout"
          targetId={reachoutId}
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleReportSubmit}
          isSubmitting={isSubmitting}
          error={reportError}
        />
      )}

      {isReviewModalOpen && otherParticipant && (
        <ReviewModal
          interactionType="Reachout"
          interactionId={reachoutId}
          otherParticipant={otherParticipant}
          isOpen={isReviewModalOpen}
          onClose={() => setIsReviewModalOpen(false)}
          onSubmit={handleReviewSubmit}
          isSubmitting={isSubmittingReview}
          error={reviewError}
        />
      )}

      <BlockModal
        isOpen={isBlockModalOpen}
        onClose={() => setIsBlockModalOpen(false)}
        sourceType="reachout"
        instanceData={reachoutData}
        onAction={toggleBlock}
        error={error}
      />
    </div>
  );
};

export default Reachout;
