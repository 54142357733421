import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "contexts/NotificationContext";
import cn from "classnames";
import styles from "./NotificationItem.module.css";
import { FaHeart } from "react-icons/fa";
import { RiHandHeartLine } from "react-icons/ri";
import * as typedefs from "typedefs";
import { formatDistanceToNow } from "date-fns";

/**
 * NotificationItem component that displays a single notification.
 *
 * @typedef {Object} Props - The component props.
 * @property {typedefs.NotificationData} props.notif - The notification data to display.
 * @property {Function} props.onClick - A function to handle click events.
 */
const NotificationItem = ({ notif, onClick }) => {
  const navigate = useNavigate();
  const { markAsRead } = useNotificationContext();
  const [isRead, setIsRead] = useState(notif.read);

  useEffect(() => {
    setIsRead(notif.read);
  }, [notif.read]);

  const handleResourceClick = () => {
    onClick();

    switch (notif.type) {
      case "hug":
        navigate(`/stories/${notif.resource._id}`);
        break;

      case "reachout":
        navigate(`/chats?type=reachouts&id=${notif.resource._id}`, {
          state: { refresh: Date.now() },
        });
        break;

      case "reachoutAccepted":
        navigate(`/chats?type=chats&id=${notif.metadata.chatId}`, {
          state: { refresh: Date.now() },
        });
        break;

      case "matchCompleted":
        navigate(`/chats?type=chats&id=${notif.metadata.chatId}`, {
          state: { refresh: Date.now() },
        });
        break;

      case "matchAccepted":
        // For first acceptance, show the match details
        navigate(`/chats?type=matches&id=${notif.metadata.matchId}`, {
          state: { refresh: Date.now() },
        });
        break;

      case "match":
        // For new matches, show the match details
        navigate(`/chats?type=matches&id=${notif.resource._id}`, {
          state: { refresh: Date.now() },
        });
        break;

      case "chatRequestApproved":
        navigate(`/chats?type=chats&id=${notif.resource._id}`, {
          state: { refresh: Date.now() },
        });
        break;

      default:
        console.warn(`Unhandled notification type: ${notif.type}`);
    }
  };

  const getResourceButtonText = () => {
    switch (notif.type) {
      case "hug":
        return "View story";
      case "reachout":
        return "View reachout";
      case "reachoutAccepted":
        return "Go to chat";
      case "matchCompleted":
        return "Go to chat";
      case "matchAccepted":
        return "View match";
      case "match":
        return "View match";
      case "chatRequestApproved":
        return "Go to chat";
      default:
        return "View details";
    }
  };

  const handleNotificationClick = async () => {
    if (!isRead) {
      setIsRead(true);

      try {
        await markAsRead(notif._id);
      } catch (error) {
        setIsRead(false);
      }
    }
  };

  return (
    <div
      className={cn(styles.notificationItem, {
        [styles.unread]: !isRead,
      })}
      onClick={handleNotificationClick}
    >
      {/* Icon column */}
      {notif.type === "hug" ? (
        <FaHeart className={styles.icon} />
      ) : (
        <RiHandHeartLine className={styles.icon} />
      )}

      {/* Content column */}
      <div className={styles.content}>
        <div className={styles.message}>
          {notif.type === "hug"
            ? `${notif.sender.username} hugged you!`
            : notif.message}
        </div>

        {/* Metadata if exists */}
        {notif.metadata?.sharedTags && (
          <div className={styles.metadata}>
            {notif.metadata.sharedTags.map((tag) => (
              <span key={tag._id} className={styles.tag}>
                {tag.name}
              </span>
            ))}
          </div>
        )}

        {/* Footer with timestamp and action button */}
        <div className={styles.footer}>
          <span className={styles.timestamp}>
            {formatDistanceToNow(new Date(notif.createdAt), {
              addSuffix: true,
            })}
          </span>
          {notif.resource && (
            <button
              className={styles.resourceButton}
              onClick={handleResourceClick}
            >
              {getResourceButtonText()}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
