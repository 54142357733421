import React from "react";
import styles from "./ChatToolbar.module.css";
import { HiOutlineInformationCircle, HiOutlineArrowLeft } from "react-icons/hi";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const ChatToolbar = ({
  toggleSideMenu,
  otherParticipant,
  openProfileModal,
  isMobileView,
  isSideMenuVisible,
  onBack,
  type,
}) => {
  // Add logging to verify data structure

  return (
    <div className={styles.toolbar}>
      {isMobileView && (
        <div className={styles.backButton} onClick={onBack}>
          <HiOutlineArrowLeft />
        </div>
      )}

      {!(isMobileView && isSideMenuVisible) && (
        <div className={styles.username} onClick={openProfileModal}>
          {otherParticipant ? (
            <>
              <span>{otherParticipant.displayName}</span>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      )}

      <div className={styles.buttonMenu}>
        <div className={styles.button} onClick={toggleSideMenu}>
          <HiOutlineInformationCircle />
        </div>
      </div>
    </div>
  );
};

export default ChatToolbar;
