import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import styles from "./SearchForm.module.css";
import { configService } from "services/configService";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import cn from "classnames";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip";

// Components
import TagSelector from "./TagSelector";
import StoryInput from "./StoryInput";

const SearchForm = ({ initialPreferences, onSubmit, minWords }) => {
  const [preferences, setPreferences] = useState({
    story: initialPreferences?.story || "",
    tags: initialPreferences?.tags || [],
    ageRanges: initialPreferences?.ageRanges || [],
    genders: initialPreferences?.genders || [],
  });

  const [config, setConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [validationStates, setValidationStates] = useState({
    story: {
      isValid: false,
      errors: ["Please enter at least " + minWords + " words"],
    },
    tags: { isValid: false, errors: [] },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const loadConfig = async () => {
      try {
        const configData = await configService.getConfig();
        if (isMounted) {
          setConfig(configData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Failed to load config:", error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadConfig();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (initialPreferences) {
      setPreferences(initialPreferences);
    }
  }, [initialPreferences]);

  useEffect(() => {
    if (initialPreferences?.story) {
      const wordCount = initialPreferences.story.trim().split(/\s+/).length;
      handleStoryValidation({
        isValid: wordCount >= minWords,
        errors:
          wordCount >= minWords
            ? []
            : [`Please enter at least ${minWords} words`],
      });
    }
  }, [initialPreferences?.story, minWords]);

  const handleStoryChange = useCallback((story) => {
    setPreferences((prev) => ({ ...prev, story }));
  }, []);

  const handleStoryValidation = useCallback((validation) => {
    setValidationStates((prev) => ({ ...prev, story: validation }));
  }, []);

  const handleAgeRangeChange = useCallback((option, isChecked) => {
    setPreferences((prev) => ({
      ...prev,
      ageRanges: isChecked
        ? [...prev.ageRanges, { min: option.min, max: option.max }]
        : prev.ageRanges.filter(
            (range) => range.min !== option.min || range.max !== option.max
          ),
    }));
  }, []);

  const isAgeRangeSelected = useCallback(
    (option) => {
      return preferences.ageRanges.some(
        (range) => range.min === option.min && range.max === option.max
      );
    },
    [preferences.ageRanges]
  );

  const handleTagChange = useCallback((tags) => {
    setPreferences((prev) => ({ ...prev, tags }));
  }, []);

  const handleTagValidation = useCallback((validation) => {
    setValidationStates((prev) => ({ ...prev, tags: validation }));
  }, []);

  const handleGenderChange = useCallback((option, isChecked) => {
    setPreferences((prev) => ({
      ...prev,
      genders: isChecked
        ? [...prev.genders, option.value]
        : prev.genders.filter((g) => g !== option.value),
    }));
  }, []);

  const isGenderSelected = useCallback(
    (option) => {
      return preferences.genders.includes(option.value);
    },
    [preferences.genders]
  );

  const validateForm = () => {
    const formErrors = [];

    if (!validationStates.story.isValid) {
      formErrors.push(...validationStates.story.errors);
    }

    return {
      isValid: formErrors.length === 0,
      errors: formErrors,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formValidation = validateForm();
      if (!formValidation.isValid) {
        return;
      }

      onSubmit(preferences);
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <motion.div
        className={cn(styles.form, styles.loadingContainer)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <LoadingMessage
          message="Loading preferences..."
          className={styles.loadingMessage}
        />
      </motion.div>
    );
  }

  if (!config) {
    return (
      <motion.div
        className={cn(styles.form, styles.errorContainer)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className={styles.errorContent}>
          <span className={styles.errorIcon}>⚠️</span>
          <h3>Configuration Error</h3>
          <p>Unable to load preferences. Please try again later.</p>
          <button
            onClick={() => window.location.reload()}
            className={styles.retryButton}
          >
            Retry
          </button>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.form
      className={styles.form}
      onSubmit={handleSubmit}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <div className={styles.mainSection}>
        <h2 className={styles.title}>What would you like to talk about?</h2>

        <StoryInput
          value={preferences.story}
          onChange={handleStoryChange}
          onValidationChange={handleStoryValidation}
          placeholder="Share what's on your mind..."
          minWords={minWords}
          required
        />
      </div>

      <button
        type="button"
        className={styles.expandButton}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? "Hide Preferences" : "Show Matching Preferences"}
        <span className={`${styles.arrow} ${isExpanded ? styles.up : ""}`}>
          ↓
        </span>
      </button>

      <motion.div
        className={styles.preferencesSection}
        initial={false}
        animate={{
          height: isExpanded ? "auto" : 0,
          opacity: isExpanded ? 1 : 0,
        }}
      >
        <div className={styles.preferenceGroup}>
          <div className={styles.headerWithTooltip}>
            <h3>Tags (Optional)</h3>
            <Tooltip>
              <TooltipTrigger>
                <span className={styles.tooltipTrigger}>ⓘ</span>
              </TooltipTrigger>
              <TooltipContent>
                Select tags to match with others interested in the same
                discussions
              </TooltipContent>
            </Tooltip>
          </div>
          <TagSelector
            selectedTags={preferences.tags}
            onChange={handleTagChange}
            onValidationChange={handleTagValidation}
            placeholder={
              preferences.tags.length >= 3
                ? "Maximum 3 tags reached"
                : "Select up to 3 tags to discuss..."
            }
            className={styles.tagSelector}
          />
        </div>

        <div className={styles.preferenceGroup}>
          <h3>Age Preferences (Optional)</h3>
          <div className={styles.checkboxGroup}>
            {config.ageRanges.map((option) => (
              <label key={option.label} className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={isAgeRangeSelected(option)}
                  onChange={(e) =>
                    handleAgeRangeChange(option, e.target.checked)
                  }
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>

        <div className={styles.preferenceGroup}>
          <h3>Gender Preferences (Optional)</h3>
          <div className={styles.checkboxGroup}>
            {config.genders.map((option) => (
              <label key={option.value} className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={isGenderSelected(option)}
                  onChange={(e) => handleGenderChange(option, e.target.checked)}
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>
      </motion.div>

      <button
        type="submit"
        className={styles.submitButton}
        disabled={!validateForm().isValid || isSubmitting}
      >
        {isSubmitting ? "Validating..." : "Start Searching"}
      </button>
    </motion.form>
  );
};

export default SearchForm;
