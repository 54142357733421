import React, { useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
} from "@floating-ui/react";
import { FiChevronDown } from "react-icons/fi";
import styles from "../Profile.module.css";

const sortOptions = [
  { value: "newest", label: "Newest first" },
  { value: "oldest", label: "Oldest first" },
  { value: "popular", label: "Most popular" },
];

const SortSelect = ({ currentSort, onSortChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  // Add useDismiss to handle clicking outside
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <div className={styles.sortContainer}>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className={styles.sortTrigger}
        aria-label="Sort options"
      >
        <span>
          {sortOptions.find((opt) => opt.value === currentSort)?.label}
        </span>
        <FiChevronDown className={styles.sortIcon} />
      </button>

      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className={styles.sortDropdown}
        >
          {sortOptions.map((option) => (
            <button
              key={option.value}
              onClick={() => {
                onSortChange(option.value);
                setIsOpen(false);
              }}
              className={`${styles.sortOption} ${
                currentSort === option.value ? styles.activeSort : ""
              }`}
              role="option"
              aria-selected={currentSort === option.value}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortSelect;
