// packages/client/src/components/Navbar/Navbar.jsx
import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import logo_chair from "../../assets/logo_chair.png";
import styles from "./Navbar.module.css";
import { RxAvatar } from "react-icons/rx";
import {
  RiProfileLine,
  RiSettings3Line,
  RiLogoutBoxRLine,
  RiDashboardLine,
  RiHomeLine,
  RiHeartLine,
  RiInformationLine,
  RiLoginBoxLine,
  RiQuestionLine,
  RiContactsLine,
} from "react-icons/ri";
import useMediaQuery from "hooks/useMediaQuery"; // Updated import
import { FloatingPortal } from "@floating-ui/react"; // Import FloatingPortal
import {
  useFloating,
  autoPlacement,
  shift,
  offset,
  autoUpdate,
} from "@floating-ui/react";
import { useDismiss } from "@floating-ui/react";
import { useInteractions } from "@floating-ui/react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";

import * as typedefs from "typedefs";

import NotificationsDropdown from "components/Notifications/NotificationsDropdown";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip";
import notificationStyles from "components/Notifications/NotificationsDropdown.module.css";

/**
 *
 * @typedef {typedefs.NotificationData} NotificationData
 */

function NavMenuItem({ link, label, handleDropDownMenuToggle, tooltip, icon }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    event.preventDefault();
    setTimeout(() => {
      navigate(link);
    }, 300);
    handleDropDownMenuToggle();
  };

  const isActive = location.pathname === link;

  const content = (
    <a
      href={link}
      onClick={handleClick}
      className={cn({ [styles.active]: isActive })}
    >
      {icon && <span className={styles.menuIcon}>{icon}</span>}
      <p>{label}</p>
    </a>
  );

  if (tooltip) {
    return (
      <li>
        <Tooltip delay={0.5}>
          <TooltipTrigger asChild>{content}</TooltipTrigger>
          <TooltipContent>{tooltip}</TooltipContent>
        </Tooltip>
      </li>
    );
  }

  return <li>{content}</li>;
}

const AvatarDropdown = ({ userData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("xs");
  const { handleLogout } = useContext(UserContext);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,

    middleware: [
      autoPlacement({
        padding: 10,
        allowedPlacements: ["top-end", "bottom-end"],
      }),
      shift(),
      offset(10),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const handleAvatarClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const LinkItem = ({ label, link, icon, onClick }) => (
    <button
      className={styles.linkItem}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else {
          navigate(link);
        }
        setIsOpen(false);
      }}
    >
      {icon}
      {label}
    </button>
  );

  return (
    <div className={styles.avatarDropDownContainer}>
      <button
        ref={refs.setReference}
        onClick={handleAvatarClick}
        {...getReferenceProps()}
      >
        <RxAvatar style={{ width: "40px", height: "40px" }} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <FloatingPortal>
            <motion.div
              ref={refs.setFloating}
              style={!isMobile ? floatingStyles : undefined}
              className={styles.avatarDropDownMenu}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              {...getFloatingProps()}
            >
              <div className={styles.dropdownHeader}>
                <h3>Account</h3>
                {isMobile && (
                  <button
                    className={styles.closeButton}
                    onClick={() => setIsOpen(false)}
                    aria-label="Close menu"
                  >
                    <IoClose size={24} />
                  </button>
                )}
              </div>
              {userData && (
                <>
                  {userData.role === "admin" && (
                    <LinkItem
                      label="Admin Dashboard"
                      link="/admin"
                      icon={<RiDashboardLine />}
                    />
                  )}
                  <LinkItem
                    label="Profile"
                    link={`/user/${userData.username}`}
                    icon={<RiProfileLine />}
                  />
                  <LinkItem
                    label="Settings"
                    link={`/settings`}
                    icon={<RiSettings3Line />}
                  />
                </>
              )}
              <LinkItem
                label="Logout"
                onClick={handleLogout}
                icon={<RiLogoutBoxRLine />}
              />
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </div>
  );
};

function Navbar() {
  const { userData } = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery("sm");
  const location = useLocation();

  // Define navigation items based on authentication status
  const mainNavItems = [
    {
      link: "/",
      label: "Home",
      icon: <RiHomeLine />,
    },
    ...(userData
      ? [
          {
            link: "/find",
            label: "Find Others",
            icon: <RiHeartLine />,
            tooltip: "Find people with similar experiences",
          },
          {
            link: "/write",
            label: "Tell Your Story",
            tooltip: "Share your journey",
          },
          {
            link: "/chats",
            label: "Chat",
            tooltip: "Connect with others",
          },
        ]
      : []),
  ];

  // Instead, define separate items for logged in and non-logged in users
  const loggedOutSecondaryNavItems = [
    {
      link: "/contact",
      label: "Contact Us",
      icon: <RiContactsLine />,
      tooltip: "Get in touch with our team",
    },
  ];

  // Additional items for logged-in users
  const loggedInSecondaryNavItems = [
    {
      link: "/help",
      label: "Help & Support",
      icon: <RiQuestionLine />,
      tooltip: "Get help and support for using Stumble",
    },
  ];

  // Combine the secondary nav items based on authentication status
  const secondaryNavItems = userData
    ? loggedInSecondaryNavItems
    : loggedOutSecondaryNavItems;

  // Add useEffect to handle body scroll when menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [menuOpen]);

  // Update click outside handler to exclude avatar dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuOpen &&
        !event.target.closest(`.${styles.nav}`) &&
        !event.target.closest(`.${styles.menuToggle}`) &&
        !event.target.closest(`.${styles.avatarDropDownMenu}`) &&
        !event.target.closest(`.${notificationStyles.dropdown}`)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  // Add this effect to close menu on route changes
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  // Add state for controlling tooltips
  const [showTooltips, setShowTooltips] = useState(true);

  // Update the menu toggle handler to also dismiss tooltips
  const handleMenuToggle = () => {
    if (menuOpen) {
      setShowTooltips(false);
      // Reset tooltip visibility after animation completes
      setTimeout(() => setShowTooltips(true), 300);
    }
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          <img className={styles.logo_image} src={logo_chair} alt="Stumble" />
          <span className={styles.logo_text}>Stumble</span>
        </Link>
      </div>

      <nav className={cn(styles.nav, { [styles.active]: menuOpen })}>
        <ul className={styles.mainNav}>
          {mainNavItems.map((item) => (
            <NavMenuItem
              key={item.link}
              {...item}
              handleDropDownMenuToggle={() => handleMenuToggle()}
              tooltip={showTooltips ? item.tooltip : null}
            />
          ))}
        </ul>

        {/* Secondary Navigation - only render if there are items */}
        {secondaryNavItems.length > 0 && (
          <ul className={styles.secondaryNav}>
            {secondaryNavItems.map((item) => (
              <NavMenuItem
                key={item.link}
                {...item}
                handleDropDownMenuToggle={() => handleMenuToggle()}
                tooltip={showTooltips ? item.tooltip : null}
              />
            ))}
          </ul>
        )}

        {/* User Actions */}
        <div className={styles.userActions}>
          {userData ? (
            <>
              <NotificationsDropdown />
              <AvatarDropdown userData={userData} />
            </>
          ) : (
            <Link to="/login" className={styles.loginButton}>
              <RiLoginBoxLine />
              <span>Login</span>
            </Link>
          )}
        </div>
      </nav>

      {/* Mobile Menu Toggle */}
      <button
        className={cn(styles.menuToggle, { [styles.active]: menuOpen })}
        onClick={handleMenuToggle}
        aria-label="Toggle menu"
        aria-expanded={menuOpen}
      >
        <div className={styles.burgerMenuIcon}>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
        </div>
      </button>
    </header>
  );
}

export default Navbar;
