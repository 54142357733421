import React, { useState, useEffect, useMemo } from "react";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import LifeExperienceForm from "./LifeExperienceForm";
import styles from "./LifeExperienceModal.module.css"; // Import the CSS module
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import useMediaQuery from "hooks/useMediaQuery";

/**
 * LifeExperienceModal component for displaying a modal to edit or create a life experience.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lifeExperience - The life experience object.
 * @param {boolean} props.isOpen - Flag to control the visibility of the modal.
 * @param {Function} props.onClose - Callback function to close the modal.
 * @param {string} props.mode - The mode of the modal, either "edit" or "new".
 * @param {Function} [props.onCreate] - Callback function to handle creating a new life experience. Required when mode is "new".
 * @param {Function} [props.onUpdate] - Callback function to handle updating a life experience. Required when mode is "edit".
 * @param {Function} [props.onDelete] - Callback function to handle deleting a life experience. Only applicable when mode is "edit".
 * @param {string} [props.error] - Error message, if any.
 * @param {string} props.status - Status of the life experience.
 * @param {string} props.statusMessage - Status message of the life experience.
 * @param {string} [props.initialStory] - Initial story for the new life experience.
 *
 * @returns {JSX.Element|null} The rendered modal or null if not open.
 */
const LifeExperienceModal = ({
  lifeExperience,
  isOpen,
  onClose,
  mode = "new",
  onSave,
  onCreate,
  onUpdate,
  onDelete,
  error,
  status,
  statusMessage,
  initialData = {},
}) => {
  const isMobile = useMediaQuery("sm"); // Use the sm breakpoint (max-width: 768px)

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context);

  // Use a simpler dismiss handler that only prevents closing when clicking joyride elements
  const dismiss = useDismiss(context, {
    outsidePress: (event) => !event.target.closest('[data-joyride="true"]'),
    outsidePressEvent: "mousedown",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  // Handle overlay background click manually
  const handleOverlayClick = (e) => {
    // Only close if clicking directly on the overlay (not on the modal or its children)
    if (e.target.className.includes(styles.overlay)) {
      onClose();
    }
  };

  const formData = useMemo(() => {
    // Start with complete defaults
    const defaults = {
      story: "",
      tags: [],
      date: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      },
      anonymized: true,
      searchable: true,
      intents: {
        venting: false,
        seeking_advice: false,
        offering_support: false,
        sharing_experience: true,
      },
    };

    // Apply layers of overrides with clear precedence
    return {
      ...defaults,
      ...initialData,
      ...(lifeExperience || {}),
    };
  }, [initialData, lifeExperience]);

  const handleUpdate = async (formData) => {
    if (!onUpdate) {
      throw new Error("onUpdate prop is required when mode is 'edit'");
    }
    await onUpdate(formData);
  };

  const handleCreate = async (formData) => {
    if (!onCreate) {
      throw new Error("onCreate prop is required when mode is 'new'");
    }
    await onCreate(formData);
  };

  const handleDelete = async () => {
    if (mode === "edit") {
      if (!onDelete) {
        throw new Error("onDelete prop is required when mode is 'edit'");
      }
      await onDelete();
    }
  };

  if (!isOpen) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll
        className={`${styles.overlay} ${isMobile ? styles.mobileOverlay : ""}`}
        onClick={handleOverlayClick}
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={`${styles.modal} ${isMobile ? styles.mobileModal : ""}`}
            {...getFloatingProps()}
          >
            <button
              onClick={onClose}
              className={styles.closeButton}
              aria-label="Close modal"
            >
              ×
            </button>
            {status === "loading" ? (
              <LoadingMessage
                message={statusMessage}
                className={styles.loading}
              />
            ) : (
              <>
                <LifeExperienceForm
                  initialData={formData}
                  mode={mode}
                  onSave={onSave}
                  onCreate={handleCreate}
                  onUpdate={handleUpdate}
                  onDelete={handleDelete}
                />
              </>
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default LifeExperienceModal;
