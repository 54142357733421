import { useState, useEffect, useContext } from "react";
import { ToastContext } from "contexts/ToastContext";

/**
 * Custom hook for managing life experiences during registration
 *
 * @param {Object} initialData - Initial form data containing life experiences
 * @param {Function} onSave - Function to save data to parent form
 * @param {Function} handleStepValidity - Function to update step validity
 * @returns {Object} Life experience management methods and state
 */
const useRegistrationLifeExperiences = (
  initialData,
  onSave,
  handleStepValidity
) => {
  const { addToast } = useContext(ToastContext);
  const [lifeExperiences, setLifeExperiences] = useState(
    initialData.lifeExperiences || []
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentExperience, setCurrentExperience] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // Maximum allowed experiences
  const MAX_EXPERIENCES = 3;

  // Update parent form data when experiences change
  useEffect(() => {
    onSave({ lifeExperiences });

    // Always consider the step valid, even with 0 experiences
    // This allows users to proceed without adding experiences if they choose
    handleStepValidity(true);
  }, [lifeExperiences, onSave, handleStepValidity]);

  // Determine if user can add a new experience
  const canAddNew = lifeExperiences.length < MAX_EXPERIENCES;

  // Handle creating a new experience
  const handleCreate = (formData) => {
    if (lifeExperiences.length >= MAX_EXPERIENCES) {
      // Don't allow creation if at limit
      return;
    }

    setLifeExperiences([...lifeExperiences, formData]);
    setIsModalOpen(false);
    setIsEditing(false);
    addToast("Life experience added successfully", "success");
  };

  // Handle updating an existing life experience
  const handleUpdate = (updatedExperience) => {
    if (currentIndex === null && currentExperience?.index === undefined) {
      console.error("Cannot update: no valid index found");
      addToast("Could not update experience", "error");
      return;
    }

    const indexToUpdate =
      currentIndex !== null ? currentIndex : currentExperience.index;

    setLifeExperiences((prev) =>
      prev.map((exp, idx) => (idx === indexToUpdate ? updatedExperience : exp))
    );

    setIsModalOpen(false);
    setIsEditing(false);
    addToast("Life experience updated successfully", "success");
  };

  // Handle initiating the delete process (shows confirmation)
  const handleDeleteClick = (experience, index) => {
    // First set the current experience and index
    setCurrentExperience({ ...experience, index });
    setCurrentIndex(index);

    // Then show the confirmation dialog
    setShowDeleteConfirmation(true);
  };

  // Handle confirming the delete action
  const handleDeleteConfirm = () => {
    if (currentIndex === null && currentExperience?.index === undefined) {
      console.error("Cannot delete: no valid index found");
      addToast("Could not delete experience", "error");
      setShowDeleteConfirmation(false);
      return;
    }

    const indexToDelete =
      currentIndex !== null ? currentIndex : currentExperience.index;

    setLifeExperiences((prev) =>
      prev.filter((_, idx) => idx !== indexToDelete)
    );

    setIsModalOpen(false);
    setIsEditing(false);
    setShowDeleteConfirmation(false);
    addToast("Life experience deleted", "success");
  };

  // Handle canceling the delete action
  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  // Handle clicking the add button
  const handleAddClick = () => {
    if (lifeExperiences.length >= MAX_EXPERIENCES) {
      // Don't open modal if at limit
      return;
    }

    setCurrentExperience(null);
    setCurrentIndex(null);
    setIsModalOpen(true);
    setIsEditing(true);
  };

  // Open modal to edit an existing life experience
  const handleEditClick = (experience, index) => {
    setCurrentExperience({ ...experience, index });
    setCurrentIndex(index); // Store index separately for redundancy
    setIsModalOpen(true);
    setIsEditing(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setCurrentExperience(null);
    setCurrentIndex(null);
  };

  return {
    lifeExperiences,
    isModalOpen,
    currentExperience,
    isEditing,
    canAddNew,
    showDeleteConfirmation,
    handleCreate,
    handleUpdate,
    handleDeleteClick,
    handleDeleteConfirm,
    handleDeleteCancel,
    handleAddClick,
    handleEditClick,
    handleCloseModal,
  };
};

export default useRegistrationLifeExperiences;
