import React, { useState } from "react";
import styles from "./SimpleCombobox.module.css";
import { FaChevronDown, FaChevronUp, FaTimes } from "react-icons/fa";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  useRole,
  FloatingPortal,
  size,
} from "@floating-ui/react";

const SimpleCombobox = ({
  options,
  value,
  onChange,
  placeholder,
  className,
  theme = "dark", // Default theme is dark
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Find the selected option label based on value
  const selectedOption = options.find((option) => option.value === value);
  const displayValue = selectedOption ? selectedOption.label : "";

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Set up Floating UI with size middleware
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(4),
      flip({ padding: 8 }),
      shift({ padding: 8 }),
      size({
        apply({ rects, elements, availableHeight }) {
          // Set the width of the floating element to match the reference element
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${Math.min(200, availableHeight)}px`,
          });
        },
        padding: 8,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  // Set up interactions
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  // Handle input change
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (!isOpen) setIsOpen(true);
  };

  // Handle option selection
  const handleSelect = (option) => {
    onChange(option.value);
    setSearchTerm("");
    setIsOpen(false);
  };

  // Clear selection
  const handleClear = (e) => {
    e.stopPropagation();
    onChange("");
    setSearchTerm("");
  };

  // Handle input click separately to prevent dropdown toggle
  const handleInputClick = (e) => {
    e.stopPropagation();
    if (!isOpen) setIsOpen(true);
  };

  return (
    <div
      className={`${styles.comboboxContainer} ${className || ""} ${
        styles[theme]
      }`}
      data-theme={theme}
    >
      <div
        ref={refs.setReference}
        className={styles.comboboxInput}
        {...getReferenceProps()}
      >
        <input
          type="text"
          value={searchTerm || displayValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          onClick={handleInputClick}
          aria-expanded={isOpen}
          aria-autocomplete="list"
        />

        {(value || searchTerm) && (
          <button
            className={styles.clearButton}
            onClick={handleClear}
            type="button"
            tabIndex={-1}
            aria-label="Clear selection"
          >
            <FaTimes />
          </button>
        )}

        <div className={styles.indicator}>
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={`${styles.dropdown} ${styles[`dropdown-${theme}`]}`}
            {...getFloatingProps()}
            data-theme={theme}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`${styles.option} ${
                    option.value === value ? styles.selected : ""
                  }`}
                  onClick={() => handleSelect(option)}
                  role="option"
                  aria-selected={option.value === value}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className={styles.noResults}>No options found</div>
            )}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};

export default SimpleCombobox;
