import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useBrowserContext } from "contexts/BrowserContext";
import styles from "./WebViewModal.module.css";

const WebViewModal = () => {
  const { redirectToNativeBrowser, getWebViewType } = useBrowserContext();
  const webViewType = getWebViewType();
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;
  const isFacebook = webViewType === "facebook";

  // Determine browser-specific messaging
  const getBrowserSpecificContent = () => {
    switch (webViewType) {
      case "facebook":
        return {
          title: "Open in Browser",
          message:
            "Facebook Messenger has limited functionality. Please open this page in your external browser:",
          buttonText: "Try Opening Browser",
          buttonColor: "#1877f2", // Facebook blue
        };
      case "instagram":
        return {
          title: "Open in Browser",
          message:
            "Instagram in-app browsers have limited functionality. Please open in your device's browser for the full experience.",
          buttonText: "Open in Browser",
          buttonColor: "#E1306C", // Instagram color
        };
      default:
        return {
          title: "Open in Browser",
          message:
            "In-app browsers have limited functionality. Please open in your device's browser for the full experience.",
          buttonText: "Open in Browser",
          buttonColor: "#4285F4", // Google blue
        };
    }
  };

  const { title, message, buttonText, buttonColor } =
    getBrowserSpecificContent();

  const handleOpenInBrowser = () => {
    redirectToNativeBrowser();
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(currentUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return createPortal(
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{title}</h2>
        <p className={styles.modalMessage}>{message}</p>

        {isFacebook ? (
          <>
            <div className={styles.facebookInstructions}>
              <p className={styles.instructionTitle}>
                Option 1: Use Messenger's menu
              </p>
              <ol className={styles.instructionList}>
                <li>
                  Tap the <strong>⋯</strong> (three dots) in the bottom right
                  corner
                </li>
                <li>
                  Select <strong>"Open in external browser"</strong>
                </li>
              </ol>

              <p className={styles.instructionTitle}>
                Option 2: Copy and paste the link
              </p>
              <ol className={styles.instructionList}>
                <li>Copy this link</li>
                <li>Open your browser app</li>
                <li>Paste the link</li>
              </ol>
            </div>

            <button className={styles.copyButton} onClick={handleCopyLink}>
              {copied ? "Link Copied!" : "Copy Link"}
            </button>

            <div className={styles.buttonSpacer}></div>

            <button
              className={styles.openButton}
              style={{ backgroundColor: buttonColor }}
              onClick={handleOpenInBrowser}
            >
              {buttonText}
            </button>
          </>
        ) : (
          <>
            <button className={styles.copyButton} onClick={handleCopyLink}>
              {copied ? "Link Copied!" : "Copy Link"}
            </button>

            <div className={styles.buttonSpacer}></div>

            <button
              className={styles.openButton}
              style={{ backgroundColor: buttonColor }}
              onClick={handleOpenInBrowser}
            >
              {buttonText}
            </button>
          </>
        )}
      </div>
    </div>,
    document.body
  );
};

export default WebViewModal;
