const countryList = require("country-list");

const countriesWithThe = [
  "BS", // Bahamas
  "GM", // Gambia
  "NL", // Netherlands
  "PH", // Philippines
  "GB", // United Kingdom
  "US", // United States
];

/**
 * Returns a formatted country name, adding "the" for certain countries
 * @param {string} countryName - The name of the country
 * @param {string} countryCode - The country code
 * @returns {string} The formatted country name
 */
const getFormattedCountryName = (countryName, countryCode) => {
  if (!countryName || !countryCode) {
    return countryName || null;
  }

  if (countriesWithThe.includes(countryCode)) {
    return `the ${countryName}`;
  }
  return countryName;
};

module.exports = {
  getFormattedCountryName,
};
