import React from "react";
import cn from "classnames";
import styles from "../ChatWindow.module.css";

const LastMessageDisplay = ({
  lastMessage,
  currentUserId,
  otherParticipantName,
}) => {
  if (!lastMessage) {
    return <div className={styles.noMessages}>No messages yet</div>;
  }

  const isUnread = !lastMessage.read?.includes(currentUserId);
  const isSystemMessage = lastMessage.type === "system";
  const messageStatus = isSystemMessage ? null : lastMessage.status || "sent";

  const getSenderName = () => {
    if (isSystemMessage) {
      return "System";
    }

    // Handle both populated and unpopulated sender cases
    const senderId =
      typeof lastMessage.sender === "string"
        ? lastMessage.sender
        : lastMessage.sender?._id;

    return senderId === currentUserId ? "You" : otherParticipantName;
  };

  const renderContent = () => {
    if (isSystemMessage) {
      switch (lastMessage.content.systemType) {
        case "matchCreated":
          return "New match created!";
        case "matchAccepted":
          return "Match accepted";
        case "matchCompleted":
          return "Match completed";
        case "reachoutCreated":
          return "New reachout";
        case "reachoutAccepted":
          return "Reachout accepted";
        case "reachoutCompleted":
          return "Reachout completed";
        case "reachoutExpired":
          return "Reachout expired";
        case "connectionAdded":
          return "New connection added";
        default:
          console.warn("Unknown system message type:", lastMessage);
          return "System notification";
      }
    }

    // For user messages
    if (lastMessage.content.type === "text") {
      // Handle both possible text locations
      const messageText =
        lastMessage.content.text || lastMessage.content.data?.text;

      if (!messageText) {
        console.error("Message content missing text:", lastMessage);
        return "Message unavailable";
      }

      return `${getSenderName()}: ${messageText}`;
    }

    // Fallback for unknown message types
    console.warn("Unknown message type:", lastMessage);
    return "New message";
  };

  const content = renderContent();

  return (
    <div
      className={cn(styles.lastMessage, {
        [styles.unread]: isUnread,
        [styles.systemMessage]: isSystemMessage,
        [styles.pending]: messageStatus === "pending",
      })}
    >
      <span className={styles.content}>
        {content}
        {messageStatus === "pending" && (
          <span className={styles.statusIndicator}>
            <span className={styles.pendingDot} />
          </span>
        )}
      </span>
    </div>
  );
};

export default LastMessageDisplay;
