import React, {
  forwardRef,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ChatMessage.module.css";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import dayjs from "dayjs"; // Import dayjs
import { formatRelativeTime } from "utils/dates";
import { UserContext } from "contexts/UserContext";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import {
  SYSTEM_MESSAGE_TYPES,
  formatResourceType,
  getOtherParticipant,
  isUserParticipant,
  getMessagePayload,
  isSystemMessageType,
} from "utils/chatUtils";

/**
 * ChatMessage component that displays a chat message.
 * @typedef {Object} Props - The component props.
 * @property {string} messageId - The ID of the chat message.
 * @property {{_id: string, username: string}} otherParticipant - Indicates if the message is from the other participant.
 * @property {React.Ref} ref - The ref object forwarded to the component.
 * @returns {JSX.Element} The rendered chat message component.
 */
const ChatMessage = forwardRef(({ message, otherParticipant }, ref) => {
  const [showTimestamp, setShowTimestamp] = useState(false);
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const toggleTimestamp = () => {
    setShowTimestamp(!showTimestamp);
  };

  // Use dayjs to format the timestamp
  const formattedTimestamp = dayjs(message.createdAt).format(
    "MMMM D, YYYY h:mm A"
  );
  const relativeTime = formatRelativeTime(message.createdAt);

  // Updated messageSenderType logic to handle string sender IDs
  const messageSenderType = useMemo(() => {
    if (message.type === "system") return "system";

    // Use the sender as a string if that's what the backend provides,
    // otherwise fall back to sender._id if available
    const senderId =
      typeof message.sender === "string" ? message.sender : message.sender?._id;

    if (senderId === userData._id) return "currentUser";
    if (otherParticipant && senderId === otherParticipant._id)
      return "otherUser";
    return message.type; // Fallback in case none match
  }, [message.type, message.sender, userData._id, otherParticipant]);

  const messageStatus = useMemo(() => {
    if (message.type === "system") return null;
    return message.status || "sent"; // Default to "sent" for backward compatibility
  }, [message.type, message.status]);

  // Helper function to find other participant in a participants array
  const findOtherParticipant = useCallback(
    (participants) => {
      return participants.find((p) => p.userId !== userData._id) || {};
    },
    [userData._id]
  );

  const renderReachoutAccepted = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.REACHOUT_ACCEPTED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const isAcceptingUser = payload.acceptingUserId === userData._id;
    const otherParticipant = getOtherParticipant(
      payload.participants,
      userData._id
    );
    if (!otherParticipant) return null;

    return (
      <div className={styles.title}>
        {isAcceptingUser ? (
          <>
            You've accepted the reachout with{" "}
            <span className={styles.pseudonym}>
              {otherParticipant.pseudonym}
            </span>
          </>
        ) : (
          <>
            <span className={styles.pseudonym}>
              {otherParticipant.pseudonym}
            </span>{" "}
            has accepted the reachout
          </>
        )}
      </div>
    );
  }, [message, userData._id]);

  const renderConnectionAdded = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.CONNECTION_ADDED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const otherParticipant = getOtherParticipant(
      payload.participants,
      userData._id
    );
    if (!otherParticipant) return null;

    return (
      <>
        <div className={styles.title}>
          New connection added with{" "}
          <span className={styles.pseudonym}>{otherParticipant.username}</span>
        </div>
        <div className={styles.metadata}>
          {payload.type === "reachout" && (
            <div className={styles.resourceSection}>
              <div className={styles.resourceType}>
                {formatResourceType(payload.metadata.resource.type)}
              </div>
              <div className={styles.resourceLabel}>
                "{payload.metadata.resource.label}"
              </div>
              {payload.metadata.message && (
                <div className={styles.messageSection}>
                  {payload.metadata.message}
                </div>
              )}
            </div>
          )}
          {payload.type === "match" && (
            <div className={styles.resourceSection}>
              <div className={styles.similarityText}>
                {Math.round(payload.metadata.similarity * 100)}% match
              </div>
              <div className={styles.storySection}>
                <div className={styles.storyLabel}>Their story</div>
                <div className={styles.storyText}>
                  {
                    payload.metadata.participants.find(
                      (p) => p.userId !== userData._id
                    )?.story
                  }
                </div>
              </div>
              <div className={styles.storySection}>
                <div className={styles.storyLabel}>Your story</div>
                <div className={styles.storyText}>
                  {
                    payload.metadata.participants.find(
                      (p) => p.userId === userData._id
                    )?.story
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }, [message, userData._id]);

  const renderMatchCreated = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.MATCH_CREATED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const otherParticipant = getOtherParticipant(
      payload.participants,
      userData._id
    );
    const currentParticipant = payload.participants.find(
      (p) => p.userId === userData._id
    );
    if (!otherParticipant || !currentParticipant) return null;

    return (
      <>
        <div className={styles.title}>
          You've matched with{" "}
          <span className={styles.pseudonym}>{otherParticipant.pseudonym}</span>
        </div>
        <div className={styles.metadata}>
          <div className={styles.resourceSection}>
            <div className={styles.storySection}>
              <div className={styles.storyLabel}>Their story</div>
              <div className={styles.storyText}>{otherParticipant.story}</div>
            </div>
            <div className={styles.storySection}>
              <div className={styles.storyLabel}>Your story</div>
              <div className={styles.storyText}>{currentParticipant.story}</div>
            </div>
            {payload.metadata.sharedTags?.length > 0 && (
              <div className={styles.tags}>
                {payload.metadata.sharedTags.map((tag) => (
                  <span key={tag._id} className={styles.tag}>
                    {tag.name}
                  </span>
                ))}
              </div>
            )}
            <div className={styles.similarityText}>
              {Math.round(payload.metadata.similarity * 100)}% match
            </div>
          </div>
        </div>
      </>
    );
  }, [message, userData._id]);

  const renderMatchAccepted = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.MATCH_ACCEPTED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const currentUserParticipant = payload.participants.find(
      (p) => p.userId === userData._id
    );
    const otherParticipant = payload.participants.find(
      (p) => p.userId !== userData._id
    );

    const didCurrentUserAccept = currentUserParticipant.accepted;

    return (
      <>
        <div className={styles.title}>
          {didCurrentUserAccept ? (
            <>
              You've accepted the match with{" "}
              <span className={styles.pseudonym}>
                {otherParticipant.pseudonym}
              </span>
            </>
          ) : (
            <>
              <span className={styles.pseudonym}>
                {otherParticipant.pseudonym}
              </span>{" "}
              has accepted your match
            </>
          )}
        </div>
      </>
    );
  }, [message, userData._id]);

  const renderMatchCompleted = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.MATCH_COMPLETED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const otherParticipant = getOtherParticipant(
      payload.participants,
      userData._id
    );
    if (!otherParticipant) return null;

    return (
      <>
        <div className={styles.title}>
          Match completed with{" "}
          <span className={styles.pseudonym}>{otherParticipant.pseudonym}</span>
        </div>
        <div className={styles.metadata}>
          <div className={styles.resourceSection}>
            <div className={styles.storySection}>
              <div className={styles.storyLabel}>Their story</div>
              <div className={styles.storyText}>{otherParticipant.story}</div>
            </div>
            <div className={styles.storySection}>
              <div className={styles.storyLabel}>Your story</div>
              <div className={styles.storyText}>
                {
                  payload.participants.find((p) => p.userId === userData._id)
                    ?.story
                }
              </div>
            </div>
            {payload.metadata?.sharedTags?.length > 0 && (
              <div className={styles.tags}>
                {payload.metadata.sharedTags.map((tag) => (
                  <span key={tag._id} className={styles.tag}>
                    {tag.name}
                  </span>
                ))}
              </div>
            )}
            <div className={styles.similarityText}>
              {Math.round(payload.metadata.similarity * 100)}% match
            </div>
            <div className={styles.chatLink}>
              <span
                className={cn(styles.link)}
                onClick={() =>
                  navigate(`/chats?type=chats&id=${payload.chatId}`)
                }
              >
                Go to chat with {otherParticipant.username}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }, [message, userData._id, navigate]);

  const renderReachoutCreated = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.REACHOUT_CREATED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const isCurrentUserSender = payload.sender.userId === userData._id;

    return (
      <>
        <div className={styles.title}>
          {isCurrentUserSender ? (
            <>
              You reached out to{" "}
              <span className={styles.pseudonym}>
                {payload.recipient.pseudonym}
              </span>
            </>
          ) : (
            <>
              <span className={styles.pseudonym}>
                {payload.sender.pseudonym}
              </span>{" "}
              reached out to you
            </>
          )}
        </div>
        <div className={styles.metadata}>
          <div className={styles.resourceSection}>
            <div className={styles.resourceType}>
              {formatResourceType(payload.reachoutResource.type)}
            </div>
            <div className={styles.resourceLabel}>
              "{payload.reachoutResource.label}"
            </div>
          </div>
          {payload.message && (
            <div
              className={cn(
                styles.messageSection,
                styles[isCurrentUserSender ? "currentUser" : "otherUser"]
              )}
            >
              {payload.message}
            </div>
          )}
        </div>
      </>
    );
  }, [message, userData._id]);

  const renderReachoutCompleted = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.REACHOUT_COMPLETED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const otherParticipant = getOtherParticipant(
      payload.participants,
      userData._id
    );
    if (!otherParticipant) return null;

    return (
      <div className={styles.title}>
        Reachout completed with{" "}
        <span className={styles.pseudonym}>{otherParticipant.pseudonym}</span>.
        You have stumbled into{" "}
        <span
          className={cn(styles.pseudonym, styles.link)}
          onClick={() => navigate(`/chats?type=chats&id=${payload.chatId}`)}
        >
          {otherParticipant.username}
        </span>
      </div>
    );
  }, [message, userData._id, navigate]);

  const renderReachoutExpired = useCallback(() => {
    if (!isSystemMessageType(message, SYSTEM_MESSAGE_TYPES.REACHOUT_EXPIRED))
      return null;

    const payload = getMessagePayload(message);
    if (!payload) return null;

    const otherParticipant = getOtherParticipant(
      payload.participants,
      userData._id
    );
    if (!otherParticipant) return null;

    return (
      <>
        <div className={styles.title}>
          Reachout with{" "}
          <span className={styles.pseudonym}>{otherParticipant.pseudonym}</span>{" "}
          has expired
        </div>
        <div className={styles.metadata}>
          <div>This reachout is no longer active</div>
          <div>You can no longer send messages in this chat</div>
        </div>
      </>
    );
  }, [message, userData._id]);

  const handleRetry = useCallback(
    (e) => {
      e.stopPropagation(); // Prevent toggling timestamp
      // Implement retry logic here - you'll need to pass a retry function from parent component
      // or dispatch a retry action if using Redux/context
    },
    [message._id]
  );

  const renderContent = useCallback(() => {
    if (message.type === "system") {
      switch (message.content.systemType) {
        case SYSTEM_MESSAGE_TYPES.MATCH_CREATED:
          return renderMatchCreated();
        case SYSTEM_MESSAGE_TYPES.REACHOUT_CREATED:
          return renderReachoutCreated();
        case SYSTEM_MESSAGE_TYPES.MATCH_ACCEPTED:
          return renderMatchAccepted();
        case SYSTEM_MESSAGE_TYPES.MATCH_COMPLETED:
          return renderMatchCompleted();
        case SYSTEM_MESSAGE_TYPES.REACHOUT_ACCEPTED:
          return renderReachoutAccepted();
        case SYSTEM_MESSAGE_TYPES.REACHOUT_COMPLETED:
          return renderReachoutCompleted();
        case SYSTEM_MESSAGE_TYPES.REACHOUT_EXPIRED:
          return renderReachoutExpired();
        case SYSTEM_MESSAGE_TYPES.CONNECTION_ADDED:
          return renderConnectionAdded();
        default:
          console.warn(
            "Unknown system message type:",
            message.content.systemType
          );
          return <div className={styles.systemMessage}>System Message</div>;
      }
    }

    // For user messages
    if (message.content.type === "text") {
      const messageText = message.content.text || message.content.data?.text;

      if (!messageText) {
        console.error("Message content missing text:", message);
        return (
          <div className={styles.errorMessage}>Message content unavailable</div>
        );
      }

      return (
        <div className={styles.messageText}>
          <span className={styles.text}>{messageText}</span>
          {messageSenderType === "currentUser" && (
            <span className={styles.statusContainer}>
              {messageStatus === "pending" ? (
                <FaCheck className={styles.pendingCheck} title="Sending" />
              ) : messageStatus === "failed" ? (
                <span
                  className={styles.failedContainer}
                  title={
                    message.error?.message || "Failed to send. Click to retry."
                  }
                  onClick={handleRetry}
                >
                  <FaExclamationTriangle className={styles.failedIcon} />
                </span>
              ) : (
                <span className={styles.deliveredContainer} title="Delivered">
                  <FaCheck className={styles.deliveredCheck} />
                  <FaCheck className={styles.deliveredCheck} />
                </span>
              )}
            </span>
          )}
        </div>
      );
    }

    // Fallback for unknown message types
    console.warn("Unknown message type:", message);
    return <div className={styles.errorMessage}>Unknown message type</div>;
  }, [
    message,
    messageStatus,
    messageSenderType,
    renderMatchCreated,
    renderMatchAccepted,
    renderMatchCompleted,
    renderReachoutCreated,
    renderReachoutAccepted,
    renderConnectionAdded,
    renderReachoutCompleted,
    renderReachoutExpired,
    message.error,
    handleRetry,
  ]);

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
    exit: {
      y: -10,
      opacity: 0,
      transition: { duration: 0.5, ease: "easeIn" },
    },
  };

  return (
    <div
      ref={ref}
      className={cn(styles.message, {
        [styles.currentUser]: messageSenderType === "currentUser",
        [styles.otherUser]: messageSenderType === "otherUser",
        [styles.system]: messageSenderType === "system",
        [styles[message.content.systemType]]: message.content.systemType,
      })}
    >
      <div className={styles.body} onClick={toggleTimestamp}>
        {renderContent()}
      </div>
      <AnimatePresence>
        {showTimestamp && (
          <motion.div
            key={formattedTimestamp}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
            className={styles.timestamp}
          >
            {relativeTime}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default ChatMessage;
