import * as api from "api/config";

const CONFIG_CACHE_KEY = "app_config";
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const DEFAULT_CONFIG = {
  ageRanges: [
    {
      min: 0,
      max: 9,
      label: "0-9",
      description:
        "Encompasses early childhood, focusing on infancy and early development.",
    },
    {
      min: 10,
      max: 19,
      label: "Teens",
      description:
        "Covers the teenage years, focusing on adolescence and the transition to adulthood.",
    },
    {
      min: 20,
      max: 24,
      label: "Early 20s",
      description:
        "Represents the early twenties, a time for exploration, education, and early career development.",
    },
    {
      min: 25,
      max: 29,
      label: "Late 20s",
      description:
        "Covers the late twenties, often associated with career establishment and personal growth.",
    },
    {
      min: 30,
      max: 39,
      label: "30s",
      description:
        "Encompasses the thirties, often marked by career advancement and family planning.",
    },
    {
      min: 40,
      max: 49,
      label: "40s",
      description:
        "Represents the forties, a period of mid-life reflection and career consolidation.",
    },
    {
      min: 50,
      max: 59,
      label: "50s",
      description:
        "Covers the fifties, often associated with preparing for retirement and life transitions.",
    },
    {
      min: 60,
      max: 69,
      label: "60s",
      description:
        "Encompasses the sixties, focusing on retirement planning and lifestyle changes.",
    },
    {
      min: 70,
      max: 120,
      label: "70+",
      description:
        "Represents the elder years, concentrating on leisure, health management, and legacy considerations.",
    },
  ],
  genders: [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ],
  // Add other default configs here
};

class ConfigService {
  constructor() {
    this.config = null;
  }

  async getConfig() {
    // Try to get cached config first
    const cachedConfig = this.getCachedConfig();
    if (cachedConfig) {
      return cachedConfig;
    }

    // If no cache or expired, fetch fresh config
    return this.fetchAndCacheConfig();
  }

  getCachedConfig() {
    const cached = localStorage.getItem(CONFIG_CACHE_KEY);
    if (!cached) return null;

    const { data, timestamp } = JSON.parse(cached);

    // Check if cache is expired
    if (Date.now() - timestamp > CACHE_DURATION) {
      localStorage.removeItem(CONFIG_CACHE_KEY);
      return null;
    }

    return data;
  }

  async fetchAndCacheConfig() {
    try {
      const config = await api.fetchConfig();

      // Cache the config with timestamp
      localStorage.setItem(
        CONFIG_CACHE_KEY,
        JSON.stringify({
          config,
          timestamp: Date.now(),
        })
      );

      return config;
    } catch (error) {
      console.error("Failed to fetch config:", error);
      // Return default config as fallback
      return this.getDefaultConfig();
    }
  }

  getDefaultConfig() {
    return DEFAULT_CONFIG;
  }
}

export const configService = new ConfigService();
