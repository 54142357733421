import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTimeAgo } from "utils/dates";
import styles from "../Profile.module.css";

/**
 * Component that displays account status information for restricted accounts
 */
const AccountStatusSection = ({
  profileData,
  accountStatusData,
  isOwnProfile,
}) => {
  const navigate = useNavigate();
  // Debug log

  if (!profileData || profileData.status === "active") {
    return null;
  }

  const isSuspended = profileData.status === "suspended";
  const isBanned = profileData.status === "banned";
  const isDeleted = profileData.status === "deleted";

  // Use account status data if available, otherwise fall back to profile data
  const suspensionDetails =
    accountStatusData?.suspensionDetails ||
    profileData?.suspensionDetails ||
    {};
  const banDetails =
    accountStatusData?.banDetails || profileData?.banDetails || {};

  let statusContent = null;

  if (isSuspended) {
    // Account suspended
    const suspensionReason =
      suspensionDetails?.reason ||
      profileData.suspensionReason ||
      "Your account has been temporarily suspended.";

    // Add a fallback for missing end date
    const endDate = suspensionDetails?.suspensionEndDate
      ? new Date(suspensionDetails.suspensionEndDate)
      : null;

    // Calculate remaining time with fallback
    const remainingDays = endDate
      ? Math.max(
          0,
          Math.ceil(
            (endDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
          )
        )
      : null;

    const adminNote = suspensionDetails.adminNote;
    const suspendedBy = suspensionDetails.suspendedBy;
    const suspendedAt = suspensionDetails.suspendedAt
      ? new Date(suspensionDetails.suspendedAt)
      : null;

    statusContent = (
      <>
        <h3>Account Suspended</h3>
        <div className={styles.statusSummary}>
          <p className={styles.statusReason}>{suspensionReason}</p>

          {endDate && (
            <div className={styles.timeRemaining}>
              {remainingDays > 0 ? (
                <span>
                  Your account will be unsuspended in{" "}
                  <strong>
                    {remainingDays} day{remainingDays !== 1 ? "s" : ""}
                  </strong>
                </span>
              ) : (
                <span>
                  Your suspension period has ended. If your account is still
                  suspended, please contact support.
                </span>
              )}
            </div>
          )}
        </div>

        <div className={styles.statusDetailsList}>
          {endDate && (
            <div className={styles.statusDetail}>
              <span className={styles.statusLabel}>Suspension ends:</span>
              <span className={styles.statusValue}>
                {formatDate(endDate)} ({formatTimeAgo(endDate)})
              </span>
            </div>
          )}

          {suspendedAt && (
            <div className={styles.statusDetail}>
              <span className={styles.statusLabel}>Suspended on:</span>
              <span className={styles.statusValue}>
                {formatDate(suspendedAt)} ({formatTimeAgo(suspendedAt)})
              </span>
            </div>
          )}

          {suspensionDetails.duration && (
            <div className={styles.statusDetail}>
              <span className={styles.statusLabel}>Suspension duration:</span>
              <span className={styles.statusValue}>
                {suspensionDetails.duration} day
                {suspensionDetails.duration !== 1 ? "s" : ""}
              </span>
            </div>
          )}

          {adminNote && (
            <div className={styles.statusDetail}>
              <span className={styles.statusLabel}>Admin note:</span>
              <p className={styles.adminNote}>{adminNote}</p>
            </div>
          )}

          <div className={styles.statusDetail}>
            <span className={styles.statusLabel}>Limited Access:</span>
            <p>
              While suspended, you can view your content but cannot create new
              content or interact with others.
            </p>
          </div>
        </div>

        {isOwnProfile && (
          <div className={styles.statusActions}>
            <button
              onClick={() => navigate("/help")}
              className={styles.secondaryButton}
            >
              Contact Support
            </button>
          </div>
        )}
      </>
    );
  } else if (isBanned) {
    // Account banned
    const banReason = banDetails.reason || profileData.banReason;
    const adminNote = banDetails.adminNote;
    const bannedBy = banDetails.bannedBy;
    const bannedAt = banDetails.bannedAt ? new Date(banDetails.bannedAt) : null;

    statusContent = (
      <>
        <h3>Account Banned</h3>
        <div className={styles.statusSummary}>
          <p className={styles.statusReason}>
            {banReason || "Your account has been permanently banned."}
          </p>
        </div>

        <div className={styles.statusDetailsList}>
          {bannedAt && (
            <div className={styles.statusDetail}>
              <span className={styles.statusLabel}>Banned on:</span>
              <span className={styles.statusValue}>
                {formatDate(bannedAt)} ({formatTimeAgo(bannedAt)})
              </span>
            </div>
          )}

          {adminNote && (
            <div className={styles.statusDetail}>
              <span className={styles.statusLabel}>Admin note:</span>
              <p className={styles.adminNote}>{adminNote}</p>
            </div>
          )}

          <div className={styles.statusDetail}>
            <span className={styles.statusLabel}>Limited Access:</span>
            <p>
              While banned, you can view your content but cannot create new
              content or interact with others.
            </p>
          </div>
        </div>

        {isOwnProfile && (
          <div className={styles.statusActions}>
            <button
              onClick={() => navigate("/help")}
              className={styles.secondaryButton}
            >
              Contact Support
            </button>
          </div>
        )}
      </>
    );
  } else if (isDeleted) {
    // Account deleted
    const deletionDetails = accountStatusData?.deletionDetails || {};
    const deletedAt = deletionDetails.deletedAt
      ? new Date(deletionDetails.deletedAt)
      : null;

    statusContent = (
      <>
        <h3>Account Deleted</h3>
        <p>Your account has been deleted.</p>

        {deletedAt && (
          <div className={styles.statusDetail}>
            <span className={styles.statusLabel}>Deleted on:</span>
            <span className={styles.statusValue}>
              {formatDate(deletedAt)} ({formatTimeAgo(deletedAt)})
            </span>
          </div>
        )}

        {isOwnProfile && profileData.isRecoverable && (
          <div className={styles.statusActions}>
            <button
              onClick={() => navigate("/reactivate")}
              className={styles.primaryButton}
            >
              Recover Account
            </button>
          </div>
        )}
      </>
    );
  }

  if (!statusContent) return null;

  return (
    <div id="accountStatusSection" className={styles.accountStatusSection}>
      {statusContent}
    </div>
  );
};

export default AccountStatusSection;
