/**
 * Detects if the current browser is a WebView
 * @returns {boolean}
 */
export const isWebView = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  // Common WebView identifiers
  const webViewIndicators = {
    instagram: userAgent.includes("instagram"),
    facebook: userAgent.includes("fban") || userAgent.includes("fbav"),
    line: userAgent.includes("line"),
    android: userAgent.includes("; wv)"),
    tiktok: userAgent.includes("tiktok"),
    // iOS WKWebView detection
    ios: /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(userAgent),
  };

  return Object.values(webViewIndicators).some(Boolean);
};

/**
 * Gets the specific WebView type if detected
 * @returns {string|null} The WebView type or null if not a WebView
 */
export const getWebViewType = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("instagram")) return "instagram";
  if (userAgent.includes("fban") || userAgent.includes("fbav"))
    return "facebook";
  if (userAgent.includes("line")) return "line";
  if (userAgent.includes("tiktok")) return "tiktok";
  if (userAgent.includes("; wv)")) return "android-webview";
  if (/(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(userAgent))
    return "ios-webview";

  return null;
};

/**
 * Gets the current browser environment type
 * @returns {'webview' | 'mobile-browser' | 'desktop'}
 */
export const getBrowserEnvironment = () => {
  return isWebView()
    ? "webview"
    : /Mobi|Android/i.test(navigator.userAgent)
    ? "mobile-browser"
    : "desktop";
};
