// packages/client/src/pages/Register/Register.jsx
import React, { useCallback, useContext, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { StepUsername } from "./StepUsername/StepUsername";
import { useMultistepForm } from "./hooks/useMultistepForm";
import { StepPersonalInfo } from "./StepPersonalInfo/StepPersonalInfo";
import StepLifeExperiences from "./StepLifeExperiences/StepLifeExperiences";
import StepReview from "./StepReview/StepReview";
import StepTerms from "./StepTerms/StepTerms";
import StepEmailVerification from "./StepEmailVerification/StepEmailVerification";

import styles from "./Register.module.css";
import Button from "components/Button/Button";

import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import { ToastContext } from "contexts/ToastContext";
import { TagsContextProvider } from "contexts/TagsContext";

/**
 * @typedef {Object} FormData
 * @property {string} username - The username of the user.
 * @property {string} birthdate - The birthdate of the user in ISO format.
 * @property {Object} location - The location of the user.
 * @property {string} location.name - The name of the location.
 * @property {string} location.formattedName - The formatted name of the location.
 * @property {string} location.code - The country code of the location.
 * @property {Array<Object>} lifeExperiences - The life experiences of the user.
 * @property {Object} lifeExperiences[].tag - The tag associated with the life experience.
 * @property {string} lifeExperiences[].tag._id - The ID of the tag.
 * @property {string} lifeExperiences[].tag.name - The name of the tag.
 * @property {string} lifeExperiences[].story - The story related to the life experience.
 * @property {Object} lifeExperiences[].date - The date of the life experience.
 * @property {number} lifeExperiences[].date.month - The month of the life experience.
 * @property {number} lifeExperiences[].date.year - The year of the life experience.
 * @property {boolean} lifeExperiences[].searchable - Indicates if the experience is searchable.
 * @property {string} gender - The gender of the user.
 */

const INITIAL_DATA = {
  username: undefined,
  birthdate: undefined,
  location: { name: "", code: "", formattedName: "" },
  lifeExperiences: [],
  gender: "",
  termsAccepted: {
    terms: false,
    privacy: false,
    rules: false,
  },
};

export const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(INITIAL_DATA);
  const { userData, updateUser } = useContext(UserContext);
  const { addToast } = useContext(ToastContext);

  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("idle");

  const [tourState, setTourState] = useState({
    usernameCompleted: false,
    personalInfoCompleted: false,
    lifeExperiencesCompleted: false,
  });

  // Check if user is already registered and redirect if needed
  useEffect(() => {
    if (userData?.isRegistered) {
      // If the user has a username, navigate to their profile
      if (userData.username) {
        navigate(`/user/${userData.username}`);
        addToast("Your account is already registered", "info");
      } else {
        // Otherwise navigate to dashboard
        navigate("/dashboard");
        addToast("Your account is already registered", "info");
      }
    }
  }, [userData, navigate, addToast]);

  // Load saved tour state on mount
  useEffect(() => {
    const savedTourState = localStorage.getItem("registrationTourState");
    if (savedTourState) {
      try {
        setTourState(JSON.parse(savedTourState));
      } catch (e) {
        console.error("Error parsing saved tour state", e);
      }
    }
  }, []);

  const updateFormData = useCallback((fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  }, []);

  const handleStepValidity = useCallback((validity) => {
    setIsCurrentStepValid(validity);
  }, []);

  const handleEmailVerificationClose = () => {
    addToast("Redirecting to find page...", "info");
    setTimeout(() => {
      navigate("/find");
    }, 500);
  };

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    submit,
    updateStepValidation,
  } = useMultistepForm([
    <StepUsername
      key="username"
      data={formData}
      onSave={updateFormData}
      handleStepValidity={handleStepValidity}
      tourCompleted={tourState.usernameCompleted}
      onTourComplete={() => handleTourComplete("username")}
    />,
    <StepPersonalInfo
      key="personal-info"
      data={formData}
      onSave={updateFormData}
      handleStepValidity={handleStepValidity}
      tourCompleted={tourState.personalInfoCompleted}
      onTourComplete={() => handleTourComplete("personalInfo")}
    />,
    <StepLifeExperiences
      key="life-experiences"
      data={formData}
      onSave={updateFormData}
      handleStepValidity={handleStepValidity}
      tourCompleted={tourState.lifeExperiencesCompleted}
      onTourComplete={() => handleTourComplete("lifeExperiences")}
    />,
    <StepReview
      key="review"
      data={formData}
      submissionStatus={submissionStatus}
    />,
    <StepTerms
      key="terms"
      data={formData}
      onSave={updateFormData}
      handleStepValidity={handleStepValidity}
      onAccept={handleAcceptTerms}
      submissionStatus={submissionStatus}
      onBack={handleBack}
    />,
    <StepEmailVerification
      key="email-verification"
      onClose={handleEmailVerificationClose}
    />,
  ]);

  useEffect(() => {
    updateStepValidation(isCurrentStepValid);
  }, [isCurrentStepValid, updateStepValidation]);

  // Update the validation logic for the life experiences step
  useEffect(() => {
    // If we're on the life experiences step (index 2)
    if (currentStepIndex === 2) {
      // Require at least one experience, maximum of 3
      const hasMinimumExperiences = formData.lifeExperiences.length >= 1;
      setIsCurrentStepValid(hasMinimumExperiences);
    }
  }, [currentStepIndex, formData.lifeExperiences]);

  function handleBack() {
    if (!isFirstStep) back();
  }

  function handleNext(e) {
    e.preventDefault();

    // Make sure we're tracking validation correctly
    if (isCurrentStepValid) {
      // Update the validation state for the current step
      handleStepValidity(true);
      next();
    } else {
      // Provide feedback that the form isn't valid yet
      addToast("Please complete all required fields correctly", "error");
    }
  }

  async function handleAcceptTerms() {
    setSubmissionStatus("loading");
    addToast("Submitting registration...", "info");

    try {
      const result = await submit(formData);

      // Check if the result is an error response
      if (result.success === false && result.error) {
        setSubmissionStatus("error");

        // Handle redirect from backend if present
        if (result.error.redirect) {
          addToast("Redirecting...", "info");
          setTimeout(() => {
            navigate(result.error.redirect);
          }, 500);
          return;
        }

        // Otherwise show error message
        addToast(result.error.message || "Registration failed", "error");
        return;
      }

      // Success path
      setSubmissionStatus("success");
      addToast("Registration successful!", "success");

      // Make sure we're updating with the complete user data including status fields
      updateUser({
        ...result.user,
        isRegistered: true, // Explicitly set this flag for immediate use
      });

      next(); // Move to email verification step
    } catch (err) {
      // This should only happen for unexpected errors not caught by the API layer
      setSubmissionStatus("error");
      addToast("An unexpected error occurred", "error");
      console.error("Unexpected error during registration:", err);
    } finally {
      setTimeout(() => {
        setSubmissionStatus("idle");
      }, 2000);
    }
  }

  // Determine if the current step is the email verification step
  const isEmailVerificationStep = currentStepIndex === steps.length - 1;

  // Determine if the current step is the terms step
  const isTermsStep = currentStepIndex === steps.length - 2;

  // Animation variants for page transitions
  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const handleTourComplete = (step) => {
    const updatedState = {
      ...tourState,
      [`${step}Completed`]: true,
    };

    setTourState(updatedState);

    // Save to localStorage for persistence
    localStorage.setItem("registrationTourState", JSON.stringify(updatedState));
  };

  return (
    <TagsContextProvider>
      <form
        className={styles.registerForm}
        onSubmit={(e) => e.preventDefault()}
      >
        {!isEmailVerificationStep && (
          <div className={styles.stepIndicator}>
            <span>
              Step {currentStepIndex + 1} of {steps.length - 1}
            </span>
            <div className={styles.progressBar}>
              <div
                className={styles.progressFill}
                style={{
                  width: `${(currentStepIndex / (steps.length - 2)) * 100}%`,
                }}
              ></div>
            </div>
          </div>
        )}

        <AnimatePresence mode="wait">
          <motion.div
            key={currentStepIndex}
            className={styles.stepContainer}
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            {step}
          </motion.div>
        </AnimatePresence>

        {!isEmailVerificationStep && !isTermsStep && (
          <div className={styles.buttonContainer}>
            {!isFirstStep && (
              <Button
                type="button"
                onClick={handleBack}
                disabled={submissionStatus === "loading"}
                className={styles.backButton}
              >
                Back
              </Button>
            )}
            <Button
              type="button"
              onClick={handleNext}
              disabled={
                !isCurrentStepValid ||
                submissionStatus === "loading" ||
                (currentStepIndex === 2 &&
                  formData.lifeExperiences.length === 0)
              }
              className={styles.nextButton}
            >
              {isLastStep ? "Review" : "Next"}
            </Button>
          </div>
        )}
      </form>
    </TagsContextProvider>
  );
};

export default Register;
