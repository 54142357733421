import React, { useEffect, useState, useMemo, useCallback } from "react";
import styles from "./Profile.module.css";
import useMediaQuery from "hooks/useMediaQuery"; // Import the useMediaQuery hook
import BadgeSection from "./components/BadgeSection";

import PageWrapper from "components/PageWrapper/PageWrapper";
import { IoEllipsisHorizontal } from "react-icons/io5";

import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import LifeExperienceContent from "./LifeExperienceContent";
import StoriesContent from "./StoriesContent";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { formatDate } from "utils/dates";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ErrorDisplay from "components/ErrorDisplay/ErrorDisplay"; // Import ErrorDisplay

import { useUserContext } from "contexts/UserContext";
import ReportModal from "components/ReportModal/ReportModal";

import * as REPORTS_API from "api/reports";
import {
  HiCheckCircle,
  HiXCircle,
  HiExclamationCircle,
  HiOutlineLocationMarker,
} from "react-icons/hi";

import { getScoreDescription } from "utils/scoreUtils";

import { useProfileData } from "./hooks/useProfileData";
import SummaryContent from "./SummaryContent";

// Import components
import ProfileHeader from "./components/ProfileHeader";
import ProfileTabs from "./components/ProfileTabs";
import AccountStatusSection from "./components/AccountStatusSection";
import RegistrationStatusSection from "./components/RegistrationStatusSection";

import * as AUTH_API from "api/auth";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import { getStatusInfo } from "./utils/profileUtils";

const Profile = () => {
  const { userData: currentUser } = useUserContext();
  const { username } = useParams();
  const [activeTab, setActiveTab] = useState("summary");
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [accountStatusData, setAccountStatusData] = useState(null);

  const {
    profileData,
    summaryData,
    badges,
    loading,
    error,
    updateLifeExperiences,
    updateStories,
    loadMoreContent,
  } = useProfileData(username);

  const navigate = useNavigate();
  const location = useLocation();

  const isOwnProfile = currentUser?.username === username;

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    switch (lastSegment) {
      case "experiences":
        setActiveTab("lifeExperiences");
        break;
      case "stories":
        setActiveTab("stories");
        break;

      default:
        setActiveTab("summary");
    }
  }, [location]);

  const handleTabClick = (tab) => {
    navigate(`/user/${username}/${tab}`);
  };

  const handleLifeExperiencesChange = updateLifeExperiences;
  const handleStoriesChange = updateStories;

  const getActionMenuOptions = () => {
    const options = [];

    if (isOwnProfile) {
      options.push({
        label: "Settings",
        onClick: () => navigate("/settings"),
      });
    }

    options.push({
      label: "Report User",
      onClick: () => setIsReportModalOpen(true),
    });

    return options;
  };

  const renderTabs = () => {
    const tabs = [
      { id: "summary", label: "Summary", path: "" },
      { id: "lifeExperiences", label: "Life Experiences", path: "experiences" },
      { id: "stories", label: "Stories", path: "stories" },
    ];

    return (
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${styles.tab} ${
              activeTab === tab.id ? styles.activeTab : ""
            }`}
            onClick={() => handleTabClick(tab.path)}
          >
            {tab.label}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (error) {
      console.error("Profile error:", error);
    }
  }, [error]);

  const renderTabContent = () => {
    // Check if we're still loading
    if (loading) {
      return <LoadingSpinner />;
    }

    // Check if we have profile data
    if (!profileData) {
      return (
        <div className={styles.errorState}>
          <HiExclamationCircle size={32} />
          <p>Unable to load profile data. Please try again later.</p>
        </div>
      );
    }

    try {
      // Simple switch based on tab - no complex nesting
      switch (activeTab) {
        case "summary":
          return (
            <SummaryContent
              summaryData={summaryData}
              profileData={profileData}
              // Only restrict for non-owners of restricted accounts
              isRestricted={isAccountRestricted && !isOwnProfile}
            />
          );
        case "lifeExperiences":
          // If account is restricted and not the owner, show restricted message
          if (isAccountRestricted && !isOwnProfile) {
            return (
              <div className={styles.restrictedContent}>
                <HiExclamationCircle className={styles.restrictedIcon} />
                <h3>Limited Access</h3>
                <p>This user's account is currently restricted.</p>
              </div>
            );
          }

          // Otherwise show normal content
          return (
            <LifeExperienceContent
              profileData={profileData}
              experiences={profileData?.lifeExperiences}
              undiscoveredCount={
                profileData?.undiscoveredLifeExperiencesCount || 0
              }
              onExperiencesChange={handleLifeExperiencesChange}
            />
          );
        case "stories":
          // If account is restricted and not the owner, show restricted message
          if (isAccountRestricted && !isOwnProfile) {
            return (
              <div className={styles.restrictedContent}>
                <HiExclamationCircle className={styles.restrictedIcon} />
                <h3>Limited Access</h3>
                <p>This user's account is currently restricted.</p>
              </div>
            );
          }

          // Otherwise show normal content
          return (
            <StoriesContent
              profileData={profileData}
              stories={profileData?.stories}
              undiscoveredCount={profileData?.undiscoveredStoriesCount || 0}
              onStoriesChange={handleStoriesChange}
            />
          );
        default:
          return null;
      }
    } catch (err) {
      console.error("Error rendering tab content:", err);
      return (
        <div className={styles.errorState}>
          <HiExclamationCircle size={32} />
          <p>There was an error displaying this content.</p>
        </div>
      );
    }
  };

  const handleReportUser = async (reportData) => {
    return REPORTS_API.reportUser({
      targetId: profileData._id,
      ...reportData,
    });
  };

  const renderBadges = () => {
    if (!profileData) return null;

    return (
      <BadgeSection
        userId={profileData._id}
        isCurrentUser={isOwnProfile}
        profileData={profileData}
      />
    );
  };

  // Update the status handling with better null checks
  const isSuspended = profileData && profileData.status === "suspended";
  const isBanned = profileData && profileData.status === "banned";
  const isDeleted = profileData && profileData.status === "deleted";
  const isAccountRestricted =
    profileData && (isSuspended || isBanned || isDeleted);

  // Create a message based on account status
  const getAccountStatusMessage = () => {
    if (isSuspended) {
      return {
        title: "Account Suspended",
        message: "This account is temporarily suspended.",
        details: isOwnProfile
          ? "You can view your content but cannot create new content or interact with others."
          : null,
        icon: HiExclamationCircle,
        className: styles.suspendedBanner,
      };
    } else if (isBanned) {
      return {
        title: "Account Banned",
        message: "This account has been permanently banned.",
        details: isOwnProfile
          ? "You can view your content but cannot create new content or interact with others."
          : null,
        icon: HiXCircle,
        className: styles.bannedBanner,
      };
    } else if (isDeleted) {
      return {
        title: "Account Deleted",
        message: "This account has been deleted.",
        icon: HiXCircle,
        className: styles.deletedBanner,
      };
    }
    return null;
  };

  // Memoize the status message calculation
  const statusMessage = useMemo(
    () => getAccountStatusMessage(),
    [
      isSuspended,
      isBanned,
      isDeleted,
      isOwnProfile,
      profileData?.suspensionReason,
      profileData?.banReason,
    ]
  );

  // Fetch account status data for own profile
  useEffect(() => {
    const fetchAccountStatus = async () => {
      if (
        isOwnProfile &&
        profileData &&
        (profileData.status !== "active" ||
          !profileData.isRegistered ||
          !profileData.isEmailVerified)
      ) {
        try {
          // Use the correct endpoint - now it's /profile
          const statusData = await AUTH_API.getAccountStatus();

          setAccountStatusData(statusData);
        } catch (error) {
          console.error("Error fetching account status:", error);
        }
      }
    };

    fetchAccountStatus();
  }, [isOwnProfile, profileData]);

  return (
    <PageWrapper>
      <div id="profile" className={styles.wrapper}>
        {/* Only render content when profileData is available */}
        {loading ? (
          <LoadingContainer>
            <LoadingMessage message="Loading profile" />
          </LoadingContainer>
        ) : !profileData ? (
          <LoadingContainer>
            <p>Unable to load profile information</p>
          </LoadingContainer>
        ) : (
          <>
            {/* Registration Status Section - Only for own profile */}
            {isOwnProfile && (
              <RegistrationStatusSection profileData={profileData} />
            )}

            {/* Profile Header */}
            <ProfileHeader
              profileData={profileData}
              isOwnProfile={isOwnProfile}
              getActionMenuOptions={getActionMenuOptions}
            />

            {/* Account Status Section - Only for own profile with restricted status */}
            {isOwnProfile && (
              <AccountStatusSection
                profileData={profileData}
                accountStatusData={accountStatusData}
                isOwnProfile={isOwnProfile}
              />
            )}

            {/* Profile Tabs */}
            <ProfileTabs
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />

            {/* Tab Content */}
            <div className={styles.tabContent}>{renderTabContent()}</div>

            {/* Report Modal */}
            {isReportModalOpen && (
              <ReportModal
                isOpen={isReportModalOpen}
                onSubmit={handleReportUser}
                onClose={() => setIsReportModalOpen(false)}
                targetType="User"
                targetData={profileData}
                targetId={profileData._id}
              />
            )}
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default Profile;
