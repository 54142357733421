import { HiCheckCircle, HiXCircle, HiExclamationCircle } from "react-icons/hi";

/**
 * Utility function to get status information for a user
 */
export const getStatusInfo = (
  status,
  registrationStatus,
  isEmailVerified,
  isAdminApproved
) => {
  // Account Status
  const accountStatus = {
    active: { label: "Active", icon: HiCheckCircle, className: "valid" },
    suspended: {
      label: "Suspended",
      icon: HiExclamationCircle,
      className: "warning",
    },
    banned: { label: "Banned", icon: HiXCircle, className: "invalid" },
    deleted: { label: "Deleted", icon: HiXCircle, className: "invalid" },
  };

  // Email Verification Status
  const emailVerificationStatus = isEmailVerified
    ? { label: "Email Verified", icon: HiCheckCircle, className: "valid" }
    : { label: "Email Not Verified", icon: HiXCircle, className: "invalid" };

  // Admin Approval Status
  const adminApprovalStatus = isAdminApproved
    ? { label: "Admin Approved", icon: HiCheckCircle, className: "valid" }
    : {
        label: "Pending Admin Approval",
        icon: HiExclamationCircle,
        className: "warning",
      };

  return {
    account: accountStatus[status] || accountStatus.active,
    emailVerification: emailVerificationStatus,
    adminApproval: adminApprovalStatus,
  };
};
