import React from "react";
import styles from "./ChatSideMenu.module.css";
import { Link } from "react-router-dom";
import cn from "classnames";

const ConnectionCard = ({ connection, userData }) => {
  const isMatch = connection.type === "match";
  const { metadata } = connection;

  if (isMatch) {
    const isUser1 = metadata.participants[0].userId === userData._id;
    const userStory = isUser1
      ? metadata.participants[0].story
      : metadata.participants[1].story;
    const otherStory = isUser1
      ? metadata.participants[1].story
      : metadata.participants[0].story;

    return (
      <div className={cn(styles.connectionItem, styles.matchConnection)}>
        <div className={styles.connectionLabel}>Match</div>
        <div className={styles.matchDetails}>
          <div className={styles.storiesContainer}>
            <div className={styles.storySection}>
              <p className={styles.storyLabel}>Your Story:</p>
              <p className={styles.storyText}>{userStory}</p>
            </div>
            <div className={styles.storySection}>
              <p className={styles.storyLabel}>Their Story:</p>
              <p className={styles.storyText}>{otherStory}</p>
            </div>
          </div>
          <div className={styles.matchMetadata}>
            <span className={styles.similarityScore}>
              {Math.round(metadata.similarity * 100)}% Match
            </span>
            {metadata.sharedTags?.length > 0 && (
              <div className={styles.tags}>
                {metadata.sharedTags.map((tag) => (
                  <span key={tag._id} className={styles.tag}>
                    {tag.name}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <span className={styles.timestamp}>
          {new Date(connection.createdAt).toLocaleDateString()}
        </span>
      </div>
    );
  }

  // Reachout connection
  const { resource, message } = metadata;
  const isAuthor = resource.authorId === userData._id;

  return (
    <div className={cn(styles.connectionItem, styles.reachoutConnection)}>
      <span className={styles.connectionLabel}>Reachout</span>
      <div className={styles.reachoutDetails}>
        <p className={styles.connectionInfo}>
          {isAuthor ? "They reached out about " : "You reached out about "}
          {resource.type === "Story" ? (
            <Link
              to={`/stories/${resource._id}`}
              className={styles.resourceLink}
            >
              "{resource.label}"
            </Link>
          ) : (
            `"${resource.label}"`
          )}
        </p>
        <p className={styles.message}>"{message}"</p>
        {resource.tags?.length > 0 && (
          <div className={styles.tags}>
            {resource.tags.map((tag) => (
              <span key={tag._id} className={styles.tag}>
                {tag.name}
              </span>
            ))}
          </div>
        )}
      </div>
      <span className={styles.timestamp}>
        {new Date(connection.createdAt).toLocaleDateString()}
      </span>
    </div>
  );
};

export default ConnectionCard;
