import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Profile.module.css";
import { HiCheckCircle, HiXCircle, HiExclamationCircle } from "react-icons/hi";
import { getStatusInfo } from "../utils/profileUtils";

/**
 * Component that displays registration status information
 * Only shown for the user's own profile when not fully registered
 */
const RegistrationStatusSection = ({ profileData }) => {
  const navigate = useNavigate();

  if (!profileData) return null;

  // If the user is fully registered, don't show this section
  if (
    profileData.isRegistered &&
    profileData.isEmailVerified &&
    (profileData.isAdminApproved || profileData.isAdminApproved === undefined)
  ) {
    return null;
  }

  // Get status information using the utility function
  const statusInfo = getStatusInfo(
    profileData.status,
    profileData.registrationStatus,
    profileData.isEmailVerified || false,
    profileData.isAdminApproved || false
  );

  return (
    <div id="registrationStatusSection" className={styles.accountStatusSection}>
      <h3>Registration Status</h3>

      <div className={styles.statusDetailsList}>
        {/* Email Verification Status */}
        <div className={styles.statusDetail}>
          <div
            className={`${styles.badge} ${
              styles[statusInfo.emailVerification.className]
            }`}
          >
            <statusInfo.emailVerification.icon />
            <span>{statusInfo.emailVerification.label}</span>
          </div>

          {!profileData.isEmailVerified && (
            <p className={styles.statusValue}>
              Please check your email for a verification link or request a new
              one.
            </p>
          )}
        </div>

        {/* Admin Approval Status - Only if relevant */}
        {profileData.isAdminApproved !== undefined && (
          <div className={styles.statusDetail}>
            <div
              className={`${styles.badge} ${
                styles[statusInfo.adminApproval.className]
              }`}
            >
              <statusInfo.adminApproval.icon />
              <span>{statusInfo.adminApproval.label}</span>
            </div>

            {!profileData.isAdminApproved && (
              <p className={styles.statusValue}>
                Your account is pending approval from an administrator.
              </p>
            )}
          </div>
        )}
      </div>

      {/* Action buttons if needed */}
      {!profileData.isEmailVerified && (
        <div className={styles.statusActions}>
          <button
            onClick={() => navigate("/verify-email")}
            className={styles.primaryButton}
          >
            Resend Verification Email
          </button>
        </div>
      )}
    </div>
  );
};

export default RegistrationStatusSection;
