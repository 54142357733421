import React from "react";
import styles from "./ChatSideMenu.module.css";
import Button from "components/Button/Button";
import cn from "classnames";
import { HiCheckCircle, HiOutlineRefresh } from "react-icons/hi";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const BilateralAcceptancePanel = ({
  type,
  data,
  otherParticipant,
  onAccept,
  isAccepting,
  acceptError,
  userData,
}) => {
  // Early return if no data or not pending
  if (!data?.status || data.status !== "pending" || !userData?._id) return null;

  // Safely find participants with null checks
  const currentUserParticipant =
    data.participants?.find((p) => p?.user?._id === userData._id) || null;

  const otherUserParticipant =
    data.participants?.find((p) => p?.user?._id !== userData._id) || null;

  // Get acceptance statuses with fallbacks
  const hasUserAccepted = currentUserParticipant?.accepted || false;
  const hasOtherAccepted = otherUserParticipant?.accepted || false;

  // Safely get other participant's display name
  const otherUserName = otherParticipant?.pseudonym || "Other participant";

  return (
    <div className={styles.sideMenuSection}>
      <div className={styles.acceptancePanel}>
        {/* Other participant's status */}
        <div
          className={cn(styles.acceptStatus, {
            [styles.accepted]: hasOtherAccepted,
            [styles.pending]: !hasOtherAccepted,
          })}
        >
          {hasOtherAccepted ? (
            <>
              <HiCheckCircle />
              <span>{otherUserName} has accepted</span>
            </>
          ) : (
            <>
              <HiOutlineRefresh />
              <span>Pending {otherUserName}'s acceptance</span>
            </>
          )}
        </div>

        {/* Your acceptance section */}
        {hasUserAccepted ? (
          <div className={cn(styles.acceptStatus, styles.accepted)}>
            <HiCheckCircle />
            <span>You've accepted</span>
          </div>
        ) : (
          <>
            <div className={styles.acceptanceInfo}>
              <p className={styles.acceptanceTitle}>Accepting will:</p>
              <ul className={styles.acceptanceBenefits}>
                <li>
                  <HiCheckCircle />
                  <span>
                    {type === "match"
                      ? "Add matched tags as connections"
                      : "Add this reachout as a connection"}
                  </span>
                </li>
                <li>
                  <HiCheckCircle />
                  <span>Enable calling features</span>
                </li>
                <li>
                  <HiCheckCircle />
                  <span>Allow mutual reviews</span>
                </li>
                <li>
                  <HiCheckCircle />
                  <span>Reveal both users' identities</span>
                </li>
              </ul>
            </div>

            <Button
              onClick={onAccept}
              color="primary"
              className={styles.acceptButton}
              disabled={isAccepting}
              size="small"
              shape="square"
            >
              {isAccepting ? (
                <div className={styles.acceptButtonContent}>
                  <LoadingSpinner size="small" />
                  <span>Accepting...</span>
                </div>
              ) : (
                `Accept ${type === "match" ? "Match" : "Reachout"}`
              )}
            </Button>
            {acceptError && <div className={styles.error}>{acceptError}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default BilateralAcceptancePanel;
