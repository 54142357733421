import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  receiveMessage,
  setReachouts,
  setMatches,
  setChats,
} from "../store/actions"; // Import the action
import { useToastContext } from "./ToastContext";
import socket from "socket";

const SearchStates = {
  INITIALIZING: "initializing",
  SEARCHING: "searching",
  MATCH_FOUND: "match_found",
  WAITING_ACCEPTANCE: "waiting_acceptance",
  MATCH_ACCEPTED: "match_accepted",
  MATCH_COMPLETE: "match_complete",
  MATCH_EXPIRED: "match_expired",
  MATCH_DISCONNECTED: "match_disconnected",
  STOPPED: "stopped",
  ERROR: "error",
};

const SocketContext = createContext();

/**
 * @typedef {Object} MatchedUserScores
 * @property {number} totalReviews - Total number of reviews received
 * @property {number} trustScore - User's trust score (0-5)
 * @property {number} warmthScore - User's warmth score (0-5)
 */

/**
 * @typedef {Object} SharedTag
 * @property {string} _id - Tag ID
 * @property {string} name - Tag name
 */

/**
 * @typedef {Object} MatchedUser
 * @property {string} userId - The matched user's ID
 * @property {string} story - The user's current story
 * @property {string} ageRange - Age range (e.g., "18-25", "26-35")
 * @property {'male' | 'female' | 'other'} gender - User's gender
 * @property {MatchedUserScores} scores - User's review scores
 * @property {SharedTag[]} sharedTags - Tags shared between matched users
 */

const SocketContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToastContext();

  // Connection states
  const [serverOnline, setServerOnline] = useState(false);
  const [loading, setLoading] = useState(true);

  // Core search states
  const [searchState, setSearchState] = useState(null);
  const [searchMetadata, setSearchMetadata] = useState(null);

  // Derived search states
  const [isSearching, setIsSearching] = useState(false);
  const [matchData, setMatchData] = useState(null);
  const [error, setError] = useState(null);
  const [matchComplete, setMatchComplete] = useState(null);
  const [searchPreferences, setSearchPreferences] = useState(null);

  // Server stats
  const [serverStats, setServerStats] = useState({
    onlineCount: 0,
    searchingCount: 0,
    status: "connected",
    lastUpdated: Date.now(),
  });

  // Cleanup function for all search-related states
  const cleanupSearchStates = useCallback(() => {
    setSearchState(null);
    setSearchMetadata(null);
    setIsSearching(false);
    setMatchData(null);
    setError(null);
    setMatchComplete(null);
    setSearchPreferences(null);
  }, []);

  // Socket message sender
  const sendMessage = useCallback((id, message, type = "chat") => {
    if (!["chat", "reachout", "match"].includes(type)) {
      console.error(
        `Invalid message type: ${type}. Must be either "chat", "reachout", or "match".`
      );
      return;
    }

    socket.emit("sendMessage", { id, message, type });
  }, []);

  // Search control functions
  const startSearch = useCallback(
    ({ tags = [], story, ageRanges = [], genders = [] }) => {
      if (!serverOnline) {
        addToast("Cannot search while offline", "error");
        return;
      }
      socket.emit("startSearch", { tags, story, ageRanges, genders });
    },
    [serverOnline, addToast]
  );

  const stopSearch = useCallback(() => {
    socket.emit("stopSearch");
  }, []);

  const acceptMatch = useCallback(() => {
    socket.emit("acceptSearchMatch");
  }, []);

  const rejectMatch = useCallback(() => {
    socket.emit("rejectSearchMatch");
  }, []);

  // Add continueSearch function
  const continueSearch = useCallback(() => {
    if (!serverOnline) {
      addToast("Cannot continue search while offline", "error");
      return;
    }
    socket.emit("continueSearch");
  }, [serverOnline, addToast]);

  // Handle search state changes
  const handleSearchStateChanged = useCallback(({ state, metadata }) => {
    setSearchState(state);
    setSearchMetadata(metadata);
  }, []);

  // Derive states from searchState and searchMetadata changes
  useEffect(() => {
    if (!searchState || !searchMetadata) return;

    switch (searchState) {
      case SearchStates.INITIALIZING:
        setIsSearching(true);
        setSearchPreferences({
          story: searchMetadata.story,
          tags: searchMetadata.tags,
          agePreferences: searchMetadata.agePreferences,
          genderPreferences: searchMetadata.genderPreferences,
          startedAt: searchMetadata.startedAt,
        });
        break;

      case SearchStates.SEARCHING:
        setIsSearching(true);
        setMatchData(null);
        if (searchMetadata.previousMatchRejected) {
          addToast(
            searchMetadata.rejectionType === "you_rejected"
              ? "Finding another match..."
              : "Previous match declined, finding new match...",
            "info"
          );
        }
        break;

      case SearchStates.MATCH_FOUND:
        setMatchData({
          user: searchMetadata.user,
          matchId: searchMetadata.matchId,
          acceptedBy: searchMetadata.acceptedBy || [],
          sharedTags: searchMetadata.sharedTags,
          similarity: searchMetadata.similarity,
          currentUserId: searchMetadata.currentUserId,
        });
        break;

      case SearchStates.MATCH_COMPLETE:
        setMatchComplete({
          matchId: searchMetadata.matchId,
        });
        break;

      case SearchStates.MATCH_EXPIRED:
        setError("expired");
        cleanupSearchStates();
        break;

      case SearchStates.MATCH_DISCONNECTED:
        setError("disconnected");
        cleanupSearchStates();
        break;

      case SearchStates.STOPPED:
        cleanupSearchStates();
        break;

      case SearchStates.ERROR:
        setError(searchMetadata.error?.message || "Unknown error");
        cleanupSearchStates();
        break;

      default:
        console.warn(`Unhandled search state: ${searchState}`);
        break;
    }
  }, [searchState, searchMetadata, addToast, cleanupSearchStates]);

  // Socket connection management
  useEffect(() => {
    const handleConnect = () => {
      setServerOnline(true);
      setLoading(false);
    };

    const handleDisconnect = () => {
      setServerOnline(false);
      setLoading(false);
      cleanupSearchStates();
    };

    const handleConnectError = (error) => {
      setLoading(false);
      setServerOnline(false);
    };

    const handleOptimisticMessage = (message) => {
      dispatch(receiveMessage(message));
    };

    const handleMessageUpdate = (updatedMessage) => {
      dispatch(receiveMessage(updatedMessage));
    };

    const handleNewMessage = (message) => {
      dispatch(receiveMessage(message));
    };

    const handleReachoutAccepted = (reachout) => {
      dispatch(setReachouts([reachout]));
    };

    const handleMatchAccepted = (updatedMatch) => {
      dispatch(setMatches([updatedMatch]));
    };

    const handleMatchCompleted = ({ match, chat }) => {
      dispatch(setMatches([match]));

      dispatch(setChats([chat]));
    };

    const handleAuthError = ({ message, status, redirect }) => {
      addToast(message, "error");
      if (redirect) {
        navigate(redirect);
      }
    };

    const handleServerStats = (stats) => {
      setServerStats({
        ...stats,
        lastUpdated: Date.now(),
      });
    };

    socket.connect();

    // Set up event listeners
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleConnectError);
    socket.on("optimisticMessage", handleOptimisticMessage);
    socket.on("messageUpdate", handleMessageUpdate);
    socket.on("newMessage", handleNewMessage);
    socket.on("searchStateChanged", handleSearchStateChanged);
    socket.on("reachoutAccepted", handleReachoutAccepted);
    socket.on("matchAccepted", handleMatchAccepted);
    socket.on("matchCompleted", handleMatchCompleted);
    socket.on("auth_error", handleAuthError);
    socket.on("serverStats", handleServerStats);

    // Cleanup
    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("connect_error", handleConnectError);
      socket.off("searchStateChanged", handleSearchStateChanged);
      socket.off("newMessage", handleNewMessage);
      socket.off("reachoutAccepted", handleReachoutAccepted);
      socket.off("matchAccepted", handleMatchAccepted);
      socket.off("matchCompleted", handleMatchCompleted);
      socket.off("auth_error", handleAuthError);
      socket.off("serverStats", handleServerStats);
      socket.off("optimisticMessage", handleOptimisticMessage);
      socket.off("messageUpdate", handleMessageUpdate);
      socket.disconnect();
      cleanupSearchStates();
    };
  }, [
    dispatch,
    addToast,
    navigate,
    cleanupSearchStates,
    handleSearchStateChanged,
  ]);

  useEffect(() => {
    if (!socket) return;

    // Server stats listener
    socket.on("serverStats", (stats) => {
      setServerStats({
        onlineCount: stats.onlineCount,
        searchingCount: stats.searchingCount,
        lastUpdated: stats.timestamp,
      });
    });

    return () => {
      socket.off("serverStats");
    };
  }, []);

  const contextValue = {
    socket,
    serverOnline,
    loading,
    sendMessage,
    isSearching,
    startSearch,
    stopSearch,
    matchData,
    error,
    acceptMatch,
    rejectMatch,
    cleanupSearchStates,
    matchComplete,
    searchState,
    searchMetadata,
    searchPreferences,
    continueSearch,
    serverStats,
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to use the SocketContext
const useSocketContext = () => {
  return useContext(SocketContext);
};

export { SocketContext, SocketContextProvider, useSocketContext };
