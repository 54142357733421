import cn from "classnames";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import styles from "./DatePickerInput.module.css";

const DatePickerInput = ({
  className,
  selected,
  value,
  onChange,
  minDate,
  maxDate = new Date(),
  placeholderText = "MM/DD/YYYY",
  disabled = false,
  required = false,
  theme = "dark",
}) => {
  const initialDate = selected || value || null;
  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    const newDate = selected || value || null;
    if (newDate && (!date || newDate.getTime() !== date.getTime())) {
      setDate(newDate);
    }
  }, [selected, value, date]);

  const handleChange = (date) => {
    setDate(date);
    onChange(date);
  };

  return (
    <div className={`${styles.wrapper} ${styles[theme]}`} data-theme={theme}>
      <DatePicker
        placeholderText={placeholderText}
        selected={date}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        icon={<FaCalendarAlt />}
        className={cn(styles.input, className)}
        showMonthDropdown
        showYearDropdown
        showPopperArrow={false}
        dropdownMode="select"
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        dateFormat="MM/dd/yyyy"
        strictParsing
        title="Select your birth date"
        disabled={disabled}
        required={required}
      />
      <span id="birthdate-description" className="sr-only">
        Must be at least 13 years old to register
      </span>
    </div>
  );
};

export default DatePickerInput;
