import React from "react";
import styles from "./LoadingContainer.module.css";
import cn from "classnames";

const LoadingContainer = ({ children, className, fullPage = true }) => {
  return (
    <div
      className={cn(styles.loadingContainer, className, {
        [styles.fullPage]: fullPage,
      })}
    >
      {children}
    </div>
  );
};

export default LoadingContainer;
