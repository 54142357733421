import React, { useEffect, useState } from "react";
import styles from "./StepTerms.module.css";
import { motion } from "framer-motion";
import Button from "components/Button/Button";

const StepTerms = ({
  data,
  onSave,
  handleStepValidity,
  onAccept,
  submissionStatus,
  onBack,
}) => {
  const [checkedItems, setCheckedItems] = useState(
    data.termsAccepted || {
      terms: false,
      privacy: false,
      rules: false,
    }
  );
  const [error, setError] = useState(null);

  const allChecked = Object.values(checkedItems).every(Boolean);
  const isSubmitting = submissionStatus === "loading";

  useEffect(() => {
    handleStepValidity(allChecked);
    onSave({ termsAccepted: checkedItems });
  }, [checkedItems, handleStepValidity, onSave]);

  // Reset error when submission status changes
  useEffect(() => {
    if (submissionStatus === "error") {
      setError(
        "There was a problem completing your registration. Please try again."
      );
    } else {
      setError(null);
    }
  }, [submissionStatus]);

  const handleCheckboxClick = (type) => {
    setCheckedItems((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleSubmit = () => {
    if (allChecked && !isSubmitting) {
      setError(null);
      onAccept();
    }
  };

  return (
    <motion.div
      className={styles.termsContainer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1 className={styles.termsHeader}>One Last Step!</h1>
      <p className={styles.termsSubheader}>
        Before we get started, please review and accept our policies by checking
        each box
      </p>

      <div className={styles.checkboxContainer}>
        <div className={styles.checkboxWrapper}>
          <input
            type="checkbox"
            id="terms"
            checked={checkedItems.terms}
            onChange={() => handleCheckboxClick("terms")}
            className={styles.checkbox}
            disabled={isSubmitting}
          />
          <label htmlFor="terms" className={styles.checkboxLabel}>
            I have read and agree to the{" "}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
          </label>
        </div>

        <div className={styles.checkboxWrapper}>
          <input
            type="checkbox"
            id="privacy"
            checked={checkedItems.privacy}
            onChange={() => handleCheckboxClick("privacy")}
            className={styles.checkbox}
            disabled={isSubmitting}
          />
          <label htmlFor="privacy" className={styles.checkboxLabel}>
            I have read and understand the{" "}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </label>
        </div>

        <div className={styles.checkboxWrapper}>
          <input
            type="checkbox"
            id="rules"
            checked={checkedItems.rules}
            onChange={() => handleCheckboxClick("rules")}
            className={styles.checkbox}
            disabled={isSubmitting}
          />
          <label htmlFor="rules" className={styles.checkboxLabel}>
            I have read and agree to follow the{" "}
            <a href="/rules" target="_blank" rel="noopener noreferrer">
              Community Rules
            </a>
          </label>
        </div>
      </div>

      {error && <div className={styles.errorMessage}>{error}</div>}

      <div className={styles.buttonContainer}>
        <Button
          type="button"
          onClick={onBack}
          disabled={isSubmitting}
          className={styles.backButton}
        >
          Back
        </Button>

        <button
          className={`${styles.acceptButton} ${
            isSubmitting ? styles.loading : ""
          }`}
          disabled={!allChecked || isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting
            ? "Processing..."
            : allChecked
            ? "Complete Registration"
            : "Please review all policies"}
        </button>
      </div>
    </motion.div>
  );
};

export default StepTerms;
